import React, { useEffect, useRef } from "react";
import Chart from "chart.js";
import { allowedChains } from "../../config";
import { useState } from "react";

export function LineChartHelper({ chartData, labelType, showLegend = true, options = {} }) {
	const chartRef = useRef(null);
	const chartInstance = useRef(null);
	const [currentTheme, setCurrentTheme] = useState(window.theme.id); // {{ edit_2 }} State for current theme

	const getLabelType = (value) => {
		switch (labelType) {
			case "username":
				return value.username;
			case "domain":
				return value.domain;
			case "design":
				return value.designName;
			case "chain":
				return value.chainID && allowedChains.find((chain) => chain.chainId === value.chainID)?.name;
			case "module":
				return value.moduleName;
			default:
				return value.domain;
		}
	};

    const getFontColorByTheme = () => {
        switch (currentTheme) { // {{ edit_3 }} Use currentTheme state
            case "greyscale":
                return "white";
            case "light":
            case "coloured":
                return "black";
            case "dark":
                return "white";
            default:
                return "black";
        }
    };

    useEffect(() => {
        const handleThemeChange = () => {
            setCurrentTheme(window.theme.id); // {{ edit_4 }} Update theme state on change
        };

        window.addEventListener("themeChange", handleThemeChange); // {{ edit_5 }} Listen for theme changes

        return () => {
            window.removeEventListener("themeChange", handleThemeChange); // Cleanup listener
        };
    }, []);

	useEffect(() => {
		if (chartRef && chartRef.current && chartData) {
			const ctx = chartRef.current.getContext("2d");

			const labels = generatePast12MonthsLabels();
			const datasets = prepareChartDatasets(chartData, labels);

			const defaultOptions = {
				maintainAspectRatio: false,
				responsive: true,
				legend: {
					display: showLegend,
					labels: {
						fontColor: getFontColorByTheme(),
					},
				},
				tooltips: {
					intersect: false,
				},
				hover: {
					intersect: true,
				},
				plugins: {
					filler: {
						propagate: false,
					},
				},
				scales: {
					xAxes: [
						{
							reverse: true,
							gridLines: {
								color: "rgba(0,0,0,0.05)",
							},
							ticks: {
								fontColor: getFontColorByTheme(),
							},
						},
					],
					yAxes: [
						{
							ticks: {
								stepSize: 500,
								fontColor: getFontColorByTheme(),
							},
							display: true,
							borderDash: [5, 5],
							gridLines: {
								color: "rgba(0,0,0,0.05)",
								fontColor: getFontColorByTheme(),
							},
						},
					],
				},
			};

			if (chartInstance.current) {
				chartInstance.current.destroy();
			}

			chartInstance.current = new window.Chart(ctx, {
				type: "line",
				data: { labels, datasets },
				options: { ...defaultOptions, ...options },
			});
		}

		return () => {
			if (chartInstance.current) {
				chartInstance.current.destroy();
			}
		};
	}, [chartData, options, currentTheme]);

	const generatePast12MonthsLabels = () => {
		const labels = [];
		for (let i = 11; i >= 0; i--) {
			const d = new Date();
			d.setMonth(d.getMonth() - i);
			labels.push(d.toLocaleString("default", { month: "long", year: "numeric" }));
		}
		return labels;
	};

	const prepareChartDatasets = (chartData, labels) => {
		const colors = generateContrastingColors(chartData.length);
		return chartData.map((user, index) => ({
			label: getLabelType(user),
			data: prepareDateForChart(user.monthlyData, labels),
			borderColor: colors[index],
			pointBackgroundColor: colors[index],
			pointHoverBorderColor: colors[index],
			backgroundColor: "transparent",
			pointBorderColor: "#fff",
			pointHoverBackgroundColor: "#fff",
			fill: false,
			tension: 0.4,
			borderWidth: 3,
			pointRadius: 5,
			pointHoverRadius: 7,
		}));
	};

	const prepareDateForChart = (monthlyData, labels) => {
		const dataMap = new Map(monthlyData.map((item) => [`${item.year}-${item.month}`, (item.revenue || 0).toFixed(2)]));
		return labels.map((label) => {
			const [month, year] = label.split(" ");
			const monthIndex = new Date(Date.parse(month + " 1, " + year)).getMonth() + 1;
			return parseFloat(dataMap.get(`${year}-${monthIndex}`) || "0.00");
		});
	};

	const getRandomColor = () => {
		const hue = Math.floor(Math.random() * 360);
		const saturation = Math.floor(Math.random() * 21) + 80; // 80-100%
		const lightness = Math.floor(Math.random() * 11) + 45; // 45-55%
		return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
	};

	return <canvas ref={chartRef} />;
}

export function getGradient(ctx, light = true) {
	const gradient = ctx.createLinearGradient(0, 0, 0, 225);
	if (light) {
		gradient.addColorStop(0, "rgba(215, 227, 244, 1)");
		gradient.addColorStop(1, "rgba(215, 227, 244, 0)");
	} else {
		gradient.addColorStop(0, "rgba(51, 66, 84, 1)");
		gradient.addColorStop(1, "rgba(51, 66, 84, 0)");
	}
	return gradient;
}

const generateContrastingColors = (count) => {
	const goldenRatioConjugate = 0.618033988749895;
	const hueStep = goldenRatioConjugate;
	const saturationRange = [0.6, 0.9];
	const lightnessRange = [0.4, 0.7];

	return Array.from({ length: count }, (_, i) => {
		const hue = (i * hueStep) % 1;
		const saturation = saturationRange[0] + (i % 2) * (saturationRange[1] - saturationRange[0]);
		const lightness = lightnessRange[0] + (Math.floor(i / 2) % 2) * (lightnessRange[1] - lightnessRange[0]);

		return `hsl(${Math.floor(hue * 360)}, ${Math.floor(saturation * 100)}%, ${Math.floor(lightness * 100)}%)`;
	});
};
