import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Hooks/useAuthentication";
import { Link } from "react-router-dom";
import { splitNameservers } from "../../../Helpers/splitNameservers";
import useDomainIcons from "../../../Hooks/useDomainIcons";
import { getDefaultIcon, placeholderIcon } from "../../../Helpers/getDomainIcon";
import { config } from "../../../config";
import DomainStatBoxes from "./DomainStatBoxes";
import DomainChart from "./DomainChart";

export default function ViewAllDomains() {
	const { username, token, loading } = useAuth();
	const [domains, setDomains] = useState([]);
	const domainIcons = useDomainIcons(domains);

	const [domainStats, setDomainStats] = useState({
		totalDomains: 0,
		totalRevenue: 0,
		bestHittingDomain: "",
		highestHitRateDomain: "",
	});

	const [chartData, setChartData] = useState([]);

  const fetchDomains = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/fpanel/user/getWebsites`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ token, username }),
      });

      if (!response.ok) {
        window.notyf.open({
          type: "danger",
          message: "Failed fetching domains!",
          duration: 4000,
        });
      }
      const data = await response.json();
      const processedDomains = data.data.map((domain) => ({
        ...domain,
        icon: domainIcons[domain.domain],
        nameserversHTML: domain.connectionNameservers ? splitNameservers(domain.connectionNameservers) : "N/A", // Check if connectionNameservers exists
      }));
      setDomains(processedDomains || []);
      const domainStats = data?.domainStats;
      setDomainStats({
        totalDomains: domainStats?.totalDomains || 0,
        totalRevenue: domainStats?.totalRevenue ? domainStats?.totalRevenue.toFixed(2) : 0,
        bestHittingDomain: domainStats?.bestHitsDomain?.domain || "?",
        highestHitRateDomain: `${domainStats?.bestHitRateDomain?.domain ? domainStats?.bestHitRateDomain?.domain : "?"} (${domainStats?.bestHitRateDomain?.hitRate ? domainStats?.bestHitRateDomain?.hitRate.toFixed(2) : 0}%)` || "?",
      });
      setChartData(data?.domainChartData || []);
    } catch (error) {
      console.error("Error fetching domains:", error);
    }
  };

	useEffect(() => {
		console.log(`Domain Stats`, domainStats);
	}, [domainStats]);

	useEffect(() => {
		if (loading) return;
		fetchDomains();
	}, [loading, token, username]); // Ensure domainIcons is stable or included in dependencies if dynamic

	if (loading) return <div>Loading...</div>;

	function makeStatusBadge(status) {
		switch (status) {
			case "active":
				return '<span class="badge bg-success rounded-pill">Active</span>';
			case "inactive":
				return '<span class="badge bg-secondary rounded-pill">Inactive</span>';
			case "pending-setup":
				return '<span class="badge bg-warning rounded-pill text-light">Pending Setup</span>';
			case "banned":
				return '<span class="badge bg-danger rounded-pill">Banned</span>';
			default:
				return '<span class="badge bg-danger rounded-pill">??</span>';
		}
	}

	const checkNameservers = async (domain) => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/checkNameservers`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ username, token, domain }),
			});

			if (!response.ok) {
				throw new Error("Failed to check nameservers");
			}

			const data = await response.json();

			// Update the domain status in the local state
			setDomains((prevDomains) => prevDomains.map((d) => (d.domain === domain ? { ...d, status: data.status } : d)));

			window.notyf.open({
				type: "success",
				message: "Nameservers checked successfully",
				duration: 3000,
			});

      await fetchDomains();
		} catch (error) {
			console.warn(error);
			window.notyf.open({
				type: "danger",
				message: error.message || "Failed to check nameservers state",
				duration: 3000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">My Domains</h1>
							<Link to="/panel/domains/add" className="btn btn-primary align-self-center">
								Add Domain <i className="fas fa-plus"></i>
							</Link>
						</div>

						<div className="row">
							<DomainStatBoxes title="Total Domains" value={domainStats.totalDomains ? domainStats.totalDomains : 0} icon="hash" />
							<DomainStatBoxes title="Total Revenue" value={`$${domainStats.totalRevenue ? domainStats.totalRevenue : 0}`} icon="shopping-bag" />
							<DomainStatBoxes title="Best Hitting Domain" value={domainStats.bestHittingDomain ? domainStats.bestHittingDomain : "N/A"} icon="sidebar" />
							<DomainStatBoxes title="Highest Hit Rate Domain" value={domainStats.highestHitRateDomain ? domainStats.highestHitRateDomain : "N/A"} icon="sidebar" />
						</div>

						<div className="row">
							<DomainChart chartData={chartData} />
						</div>

						<div className="row">
							<div className="col-12 col-xl-12">
								<div className="card">
									<div className="table-responsive">
										<table className="table table-striped table-hover">
											<thead>
												<tr>
													<th>Domain</th>
													<th>Connection Type</th>
													<th>Nameservers</th>
													<th>Design</th>
													<th>Status</th>
													<th>Connects</th>
													<th>Hits</th>
													<th>Total Revenue</th>
													<th>Manage</th>
												</tr>
											</thead>
											<tbody>
												{domains && domains.length > 0 ? (
													domains.map((domain, index) => (
														<tr key={index}>
															<td style={{ position: "relative", height: "100%", padding: "10px" }}>
																{domainIcons[domain.domain] && (domainIcons[domain.domain].startsWith("http") || domainIcons[domain.domain].startsWith("/img")) ? (
																	<img
																		src={domainIcons[domain.domain]}
																		alt="Domain Icon"
																		style={{
																			width: "30px",
																			height: "30px",
																			marginRight: "10px",
																			display: "inline-block",
																			verticalAlign: "middle",
																		}}
																	/>
																) : (
																	<div
																		dangerouslySetInnerHTML={{ __html: domainIcons[domain.domain] }}
																		style={{ display: "inline-block", verticalAlign: "middle", marginRight: "10px", marginLeft: "10px" }}
																	/>
																)}
																<p style={{ margin: 0, display: "inline-block", verticalAlign: "middle" }}>{domain.domain ? <>
																	<a href={`https://${domain.domain}`} target="_blank" className="text-dark text-decoration-none"><i className="fas fa-external-link-alt"></i></a>
																	<span className="ms-2">{domain.domain}</span>
																</> : "N/A"}</p>
															</td>
															<td style={{ textTransform: "capitalize" }}>{domain.connectionType}</td>
															<td
																dangerouslySetInnerHTML={domain.connectionNameservers ? { __html: splitNameservers(domain.connectionNameservers) } : { __html: "N/A" }}
															></td>
															<td style={{ textTransform: "capitalize" }}>{domain.designName}</td>
															<td style={{ textTransform: "capitalize" }}>
																<span dangerouslySetInnerHTML={{ __html: makeStatusBadge(domain.status) }}></span>
																{domain.connectionType == 'nameservers' && (
																	<button className="btn btn-sm btn-link ml-2" onClick={() => checkNameservers(domain.domain)} title="Refresh nameservers status">
																		<i className="fas fa-sync-alt"></i>
																	</button>
																)}
															</td>
															<td>{domain.connects}</td>
															<td>{domain.hits}</td>
															<td>${domain.revenue ? parseFloat(domain.revenue).toFixed(2) : 0}</td>
															<td>
																<Link to={`manage/${domain.domain}`}>
																	<button className="btn btn-primary rounded-pill">
																		<i data-feather="edit"></i>
																	</button>
																</Link>
																<Link to={`/panel/domains/${domain.domain}/logs`} className="btn btn-primary rounded-pill ms-2">
																	<i className="fa-solid fa-history"></i>
																</Link>
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="12" className="text-center">
															No domains found
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
