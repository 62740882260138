import React, { useState, useEffect } from "react";
import { useAuth } from "../../Hooks/useAuthentication";
import { config, allowedChains, chainMappingRPCs } from "../../config";
import { getLevelFromXP, useXPSystem, getStyledUsername } from "../../Hooks/useXPSystem";
import { rewardPotPositionAmounts } from "../../config";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { formatNumber } from "../../Helpers/formatNumber";
import TopBar from "./Sections/Header/TopBar";
import Menu from "./Sections/Header/Menu";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { useCallback } from "react";
import { useMemo } from "react";
import { formatDateTimeForInput, formatDateTimeForDisplay } from "../../Helpers/epochToString";

export default function RewardPot() {
	const { token, username, loading } = useAuth();
	const [endTime, setEndTime] = useState(null);
	const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
	const [potChain, setPotChain] = useState(0);

	const calculateTimeLeft = useCallback(() => {
		if (!endTime) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

		const now = new Date().getTime();
		const difference = endTime - now;

		if (difference <= 0) {
			return { days: 0, hours: 0, minutes: 0, seconds: 0 };
		}

		const days = Math.floor(difference / (1000 * 60 * 60 * 24));
		const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((difference % (1000 * 60)) / 1000);

		return { days, hours, minutes, seconds };
	}, [endTime]);

	const [potAmount, setPotAmount] = useState(0);
	const [potAmountUSD, setPotAmountUSD] = useState(0);
	const [totalParticipants, setTotalParticipants] = useState(0);
	const [nextPayoutDate, setNextPayoutDate] = useState(null);
	const [largestPayout, setLargestPayout] = useState(0);
	const [totalSuccessfulPayouts, setTotalSuccessfulPayouts] = useState(0);
	const [rewardPotAddress, setRewardPotAddress] = useState("");

	const [showTransactions, setShowTransactions] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [pagination, setPagination] = useState({
		currentPage: 1,
		totalPages: 1,
		totalTransactions: 0,
	});

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [leaderboard, setLeaderboard] = useState([]);

	const getTheme = () => {
		return document.body.getAttribute("data-theme") || window.theme?.id || "light";
	};

	const fetchRewardPotData = useCallback(
		async (page) => {
			if (!token) return;

			try {
				const response = await fetch(`${config.apiUrl}/fpanel/user/getRewardPotCountdown`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						token: token,
						username: username,
						page: page,
					}),
				});

				if (!response.ok) {
					throw new Error("Failed to fetch reward pot data");
				}

				const data = await response.json();

				setPotAmount(data?.data?.potAmount);
				setPotAmountUSD(data?.data?.potAmountUSD);
				setTotalParticipants(data?.data?.totalEligibleParticipants);
				setLargestPayout(data?.data?.largestPayout);
				setTotalSuccessfulPayouts(data?.data?.totalSuccessfulPayouts);

				setTransactions(data?.data?.transactions);
				setPagination(data?.data?.pagination);
				setTotalPages(data?.data?.pagination?.totalPages || 1);

				setRewardPotAddress(data?.data?.rewardPotAddress);

				setPotChain(data?.data?.potChainID);

				// When setting the nextPayoutDate in your fetchRewardPotData function
				const nextPayoutEpoch = data?.data?.nextPayoutTime;
				if (nextPayoutEpoch) {
					setEndTime(nextPayoutEpoch * 1000); // Store as milliseconds for countdown
					setNextPayoutDate(nextPayoutEpoch); // Store as seconds (epoch)
				} else {
					setEndTime(null);
					setNextPayoutDate(null);
				}

			} catch (error) {
				console.error("Error fetching reward pot data:", error);
			}
		},
		[token, username]
	);

	const sendReminder = async (page) => {
		if (!token) return;

		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/setRewardPotReminder`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					token: token,
					username: username,
				}),
			});

			if (!response.ok) {
				throw new Error("Limit exceeded please try again later");
			}

			const data = await response.json();
			window.notyf.open({
				type: "success",
				message: "Reminder sent",
				duration: 4000,
			});
		} catch (error) {
			console.error("Error fetching reward pot data:", error);
			window.notyf.open({
				type: "danger",
				message: error.message || "Failed to send reminder",
				duration: 4000,
			});
		}
	};

	useEffect(() => {
		const updateTheme = () => {
			const theme = getTheme();
			const rewardPotSection = document.querySelector(".reward-pot-section");
			if (rewardPotSection) {
				rewardPotSection.className = `reward-pot-section py-5 theme-${theme}`;
			}
		};

		updateTheme();

		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === "attributes" && mutation.attributeName === "data-theme") {
					updateTheme();
				}
			});
		});

		observer.observe(document.body, { attributes: true });

		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		if (loading || !token) return;
		fetchRewardPotData(currentPage);
	}, [fetchRewardPotData, currentPage, loading, token]);

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, [calculateTimeLeft]);

	useEffect(() => {
		if (loading || !token) return;

		const fetchLeaderboardData = async () => {
			try {
				const response = await fetch(`${config.apiUrl}/fpanel/user/getLeaderBoard`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						token: token,
						username: username,
						website: "test",
					}),
				});

				const data = await response.json();

				if (data && data.success) {
					setLeaderboard(data.data.slice(0, 3) || []);
				} else {
					throw new Error("Failed to fetch leaderboard data");
				}
			} catch (error) {
				console.error("Error fetching leaderboard:", error);
			}
		};

		fetchLeaderboardData();
	}, [token, loading, username]);

	const goalAmount = 10; // 10 ETH goal
	const progressPercentage = Math.min((potAmount / goalAmount) * 100, 100).toFixed(2);

	const handlePageChange = async (page) => {
		setCurrentPage(page);
		fetchRewardPotData(page);
	};

	const makePosition = (index) => {
		switch (index) {
			case 1:
				return <span className="badge bg-success rounded-pill p-2">{index}</span>;
			case 2:
				return <span className="badge bg-primary rounded-pill p-2">{index}</span>;
			case 3:
				return <span className="badge bg-warning rounded-pill p-2">{index}</span>;
			default:
				return <span className="badge bg-secondary rounded-pill p-2">{index}</span>;
		}
	};

	const makeExplorerLink = (chainID, address, isTx = false) => {
		var matchingChain = chainMappingRPCs[chainID]?.explorerUrl;
		if (matchingChain) {
			return `${matchingChain}/${isTx ? "tx" : "address"}/${address}`;
		}
		return false;
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Reward Pot</h1>
						</div>

						<div className="alert alert-primary alert-outline-coloured alert-dismissible" role="alert">
							<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
							<div className="alert-icon">
								<i className="far fa-fw fa-bell" />
							</div>
							<div className="alert-message">
								The <strong>top 3 users every 2 weeks</strong> will receive a reward. The size of the reward will be determined by the size of this pot. Places 1,2 and 3 will be split
								according to your position.
							</div>
						</div>

						<div className="row row-reward-pot-section">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className={`reward-pot-section py-5 theme-${getTheme()}`}>
											<div className="container">
												<div className="text-center mb-5">
													<p className="section-subtitle">Track real-time rewards and upcoming payouts</p>
													<a
														href={makeExplorerLink(potChain, rewardPotAddress)}
														target="_blank"
														rel="noopener noreferrer"
														className="viewtxs"
													>
														{rewardPotAddress} <i className="fa-solid fa-link" />
													</a>
												</div>

												{!showTransactions ? (
													<>

														<div className="reward-cards-container">

															<div className="reward-card mb-3">
																<div className="card-header">
																	<h3>Recent Payout</h3>
																	<span className="badge bg-primary">Previous</span>
																</div>
																<div className="card-body">
																	{transactions && transactions.length > 0 ? (
																		<ul className="list-group list-group-flush">
																			<li className="list-group-item">
																				<strong>Date:</strong> {timeAgo(transactions[0].datetime)}
																			</li>
																			<li className="list-group-item">
																				<strong>Winner:</strong> {getStyledUsername(transactions[0].usersUsername, getLevelFromXP(transactions[0].userXP)?.name)}
																			</li>
																			<li className="list-group-item">
																				<strong>Amount:</strong> {transactions[0].amount} <ChainLogo chainID={1} showSymbol={true} />
																			</li>
																			<li className="list-group-item">
																				<strong>TX Link:</strong> <a href={makeExplorerLink(potChain, transactions[0].tx_hash, true)} target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-link text-dark"></i></a>
																			</li>
																		</ul>
																	) : (
																		<p className="text-muted">No payouts recorded yet.</p>
																	)}
																</div>
															</div>

															<div className="reward-card current-pot">
																<div className="card-header">
																	<h3>Current Pot</h3>
																	<span className="badge badge-success">Active</span>
																</div>
																<div className="card-body">
																	<div className="pot-amount">
																		<ChainLogo chainID={1} showSymbol={true} />
																		<span className="amount">{potAmount ? parseFloat(potAmount).toFixed(2) : 0}</span>
																	</div>
																	<p className="usd-equivalent">≈ ${potAmountUSD ? formatNumber(potAmountUSD) : 0} USD</p>
																	<div className="progress-container">
																		<div className="progress">
																			<div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
																		</div>
																		<span className="progress-text">
																			{progressPercentage}% of {goalAmount} ETH goal
																		</span>
																	</div>
																</div>
																<div className="card-footer">
																	<button className="btn btn-primary btn-block" onClick={() => setShowTransactions(true)}>
																		View Payouts
																	</button>
																</div>
															</div>

															<div className="reward-card countdown">
																<div className="card-header">
																	<h3>Next Payout</h3>
																	<span className="badge badge-warning">Upcoming</span>
																</div>
																<div className="card-body">
																	<div className="countdown-grid">
																		{[
																			{ label: "Days", value: timeLeft.days },
																			{ label: "Hours", value: timeLeft.hours },
																			{ label: "Minutes", value: timeLeft.minutes },
																			{ label: "Seconds", value: timeLeft.seconds },
																		].map((item, index) => (
																			<div key={index} className="countdown-item">
																				<span className="countdown-value">{item.value}</span>
																				<span className="countdown-label">{item.label}</span>
																			</div>
																		))}
																	</div>
																	<p className="payout-date">
																		Estimated payout
																		<span className="payout-date-value d-block w-100 h3 blur mt-2">{nextPayoutDate ? formatDateTimeForDisplay(nextPayoutDate) : "N/A"}</span>
																	</p>
																</div>
																<div className="card-footer">
																	<button className="btn btn-primary btn-block" onClick={() => sendReminder()}>
																		Set Reminder <i className="fab fa-telegram" />
																	</button>
																</div>
															</div>
														</div>

														<div className="reward-stats mt-5">
															<div className="stat-card">
																<i className="fas fa-users stat-icon"></i>
																<div className="stat-content">
																	<span className="stat-value">{totalParticipants ? totalParticipants.toLocaleString() : 0}</span>
																	<span className="stat-label">Total Participants</span>
																</div>
															</div>
															<div className="stat-card">
																<i className="fas fa-chart-line stat-icon"></i>
																<div className="stat-content">
																	<span className="stat-value">{largestPayout ? parseFloat(largestPayout).toFixed(3) : 0}</span>
																	<span className="stat-label">Largest Payout</span>
																</div>
															</div>
															<div className="stat-card">
																<i className="fas fa-calendar-check stat-icon"></i>
																<div className="stat-content">
																	<span className="stat-value">{totalSuccessfulPayouts ? totalSuccessfulPayouts : 0}</span>
																	<span className="stat-label">Successful Payouts</span>
																</div>
															</div>
														</div>
													</>
												) : (
													<div className="transactions-section">
														<div className="card">
															<div className="card-header">
																<div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">
																	<h3 className="mb-3 mb-md-0 text-center text-md-start">Previous Payouts</h3>
																	<button className="btn btn-primary" onClick={() => setShowTransactions(false)}>
																		<i className="fa-solid fa-arrow-left me-2"></i> Back to Reward Pot
																	</button>
																</div>
															</div>
															<div className="card-body">
																<div className="table-responsive">
																	<table className="table table-striped">
																		<thead>
																			<tr>
																				<th>Date</th>
																				<th>Winner</th>
																				<th>Amount</th>
																				<th>Position</th>
																				<th>TX Link</th>
																			</tr>
																		</thead>
																		<tbody>
																			{transactions &&
																				transactions.length > 0 &&
																				transactions.map((transaction, index) => (
																					<tr key={index}>
																						<td>{transaction.datetime ? timeAgo(transaction.datetime) : "N/A"}</td>
																						<td>
																							{transaction.usersUsername
																								? getStyledUsername(transaction.usersUsername, getLevelFromXP(transaction.userXP)?.name)
																								: "N/A"}
																						</td>
																						<td>
																							{transaction.amount} <ChainLogo chainID={1} showSymbol={true} />
																						</td>
																						<td>{transaction.winner_position}</td>
																						<td>
																							{transaction.tx_hash ? (
																								<span className="badge bg-primary rounded text-white">
																									<a
																										href={makeExplorerLink(potChain, transaction.tx_hash, true)}
																										target="_blank"
																										rel="noopener noreferrer"
																										className="text-white"
																									>
																										<i className="fas fa-external-link-alt"></i>
																									</a>
																								</span>
																							) : (
																								"N/A"
																							)}
																						</td>
																					</tr>
																				))}
																		</tbody>
																	</table>
																</div>
																<footer className="footer bg-light w-100 overflow-auto">
																	<div className="d-flex justify-content-between align-items-center mb-2">
																		<span>
																			Showing {Math.min((currentPage - 1) * 10 + 1, transactions.length)}-{Math.min(currentPage * 10, transactions.length)} of{" "}
																			{transactions.length} results
																		</span>
																	</div>
																	<nav className="w-100">
																		<ul className="pagination">
																			<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
																				<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
																					Previous
																				</button>
																			</li>
																			{totalPages > 0 &&
																				Array.from({ length: totalPages }, (_, i) => (
																					<li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
																						<button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
																							{i + 1}
																						</button>
																					</li>
																				))}
																			<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
																				<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
																					Next
																				</button>
																			</li>
																		</ul>
																	</nav>
																</footer>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>

									<div className="table-responsive">
										<table className="table table-striped" style={{ width: "100%" }}>
											<thead>
												<tr>
													<th>Position</th>
													<th>Percentage</th>
													<th>Username</th>
													<th>Hits</th>
													<th>Connects</th>
													<th>Revenue</th>
												</tr>
											</thead>
											<tbody>
												{leaderboard &&
													leaderboard.length > 0 ?
													leaderboard.map((user, index) => (
														<tr>
															<td>{makePosition(index + 1)}</td>
															<td>{rewardPotPositionAmounts[index]?.percenatge}%</td>
															<td style={{ minWidth: "120px" }}>
																<img
																	className="bg-dark rounded-circle"
																	style={{ verticalAlign: "middle", marginRight: "10px" }}
																	src={user.avatarURL && user.avatarURL !== null ? user.avatarURL : "img/newLogoLight.png"}
																	width={32}
																	height={32}
																	alt="Avatar"
																/>
																<p style={{ display: "inline", marginBottom: 0, verticalAlign: "middle" }}>
																	{/* Use getStyledUsername to display styled username */}
																	{getStyledUsername(user.username, user.levelName)}
																</p>
															</td>
															<td>{user.total_hits}</td>
															<td>{user.total_connects}</td>
															<td>
																<span className="badge bg-primary rounded-pill p-2">${user.total_hit_price ? parseFloat(user.total_hit_price).toFixed(2) : 0}</span>
															</td>
														</tr>
													)) : (
														<tr>
															<td colSpan={12} style={{ textAlign: "center" }}>No eligible participants found</td>
														</tr>
													)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
