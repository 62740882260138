import React, { useEffect, useState } from "react";
import { config } from "../../config";
import { myCssEditorPlugin, myAnimationPlugin, myCustomJsEditorPlugin, mySmoothScrollPlugin } from "./GrapesJSPlugins";

// Function to convert full HTML to body content and CSS
const convertToBodyContent = (fullHtml) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(fullHtml, "text/html");

	// Extract CSS from <style> tags
	const styles = Array.from(doc.querySelectorAll("style"))
		.map((style) => style.innerHTML)
		.join("\n");

	// Extract content from <body>
	const bodyContent = doc.body.innerHTML;

	return { bodyContent, styles };
};

const selectedLayerStyle = `
	.gjs-layer-selected {
		background-color: rgba(0, 0, 0, 0.5) !important; /* Darker background for selected layer */
	}
`;

export default function Gasperee({ inputHTML = null }) {
	const [code, setCode] = useState(inputHTML || '<div className="txt-red">Hello world!</div>');
	const [isModalOpen, setModalOpen] = useState(false);
	const [editor_, setEditor] = useState(null);

	useEffect(() => {
		const { bodyContent, styles } = convertToBodyContent(inputHTML || ""); // Convert inputHTML if provided

		const editor = window.grapesjs.init({
			container: "#gjs",
			components: bodyContent || "",
			style: styles || "",
			height: "100%",
			plugins: [
				"grapesjs-preset-webpage",
				"grapesjs-custom-code",
				"@silexlabs/grapesjs-fonts",
				"gjs-blocks-basic",
				"grapesjs-plugin-forms",
				"grapesjs-tui-image-editor",
				"grapesjs-style-gradient",
				"grapesjs-blocks-flexbox",
				"grapesjs-templates",
				'grapesjs-page-break',
				'grapesjs-tailwind',
				myCssEditorPlugin,
				myAnimationPlugin,
				myCustomJsEditorPlugin,
				mySmoothScrollPlugin,
			],
			pluginsOpts: {
				"grapesjs-preset-webpage": {
					// options
				},
				"grapesjs-custom-code": {
					// options
				},
				"@silexlabs/grapesjs-fonts": {
					api_key: "...",
				},
				"grapesjs-tui-image-editor": {
					config: {
						includeUI: {
							initMenu: "filter",
						},
					},
				},
				"grapesjs-advance-components": {
					components: {},
				},
				"grapesjs-templates": {
					// Add our own endpoint to load designs here
					//templates: "http://localhost:3000/templates",
					//projects: "http://localhost:3000/projects",
				},
				'gjs-blocks-basic': {
					stylePrefix: '', // no gjs- prefix
					flexGrid: 1 // use flexbox instead of tables
				}
			},
			canvas: {
				styles: [
					'https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css',
					'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css',
					'https://unpkg.com/lenis@1.1.13/dist/lenis.css'
				],
				scripts: [
					'https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/js/bootstrap.bundle.min.js',
					'/js/scrollObserver.js',
					'https://unpkg.com/lenis@1.1.13/dist/lenis.min.js'
				],
			},
			assetManager: {
				assets: [
					'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css'
				],
			},
			storageManager: false,
			allowScripts: true
		});

		setEditor(editor);

		// ------------------------------------------------------------------------------------------------

		editor.on("layer:component", (model) => {
			const layers = editor.Layers;

			const component = editor.getSelected(); // Get the currently selected component

			if (!component) {
				console.warn("No component is currently selected."); // Log a warning if no component is selected
				return; // Exit the function if component is undefined
			}

			const layerId = model.getId(); // Get the ID of the selected component

			// Change the background of the selected layer to a darker color
			const layerEl = document.querySelector(`.gjs-layer[data-gjs-id="${layerId}"]`);
			if (layerEl) {
				layerEl.classList.add("gjs-layer-selected"); // Add the selected class to the layer element
			}

			// Remove the selected class from other layers
			const allLayers = document.querySelectorAll(".gjs-layer");
			allLayers.forEach((layer) => {
				if (layer !== layerEl) {
					layer.classList.remove("gjs-layer-selected"); // Remove the selected class from other layers
				}
			});
		});

		// Update the code state when the editor changes
		editor.on("component:update", () => {
			console.log(`Component updated in here`);
			setCode(editor.getHtml());
		});

		// Function to save HTML content to a file
		const saveToFile = () => {
			const htmlContent = editor.getHtml();
			const cssContent = editor.getCss();
		
			// Retrieve the custom JavaScript from localStorage
			const customJs = localStorage.getItem('customJs') || '';
		
			const scrollObserverScript = `
			<script>
			(function() {
				const animateCSS = (element, animation, prefix = 'animate__') =>
					new Promise((resolve, reject) => {
						const animationName = \`\${prefix}\${animation}\`;
						element.classList.add(\`\${prefix}animated\`, animationName);
				  
						function handleAnimationEnd(event) {
							event.stopPropagation();
							element.classList.remove(\`\${prefix}animated\`, animationName);
							resolve('Animation ended');
						}
				  
						element.addEventListener('animationend', handleAnimationEnd, {once: true});
					});
			
				const setupScrollAnimation = (el) => {
					if (!el) {
						console.error("Element not found:", el);
						return;
					}
			
					const animationClass = el.getAttribute("data-animation");
					const showIfInScroll = el.getAttribute("data-show-on-scroll") === "true";
					const reshowOnScroll = el.getAttribute('re-show-on-scroll') === 'true';
					let isAnimating = false;
					let hasAnimated = false;
			
					const observer = new IntersectionObserver((entries) => {
						entries.forEach((entry) => {
							if (showIfInScroll && animationClass) {
								if (entry.isIntersecting && entry.intersectionRatio >= 0.5 && !isAnimating) {
									if (!hasAnimated || reshowOnScroll) {
										isAnimating = true;
										animateCSS(el, animationClass.replace('animate__', '')).then(() => {
											isAnimating = false;
											hasAnimated = true;
										});
									}
								} else if (!entry.isIntersecting) {
									// Reset hasAnimated when element is out of view
									hasAnimated = false;
								}
							}
						});
					}, { threshold: [0, 0.5] }); // Trigger at start, middle, and when fully visible
			
					observer.observe(el);
				};
			
				document.addEventListener('DOMContentLoaded', () => {
					document.querySelectorAll('[data-animation]').forEach(el => {
						const showOnScroll = el.getAttribute('data-show-on-scroll') === 'true';
						const animationClass = el.getAttribute('data-animation');
			
						if (animationClass && animationClass !== 'default') {
							if (showOnScroll) {
								setupScrollAnimation(el);
							} else {
								el.classList.add('animate__animated', animationClass);
							}
						}
					});
				});
			})();
			</script>
			`;
		
			const fullHtml = `
				<!DOCTYPE html>
				<html lang="en">
				<head>
					<meta charset="UTF-8">
					<meta name="viewport" content="width=device-width, initial-scale=1.0">
					<title>Saved Document</title>
					<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css">
					<style>
						body {
							overflow: auto; /* Allow scrolling */
						}
						${cssContent}
					</style>
					${scrollObserverScript}
				</head>
				<body>
					${htmlContent}
					<script>
						${customJs}
					</script>
					<script src="https://cdn.tailwindcss.com"></script>
				</body>
				</html>
			`;
		
			const blob = new Blob([fullHtml], { type: "text/html" });
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = "index.html";
			link.click();
			URL.revokeObjectURL(link.href);
		};

		// Function to format HTML code
		const formatHTML = (html) => {
			try {
				const formatted = html
				.replace(/>\s*</g, ">\n<") // Add new lines between tags
				.replace(/</g, "\n<") // Add new lines before opening tags
				.replace(/>\s*</g, ">\n<") // Add new lines between tags
				.replace(/(\s*)(<[^/][^>]*>)/g, (match, spaces, tag) => {
					return `${spaces}${tag}`; // Maintain existing spaces
				});
				return formatted.trim(); // Trim leading/trailing whitespace	
			} catch (error) {
				return html;
			}
		};

		// Function to open gradient selection modal with advanced options
		const openGradientModal = () => {
			const modal = document.createElement("div");
			modal.style.position = "fixed";
			modal.style.top = "50%";
			modal.style.left = "50%";
			modal.style.transform = "translate(-50%, -50%)";
			modal.style.zIndex = "1000"; // Ensure it appears above other elements
			modal.innerHTML = `
				<div style="display: flex; flex-direction: column; gap: 15px; 
					width: 400px; padding: 30px; background-color: white; 
					border-radius: 10px; box-shadow: 0 8px 25px rgba(0, 0, 0, 0.4);">
					<h2 style="margin: 0; font-size: 18px; text-align: center;">Advanced Gradient Selector</h2>
					<label style="font-weight: bold;">Angle: 
						<input type="number" id="angle" value="90" style="margin-left: 10px;"> degrees
					</label>
					<div id="colorPickerContainer">
						<label style="font-weight: bold;">Color 1: 
							<input type="color" id="color1" value="#ff0000" style="margin-left: 10px;">
						</label>
					</div>
					<button id="addColorButton">Add Color</button>
					<div style="display: flex; gap: 15px; justify-content: center;">
						<button id="setGradient" type="button" style="background-color: #4CAF50; color: white; border: none; padding: 12px 20px; cursor: pointer; border-radius: 5px; font-size: 16px;">Set Gradient</button>
						<button id="closeModal" type="button" style="background-color: #f44336; color: white; border: none; padding: 12px 20px; cursor: pointer; border-radius: 5px; font-size: 16px;">Close</button>
					</div>
				</div>
			`;
			document.body.appendChild(modal);

			const addColorButton = document.getElementById("addColorButton");
			addColorButton.onclick = () => {
				const container = document.getElementById("colorPickerContainer");
				const newColorPicker = document.createElement("label");
				newColorPicker.style.fontWeight = "bold";
				newColorPicker.innerHTML = `Color ${container.children.length + 1}: 
					<input type="color" value="#000000" style="margin-left: 10px;">`;
				container.appendChild(newColorPicker);
			};

			document.getElementById("setGradient").onclick = (event) => {
				event.preventDefault(); // Prevent default button behavior
				const angle = document.getElementById("angle").value;
				const colors = Array.from(document.querySelectorAll("#colorPickerContainer input[type='color']"))
					.map(input => input.value);
				const gradient = `linear-gradient(${angle}deg, ${colors.join(', ')})`;

				console.log("Setting gradient:", gradient); // Debug log
				// Apply gradient to the selected component
				const selected = editor.getSelected();
				if (selected) {
					selected.addStyle({ "background-image": `${gradient} !important` }); // Add !important
				} else {
					console.error("No component selected to apply gradient.");
				}

				document.body.removeChild(modal); // Close modal
			};

			document.getElementById("closeModal").onclick = () => {
				document.body.removeChild(modal); // Close modal
			};
		};

		// Function to open the image editor directly
		const openImageEditor = () => {
			const selected = editor.getSelected();
			if (selected) {
				editor.runCommand("tui-image-editor", {
					target: selected,
				});
			} else {
				console.error("No component selected to edit.");
				alert("No component selected to edit.");
			}
		};

		// Create save button
		const saveButton = document.createElement("button");
		saveButton.style.borderRadius = "10px";
		saveButton.style.backgroundColor = "white";
		saveButton.style.color = "black";
		saveButton.style.border = "none";
		saveButton.style.cursor = "pointer";
		saveButton.style.fontSize = "16px";
		saveButton.style.fontWeight = "bold";
		saveButton.innerHTML = "💾"; // Use an icon (Unicode character)
		saveButton.title = "Save to index.html";
		saveButton.onclick = saveToFile;

		// Create buttons with icons
		const gradientButton = document.createElement("button");
		gradientButton.style.borderRadius = "10px";
		gradientButton.style.backgroundColor = "white";
		gradientButton.style.color = "black";
		gradientButton.style.border = "none";
		gradientButton.style.cursor = "pointer";
		gradientButton.style.fontSize = "16px";
		gradientButton.style.fontWeight = "bold";
		gradientButton.innerHTML = "🌈"; // Use an icon (Unicode character)
		gradientButton.title = "Open Gradient Selector";
		gradientButton.onclick = openGradientModal;

		const imageButton = document.createElement("button");
		imageButton.style.borderRadius = "10px";
		imageButton.style.backgroundColor = "white";
		imageButton.style.color = "black";
		imageButton.style.border = "none";
		imageButton.style.cursor = "pointer";
		imageButton.style.fontSize = "16px";
		imageButton.style.fontWeight = "bold";
		imageButton.innerHTML = "🖼️"; // Use an icon (Unicode character)
		imageButton.title = "Open Image Editor";
		imageButton.onclick = openImageEditor;

		// Create custom button for top bar
		const XButton = document.createElement("button");
		XButton.style.borderRadius = "10px";
		XButton.style.backgroundColor = "white";
		XButton.style.color = "black";
		XButton.style.border = "none";
		XButton.style.cursor = "pointer";
		XButton.style.fontSize = "16px";
		XButton.style.fontWeight = "bold";
		XButton.style.order = "-100";
		XButton.innerHTML = "🔙";
		XButton.title = "Back to Panel";
		XButton.onclick = () => {
			window.location.href = "http://localhost:3001/panel/domains/view"; // Redirect to the specified URL
		};

		// Append buttons to the gjs-pn-buttons container
		const optionsPanel = document.querySelector(".gjs-pn-panel.gjs-pn-options.gjs-one-bg.gjs-two-color");
		const buttonContainer = optionsPanel.querySelector(".gjs-pn-buttons"); // Select the button container
		if (buttonContainer) {
			buttonContainer.appendChild(saveButton); // Append save button
			buttonContainer.appendChild(gradientButton);
			buttonContainer.appendChild(imageButton);
			buttonContainer.appendChild(XButton);
		} else {
			console.error("Button container not found.");
		}

		// Code Editor Modal
		const modal = document.createElement("div");
		modal.className = "modal";
		modal.style.position = "fixed";
		modal.style.top = "0";
		modal.style.left = "0";
		modal.style.right = "0";
		modal.style.bottom = "0";
		modal.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
		modal.style.zIndex = "1000";
		modal.style.display = "none";

		const modalContent = document.createElement("div");
		modalContent.style.background = "white";
		modalContent.style.padding = "20px";
		modalContent.style.borderRadius = "5px";
		modalContent.style.margin = "100px auto";
		modalContent.style.width = "90%";
		modalContent.style.maxWidth = "900px"; // Increased width for better readability

		const closeModal = document.createElement("span");
		closeModal.innerHTML = "&times;";
		closeModal.style.cursor = "pointer";
		closeModal.style.float = "right";
		closeModal.style.fontSize = "20px";
		closeModal.onclick = () => {
			modal.style.display = "none";
		};

		const modalHeader = document.createElement("h2");
		modalHeader.innerText = "Edit Code";

		const textArea = document.createElement("textarea");
		textArea.value = formatHTML(code);
		textArea.style.width = "100%";
		textArea.style.height = "700px"; // Increased height for better readability
		textArea.style.fontFamily = "monospace";
		textArea.style.fontSize = "16px"; // Increased font size for better readability
		textArea.oninput = (e) => {
			setCode(e.target.value);
		};

		const saveButtonModal = document.createElement("button");
		saveButtonModal.innerText = "Save";
		saveButtonModal.onclick = () => {
			console.log(`Saving code`, textArea.value); // Log the current value of the textarea
			setCode(textArea.value); // Update the state with the latest textarea value
			editor.setComponents(textArea.value); // Set the new components
			modal.style.display = "none";
		};

		modalContent.appendChild(closeModal);
		modalContent.appendChild(modalHeader);
		modalContent.appendChild(textArea);
		modalContent.appendChild(saveButtonModal);
		modal.appendChild(modalContent);
		document.body.appendChild(modal);

		// Function to open the code editor modal
		const openCodeEditor = () => {
			textArea.value = code; // Ensure the textarea has the latest code
			modal.style.display = "block";
		};

		// Create Edit Code button
		const editCodeButton = document.createElement("button");
		editCodeButton.style.borderRadius = "10px";
		editCodeButton.style.backgroundColor = "white";
		editCodeButton.style.color = "black";
		editCodeButton.style.border = "none";
		editCodeButton.style.cursor = "pointer";
		editCodeButton.style.fontSize = "16px";
		editCodeButton.style.fontWeight = "bold";
		editCodeButton.innerHTML = "✏️"; // Use an icon (Unicode character)
		editCodeButton.title = "Edit Code";
		editCodeButton.onclick = openCodeEditor;

		// Append Edit Code button to the options panel
		if (buttonContainer) {
			buttonContainer.appendChild(editCodeButton);
		}

		return () => {
			// Cleanup modal on component unmount
			document.body.removeChild(modal);
			editor.destroy();
		};
	}, []);

	return (
		<>
			<div className="panel__top">
				<div className="panel__basic-actions"></div>
			</div>
			<div id="gjs"></div>
			<div id="blocks"></div>
		</>
	);
}
