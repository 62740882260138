import React, { useState, useEffect } from 'react';
import { allowedChains } from '../config';

const importSvg = (chainName) => {
    return import(`@web3icons/core/svgs/networks/branded/${chainName}.svg`)
        .then(module => module.default)
        .catch(error => {
            console.error('Failed to load SVG:', error);
            return null;
        });
};

export const ChainLogo = ({ chainID, showSymbol = true }) => {
    const [logoUrl, setLogoUrl] = useState(null);
    const [chainSymbol, setChainSymbol] = useState(''); // Change variable name to chainSymbol

    useEffect(() => {
        const chain = allowedChains.find(chain => chain.chainId === chainID);

        if (!chain) {
            setLogoUrl(null);
            setChainSymbol(''); // Update here to reset chainSymbol
            return;
        }

        setChainSymbol(chain.symbol); // Set the chain symbol
        importSvg(chain.web3IconName).then(setLogoUrl);
    }, [chainID]);

    if (!logoUrl) return null;

    return (
        <>
            <img src={logoUrl} alt={chainSymbol} title={chainSymbol} />
            {showSymbol && <span className="ms-2">{chainSymbol}</span>}
        </>
    );
};