import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuthentication";
import { config } from "../../config";
import { useState } from "react";

export default function AddTicket() {
	const auth = useAuth();

	const [ticket, setTicket] = useState({
        subject: "",
        message: "",
        ticketStatus: "awaiting-reply",
        userID: null,
        dateCreated: new Date(),
        lastReplyFrom: "",
        lastReplyUserLevel: "",
        totalChats: 0,
    });

    const dateCreated = ticket && ticket.dateCreated && !isNaN(ticket.dateCreated) ? new Date(ticket.dateCreated * 1000).toISOString().split("T")[0] : "";

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		if (type === "date") {
			setTicket((prevState) => ({
				...prevState,
				[name]: new Date(value).getTime() / 1000, // Convert back to epoch timestamp
			}));
		} else {
			setTicket((prevState) => ({
				...prevState,
				[name]: type === "checkbox" ? checked : value,
			}));
		}
	};

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/admin/addTicket`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: auth.token,
                    username: auth.username,
                    data: ticket,
                }),
            });
            if (!response.ok) {
                throw new Error("Failed to add ticket");
            }
            const data = await response.json();
            window.notyf.open({
                type: "success",
                message: "Ticket added successfully",
                duration: 4000,
            });
        } catch (error) {
            console.error("Error adding ticket:", error);
            window.notyf.open({
                type: "danger",
                message: "Failed to add ticket",
                duration: 4000,
            });
        }
    };

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Add Ticket</h1>
							<div>
								<Link to="/panel/admin/manage-tickets/view" className="btn btn-primary rounded-pill">
									<i className="fa fa-plus"></i> Back
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
										<form onSubmit={handleSubmit}>
											<div className="mb-3">
												<label htmlFor="subject" className="form-label">
													Subject
												</label>
												<input type="text" className="form-control" id="subject" value={ticket.subject} onChange={handleChange} name="subject" />
											</div>
											<div className="mb-3">
												<label htmlFor="message" className="form-label">
													Message
												</label>
												<textarea className="form-control" id="message" rows="3" value={ticket.message} onChange={handleChange} name="message"></textarea>
											</div>
											<div className="mb-3">
												<label htmlFor="status" className="form-label">
													Status
												</label>
												<select className="form-select" id="status" value={ticket.ticketStatus} onChange={handleChange} name="ticketStatus">
													<option value="awaiting-reply">Awaiting Reply</option>
													<option value="open">Open</option>
													<option value="closed">Closed</option>
												</select>
											</div>
											<div className="mb-3">
												<label htmlFor="userID" className="form-label">
													User ID
												</label>
												<input type="text" className="form-control" id="userID" value={ticket.userID} onChange={handleChange} name="userID" />
											</div>
											<div className="mb-3">
												<label htmlFor="dateCreated" className="form-label">
													Date Created
												</label>
												<input type="date" className="form-control" id="dateCreated" value={dateCreated} onChange={handleChange} name="dateCreated" />
											</div>
											<button type="submit" className="btn btn-primary">
												Add Ticket
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
