import { useState, useEffect } from "react";
import { blurUsername } from "../Helpers/blurUsername";

const levels = [
    { name: "Newbie", xp: 250, icon: '<span class="fa-solid fa-baby"></span>', color: "#e600ff", img: '/icons/ranks/newbie.png', description: 'New to the game', xpRange: [0, 500], perks: 'All start on this level + all gain a purple styled name', cssNameStyle: 'newbie-style' },
    { name: "Starter", xp: 500, icon: '<span class="fa-solid fa-walking"></span>', color: "#17a2b8", img: '/icons/ranks/starter.png', description: 'Starter to the game', xpRange: [500, 1000], perks: 'Unlock access to the reward pot & a cyan styled name', cssNameStyle: 'starter-style' },
    { name: "Intermediate", xp: 2500, icon: '<span class="fa-solid fa-running"></span>', color: "#ffc107", img: '/icons/ranks/intermediate.png', description: 'Intermediate to the game', xpRange: [1000, 2500], perks: 'XP Boost & a yellow styled name', cssNameStyle: 'intermediate-style' },
    { name: "Advanced", xp: 5000, icon: '<span class="fa-solid fa-biking"></span>', color: "#28a745", img: '/icons/ranks/advanced.png', description: 'Advanced to the game', xpRange: [2500, 5000], perks: 'All from Intermediate, XP Boost & a green styled name', cssNameStyle: 'advanced-style' },
    { name: "Expert", xp: 20000, icon: '<span class="fa-solid fa-mountain"></span>', color: "#dc3545", img: '/icons/ranks/expert.png', description: 'Expert to the game', xpRange: [5000, 20000], perks: 'All from Advanced, -4% fees & a red styled name', cssNameStyle: 'expert-style' },
];

export function useXPSystem(currentXP, username=null) {
	const [currentLevel, setCurrentLevel] = useState(getLevelFromXP(currentXP));
	const [nextLevel, setNextLevel] = useState(getNextLevel(currentLevel));
	const [levelColor, setLevelColor] = useState(currentLevel.color);

	useEffect(() => {
		console.log(`currentXP`, currentXP);
		const updatedCurrentLevel = getLevelFromXP(currentXP);
		setCurrentLevel(updatedCurrentLevel);
		setNextLevel(getNextLevel(updatedCurrentLevel));
		setLevelColor(updatedCurrentLevel.color);
		console.log(`updatedCurrentLevel`, updatedCurrentLevel);
	}, [currentXP]);

	function getLevelFromXP(currentXP) {
		return levels.reduce((prevLevel, currentLevel, index) => {
			const minXP = index === 0 ? 0 : levels[index - 1].xp;
			if (currentXP >= minXP && currentXP < currentLevel.xp) {
				return currentLevel;
			}
			return prevLevel;
		}, levels[levels.length - 1]);
	}

	function getNextLevel(currentLevel) {
		const index = levels.indexOf(currentLevel);
		console.log(`index`, index);
		return index !== -1 && index + 1 < levels.length ? levels[index + 1] : null;
	}

    // Additional code remains unchanged
	const currentLevelXP = currentLevel.xp;
	const nextLevelXP = nextLevel ? nextLevel.xp : currentLevelXP;
	const xpToNextLevel = nextLevelXP - currentXP;
	const previousLevelXP = levels[levels.indexOf(currentLevel) - 1]?.xp || 0;
	const xpProgress = currentXP - previousLevelXP;
	const xpRange = currentLevelXP - previousLevelXP;
	const xpPercentage = Math.floor((xpProgress / xpRange) * 100);

    return {
        currentLevel,
        nextLevel,
        levelColor,
        xpPercentage: xpPercentage > 100 ? 100 : xpPercentage,
        xpToNextLevel,
        currentXP,
        currentLevelXP,
        nextLevelXP,
        levels,
        highestLevel: levels[levels.length - 1],
		styledUsername: <span className={`${currentLevel.cssNameStyle}`}>{username}</span>,
		styledBluredUsername: <span className={`${currentLevel.cssNameStyle}`}>{blurUsername(username)}</span>
    };
}

export function getLevelFromXP(currentXP) {
    return levels.reduce((prevLevel, currentLevel, index) => {
        const minXP = index === 0 ? 0 : levels[index - 1].xp;
        if (currentXP >= minXP && currentXP < currentLevel.xp) {
            return currentLevel;
        }
        return prevLevel;
    }, levels[levels.length - 1]);
}

export function getStyledUsername(username, levelName) {
    const currentLevel = levels.find(level => level.name === levelName);
    const isTopLevel = levels.slice(-2).some(level => level.name === levelName); // Check if the level is within the top 2
    const style = `${currentLevel.cssNameStyle} ${isTopLevel ? 'font-bold' : ''}`; // Apply bold if top level
    return <span className={style}>{username}</span>;
}

export function getStyledBluredUsername(username, levelName) {
    const currentLevel = levels.find(level => level.name === levelName);
    const isTopLevel = levels.slice(-2).some(level => level.name === levelName); // Check if the level is within the top 2
    const style = `${currentLevel.cssNameStyle} ${isTopLevel ? 'font-bold' : ''}`; // Apply bold if top level
    return <span className={style}>{blurUsername(username)}</span>;
}