import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useAuth} from '../../../Hooks/useAuthentication';
import { config } from '../../../config';

import Menu from '../Sections/Header/Menu';
import TopBar from '../Sections/Header/TopBar';
import { timeAgo } from '../../../Helpers/epochTimeAgo';
import { truncateText } from '../../../Helpers/truncateText';
import { capitalizeFirst } from '../../../Helpers/capitalizeFirst';
import { useXPSystem } from '../../../Hooks/useXPSystem';

export default function AllTickets() {
  const { username, token, loading, userID } = useAuth();
  const [tickets, setTickets] = useState([]); // State to store tickets
  const { styledUsername, styledBluredUsername } = useXPSystem(0, username);

  useEffect(() => {
    if (loading) return;

    const fetchTickets = async () => {
      const response = await fetch(`${config.apiUrl}/fpanel/user/getUserSupportTickets`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ username, token })
      });

      const data = await response.json();
      if (data.success && data.data.length > 0) {
        setTickets(data.data);
      }
    };

    fetchTickets();
  }, [token, loading, username]); // Include username in the dependency array

  const makeTheLastReplyFrom = (ticket) => {
    var displayName = ticket.lastReplyFrom === username ? <span>{styledUsername}</span> : <span>{capitalizeFirst(ticket.lastReplyFrom)}</span>;
    var userLevelBadge = ticket.lastReplyUserLevel ? ticket.lastReplyUserLevel : 'N/A';
    var lastReplyDate = ticket.lastReplyDate ? timeAgo(ticket.lastReplyDate) : 'N/A';
  
    return (
      <>
        {ticket.lastReplyFrom ? (
          <>
            {displayName} <span className={`badge bg-primary ${userLevelBadge === 'admin' ? 'bg-danger' : 'bg-primary'}`}>{userLevelBadge}</span>
            <br />
            <span className="badge bg-primary d-inline-block mt-2 rounded">{lastReplyDate}</span>
          </>
        ) : (
          'N/A'
        )}
      </>
    );
  };

  const makeTicketStatus = (ticket) => {
    var ticketStatus = ticket.ticketStatus;
    console.log(`ticketStatus: ${ticketStatus}`);
		switch (ticketStatus) {
			case "open":
				return <span className="badge bg-primary rounded-pill">Open</span>;
			case "closed":
				return <span className="badge bg-danger rounded-pill">Closed</span>;
			case "awaiting-reply":
				return <span className="badge bg-warning rounded-pill text-light">Awaiting Reply</span>;
			default:
				return <span className="badge bg-secondary rounded-pill">Unknown</span>;
		}
  };

  return (
    <div className="wrapper">
      <Menu />
      <div className="main">
        <TopBar />
        <main className="content">
          <div className="container-fluid p-0">

          <div className="d-flex justify-content-between align-items-center mb-3">
              <h1 className="h3">Support Tickets</h1>
              <Link to="/panel/support/new" className="btn btn-primary rounded-pill"><i className="far fa-plus"></i> New Ticket</Link>
            </div>

            <div className="alert alert-warning alert-outline-coloured alert-dismissible" role="alert">
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
              <div className="alert-icon">
                <i className="far fa-fw fa-bell" />
              </div>
              <div className="alert-message">
                We do our best to answer all tickets within 24 hours. If you need urgent help, please contact us via{' '}
                <span className="fab fa-telegram"></span> Telegram in the bottom left corner.
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Ticket ID</th>
                          <th>Created</th>
                          <th>Status</th>
                          <th>Subject</th>
                          <th>Message</th>
                          <th>Last Reply</th>
                          <th>Total Chats</th>
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tickets && tickets.length > 0 ? tickets.map((ticket) => (
                          <tr key={ticket.id}>
                            <td>{ticket.ticketID}</td>
                            <td>{ticket.dateCreated ? timeAgo(ticket.dateCreated) : 'N/A'}</td>
                            <td>{ticket.ticketStatus ? makeTicketStatus(ticket) : 'N/A'}</td>
                            <td>{ticket.subject}</td>
                            <td style={{ textTransform: 'capitalize' }}>{ticket.message ? truncateText(ticket.message, 30) : 'N/A'}</td>
                            <td>{makeTheLastReplyFrom(ticket)}</td>
                            <td>{ticket.countOfChats}</td>
                            <td>
                              <Link to={`/panel/tickets/${ticket.ticketID}`} className="btn btn-primary rounded-pill">
                                <i className="far fa-eye" />
                              </Link>
                            </td>
                          </tr>
                        )) : (
                          <tr>
                            <td colSpan="12" className="text-center">No tickets found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
