import { Link, useHistory } from "react-router-dom";
import { copyToClipboard } from "../../../Helpers/copyToClipboard";
import { useState, useEffect } from "react";
import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { useAuth } from "../../../Hooks/useAuthentication";
import { config } from "../../../config";
import validateWeb3Address from "../../../Helpers/validateWeb3Address";

export default function AddDomain() {
	const history = useHistory();

	const { username, token, loading } = useAuth();
	const [designs, setDesigns] = useState([]);

	const [connectionType, setConnectionType] = useState("nameservers");
	const [selectedDesign, setSelectedDesign] = useState(null);
	const [domain, setDomain] = useState("");
	const [mainPayoutAddress, setMainPayoutAddress] = useState("");
	const [nameservers, setNameservers] = useState([]);
	const [isAddingDomain, setIsAddingDomain] = useState(false); // State to manage loading spinner for adding domain
	const [isCheckingNameservers, setIsCheckingNameservers] = useState(false); // State to manage loading spinner for checking nameservers

	// CDN type
	const [cdnLinks, setCdnLinks] = useState([]);
	const [isLoadingCdnLinks, setIsLoadingCdnLinks] = useState(false);

	// New zip file uploader
	const [zipFile, setZipFile] = useState(null);

	useEffect(() => {
		if (loading) return;
		fetchDesigns();
	}, [username, token]);

	const fetchDesigns = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/getDesigns`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({
					username,
					token,
				}),
			});
			const data = await response.json();

			if (data && data.success) {
				console.log(data.data);
				// Add the custom design into it manually
				var customDesign = {
					name: "Custom",
					previewImg: `${process.env.REACT_APP_SERVER_URL}/uploads/custom-design.png`,
					id: 999, // 999 == custom
				};
				setDesigns([customDesign, ...(data.data || [])]);
			} else {
				setDesigns([]);
				window.notyf.open({
					type: "danger",
					message: "Failed fetching designs!",
					duration: 4000,
				});
			}
		} catch (error) {
			console.error("Failed to fetch designs:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed fetching designs!",
				duration: 4000,
			});
		}
	};

	// NEW METHOD TO ADD WEBSITE!
	// Adds the website for the user
	const addDesign = async (zipFile) => {
		setIsAddingDomain(true); // Show spinner
		try {
			const formData = new FormData();
			formData.append("token", token);
			formData.append("username", username);
			formData.append("domain", domain);
			formData.append("designId", selectedDesign);
			formData.append("mainPayoutAddress", mainPayoutAddress);
			formData.append("connectionType", connectionType);
			if (zipFile) {
				formData.append("zipFile", zipFile);
			}

			const response = await fetch(`${config.apiUrl}/fpanel/user/addWebsite`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			const data = await response.json();
			if (data.success) {
				window.notyf.open({ type: "success", message: "New domain added successfully!", duration: 4000 });

				// Set the nameservers
				if (connectionType === "nameservers" && data.data && data.data.nameservers) {
					setNameservers(data.data.nameservers);
				} else if (connectionType === "cdn") {
					await fetchCdnLinks();
				}
			} else {
				throw new Error(data.message || "Failed to add domain");
			}
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: error.message || "Failed to add domain!",
				duration: 4000,
			});
		} finally {
			setIsAddingDomain(false); // Hide spinner
		}
	};

	// Submits the next button form
	// should check if selectedDesign is 999 (custom) and then if it is, validate the zip file etc
	const handleSetCustomDesignHTML = async (event) => {
		event.preventDefault();

		// Make sure they have set the domain, connection type and evm payout address, else show error
		if (!domain || !connectionType || !mainPayoutAddress) {
			window.notyf.open({
				type: "danger",
				message: "Please fill in all fields!",
				duration: 4000,
			});
			return;
		}

		if (!validateWeb3Address(mainPayoutAddress)) {
			window.notyf.open({
				type: "danger",
				message: "Please enter a valid EVM payout address!",
				duration: 4000,
			});
			return;
		}

		// Execute the add domain request
		return await addDesign(zipFile);
	};

	/*const handleAddDomain = async (event) => {
		if(event) event.preventDefault(); // Prevent the default form submission behavior

		setIsAddingDomain(true); // Show spinner
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/addWebsite`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					username,
					token,
					domain,
					connectionType,
          mainPayoutAddress,
					designId: selectedDesign, // also send the design id if the connection type is nameservers
					customHTML: customDesignHTML
				}),
			});
			const data = await response.json();
			if (data && data.success) {
				// Set nameservers if available
				if (connectionType === "nameservers" && data.data && data.data.nameservers) {
					setNameservers(data.data.nameservers);
				} else if (connectionType === "cdn") {
					await fetchCdnLinks();
				}
				window.notyf.open({
					type: "success",
					message: "Domain added successfully!",
					duration: 4000,
				});
			} else {
				throw new Error(data.message || "Failed to add domain");
			}
		} catch (error) {
			console.error("Failed to add domain:", error);
			window.notyf.open({
				type: "danger",
				message: error.message || "Failed to add domain",
				duration: 4000,
			});
		}
		setIsAddingDomain(false); // Hide spinner
	};*/

	const checkNameservers = async () => {
		setIsCheckingNameservers(true); // Start loading
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/checkNameservers`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({
					username,
					token,
					domain,
				}),
			});

			const data = await response.json();
			if (data.success) {
				window.notyf.open({
					type: "success",
					message: "Nameservers are visible and set! Redirecting to your domains...",
					duration: 5000,
				});
				setTimeout(() => {
					history.push("/panel/domains/view"); // Redirect to the domains page
				}, 5000);
			} else {
				window.notyf.open({
					type: "danger",
					message: "Nameservers are not visible, check back shortly",
					duration: 4000,
				});
				setTimeout(() => {
					history.push("/panel/domains/view"); // Redirect to the domains page
				}, 3000);
			}
		} catch (error) {
			console.error("Error checking nameservers:", error);
			window.notyf.open({
				type: "danger",
				message: error.message || "Failed to check nameservers!",
				duration: 4000,
			});
		}
		setIsCheckingNameservers(false); // End loading
	};

	const fetchCdnLinks = async () => {
		setIsLoadingCdnLinks(true);
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/getCdnLinks`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({
					username,
					token,
				}),
			});
			const data = await response.json();
			if (data && data.success) {
				setCdnLinks(data?.data?.cdnLinks || []);
			} else {
				throw new Error(data.message || "Failed to fetch CDN links");
			}
		} catch (error) {
			console.error("Failed to fetch CDN links:", error);
			window.notyf.open({
				type: "danger",
				message: error.message || "Failed to fetch CDN links",
				duration: 4000,
			});
		}
		setIsLoadingCdnLinks(false);
	};

	// ---------------------------------------------------------------

	const handleFileChange = (event) => {
		setZipFile(event.target.files[0]);
	};
	// ---------------------------------------------------------------

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<h1 className="h3 mb-3">Add Domain</h1>
						<div className="row">
							<div className="col-12 col-md-12 col-lg-12">
								{isAddingDomain ? (
									<div className="card">
										<div className="card-body">
											<h4 className="h4">Adding domain...</h4>
											<div
												className="spinner-border text-dark me-2 mt-3"
												role="status"
												style={{ width: "20px", height: "20px" }}
											>
												<span className="visually-hidden">Loading...</span>
											</div>
										</div>
									</div>
								) : isCheckingNameservers ? (
									<div className="card">
										<div className="card-body">
											<h4 className="h4">Checking nameservers...</h4>
											<div
												className="spinner-border text-dark me-2 mt-3"
												role="status"
												style={{ width: "20px", height: "20px" }}
											>
												<span className="visually-hidden">Loading...</span>
											</div>
										</div>
									</div>
								) : nameservers.length > 0 ? (
									<div className="card">
										<div className="card-body">
											<h4 className="h4">Nameserver Update Required</h4>
											<h5 className="card-text">Please update your domain's nameservers to the following:</h5>
											<ul className="list-group">
												{nameservers.map((ns, index) => (
													<li
														key={index}
														className="list-group-item"
													>
														<span
															className="badge bg-primary"
															onClick={() => copyToClipboard(ns, "Nameserver copied to clipboard!")}
														>
															{ns}
														</span>
													</li>
												))}
											</ul>
											<small className="text-muted mt-4 d-block">Updating nameservers is essential for domain activation.</small>
											<div
												className="alert alert-primary alert-dismissible mt-2"
												role="alert"
											>
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="alert"
													aria-label="Close"
												/>
												<div className="alert-message">
													<strong>Warning!</strong> Nameservers can take from 2 minutes up to 48 hours to propagate so be patient!
												</div>
											</div>

											{isCheckingNameservers ? (
												<div
													className="spinner-border text-dark me-2 mt-3"
													role="status"
													style={{ width: "20px", height: "20px" }}
												>
													<span className="visually-hidden">Loading...</span>
												</div>
											) : (
												<button
													className="btn btn-primary mt-3"
													onClick={checkNameservers}
												>
													I've updated my nameservers
												</button>
											)}
										</div>
									</div>
								) : (
									<>
										{cdnLinks.length > 0 ? (
											<>
												{/* Show cdn links here */}
												<div className="card">
													<div className="card-body">
														<h4 className="h4">CDN Links</h4>
														<h5 className="card-text">
															Please add our CDN links to your domain's <code>{"<head>"}</code> tag.
														</h5>
														<ul className="list-group">
															{cdnLinks.map((link, index) => (
																<li
																	key={index}
																	className="list-group-item"
																>
																	<span className="badge bg-primary">{link}</span>
																	<i
																		className="fas fa-copy ms-2"
																		style={{ cursor: "pointer" }}
																		onClick={() => copyToClipboard(link, "CDN link copied to clipboard!")}
																	></i>
																</li>
															))}
														</ul>
														<small className="text-muted mt-4 d-block">If you do not include our CDN links, your domain will not work as expected.</small>
														<Link to="/panel/domains/view">
															<button className="btn btn-primary mt-3">Finish</button>
														</Link>
													</div>
												</div>
											</>
										) : (
											<div className="card">
												<div className="card-body">
													<form onSubmit={handleSetCustomDesignHTML}>
														<div className="mb-3">
															<label className="form-label">Domain</label>
															<input
																type="text"
																className="form-control"
																placeholder="google.com"
																value={domain}
																onChange={(e) => setDomain(e.target.value)}
															/>
														</div>
														<div className="mb-3">
															<label className="form-label">Website connection type</label>
															<select
																className="form-select"
																onChange={(e) => setConnectionType(e.target.value)}
															>
																<option value="nameservers">Nameservers (we host, you buy domain)</option>
																<option value="cdn">CDN (you host, you buy domain)</option>
															</select>
														</div>
														<div className="mb-3">
															<label className="form-label">Main EVM Payout Address</label>
															<input
																type="text"
																className="form-control"
																placeholder="0x95222290dd7278aa3ddd389cc1e1d165cc4bafe5"
																value={mainPayoutAddress}
																onChange={(e) => setMainPayoutAddress(e.target.value)}
															/>
														</div>
														<div className="mb-3">
															{connectionType === "nameservers" && (
																<>
																	<div
																		className="alert alert-primary"
																		role="alert"
																	>
																		<div className="alert-message">
																			<strong>Heads up!</strong> You can view all designs{" "}
																			<Link
																				to="/panel/designs"
																				className="text-dark font-italic"
																				style={{ fontStyle: "italic" }}
																			>
																				here
																			</Link>{" "}
																			and request new ones{" "}
																			<Link
																				to="/panel/support/new"
																				style={{ fontStyle: "italic" }}
																				className="text-dark italic"
																			>
																				here
																			</Link>
																			.
																		</div>
																	</div>

																	<h3 className="form-label mb-3">Click a design to select it</h3>
																	<div className="row">
																		{designs.map((design) => (
																			<div className="col-md-6 col-sm-2 col-lg-3">
																				<div
																					className={`card ${selectedDesign === design.id ? "opacity-100" : "opacity-50"} w-100`}
																					style={
																						design.previewImg
																							? {
																									minHeight: "200px",
																									backgroundPosition: "center",
																									backgroundImage: `url(${design.previewImg})`,
																									backgroundSize: "cover",
																									transform: selectedDesign === design.id ? "scale(1.05)" : "scale(1)",
																									transition: "transform 0.3s ease",
																							  }
																							: {
																									backgroundColor: "#19222C",
																									transform: selectedDesign === design.id ? "scale(1.1)" : "scale(1)",
																									transition: "transform 0.3s ease",
																							  }
																					}
																					onClick={() => setSelectedDesign(design.id)} // Set selected design on click
																				>
																					<div className="card-body">
																						<h5 className="badge bg-primary">{design.name}</h5>
																					</div>
																				</div>
																			</div>
																		))}
																	</div>
																</>
															)}
															{connectionType === 'nameservers' && selectedDesign === 999 && (
																<>
																	<div className="form-control mt-3">
																		<input
																			id="zipFileInput"
																			type="file"
																			accept=".zip"
																			onChange={handleFileChange}
																		/>
																		<label htmlFor="zipFileInput">Choose a .zip file</label>
																	</div>
																	<div
																		className="alert alert-warning alert-dismissible mt-3"
																		role="alert"
																	>
																		<button
																			type="button"
																			className="btn-close"
																			data-bs-dismiss="alert"
																			aria-label="Close"
																		/>
																		<div className="alert-message">
																			<strong>Things to keep in mind!</strong>
																			<br />
																			<ul>
																				<li>Only .html, .css and .js files are allowed</li>
																				<li>The .zip file must contain only one .html file</li>
																				<li>Max file size is 200MB per file</li>
																				<li>Max files is 100 files (can be any of the allowed file types above)</li>
																				<li>You can add directories, it will loop through all the files in the directory</li>
																				<li>Don't use encrypted js or html as will add extreme overhead to your file size</li>
																			</ul>
																		</div>
																	</div>
																</>
															)}
														</div>
														<button
															type="submit"
															className="btn btn-primary"
														>
															Next
														</button>
													</form>
												</div>
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
