import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../../config";
import {useAuth} from "../../../Hooks/useAuthentication";
import { copyToClipboard } from "../../../Helpers/copyToClipboard";

export default function Blacklist() {
	const { username, token, loading } = useAuth();
    const [blacklists, setBlacklists] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (loading) return;
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.apiUrl}/fpanel/user/blacklists`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        username,
                        token,
                        page,
                        perPage,
                    }),
                });
                const data = await response.json();
                if (data.success) {
                    setBlacklists(data.blacklists);
                    setPage(data.currentPage);
                    setTotalPages(data.totalPages); // Update total pages
                } else {
                    console.error(data.message);
                    window.notyf.open({
                        message: data.message || "Failed to fetch blacklists",
                        type: "error",
                        duration: 4000,
                    });
                }
            } catch (error) {
                console.error("Failed to fetch blacklists:", error);
                window.notyf.open({
                    message: "Failed to fetch blacklists",
                    type: "error",
                    duration: 4000,
                });
            }
        };
    
        fetchData();
    }, [page, perPage, username, token]);

    // Function to handle page change
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    
    // Handle blacklist delete
    const handleDelete = async (id) => {
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/user/delete-blacklist`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    username,
                    token,
                    blacklistId: id,
                }),
            });
            const data = await response.json();
            if (data.success) {
                // Remove the deleted blacklist from the state
                setBlacklists(blacklists.filter(blacklist => blacklist.id !== id));
                window.notyf.open({
                    message: "Blacklist successfully deleted",
                    type: "success",
                    duration: 4000,
                });
            } else {
                throw new Error(data.message || "Failed to delete blacklist");
            }
        } catch (error) {
            console.error("Delete blacklist error:", error);
            window.notyf.open({
                message: error.message || "Failed to delete blacklist",
                type: "error",
                duration: 4000,
            });
        }
    };

	return (
		<>
			<div className="wrapper">
				<Menu />
				<div className="main">
					<TopBar />
					<main className="content">
						<div className="container-fluid p-0">
							<div className="d-flex justify-content-between align-items-center mb-3">
								<h1 className="h3">My Blacklists</h1>
								<Link to="/panel/blacklists/new" className="btn btn-primary rounded">
									<i className="far fa-plus"></i> New Blacklist
								</Link>
							</div>
							<div className="row">
								<div className="col-12 col-lg-12">
									<div className="card">
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>Type (IP / Address</th>
														<th>Value</th>
														<th>Delete</th>
													</tr>
												</thead>
												<tbody>
													{blacklists && blacklists.length > 0 ? blacklists.map((blacklist) => (
														<tr key={blacklist.id}>
															<td><span className="badge bg-primary rounded-pill">{blacklist.blacklistType}</span></td>
															<td><span className="badge bg-primary rounded-pill">{blacklist.blacklistValue}</span> <a onClick={() => copyToClipboard(blacklist.blacklistValue)}><i className="far fa-copy"></i></a></td>
															<td>
																<button onClick={() => handleDelete(blacklist.id)} className="btn btn-danger rounded w-100">
																	<i className="fa-solid fa-times" />
																</button>
															</td>
														</tr>
													)) : (
														<tr>
															<td colSpan="3" className="text-center">No blacklists found</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
                                        <footer className="footer bg-light w-100">
										<div className="w-100">
											<div className="pagination">
												<nav aria-label="Page navigation example">
													<ul className="pagination">
														<li className={`page-item ${page === 1 ? "disabled" : ""}`}>
															<button className="page-link" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
																Previous
															</button>
														</li>
														<li className="page-item active">
															<span className="page-link">
																Page {page}
																<span className="sr-only">(current)</span>
															</span>
														</li>
														<li className="page-item">
															<button className="page-link" onClick={() => handlePageChange(page + 1)} disabled={page >= totalPages}>
																Next
															</button>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</footer>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</>
	);
}
