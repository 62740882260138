import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../Hooks/useAuthentication';

function Logout() {
  const { clearAuth } = useAuth();
  const history = useHistory();

  useEffect(() => {
    console.log("Logging out...");
    clearAuth();
    console.log("Redirecting to login...");
    setTimeout(() => {
      history.push('/login');
    }, 0);
  }, [clearAuth, history]);

  return null;
}

export default Logout;