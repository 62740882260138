import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { useParams } from "react-router-dom";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { timeAgo } from "../../Helpers/epochTimeAgo";

export default function ManageCloudflare() {
	const auth = useAuth();
	const { domainId } = useParams();
	const [nameserverState, setNameserverState] = useState(null);
	const [domainZoneDetails, setDomainZoneDetails] = useState(null);
	const [dnsRecords, setDnsRecords] = useState([]);
	const [dnsRecordsLastUpdated, setDnsRecordsLastUpdated] = useState(null);
	const [lastUpdated, setLastUpdated] = useState(Math.floor(Date.now() / 1000));

	// Edit dns
	const [showEditModal, setShowEditModal] = useState(false);
	const [editingRecord, setEditingRecord] = useState({
		recordType: "CNAME",
		name: null,
		content: null,
		ttl: null,
		proxied: true,
	});

	// Add new dns
	const [showAddModal, setShowAddModal] = useState(false);
	const [addingRecord, setAddingRecord] = useState({
		recordType: "CNAME",
		name: domainZoneDetails && domainZoneDetails.name,
		content: null,
		ttl: null,
		proxied: true,
	});

	// Confirm delete site popup
	const [showDeleteSiteModal, setShowDeleteSiteModal] = useState(false);
	const [deleteSiteCheckbox, setDeleteSiteCheckbox] = useState(false);

	const handleEditDNSRecord = (record) => {
		setEditingRecord({
			id: record.id,
			recordType: record.type,
			name: record.name,
			content: record.content,
			ttl: record.ttl,
			proxied: record.proxied,
		});
		setShowEditModal(true);
	};

	const handleAddDNSRecord = () => {
		console.log(`Adding DNS record for ${domainZoneDetails.name}`);
		setAddingRecord({
			recordType: "CNAME",
			name: domainZoneDetails ? domainZoneDetails.name : "",
			content: null,
			ttl: null,
			proxied: true,
		});
		setShowAddModal(true);
	};

	const fetchNsState = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/cloudflareDomain_checkNameserverState`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					domainId: domainId,
					token: auth.token,
					username: auth.username,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch nameserver state");
			}
			const data = await response.json();
			setNameserverState(data?.state?.message || "Unknown");
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: error.message || "Error fetching nameserver state",
				duration: 4000,
			});
		}
	};

	const fetchDomainZoneDetails = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/cloudflareDomain_getZoneDetails`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					domainId: domainId,
					token: auth.token,
					username: auth.username,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch domain zone details");
			}
			const data = await response.json();
			setDomainZoneDetails(data?.zoneDetails || null);
			setLastUpdated(Math.floor(Date.now() / 1000));
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: error.message || "Error fetching domain zone details",
				duration: 4000,
			});
		}
	};

	const fetchDNSRecords = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/cloudflareDomain_getDNSRecords`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					domainId: domainId,
					token: auth.token,
					username: auth.username,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch DNS records");
			}
			const data = await response.json();
			// Handle both object and array cases
			setDnsRecords(Array.isArray(data?.dnsRecords) ? data.dnsRecords : data?.dnsRecords ? [data.dnsRecords] : []);
			setDnsRecordsLastUpdated(Math.floor(Date.now() / 1000));
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: error.message || "Error fetching DNS records",
				duration: 4000,
			});
		}
	};

	useEffect(() => {
		if (auth.loading) return;
		fetchNsState();
		fetchDomainZoneDetails();
		fetchDNSRecords();
	}, [domainId, auth.token, auth.username]);

	const handleSaveDNSRecord = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/cloudflareDomain_changeDNS`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					domainId: domainId,
					token: auth.token,
					username: auth.username,
					...editingRecord,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to update DNS record");
			}
			await fetchDNSRecords();
			setShowEditModal(false);
			window.notyf.success("DNS record updated successfully");
		} catch (error) {
			window.notyf.error(error.message || "Error updating DNS record");
		}
	};

	if (auth.loading) {
		return (
			<div className="text-center mt-5">
				<div className="spinner-border" role="status"></div>
			</div>
		);
	}

	const handleDeleteDNSRecord = async (recordId) => {
		if (!recordId) {
			window.notyf.error({
				type: "danger",
				message: "No record ID provided",
				duration: 4000,
			});
			return;
		}
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/cloudflareDomain_deleteDNS`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					domainId: domainId,
					token: auth.token,
					username: auth.username,
					recordId: recordId,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to delete DNS record");
			}
			await fetchDNSRecords();
			window.notyf.success({
				type: "success",
				message: "DNS record deleted successfully",
				duration: 4000,
			});
		} catch (error) {
			window.notyf.error({
				type: "danger",
				message: error.message || "Error deleting DNS record",
				duration: 4000,
			});
		}
	};

	// Add new dns record
	const handleAddDNS = async () => {
		if (!addingRecord || !addingRecord.recordType || !addingRecord.name || !addingRecord.content || !addingRecord.ttl || !addingRecord.proxied) {
			window.notyf.error({
				type: "danger",
				message: "No record details provided",
				duration: 4000,
			});
			return;
		}
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/cloudflareDomain_addDNS`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					domainId: domainId,
					token: auth.token,
					username: auth.username,
					...addingRecord,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to add DNS record");
			}
			await fetchDNSRecords();
			window.notyf.success({
				type: "success",
				message: "DNS record added successfully",
				duration: 4000,
			});
		} catch (error) {
			window.notyf.error({
				type: "danger",
				message: error.message || "Error adding DNS record",
				duration: 4000,
			});
		}
	};

	// Add new dns record
	const handleDeleteSite = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/cloudflareDomain_deleteSiteAdmin`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					domainId: domainId,
					token: auth.token,
					username: auth.username,
					deleteSiteFromDomains: deleteSiteCheckbox ? true : false,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to delete site from cloudflare");
			}
			await fetchDNSRecords();
			window.notyf.success({
				type: "success",
				message: "Deleted site from cloudflare",
				duration: 4000,
			});
		} catch (error) {
			window.notyf.error({
				type: "danger",
				message: error.message || "Error deleting site from cloudflare",
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Domain {domainId}'s Cloudflare</h1>
							<div>
								<Link to={`/panel/admin/manage-domains/view`} className="btn btn-primary rounded-pill">
									<i className="fas fa-arrow-left me-2"></i> Back
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-xl-6">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title mb-0">Nameserver State</h5>
									</div>
									<div className="card-body">
										<p className="mb-0">
											Current state:{" "}
											<span className={`badge bg-${nameserverState && nameserverState.includes("is now active") ? "success" : "warning"} text-light`}>{nameserverState}</span>
										</p>
									</div>
								</div>
							</div>
							<div className="col-12 col-xl-6">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title mb-0">Actions</h5>
									</div>
									<div className="card-body">
										<button className="btn btn-danger btn-sm" onClick={() => setShowDeleteSiteModal(true)}>
											<i className="fas fa-trash-alt me-2"></i> Delete Site From Cloudflare
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header d-flex justify-content-between align-items-center">
								<h5 className="card-title mb-0">
									Zone Details <i className="fa-solid fa-refresh ms-2" style={{ cursor: "pointer" }} onClick={fetchDomainZoneDetails}></i>
								</h5>
								<div className="d-flex align-items-center">
									<span className="text-muted">Last Updated: {new Date(lastUpdated * 1000).toLocaleString()}</span>
									<small className="text-muted ms-2 badge bg-light text-dark">{timeAgo(lastUpdated)}</small>
								</div>
							</div>
							<div className="card-body">
								{domainZoneDetails ? (
									<table className="table table-striped table-hover">
										<tbody>
											<tr>
												<th>Domain Name</th>
												<td>
													<span className="badge bg-primary" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(domainZoneDetails.name)}>
														{domainZoneDetails.name}
													</span>{" "}
													<i className="fa-solid fa-copy ms-2" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(domainZoneDetails.name)}></i>
												</td>
											</tr>
											<tr>
												<th>Status</th>
												<td>
													<span className={`badge bg-${domainZoneDetails.status && domainZoneDetails.status == "active" ? "success" : "warning"}`}>
														{domainZoneDetails.status}
													</span>
												</td>
											</tr>
											<tr>
												<th>Paused</th>
												<td>
													<span className={`badge bg-${domainZoneDetails.paused ? "danger" : "success"}`}>{domainZoneDetails.paused ? "Yes" : "No"}</span>
												</td>
											</tr>
											<tr>
												<th>Type</th>
												<td>
													<span className="badge bg-info">{domainZoneDetails.type}</span>
												</td>
											</tr>
											<tr>
												<th>Name Servers</th>
												<td>
													{domainZoneDetails.name_servers && domainZoneDetails.name_servers.map((ns, index) => (
														<>
															<span
																key={index}
																className={`badge bg-light text-dark me-1 ${index > 0 ? " ms-2" : ""}`}
																style={{ cursor: "pointer" }}
																onClick={() => copyToClipboard(ns)}
															>
																{ns}
															</span>{" "}
															<i className="fa-solid fa-copy" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(ns)}></i>
														</>
													))}
												</td>
											</tr>
											<tr>
												<th>Original Name Servers</th>
												<td>
													{domainZoneDetails.original_name_servers && domainZoneDetails.original_name_servers.map((ns, index) => (
														<>
															<span
																key={index}
																className={`badge bg-light text-dark me-1 ${index > 0 ? " ms-2" : ""}`}
																style={{ cursor: "pointer" }}
																onClick={() => copyToClipboard(ns)}
															>
																{ns}
															</span>{" "}
															<i className="fa-solid fa-copy" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(ns)}></i>
														</>
													))}
												</td>
											</tr>
											<tr>
												<th>Original Registrar</th>
												<td>
													<span className="badge bg-light">{domainZoneDetails.original_registrar ? domainZoneDetails.original_registrar : "N/A"}</span>
												</td>
											</tr>
											<tr>
												<th>Created On</th>
												<td>
													<span className="text-muted">{domainZoneDetails.created_on ? domainZoneDetails.created_on : "N/A"}</span>
												</td>
											</tr>
											<tr>
												<th>Activated On</th>
												<td>
													<span className="text-muted">{domainZoneDetails.activated_on ? domainZoneDetails.activated_on : "N/A"}</span>
												</td>
											</tr>
											<tr>
												<th>Account Name</th>
												<td>
													<span className="badge bg-info">{domainZoneDetails.account.name ? domainZoneDetails.account.name : "N/A"}</span>
												</td>
											</tr>
											<tr>
												<th>Phishing Detected</th>
												<td>
													<span className={`badge bg-${domainZoneDetails.meta.phishing_detected ? "danger" : "success"}`}>
														{domainZoneDetails.meta.phishing_detected ? "Yes" : "No"}
													</span>
												</td>
											</tr>
										</tbody>
									</table>
								) : (
									<p className="text-muted">No zone details available.</p>
								)}
							</div>
						</div>

						{/* New DNS Records Section */}
						<div className="card mt-4">
							<div className="card-header d-flex justify-content-between align-items-center">
								<h5 className="card-title mb-0">
									DNS Records
									<i className="fa-solid fa-refresh ms-2" style={{ cursor: "pointer" }} onClick={fetchDNSRecords}></i>
									<button className="btn btn-primary rounded btn-sm ms-2" onClick={handleAddDNSRecord}>
										<i className="fa fa-plus"></i>
									</button>
								</h5>
								{dnsRecordsLastUpdated && (
									<div className="d-flex align-items-center">
										<span className="text-muted">Last Updated: {new Date(dnsRecordsLastUpdated * 1000).toLocaleString()}</span>
										<small className="text-muted ms-2 badge bg-light text-dark">{timeAgo(dnsRecordsLastUpdated)}</small>
									</div>
								)}
							</div>
							<div className="card-body">
								{dnsRecords.length > 0 ? (
									<div className="table-responsive">
										<table className="table table-striped table-hover">
											<thead>
												<tr>
													<th>ID</th>
													<th>Type</th>
													<th>Name</th>
													<th>Content</th>
													<th>TTL</th>
													<th>Proxied</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
												{dnsRecords &&
													dnsRecords.length > 0 &&
													dnsRecords.map((record, index) => (
														<tr key={index}>
															<td>
																<span className="badge bg-primary">{record.id}</span>{" "}
																<i className="fa-solid fa-copy ms-2" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(record.id)}></i>
															</td>
															<td>
																<span className="badge bg-primary">{record.type}</span>
															</td>
															<td>
																{record.name} <i className="fa-solid fa-copy ms-2" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(record.name)}></i>
															</td>
															<td>
																<span className="text-break">{record.content}</span>
																<i className="fa-solid fa-copy ms-2" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(record.content)}></i>
															</td>
															<td>{record.ttl === 1 ? "Auto" : record.ttl}</td>
															<td>
																<span className={`badge bg-${record.proxied ? "success" : "warning"}`}>{record.proxied ? "Yes" : "No"}</span>
															</td>
															<td>
																<button className="btn btn-danger btn-sm" onClick={() => handleDeleteDNSRecord(record.id)}>
																	<i className="fa-solid fa-trash-alt"></i> Delete
																</button>
																<button className="btn btn-primary btn-sm ms-2" onClick={() => handleEditDNSRecord(record)}>
																	<i className="fa-solid fa-edit"></i> Edit
																</button>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								) : (
									<p className="text-muted">No DNS records available.</p>
								)}
							</div>
						</div>
					</div>
				</main>
			</div>
			{showEditModal && (
				<>
					<div className="modal fadeUp show" tabIndex={-1} style={{ display: "block" }} aria-modal="true" role="dialog">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Edit DNS Record</h5>
									<button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close" />
								</div>
								<div className="modal-body">
									<div className="mb-3">
										<label className="form-label">Type</label>
										<select className="form-control" value={editingRecord.recordType} onChange={(e) => setEditingRecord({ ...editingRecord, recordType: e.target.value })}>
											<option value="A">A</option>
											<option value="AAAA">AAAA</option>
											<option value="CNAME">CNAME</option>
											<option value="MX">MX</option>
											<option value="TXT">TXT</option>
										</select>
									</div>
									<div className="mb-3">
										<label className="form-label">Name</label>
										<input type="text" className="form-control" value={editingRecord.name} onChange={(e) => setEditingRecord({ ...editingRecord, name: e.target.value })} />
									</div>
									<div className="mb-3">
										<label className="form-label">Content</label>
										<input type="text" className="form-control" value={editingRecord.content} onChange={(e) => setEditingRecord({ ...editingRecord, content: e.target.value })} />
									</div>
									<div className="mb-3">
										<label className="form-label">TTL</label>
										<input
											type="number"
											className="form-control"
											value={editingRecord.ttl}
											onChange={(e) => setEditingRecord({ ...editingRecord, ttl: parseInt(e.target.value) })}
										/>
									</div>
									<div className="mb-3 form-check">
										<input
											type="checkbox"
											className="form-check-input"
											id="proxiedCheck"
											checked={editingRecord.proxied}
											onChange={(e) => setEditingRecord({ ...editingRecord, proxied: e.target.checked })}
										/>
										<label className="form-check-label" htmlFor="proxiedCheck">
											Proxied
										</label>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)}>
										Cancel
									</button>
									<button type="button" className="btn btn-primary" onClick={handleSaveDNSRecord}>
										Save changes
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
			{showAddModal && (
				<>
					<div className="modal fadeUp show" tabIndex={-1} style={{ display: "block" }} aria-modal="true" role="dialog">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Add DNS Record</h5>
									<button type="button" className="btn-close" onClick={() => setShowAddModal(false)} aria-label="Close" />
								</div>
								<div className="modal-body">
									<div className="mb-3">
										<label className="form-label">Type</label>
										<select className="form-control" value={addingRecord.recordType} onChange={(e) => setAddingRecord({ ...addingRecord, recordType: e.target.value })}>
											<option value="A">A</option>
											<option value="AAAA">AAAA</option>
											<option value="CNAME">CNAME</option>
											<option value="MX">MX</option>
											<option value="TXT">TXT</option>
										</select>
									</div>
									<div className="mb-3">
    <label className="form-label">Name</label>
    <input 
        type="text" 
        className="form-control" 
        value={addingRecord.name || (domainZoneDetails && domainZoneDetails.name) || ''} 
        onChange={(e) => setAddingRecord({ ...addingRecord, name: e.target.value })} 
    />
</div>
									<div className="mb-3">
										<label className="form-label">Content</label>
										<input type="text" className="form-control" value={addingRecord.content} onChange={(e) => setAddingRecord({ ...addingRecord, content: e.target.value })} />
									</div>
									<div className="mb-3">
										<label className="form-label">TTL</label>
										<input type="number" className="form-control" value={addingRecord.ttl} onChange={(e) => setAddingRecord({ ...addingRecord, ttl: parseInt(e.target.value) })} />
									</div>
									<div className="mb-3 form-check">
										<input
											type="checkbox"
											className="form-check-input"
											id="proxiedCheck"
											checked={addingRecord.proxied}
											onChange={(e) => setAddingRecord({ ...addingRecord, proxied: e.target.checked })}
										/>
										<label className="form-check-label" htmlFor="proxiedCheck">
											Proxied
										</label>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" onClick={() => setShowAddModal(false)}>
										Cancel
									</button>
									<button type="button" className="btn btn-primary" onClick={handleAddDNS}>
										Add Record
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
			{showDeleteSiteModal && (
				<>
					<div className="modal fadeUp show" tabIndex={-1} style={{ display: "block" }} aria-modal="true" role="dialog">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Delete Site From Cloudflare</h5>
									<button type="button" className="btn-close" onClick={() => setShowDeleteSiteModal(false)} aria-label="Close" />
								</div>
								<div className="modal-body">
									<p>Are you sure you want to delete this site from cloudflare?</p>
									<p>It won't delete the site from domains table unless you check the below box</p>
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="deleteSiteCheckbox" checked={deleteSiteCheckbox} onChange={(e) => setDeleteSiteCheckbox(e.target.checked)} />
										<label className="form-check-label" htmlFor="deleteSiteCheckbox">
											Delete Site From Domains Table
										</label>
									</div>
									<p className="badge bg-danger mt-3">This action is irreversible!</p>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" onClick={() => setShowDeleteSiteModal(false)}>
										Cancel
									</button>
									<button type="button" className="btn btn-danger" onClick={handleDeleteSite}>
										Delete
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
		</div>
	);
}
