import { config } from '../../../../config';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import feather from 'feather-icons';
import { useEffect } from 'react';
import { useAuth } from '../../../../Hooks/useAuthentication';
import {adminMenuItems} from '../../../../adminComponents/Authed/Sections/Header/Menu';

// Define the users menu items
const userMenuItems = [
  {
    label: 'Dashboard',
    icon: 'home',
    path: '/panel/dashboard',
    hasSubmenu: false
  },
  {
    label: 'Cloaker',
    icon: 'eye-off',
    path: '/panel/cloaker',
    hasSubmenu: false
  },
  {
    label: 'My Domains',
    icon: 'layout',
    path: '#domains',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'View Domains', path: '/panel/domains/view' },
      { label: 'Add Domain', path: '/panel/domains/add' }
    ]
  },
  {
    label: 'My Account',
    icon: 'user',
    path: '/panel/my-account',
    hasSubmenu: false
  },
  {
    label: 'My Blacklists',
    icon: 'slash',
    path: '#blacklists',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'View Blacklists', path: '/panel/blacklists/view' },
      { label: 'Add Blacklist', path: '/panel/blacklists/new' }
    ]
  },
  {
    label: 'My Storage Balances',
    icon: 'database',
    path: '/panel/storageBalances',
    hasSubmenu: false,
  },
  {
    label: 'My Sales',
    icon: 'download-cloud',
    path: '#sales',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'NFT Sales', path: '/panel/sales/nft' },
      { label: 'Token Sales', path: '/panel/sales/token' }
    ]
  },
  {
    label: 'My Tickets',
    icon: 'help-circle',
    path: '#support',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'All Tickets', path: '/panel/support/all' },
      { label: 'New Ticket', path: '/panel/support/new' }
    ]
  },
  {
    label: 'Designs',
    icon: 'link',
    path: '/panel/designs',
    hasSubmenu: false
  },
  {
    label: 'Global Hits',
    icon: 'dollar-sign',
    path: '/panel/global-hits',
    hasSubmenu: false
  },
  {
    label: 'Leaderboard',
    icon: 'bar-chart-2',
    path: '/panel/leaderboard',
    hasSubmenu: false,
    badge: 'New'
  },
  {
    label: 'Reward Pot',
    icon: 'database',
    path: '/panel/reward-pot',
    hasSubmenu: false,
    badge: 'New'
  },
  {
    label: 'News',
    icon: 'send',
    path: '/panel/news',
    hasSubmenu: false
  },
  {
    label: 'Ranks',
    icon: 'target',
    path: '/panel/ranks',
    hasSubmenu: false
  },  
  {
    label: 'Auto Seller Statuses',
    icon: 'pause',
    path: '/panel/auto-seller-statuses',
    hasSubmenu: false
  },  
  {
    label: 'Tools',
    icon: 'cloud-snow',
    path: '/panel/tools',
    hasSubmenu: false
  },  
  {
    label: 'Pricing',
    icon: 'package',
    path: '/panel/pricing',
    hasSubmenu: false
  },  
  {
    label: 'FAQ',
    icon: 'twitch',
    path: '/panel/faq',
    hasSubmenu: false
  },  
  {
    label: 'Documentation',
    icon: 'book',
    path: '#documentation',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'Get Started', path: '/panel/documentation/get-started' },
      { label: 'All Supported Modules', path: '/panel/documentation/modules' },
      { label: 'Coding your own setup', path: '/panel/documentation/coding', badge: 'New' },
    ]
  }
];

export default function Menu() {
  const auth = useAuth();
  const {userLevel} = auth;
  const location = useLocation(); // Get the current location

  useEffect(() => {
    console.log("Location changed:", location.pathname);
    feather.replace();
  }, []);

  return (
    <nav id="sidebar" className="sidebar js-sidebar">
      <div className="sidebar-content js-simplebar" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Link className="sidebar-brand" to="/panel/dashboard">
          <span className="sidebar-brand-text align-middle d-flex flex-row justify-content-between align-items-center">
            <img src='/img/newLogoLight.png' alt="Logo" width={32} height={32} className='siteLogo' />
            <span className='siteTextLogo'>{config.siteName}</span>
          </span>
          <svg
            className="sidebar-brand-icon align-middle"
            width="32px"
            height="32px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="miter"
            color="#FFFFFF"
            style={{ marginLeft: '-3px' }}
          >
            <path d="M12 4L20 8.00004L12 12L4 8.00004L12 4Z" />
            <path d="M20 12L12 16L4 12" />
            <path d="M20 16L12 20L4 16" />
          </svg>
        </Link>

        <ul className="sidebar-nav">
          {userLevel === 'admin' ? adminMenuItems.map((item, index) => (
            <li
              key={index}
              className={`sidebar-item ${item.hasSubmenu ? 'has-submenu' : ''} ${location.pathname.endsWith(item.path) ? 'active' : ''}`}
            >
              <Link
                to={item.hasSubmenu ? '#' : item.path}
                className={`sidebar-link ${item.hasSubmenu ? 'collapsed' : ''}`}
                data-bs-target={item.path}
                data-bs-toggle={item.hasSubmenu ? 'collapse' : ''}
              >
                <i className="align-middle" data-feather={item.icon} /> <span className="align-middle">{item.label}</span>
                {item.badge && <span className="sidebar-badge badge bg-primary">{item.badge}</span>}
              </Link>
              {item.hasSubmenu && (
                <ul id={item.path.substring(1)} className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                  {item.subMenuItems.map((subItem, subIndex) => (
                    <li
                      key={subIndex}
                      className={`sidebar-item ${location.pathname.startsWith(subItem.path) ? 'active' : ''}`}
                    >
                      <Link className="sidebar-link" to={subItem.path}>
                        {subItem.label}
                        {subItem.badge && <span className="sidebar-badge badge bg-primary">{subItem.badge}</span>}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )) : (
            userMenuItems.map((item, index) => (
              <li
              key={index}
              className={`sidebar-item ${item.hasSubmenu ? 'has-submenu' : ''} ${location.pathname.endsWith(item.path) ? 'active' : ''}`}
            >
              <Link
                to={item.hasSubmenu ? '#' : item.path}
                className={`sidebar-link ${item.hasSubmenu ? 'collapsed' : ''}`}
                data-bs-target={item.path}
                data-bs-toggle={item.hasSubmenu ? 'collapse' : ''}
              >
                <i className="align-middle" data-feather={item.icon} /> <span className="align-middle">{item.label}</span>
                {item.badge && <span className="sidebar-badge badge bg-primary">{item.badge}</span>}
              </Link>
              {item.hasSubmenu && (
                <ul id={item.path.substring(1)} className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                  {item.subMenuItems.map((subItem, subIndex) => (
                    <li
                      key={subIndex}
                      className={`sidebar-item ${location.pathname.startsWith(subItem.path) ? 'active' : ''}`}
                    >
                      <Link className="sidebar-link" to={subItem.path}>
                        {subItem.label}
                        {subItem.badge && <span className="sidebar-badge badge bg-primary">{subItem.badge}</span>}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            ))
          )}
        </ul>

        <div style={{ flexGrow: 1 }}></div>

        <div className="sidebar-cta">
          <div className="sidebar-cta-content">
            <strong className="d-inline-block mb-2">24/7 Support</strong>
            <div className="mb-3 text-sm">We're here, on hand whenever you need us.</div>
            <div className="d-grid">
              <a href="https://t.me/+Jv8ZJZjQ5ZhjZTNi" className="btn btn-primary" target="_blank">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
