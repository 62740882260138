export const timeAgo = (epoch, showAgo = true) => {
    const seconds = Math.floor((new Date() - new Date(epoch * 1000)) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + (showAgo ? " years ago" : " years");
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + (showAgo ? " months ago" : " months");
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + (showAgo ? " days ago" : " days");
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + (showAgo ? " hours ago" : " hours");
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + (showAgo ? " minutes ago" : " minutes");
    }
    return Math.floor(seconds) + (showAgo ? " seconds ago" : " seconds");
};