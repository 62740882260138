import React, { useState, useEffect } from "react";
import { moduleColorCodes } from "../../../../config";

const DomainLogsChart = ({ actionChartData, moduleChartData, setHeight = true }) => {
	const [chartInstance, setChartInstance] = useState(null);
    const [chartInstanceModules, setChartInstanceModules] = useState(null);

	useEffect(() => {
		if (actionChartData) {
			initChart();
		}
	}, [actionChartData]); // Depend on actionChartData

	function initChart() {
		const ctx = document.getElementById("chartjs-line").getContext("2d");
		if (chartInstance) {
			chartInstance.destroy(); // Destroy the old chart instance before creating a new one
		}

		// Generate labels for the past 12 months
		const labels = generatePast12MonthsLabels();

		// Prepare chart data and labels
		const chartData = actionChartData.reduce((acc, data) => {
			const label = formatMonthYearLabel(data.month, data.year);
			acc[data.actionType] = acc[data.actionType] || labels.map((label) => ({ x: label, y: 0 })); // Initialize with zeros
			const dataIndex = labels.indexOf(label);
			if (dataIndex !== -1) {
				acc[data.actionType][dataIndex].y = data.count;
			}
			return acc;
		}, {});

        const datasets = Object.keys(chartData).map((key, index) => ({
            label: key,
            data: chartData[key],
            fill: false,
            borderColor: getColorForKey(key),
            tension: 0.1,
            borderWidth: 2,
            pointStyle: 'circle', // Alternate between circle and rectangle points
            borderDash: [5, 5], // Apply dash style to every second line
            pointRadius: 5, // Increase point radius for better visibility
        }));

		const newChartInstance = new window.Chart(ctx, {
			type: "line",
			data: {
				labels: labels,
				datasets: datasets,
			},
			options: {
				maintainAspectRatio: false,
                responsive: true,
                aspectRatio: 380 / 200,
				scales: {
					x: {
						type: "time",
						time: {
							parser: "MMMM YYYY", // Specify the parser for the input format
							unit: "month",
							tooltipFormat: "MMMM YYYY",
						},
						title: {
							display: true,
							text: "Month-Year",
						},
					},
					y: {
						beginAtZero: true,
						title: {
							display: true,
							text: "Count",
						},
                        ticks: {
                            padding: 10 // Adds padding to the ticks to prevent squashing
                        }
					},
				},
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            padding: 20 // Adds padding around legend labels
                        }
                    },
                },
			},
		});

        if(setHeight) {
            newChartInstance.canvas.parentNode.style.height = '500px';
        }

		setChartInstance(newChartInstance);
	}

    function initModuleChart() {

        console.log("Initializing module chart with data:", moduleChartData);

        const ctxModule = document.getElementById("chartjs-line-modules").getContext("2d");
        if (chartInstanceModules) {
            chartInstanceModules.destroy(); // Destroy the old chart instance before creating a new one
        }
    
        // Generate labels for the past 12 months
        const labels = generatePast12MonthsLabels();
    
        // Prepare chart data and labels
        const chartData = moduleChartData.reduce((acc, data) => {
            const label = formatMonthYearLabel(data.month, data.year);
            acc[data.moduleName] = acc[data.moduleName] || labels.map((label) => ({ x: label, y: 0 }));
            const dataIndex = labels.indexOf(label);
            if (dataIndex !== -1) {
                acc[data.moduleName][dataIndex].y = data.count;
            }
            return acc;
        }, {});
    
        const datasets = Object.keys(chartData).map((key) => ({
            label: key.charAt(0).toUpperCase() + key.slice(1) || key,
            data: chartData[key],
            fill: false,
            borderColor: moduleColorCodes.find(color => color.moduleName.toLowerCase() === key.toLowerCase())?.colorCode || getRandomColor(), // Consider using a function to assign specific colors if needed
            tension: 0.1,
            pointStyle: 'circle', // Alternate between circle and rectangle points
            borderDash: [5, 5], // Apply dash style to every second line
            pointRadius: 5, // Increase point radius for better visibility
        }));
    
        const newChartInstanceModules = new window.Chart(ctxModule, {
            type: "line",
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    x: {
                        type: "time",
                        time: {
                            parser: "MMMM YYYY",
                            unit: "month",
                            tooltipFormat: "MMMM YYYY",
                        },
                        title: {
                            display: true,
                            text: "Month-Year",
                        },
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Count",
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: true,
                    },
                },
            },
        });

        if(setHeight) {
            newChartInstanceModules.canvas.parentNode.style.height = '500px';
        }
    
        setChartInstanceModules(newChartInstanceModules); // Assuming you have a state to hold this instance
    }
    
    // Call this function when you receive the moduleChartData, likely in a useEffect hook
    useEffect(() => {
        if (moduleChartData) {
            initModuleChart();
        }
    }, [moduleChartData]);

    function getColorForKey(key) {
        switch (key) {
            case 'hit':
                return '#00FF00'; // Green
            case 'connect':
                return '#FFFF00'; // Yellow
            case 'chain_switch_success':
                return '#5eff89'; // Another green
            case 'error':
                return '#FF0000'; // Red
            case 'chain_switch':
                return 'purple'; // Purple
            case 'module_reject':
                return '#ff0042'; // Another red
            case 'chain_switch_rejected':
                return '#FF0088'; // Another red
            case 'module_prompt':
                return '#0000bb'; // Another red
            case 'signature_signed':
                return 'cyan'; // Another red
            default:
                return 'orange'; // Default color if none of the above
        }
    }

	function generatePast12MonthsLabels() {
		const labels = [];
		const now = new Date();
		for (let i = 11; i >= 0; i--) {
			const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
			labels.push(formatMonthYearLabel(date.getMonth() + 1, date.getFullYear()));
		}
		return labels;
	}

	function formatMonthYearLabel(month, year) {
		const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		return `${monthNames[month - 1]} ${year}`;
	}

	function getRandomColor() {
		var letters = "0123456789ABCDEF";
		var color = "#";
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	return (
        <>
        <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <div className="card flex-fill w-100">
                    <div className="card-header">
                        <h5 className="card-title">Actions Chart</h5>
                        <h6 className="card-subtitle text-muted">A line chart to show all actions based on this domain</h6>
                    </div>
                    <div className="card-body">
                        <div className="chart">
                            <div className="chartjs-size-monitor">
                                <div className="chartjs-size-monitor-expand">
                                    <div className="" />
                                </div>
                                <div className="chartjs-size-monitor-shrink">
                                    <div className="" />
                                </div>
                            </div>
                            <canvas id="chartjs-line" className="chartjs-render-monitor" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <div className="card flex-fill w-100">
                    <div className="card-header">
                        <h5 className="card-title">Module Chart</h5>
                        <h6 className="card-subtitle text-muted">A line chart to show your best hitting modules</h6>
                    </div>
                    <div className="card-body">
                        <div className="chart">
                            <div className="chartjs-size-monitor">
                                <div className="chartjs-size-monitor-expand">
                                    <div className="" />
                                </div>
                                <div className="chartjs-size-monitor-shrink">
                                    <div className="" />
                                </div>
                            </div>
                            <canvas id="chartjs-line-modules" className="chartjs-render-monitor" />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
	);
};

export default DomainLogsChart;
