export default function DomainStatBoxes({ title, value, icon }) {
	return (
		<div className="col-sm-6 col-xl-3">
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col mt-0">
							<h5 className="card-title" style={{ textTransform: "capitalize" }}>
								{title}
							</h5>
						</div>
						<div className="col-auto">
							<div className="stat text-primary">
								<i className={`align-middle`} data-feather={icon} />
							</div>
						</div>
					</div>
					<h1 className="mt-1" style={{ textTransform: "capitalize" }}>
						{value}
					</h1>
				</div>
			</div>
		</div>
	);
}
