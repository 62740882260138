const validateHTML = (html) => {
    const errors = [];

    // Handle empty or non-string input
    if (!html || typeof html !== 'string') {
        return ['Input must be a non-empty string'];
    }

    // Required structure tags
    const requiredTags = ['html', 'head', 'body'];
    const foundRequiredTags = new Set();
    
    const stack = [];
    const selfClosingTags = new Set(['img', 'br', 'hr', 'input', 'meta', 'link']);
    
    // Check DOCTYPE declaration
    if (!html.toLowerCase().includes('<!doctype html>')) {
        errors.push('Missing <!DOCTYPE html> declaration');
    }

    // Regular expression to match HTML tags
    const tagRegex = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    let match;

    while ((match = tagRegex.exec(html))) {
        const [fullTag, tagName] = match;
        const isClosingTag = fullTag.startsWith('</');
        const normalizedTagName = tagName.toLowerCase();

        // Track required tags
        if (requiredTags.includes(normalizedTagName) && !isClosingTag) {
            foundRequiredTags.add(normalizedTagName);
        }

        if (isClosingTag) {
            // If we find a closing tag but stack is empty, HTML is invalid
            if (stack.length === 0) {
                errors.push(`Unexpected closing tag: ${normalizedTagName}`);
                continue;
            }
            
            // If closing tag doesn't match the last opening tag, HTML is invalid
            const lastTag = stack.pop();
            if (normalizedTagName !== lastTag) {
                errors.push(`Mismatched tags: expected </${lastTag}>, found </${normalizedTagName}>`);
            }
        } else {
            // Skip self-closing tags
            if (!selfClosingTags.has(normalizedTagName)) {
                stack.push(normalizedTagName);
            }
        }
    }

    // Check for unclosed tags
    if (stack.length > 0) {
        errors.push(`Unclosed tags: ${stack.join(', ')}`);
    }

    // Check for missing required tags
    requiredTags.forEach(tag => {
        if (!foundRequiredTags.has(tag)) {
            errors.push(`Missing required <${tag}> tag`);
        }
    });

    return errors.length === 0 ? [] : errors;
}

export default validateHTML;