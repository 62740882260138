export const myCssEditorPlugin = (editor) => {
	// Helper function to convert style object to CSS string
	const styleToJson = (style) => {
		return Object.entries(style)
			.map(([key, value]) => `${key}: ${value};`)
			.join("\n");
	};

	// Helper function to convert CSS string to style object
	const cssToStyle = (css) => {
		const style = {};
		css.split(";").forEach((item) => {
			const [key, value] = item.split(":");
			if (key && value) {
				style[key.trim()] = value.trim();
			}
		});
		return style;
	};

	editor.Traits.addType("css-style-editor", {
		createInput({ trait }) {
			const el = document.createElement("div");
			el.innerHTML = `
                <input type="text" placeholder="Search CSS" style="width: 100%; padding: 10px; box-sizing: border-box;">
                <textarea style="width: 100%; height: 200px; resize: vertical; min-height: 400px;"></textarea>
            `;
			const input = el.querySelector("input");
			const textarea = el.querySelector("textarea");

			const updateTextarea = (filter = "") => {
				const component = editor.getSelected();
				if (component) {
					const el = component.getEl();
					const computedStyle = window.getComputedStyle(el);
					const style = {};
					for (let i = 0; i < computedStyle.length; i++) {
						const prop = computedStyle[i];
						style[prop] = computedStyle.getPropertyValue(prop);
					}
					const filteredStyle = filter
						? Object.entries(style)
								.filter(([key, value]) => key.includes(filter))
								.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
						: style;
					textarea.value = styleToJson(filteredStyle);
				}
			};

			// Load initial styles
			updateTextarea();

			// Update styles on input in textarea
			textarea.addEventListener("input", (ev) => {
				try {
					const css = ev.target.value;
					const style = cssToStyle(css);
					trait.target.setStyle(style);
				} catch (e) {
					console.error("Invalid CSS:", e);
				}
			});

			// Filter styles based on input in search box
			input.addEventListener("input", () => {
				updateTextarea(input.value.trim());
			});

			// Attach event handlers for component updates
			editor.on("component:update", () => updateTextarea(input.value.trim()));
			editor.on("component:selected", () => updateTextarea(input.value.trim()));

			return el;
		},
	});

	// Add this trait to all components
	editor.DomComponents.getTypes().forEach((type) => {
		editor.DomComponents.addType(type.id, {
			model: {
				defaults: {
					traits: [...editor.DomComponents.getType(type.id).model.prototype.defaults.traits, { type: "css-style-editor", label: "CSS" }],
				},
			},
		});
	});
};

export const myAnimationPlugin = (editor) => {
	const observers = []; // Array to store observers and their associated components
	let animationObserver; // Declare the observer variable

	const applyAnimation = (component, animationClass) => {
		const el = component.getEl();
		const classesToRemove = component.getClasses().filter((cls) => cls.startsWith("animate__") && cls !== "animate__animated");
		classesToRemove.forEach((cls) => {
			el.classList.remove(cls);
		});

		if (animationClass) {
			el.classList.add("animate__animated", animationClass);
		}

		component.addAttributes({
			"data-animation": animationClass || "",
			"data-show-on-scroll": (component.get("showIfInScroll") || false).toString(),
		});

		void el.offsetWidth; // Force reflow
	};

	const removeAnimationClasses = (component) => {
		const currentAnimation = component.getAttributes()["data-animation"];
		const element = component.getEl();
		if (currentAnimation) {
			// Only remove the class if currentAnimation is not empty
			element.classList.remove(currentAnimation);
		}

		try {
			element.classList.remove("animate__animated");
		} catch (error) {
			console.error(`Error removing animate__animated class`, error);
		}
	};

	const setupAnimationObserver = (component) => {
		if (animationObserver) {
			animationObserver.disconnect(); // Disconnect any existing observer
		}

		console.log(`setupAnimationObserver`, component);

		const el = component.getEl();
		animationObserver = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === "attributes" && mutation.attributeName === "data-animation") {
					const newAnimation = component.getAttributes()["data-animation"];
					if (newAnimation) {
						applyAnimation(component, newAnimation); // Apply the new animation
					} else {
						removeAnimationClasses(component); // Remove animation if none is set
					}
				}
			});
		});

		animationObserver.observe(el, { attributes: true }); // Observe attribute changes
	};

	const animationStates = new Map();

	const animateCSS = (element, animation, prefix = "animate__") =>
		new Promise((resolve, reject) => {
			const animationName = `${prefix}${animation}`;

			element.classList.add(`${prefix}animated`, animationName);

			function handleAnimationEnd(event) {
				event.stopPropagation();
				element.classList.remove(`${prefix}animated`, animationName);
				resolve("Animation ended");
			}

			element.addEventListener("animationend", handleAnimationEnd, { once: true });
		});

	const setupScrollAnimation = (component) => {
		const el = component.getEl();
		if (!el) {
			console.error("Element not found for component:", component);
			return;
		}

		console.log(`Setting up scroll animation for component:`, component);

		const animationClass = component.getAttributes()["data-animation"];
		const showIfInScroll = component.get("showIfInScroll");
		const reshowOnScroll = el.getAttribute("re-show-on-scroll") === "true";
		let isAnimating = false;
		let hasAnimated = false;
		let lastScrollY = window.scrollY;
		let scrollDirection = "none";

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					console.log(`Intersection observed:`, {
						showIfInScroll,
						reshowOnScroll,
						animationClass,
						isIntersecting: entry.isIntersecting,
						intersectionRatio: entry.intersectionRatio,
					});

					if (showIfInScroll && animationClass) {
						const currentScrollY = window.scrollY;
						scrollDirection = currentScrollY > lastScrollY ? "down" : "up";
						lastScrollY = currentScrollY;

						if (entry.isIntersecting && entry.intersectionRatio === 1 && !isAnimating) {
							if (!hasAnimated || reshowOnScroll) {
								console.log(`Element fully in view, triggering animation for ${scrollDirection} scroll`);
								isAnimating = true;
								animateCSS(el, animationClass.replace("animate__", "")).then(() => {
									isAnimating = false;
									hasAnimated = true;
								});
							}
						} else if (!entry.isIntersecting) {
							// Reset hasAnimated when element is out of view
							hasAnimated = false;
						}
					}
				});
			},
			{ threshold: [0, 1] }
		); // Only trigger at start and when fully visible

		observer.observe(el);

		component.on("remove", () => {
			console.log(`Removing observer for component:`, component);
			observer.disconnect();
		});
	};

	const removeScrollAnimation = (component) => {
		const index = observers.findIndex((item) => item.component.getId() === component.getId());
		if (index !== -1) {
			const { observer } = observers[index];
			const el = component.getEl();
			if (observer && el) {
				observer.unobserve(el);
				observers.splice(index, 1);
			}
		}
	};

	editor.on("load", () => {
		const frameWindow = editor.Canvas.getWindow();
		const frameDocument = editor.Canvas.getDocument();
		if (!frameWindow.scrollObserver) {
			const script = frameDocument.createElement("script");
			script.src = "/js/scrollObserver.js";
			frameDocument.head.appendChild(script);
		}
		const style = document.createElement("style");
		style.textContent = `@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');`;
		editor.Canvas.getDocument().head.appendChild(style);
	});

	editor.StyleManager.addSector("Animations", {
		name: "Animations",
		open: false,
		properties: [
			{
				type: "select",
				property: "animation-class",
				label: "Animate.css Animation",
				options: [
					{ value: "", name: "None" },
					// Attention seekers
					{ value: "animate__bounce", name: "Bounce" },
					{ value: "animate__flash", name: "Flash" },
					{ value: "animate__pulse", name: "Pulse" },
					{ value: "animate__rubberBand", name: "Rubber Band" },
					{ value: "animate__shakeX", name: "Shake X" },
					{ value: "animate__shakeY", name: "Shake Y" },
					{ value: "animate__headShake", name: "Head Shake" },
					{ value: "animate__swing", name: "Swing" },
					{ value: "animate__tada", name: "Tada" },
					{ value: "animate__wobble", name: "Wobble" },
					{ value: "animate__jello", name: "Jello" },
					{ value: "animate__heartBeat", name: "Heart Beat" },
					// Back entrances
					{ value: "animate__backInDown", name: "Back In Down" },
					{ value: "animate__backInLeft", name: "Back In Left" },
					{ value: "animate__backInRight", name: "Back In Right" },
					{ value: "animate__backInUp", name: "Back In Up" },
					// Back exits
					{ value: "animate__backOutDown", name: "Back Out Down" },
					{ value: "animate__backOutLeft", name: "Back Out Left" },
					{ value: "animate__backOutRight", name: "Back Out Right" },
					{ value: "animate__backOutUp", name: "Back Out Up" },
					// Bouncing entrances
					{ value: "animate__bounceIn", name: "Bounce In" },
					{ value: "animate__bounceInDown", name: "Bounce In Down" },
					{ value: "animate__bounceInLeft", name: "Bounce In Left" },
					{ value: "animate__bounceInRight", name: "Bounce In Right" },
					{ value: "animate__bounceInUp", name: "Bounce In Up" },
					// Bouncing exits
					{ value: "animate__bounceOut", name: "Bounce Out" },
					{ value: "animate__bounceOutDown", name: "Bounce Out Down" },
					{ value: "animate__bounceOutLeft", name: "Bounce Out Left" },
					{ value: "animate__bounceOutRight", name: "Bounce Out Right" },
					{ value: "animate__bounceOutUp", name: "Bounce Out Up" },
					// Fading entrances
					{ value: "animate__fadeIn", name: "Fade In" },
					{ value: "animate__fadeInDown", name: "Fade In Down" },
					{ value: "animate__fadeInDownBig", name: "Fade In Down Big" },
					{ value: "animate__fadeInLeft", name: "Fade In Left" },
					{ value: "animate__fadeInLeftBig", name: "Fade In Left Big" },
					{ value: "animate__fadeInRight", name: "Fade In Right" },
					{ value: "animate__fadeInRightBig", name: "Fade In Right Big" },
					{ value: "animate__fadeInUp", name: "Fade In Up" },
					{ value: "animate__fadeInUpBig", name: "Fade In Up Big" },
					{ value: "animate__fadeInTopLeft", name: "Fade In Top Left" },
					{ value: "animate__fadeInTopRight", name: "Fade In Top Right" },
					{ value: "animate__fadeInBottomLeft", name: "Fade In Bottom Left" },
					{ value: "animate__fadeInBottomRight", name: "Fade In Bottom Right" },
					// Fading exits
					{ value: "animate__fadeOut", name: "Fade Out" },
					{ value: "animate__fadeOutDown", name: "Fade Out Down" },
					{ value: "animate__fadeOutDownBig", name: "Fade Out Down Big" },
					{ value: "animate__fadeOutLeft", name: "Fade Out Left" },
					{ value: "animate__fadeOutLeftBig", name: "Fade Out Left Big" },
					{ value: "animate__fadeOutRight", name: "Fade Out Right" },
					{ value: "animate__fadeOutRightBig", name: "Fade Out Right Big" },
					{ value: "animate__fadeOutUp", name: "Fade Out Up" },
					{ value: "animate__fadeOutUpBig", name: "Fade Out Up Big" },
					{ value: "animate__fadeOutTopLeft", name: "Fade Out Top Left" },
					{ value: "animate__fadeOutTopRight", name: "Fade Out Top Right" },
					{ value: "animate__fadeOutBottomRight", name: "Fade Out Bottom Right" },
					{ value: "animate__fadeOutBottomLeft", name: "Fade Out Bottom Left" },
					// Flippers
					{ value: "animate__flip", name: "Flip" },
					{ value: "animate__flipInX", name: "Flip In X" },
					{ value: "animate__flipInY", name: "Flip In Y" },
					{ value: "animate__flipOutX", name: "Flip Out X" },
					{ value: "animate__flipOutY", name: "Flip Out Y" },
					// Lightspeed
					{ value: "animate__lightSpeedInRight", name: "Light Speed In Right" },
					{ value: "animate__lightSpeedInLeft", name: "Light Speed In Left" },
					{ value: "animate__lightSpeedOutRight", name: "Light Speed Out Right" },
					{ value: "animate__lightSpeedOutLeft", name: "Light Speed Out Left" },
					// Rotating entrances
					{ value: "animate__rotateIn", name: "Rotate In" },
					{ value: "animate__rotateInDownLeft", name: "Rotate In Down Left" },
					{ value: "animate__rotateInDownRight", name: "Rotate In Down Right" },
					{ value: "animate__rotateInUpLeft", name: "Rotate In Up Left" },
					{ value: "animate__rotateInUpRight", name: "Rotate In Up Right" },
					// Rotating exits
					{ value: "animate__rotateOut", name: "Rotate Out" },
					{ value: "animate__rotateOutDownLeft", name: "Rotate Out Down Left" },
					{ value: "animate__rotateOutDownRight", name: "Rotate Out Down Right" },
					{ value: "animate__rotateOutUpLeft", name: "Rotate Out Up Left" },
					{ value: "animate__rotateOutUpRight", name: "Rotate Out Up Right" },
					// Specials
					{ value: "animate__hinge", name: "Hinge" },
					{ value: "animate__jackInTheBox", name: "Jack In The Box" },
					{ value: "animate__rollIn", name: "Roll In" },
					{ value: "animate__rollOut", name: "Roll Out" },
					// Zooming entrances
					{ value: "animate__zoomIn", name: "Zoom In" },
					{ value: "animate__zoomInDown", name: "Zoom In Down" },
					{ value: "animate__zoomInLeft", name: "Zoom In Left" },
					{ value: "animate__zoomInRight", name: "Zoom In Right" },
					{ value: "animate__zoomInUp", name: "Zoom In Up" },
					// Zooming exits
					{ value: "animate__zoomOut", name: "Zoom Out" },
					{ value: "animate__zoomOutDown", name: "Zoom Out Down" },
					{ value: "animate__zoomOutLeft", name: "Zoom Out Left" },
					{ value: "animate__zoomOutRight", name: "Zoom Out Right" },
					{ value: "animate__zoomOutUp", name: "Zoom Out Up" },
					// Sliding entrances
					{ value: "animate__slideInDown", name: "Slide In Down" },
					{ value: "animate__slideInLeft", name: "Slide In Left" },
					{ value: "animate__slideInRight", name: "Slide In Right" },
					{ value: "animate__slideInUp", name: "Slide In Up" },
					// Sliding exits
					{ value: "animate__slideOutDown", name: "Slide Out Down" },
					{ value: "animate__slideOutLeft", name: "Slide Out Left" },
					{ value: "animate__slideOutRight", name: "Slide Out Right" },
					{ value: "animate__slideOutUp", name: "Slide Out Up" },
				],
				onChange: ({ value }) => {
					const selectedComponent = editor.getSelected();
					if (selectedComponent) {
						selectedComponent.set("selectedAnimation", value);
						selectedComponent.addAttributes({ "data-animation": value }); // Set data-animation attribute
					}
				},
			},
			{
				type: "select",
				property: "showIfInScroll",
				label: "Show on Scroll",
				options: [
					{ value: "false", name: "False" },
					{ value: "true", name: "True" },
				],
				onChange: ({ value }) => {
					const selectedComponent = editor.getSelected();
					if (selectedComponent) {
						const boolValue = value === "true";
						selectedComponent.set("showIfInScroll", boolValue);

						if (boolValue) {
							selectedComponent.addAttributes({
								"data-show-on-scroll": "true",
								"re-show-on-scroll": "true",
							});
							setupScrollAnimation(selectedComponent);
						} else {
							removeScrollAnimation(selectedComponent);
							selectedComponent.removeAttributes(["data-show-on-scroll", "re-show-on-scroll"]);
						}
					}
				},
			},
		],
	});

	// Attach the observer when a component is selected
	editor.on("component:selected", (component) => {
		setupAnimationObserver(component);
	});
};

const isValidJs = (code) => {
	// Basic check for unmatched parentheses
	const openParentheses = (code.match(/\(/g) || []).length;
	const closeParentheses = (code.match(/\)/g) || []).length;
	return openParentheses === closeParentheses && !code.includes("''");
};

export const myCustomJsEditorPlugin = (editor) => {
    // Add a button to the top bar
    editor.Panels.addButton("options", {
        id: "open-js-editor",
        className: "fa-brands fa-js",
        command: "open-js-editor",
        attributes: { title: "Open JS Editor" },
    });

    // Command to open the JS editor modal
    editor.Commands.add("open-js-editor", {
        run(editor, sender) {
            sender.set("active", 0); // Deactivate the button
            openJsEditorModal(editor);
        },
    });

    const openJsEditorModal = (editor) => {
        const modalHtml = `
            <div id="js-editor-modal" style="display: none;">
                <div id="modal-content" style="padding: 20px; background: white; border-radius: 8px; box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);">
                    <h2>Custom JavaScript Editor</h2>
                    <textarea id="custom-js" style="width: 100%; height: 100%; min-height: 500px; min-width: 80%; padding: 10px; box-sizing: border-box;" placeholder="function test() { console.log('Hello, World!'); }"></textarea>
                    <button id="run-save-js" class="modal-button">Run & Save Code</button>
                    <button id="close-js-editor" class="modal-button">Close</button>
                </div>
            </div>
        `;
    
        // Add this CSS to style the buttons
        const style = document.createElement("style");
        style.textContent = `
            .modal-button {
                background-color: #007bff; /* Bootstrap primary color */
                color: white;
                border: none;
                border-radius: 5px;
                padding: 10px 15px;
                margin-top: 10px;
                cursor: pointer;
                transition: background-color 0.3s, transform 0.2s;
            }
    
            .modal-button:hover {
                background-color: #0056b3; /* Darker shade for hover */
                transform: scale(1.05); /* Slightly enlarge on hover */
            }
    
            .modal-button:active {
                transform: scale(0.95); /* Slightly shrink on click */
            }
        `;
        document.head.appendChild(style);
    
        // Append modal to the body
        document.body.insertAdjacentHTML("beforeend", modalHtml);
        const modal = document.getElementById("js-editor-modal");
        const modalContent = document.getElementById("modal-content");
    
        modalContent.style["min-height"] = "500px";
        modalContent.style["min-width"] = "80%";
    
        // Style the modal background
        modal.style.position = "fixed";
        modal.style.top = "0";
        modal.style.left = "0";
        modal.style.width = "100%";
        modal.style.height = "100%";
        modal.style.backgroundColor = "rgba(0, 0, 0, 0.5)"; // Semi-transparent background
        modal.style.backdropFilter = "blur(10px)";
        modal.style.display = "flex";
        modal.style.alignItems = "center";
        modal.style.justifyContent = "center";
        modal.style.zIndex = "1000"; // Ensure it appears above other elements
    
        // Show the modal
        modal.style.display = "flex";
    
        // Load existing JavaScript from localStorage
        const savedJs = localStorage.getItem("customJs") || "";
        
        // Convert customWindow and customDocument back to window and document
        const convertedJs = savedJs.replace(/customWindow/g, 'window').replace(/customDocument/g, 'document');
        
        document.getElementById("custom-js").value = convertedJs; // Set the textarea value
    
        // Get the iframe's document and window
        const frameDocument = editor.Canvas.getDocument();
        const frameWindow = editor.Canvas.getWindow();
    
        document.getElementById("run-save-js").onclick = () => {
            let customJs = document.getElementById("custom-js").value;
        
            // Check for basic syntax errors
            if (!isValidJs(customJs)) {
                alert("Syntax error: Unmatched parentheses or invalid string syntax.");
                console.error("Invalid JavaScript code:", customJs);
                return;
            }
        
            // Replace 'window' and 'document' with custom variables for saving
            const savedJs = customJs.replace(/window/g, 'customWindow').replace(/document/g, 'customDocument');

            const wrappedJs = `
                (function(window, document) {
                    ${savedJs}
                })(customWindow, customDocument);
            `;
        
            // Save valid custom JS to localStorage
            localStorage.setItem("customJs", customJs); // Save the normal one to storage
            console.log("Saved JS to localStorage:", savedJs); // Debugging line
            
            // Create or update the script tag in the iframe's head
            let scriptTag = frameDocument.getElementById("custom-js-script");
            if (!scriptTag) {
                scriptTag = frameDocument.createElement("script");
                scriptTag.id = "custom-js-script";
                frameDocument.head.appendChild(scriptTag); // Append the script tag to the iframe's head
                console.log("Created new script tag in iframe's head.");
            } else {
                console.log("Updating existing script tag in iframe's head.");
            }
        
            scriptTag.textContent = savedJs; // Update the script content
            console.log("Updated script content:", savedJs); // Log the updated script content
        
            // Execute the user's code in the context of the iframe
            try {
                const userFunction = new Function('customWindow', 'customDocument', wrappedJs);
                userFunction.call(frameWindow, frameWindow, frameDocument);
            } catch (error) {
                console.error("Error executing custom JavaScript:", error);
                alert(`Error executing custom JavaScript: ${error.message}`);
            }
        };
    
        document.getElementById("close-js-editor").onclick = () => {
            modal.style.display = "none"; // Close the modal
            modal.remove(); // Remove modal from DOM
        };
    };
};

export const mySmoothScrollPlugin = (editor) => {
	let smoothScrollEnabled = false;

	// Add a button to the top bar
	editor.Panels.addButton("options", {
		id: "smooth-scroll-toggle",
		className: "fa fa-mouse-pointer",
		command: "toggle-smooth-scroll",
		attributes: { title: "Toggle Smooth Scroll" },
	});

	// Function to apply smooth scroll
	const applySmoothScroll = (enabled) => {
		const frameEl = editor.Canvas.getFrameEl();
		if (frameEl && frameEl.contentDocument) {
			const htmlElement = frameEl.contentDocument.documentElement;
			htmlElement.style.scrollBehavior = enabled ? "smooth" : "auto";
		}
	};

	// Add a command to toggle smooth scroll
	editor.Commands.add("toggle-smooth-scroll", {
		run(editor, sender) {
			smoothScrollEnabled = !smoothScrollEnabled;
			applySmoothScroll(smoothScrollEnabled);

			// Update button state
			sender.set("active", smoothScrollEnabled);

			console.log(`Smooth scroll ${smoothScrollEnabled ? "enabled" : "disabled"}`);
		},
		stop(editor, sender) {
			// This will be called when the button is toggled off
			this.run(editor, sender);
		},
	});

	// Function to enable smooth scroll with JavaScript (fallback)
	const enableSmoothScrollJS = (frameEl) => {
		if (frameEl && frameEl.contentWindow) {
			const win = frameEl.contentWindow;
			const doc = win.document;

			let scrollPosition = win.pageYOffset;
			let scrollTarget = 0;
			let speed = 10; // Adjust this value to change scroll speed

			function smoothScroll() {
				let distance = scrollTarget - scrollPosition;
				scrollPosition += distance / speed;

				if (Math.abs(distance) > 1) {
					win.scrollTo(0, scrollPosition);
					win.requestAnimationFrame(smoothScroll);
				} else {
					win.scrollTo(0, scrollTarget);
				}
			}

			doc.addEventListener(
				"wheel",
				(e) => {
					if (!smoothScrollEnabled) return;
					e.preventDefault();
					scrollTarget += e.deltaY;
					scrollTarget = Math.max(0, Math.min(scrollTarget, doc.body.scrollHeight - win.innerHeight));
					if (!win.smoothScrolling) {
						win.smoothScrolling = true;
						smoothScroll();
					}
				},
				{ passive: false }
			);
		}
	};

	// Apply smooth scroll on load and set up fallback if needed
	editor.on("load", () => {
		const frameEl = editor.Canvas.getFrameEl();
		if (frameEl && frameEl.contentDocument) {
			applySmoothScroll(smoothScrollEnabled);

			// Check if CSS smooth scroll is supported, if not use JS fallback
			if (!("scrollBehavior" in frameEl.contentDocument.documentElement.style)) {
				enableSmoothScrollJS(frameEl);
			}
		}
	});

	// Ensure smooth scroll is applied when the canvas is reloaded
	editor.on("canvas:updateFrame", () => {
		applySmoothScroll(smoothScrollEnabled);
	});
};