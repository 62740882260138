import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../Hooks/useAuthentication";
import { useEffect } from "react";
import { config } from "../../config";

export default function ManageUser() {
	const auth = useAuth();

	const { userId } = useParams();
	const [user, setUser] = useState({
		username: "",
		registration_date: "",
		approved_date: "",
		traffic_method: "",
		how_find_us: "",
		bot_token: "",
		status: "",
		user_level: "",
		bot_chat_id: "",
		use_global_telegram_tokens: false,
		withdraw_address: "",
		avatarURL: "",
		xp: "",
		password: "",
	});

	useEffect(() => {
		if (auth.loading) return;

		const fetchUser = async () => {
			if (auth.loading) return;
			try {
				const response = await fetch(`${config.apiUrl}/fpanel/admin/getUserData`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${auth.token}`,
					},
					body: JSON.stringify({ username: auth.username, token: auth.token, id: userId }),
				});

				const data = await response.json();
				if (data.success) {
					delete data.data.pass_salt; // delete the pass_salt from the data
					delete data.data.password; // delete the password from the data
					setUser(data.data);
				} else {
					console.error("API did not return success:", data.message);
				}
			} catch (error) {
				console.error("Failed to fetch user:", error);
				window.notyf.open({
					type: "danger",
					message: "Failed to get user",
					duration: 4000,
				});
			}
		};

		fetchUser();
	}, [auth.token, auth.loading, auth.username]);

	// Convert epoch to date string for date input
	const registrationDate = user.registration_date && !isNaN(user.registration_date) ? new Date(user.registration_date * 1000).toISOString().split("T")[0] : "";
	const approvedDate = user.approved_date && !isNaN(user.approved_date) ? new Date(user.approved_date * 1000).toISOString().split("T")[0] : "";

	// Function to handle changes in input fields
	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		if (type === "date") {
			setUser((prevState) => ({
				...prevState,
				[name]: new Date(value).getTime() / 1000, // Convert back to epoch timestamp
			}));
		} else {
			setUser((prevState) => ({
				...prevState,
				[name]: type === "checkbox" ? checked : value,
			}));
		}
	};

	const handleUpdateUser = async (event) => {
		event.preventDefault(); // Prevent the default form submission behavior

		// Get the password value from the input field
		const passwordInput = document.getElementById("change_password").value;

		// Prepare the updated user data, conditionally including the password
		const updatedUser = {
			...user,
			...(passwordInput && { password: passwordInput }), // Include password only if it's not empty
		};

		const response = await fetch(`${config.apiUrl}/fpanel/admin/updateUser`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${auth.token}`,
			},
			body: JSON.stringify({
				token: auth.token,
				username: auth.username,
				data: updatedUser,
			}),
		});

		const responseData = await response.json();
		if (responseData.success) {
			window.notyf.open({
				type: "success",
				message: "User updated successfully",
				duration: 4000,
			});
		} else {
			console.error("Failed to update user:", responseData.message);
			window.notyf.open({
				type: "danger",
				message: "Failed to update user: " + responseData.message,
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage User {userId}</h1>
							<div>
								<Link to="/panel/admin/manage-users/view" className="btn btn-primary rounded-pill">
									<i className="far fa-plus"></i> Back
								</Link>
								<Link to={`/panel/admin/manage-users/${userId}/analytics`} className="btn btn-primary rounded-pill ms-2">
									<i className="fa fa-chart-line"></i> Analytics
								</Link>
								<Link to={`/panel/admin/manage-users/${userId}/withdraw`} className="btn btn-primary rounded-pill ms-2">
									<i className="fa-solid fa-money-bill-alt"></i> Pot Balances
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
										{/* Form for user details */}
										<form onSubmit={handleUpdateUser}>
											<div className="mb-3">
												<label htmlFor="username" className="form-label">
													Username
												</label>
												<input type="text" className="form-control" id="username" value={user.username} onChange={handleChange} name="username" />
											</div>
											<div className="mb-3">
												<label htmlFor="registrationDate" className="form-label">
													Registration Date
												</label>
												<input type="date" className="form-control" id="registration_date" value={registrationDate} onChange={handleChange} name="registration_date" />
											</div>
											<div className="mb-3">
												<label htmlFor="approvedDate" className="form-label">
													Approved Date
												</label>
												<input type="date" className="form-control" id="approved_date" value={approvedDate} onChange={handleChange} name="approved_date" />
											</div>
											<div className="mb-3">
												<label htmlFor="trafficMethod" className="form-label">
													Traffic Method
												</label>
												<input type="text" className="form-control" id="traffic_method" value={user.traffic_method} onChange={handleChange} name="traffic_method" />
											</div>
											<div className="mb-3">
												<label htmlFor="howFindUs" className="form-label">
													How Did They Find Us?
												</label>
												<input type="text" className="form-control" id="how_find_us" value={user.how_find_us} onChange={handleChange} name="how_find_us" />
											</div>
											<div className="mb-3">
												<label htmlFor="botToken" className="form-label">
													Bot Token
												</label>
												<input type="text" className="form-control" id="bot_token" value={user.bot_token} onChange={handleChange} name="bot_token" />
											</div>
											<div className="mb-3">
												<label htmlFor="status" className="form-label">
													Status
												</label>
												<select className="form-select" value={user.status} onChange={handleChange} name="status">
													<option value="activated">Active</option>
													<option value="pending_approval">Pending Approval</option>
													<option value="banned">Banned</option>
												</select>
											</div>
											<div className="mb-3">
												<label htmlFor="userLevel" className="form-label">
													User Level
												</label>
												<select className="form-select" value={user.user_level} onChange={handleChange} name="user_level">
													<option value="admin">Admin</option>
													<option value="user">User</option>
												</select>
											</div>
											<div className="mb-3">
												<label htmlFor="botChatId" className="form-label">
													Bot Chat ID
												</label>
												<input type="text" className="form-control" id="bot_chat_id" value={user.bot_chat_id} onChange={handleChange} name="bot_chat_id" />
											</div>
											<div className="mb-3 form-check">
												<input
													type="checkbox"
													className="form-check-input"
													id="useGlobalTelegramTokens"
													checked={user.use_global_telegram_tokens}
													onChange={handleChange}
													name="use_global_telegram_tokens"
												/>
												<label className="form-check-label" htmlFor="useGlobalTelegramTokens">
													Use Global Telegram Tokens
												</label>
											</div>
											<div className="mb-3">
												<label htmlFor="withdrawAddress" className="form-label">
													Withdraw Address
												</label>
												<input type="text" className="form-control" id="withdraw_address" value={user.withdraw_address} onChange={handleChange} name="withdraw_address" />
											</div>
											<div className="mb-3">
												<label htmlFor="avatarURL" className="form-label">
													Avatar URL
												</label>
												<div className="d-flex align-items-center">
													<input type="text" className="form-control" id="avatarURL" value={user.avatarURL} onChange={handleChange} name="avatarURL" />
													<img
														className="rounded"
														src={user.avatarURL ? user.avatarURL : "img/newLogoLight.png"}
														alt="User Avatar"
														style={{ width: "50px", height: "50px", marginLeft: "10px" }}
													/>
												</div>
											</div>
											<div className="mb-3">
												<label htmlFor="xp" className="form-label">
													XP
												</label>
												<input type="number" className="form-control" id="xp" value={user.xp} onChange={handleChange} name="xp" />
											</div>
											<div className="mb-3">
												<label htmlFor="changePassword" className="form-label">
													Change Password
												</label>
												<input type="text" className="form-control" id="change_password" onChange={handleChange} name="password" />
											</div>
											<button type="submit" className="btn btn-primary">
												Save Changes
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
