import TopBar from "./Sections/Header/TopBar";
import Menu from "./Sections/Header/Menu";
import { Link } from "react-router-dom";
import {useAuth} from "../../Hooks/useAuthentication";
import { useEffect, useState } from "react";
import { config } from "../../config";
import { blurUsername } from "../../Helpers/blurUsername";
import { useXPSystem } from "../../Hooks/useXPSystem";
import { getStyledUsername } from "../../Hooks/useXPSystem";

export default function Leaderboard() {
	const { username, token, loading, userID } = useAuth();
	const [leaderboard, setLeaderboard] = useState([]);

	const { styledUsername, styledBluredUsername } = useXPSystem(0, username);

	useEffect(() => {
		if (loading || !token) return;

		const fetchData = async () => {
			try {
				const response = await fetch(
					`${config.apiUrl}/fpanel/user/getLeaderBoard`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({
							token: token,
							username: username,
						}),
					}
				);

				const data = await response.json(); // Parse JSON response

				if (data && data.success) {
					// Set your state with data here
					console.log("Data set:", data.data);
					setLeaderboard(data.data);
				} else {
					throw new Error("Failed to fetch leaderboard data");
				}
			} catch (error) {
				window.notyf.open({
					type: "danger",
					message: error.message || "Failed getting leaderboard",
					duration: 4000,
				});
			}
		};

		fetchData();
	}, [token, loading, username]); // Ensure dependencies are correct

	const makePosition = (index) => {
		switch (index) {
			case 1:
				return <span className="badge bg-success rounded-pill p-2">{index}</span>;
			case 2:
				return <span className="badge bg-primary rounded-pill p-2">{index}</span>;
			case 3:
				return <span className="badge bg-warning rounded-pill p-2">{index}</span>;
			default:
				return <span className="badge bg-secondary rounded-pill p-2">{index}</span>;
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Global Leaderboard</h1>
						</div>

						<div className="alert alert-primary alert-outline-coloured alert-dismissible" role="alert">
							<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
							<div className="alert-icon">
								<i className="far fa-fw fa-bell" />
							</div>
							<div className="alert-message">The more you earn, the higher you will be on the leaderboard.</div>
						</div>

						<div className="row">
							<div className="col-12 col-md-12 col-sm-12 col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped" style={{ width: "100%" }}>
												<thead>
													<tr>
														<th>Position</th>
														<th>Username</th>
														<th>Hits</th>
														<th>Connects</th>
														<th>Revenue</th>
													</tr>
												</thead>
												<tbody>
													{leaderboard &&
														leaderboard.length > 0 &&
														leaderboard.map((user, index) => (
															<tr>
																<td>{makePosition(index + 1)}</td>
																<td style={{ minWidth: "120px" }}>
																	<img
																		className="bg-dark rounded-circle"
																		style={{ verticalAlign: "middle", marginRight: "10px" }}
																		src={user.avatarURL && user.avatarURL !== null ? user.avatarURL : "img/newLogoLight.png"}
																		width={32}
																		height={32}
																		alt="Avatar"
																	/>
																	<p style={{ display: "inline", marginBottom: 0, verticalAlign: "middle" }}>{getStyledUsername(user.username, user.levelName)}</p>
																</td>
																<td>{user.total_hits}</td>
																<td>{user.total_connects}</td>
																<td>
																	<span className="badge bg-primary rounded-pill p-2">${user.total_hit_price ? parseFloat(user.total_hit_price).toFixed(2) : 0}</span>
																</td>
															</tr>
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
