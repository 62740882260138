import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../Hooks/useAuthentication";

const AdminRoute = ({ component: Component, ...rest }) => {
	const { isAuthenticated, userLevel, loading } = useAuth();

	useEffect(() => {
		console.log(`AdminRoute`, Component);
	}, []);

  useEffect(() => {
    console.log(`userLevel`, userLevel);
  }, [userLevel]);

	if (loading) {
		return <div>Loading...</div>;
	}

	return <Route {...rest} render={(props) => (isAuthenticated && userLevel === "admin" ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

export default AdminRoute;
