import { useState, useEffect } from 'react';
import { getDomainIcon, getDefaultIcon, placeholderIcon } from '../Helpers/getDomainIcon';

const useDomainIcons = (domains) => {
  const [domainIcons, setDomainIcons] = useState({});

  useEffect(() => {
    const icons = domains.reduce((acc, domain) => ({
      ...acc,
      [domain.domain]: getDefaultIcon()
    }), {});

    setDomainIcons(icons);

    domains.forEach(async (domain) => {
      const icon = await getDomainIcon(domain.domain);
      setDomainIcons(prev => ({ ...prev, [domain.domain]: icon }));
    });
  }, [domains]);

  return domainIcons;
};

export default useDomainIcons;