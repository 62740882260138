import { truncateText } from "../../../Helpers/truncateText";
import { Link } from "react-router-dom";
import { timeAgo } from "../../../Helpers/epochTimeAgo";

export default function DashboardTickets({ recentTickets }) {
	const makeStatusBadge = (status) => {
		switch (status) {
			case "open":
				return <span className="badge bg-primary rounded mt-2 d-block p-2">Open</span>;
			case "closed":
				return <span className="badge bg-danger rounded mt-2 d-block p-2">Closed</span>;
			case "awaiting-reply":
				return <span className="badge bg-warning rounded text-light mt-2 d-block p-2">Awaiting Reply</span>;
			default:
				return <span className="badge bg-secondary rounded mt-2 d-block p-2">Unknown</span>;
		}
	};

	return (
		<div className="col-12">
			<div className="card">
				<div className="card-body">
					<h4 className="card-title mb-4">My Recent Tickets</h4>

					<div className="row">
						{recentTickets.map((ticket, index) => (
							<div
								className="col-12 mb-3" // Full width with margin at the bottom
								key={index}
							>
								<div className="card h-100 bg-light shadow-md border-0"> {/* Removed border for a cleaner look */}
									<div className="card-body d-flex flex-column">
										<h5 className="card-title text-truncate">{ticket.subject}</h5>
										<p className="card-text flex-grow-1">{ticket.message ? truncateText(ticket.message, 40) : "No message"}</p>
										<div className="mt-auto">
											<Link
												to={`/panel/tickets/${ticket.ticketID}`}
												className="btn btn-primary mb-2 rounded"
											>
												View Ticket <i className="fa-solid fa-arrow-right"></i>
											</Link>
											{ticket.ticketStatus ? makeStatusBadge(ticket.ticketStatus) : <span className="badge bg-secondary rounded">Unknown</span>}
											<small className="text-muted d-block mt-2">{ticket.dateCreated ? timeAgo(ticket.dateCreated) : "Unknown"}</small>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}