import TopBar from "./Sections/Header/TopBar";
import Menu from "./Sections/Header/Menu";
import { useAuth } from "../../Hooks/useAuthentication";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { getStyledUsername, useXPSystem } from "../../Hooks/useXPSystem";
import { timeAgo } from "../../Helpers/epochTimeAgo";

export default function MyAccount() {
	const history = useHistory();
	const auth = useAuth();
	const { getUserRank, currentLevelXP, nextLevelXP } = useXPSystem(auth.xp, auth.username);

	// State for avatar update
	const [file, setFile] = useState(null);

	// State for password change
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

	// State for account deletion
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	useEffect(() => {
		console.log('Component re-rendered with auth:', auth);
	}, [auth]);

	// Handlers
	const handleFileChange = (event) => {
		setFile(event.target.files[0]);
	};

	const handleUrlChange = async (event) => {
		const url = event.target.value;
		
		try {
			const response = await fetch(url, { method: "HEAD" });
			const contentType = response.headers.get("content-type");
			
			if (response.ok && contentType && contentType.startsWith("image/")) {
				auth.setAuth(url, 'avatar');
				setFile(null); // Clear file input if URL is provided
			} else {
				throw new Error("URL does not point to a valid image.");
			}
		} catch (error) {
			window.notyf.open({ type: "error", message: "Please enter a valid image URL.", duration: 4000 });
			auth.setAuth("", 'avatar'); // Clear the URL if it's not valid
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		auth.setAuth(true, 'loading');
	
		if (!file && !auth.avatar) {
			window.notyf.open({ type: "error", message: "Please provide an avatar file or URL.", duration: 4000 });
			return;
		}
	
		const formData = new FormData();
		formData.append("token", auth.token);
		formData.append("username", auth.username);
	
		if (file) {
			formData.append("avatarFile", file);
		} else {
			formData.append("avatarUrl", auth.avatar);
		}
	
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/update-avatar`, {
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${auth.token}`,
				},
			});
	
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
	
			const data = await response.json();
			if (data.success) {

				console.log(`[*] Avatar updated to ${data.avatarPath}`);

				window.notyf.open({ type: "success", message: data.message || "Avatar updated successfully", duration: 4000 });
				auth.setAuth(data.avatarPath, 'avatar');
				localStorage.setItem("avatar", data.avatarPath);
				auth.setAuth(false, 'loading');
				console.log(`[*] Avatar updated to ${data.avatarPath}`);

			} else {
				throw new Error(data.message);
			}
		} catch (error) {
			window.notyf.open({ type: "error", message: error.message || "An error occurred", duration: 4000 });
		} finally {
			auth.setAuth(false, 'loading');
		}
	};

	const handleChangePasswordSubmit = async (event) => {
		event.preventDefault();
		const currentPassword = event.target.inputPasswordCurrent.value;
		const newPassword = event.target.inputPasswordNew.value;
		const newPasswordConfirm = event.target.inputPasswordNew2.value;

		if (newPassword !== newPasswordConfirm) {
			window.notyf.open({ type: "error", message: "Passwords do not match", duration: 4000 });
			return;
		}

		if (newPassword.length < 4) {
			window.notyf.open({ type: "error", message: "Password must be at least 4 characters long", duration: 4000 });
			return;
		}

		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/updatePassword`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					currentPassword,
					newPassword,
				}),
			});

			const data = await response.json();
			if (data.success) {
				window.notyf.open({ type: "success", message: data.message, duration: 4000 });
			} else {
				throw new Error(data.message);
			}
		} catch (error) {
			window.notyf.open({ type: "error", message: error.message || "Failed to update password", duration: 4000 });
		}
	};

	const handleNotificationsSubmit = async (event) => {
		event.preventDefault();

		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/updateBotTokens`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					botToken: auth.telegramGlobalToken,
					chatId: auth.telegramChatID,
					useGlobal: auth.useGlobalTelegramPings,
					pingMethod: auth.pingMethod,
					groupChatID: auth.groupChatID,
				}),
			});

			const data = await response.json();
			if (data.success) {
				window.notyf.open({ type: "success", message: data.message || "Settings updated successfully", duration: 4000 });
			} else {
				throw new Error(data.message);
			}
		} catch (error) {
			window.notyf.open({ type: "error", message: error.message || "Failed to update settings", duration: 4000 });
		}
	};

	const handleCheckboxChange = (e) => {
		auth.setAuth(e.target.checked, 'useGlobalTelegramPings');
	};

	// Handle delete account
	const handleDeleteAccount = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/delete-user`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
				}),
			});
			const data = await response.json();
			if (data.success) {
				window.notyf.open({ type: "success", message: data.message, duration: 4000 });
				setTimeout(() => {
					history.push("/login");
				}, 4000);
			} else {
				throw new Error(data.message);
			}
		} catch (error) {
			window.notyf.open({ type: "error", message: error.message || "Failed to delete account", duration: 4000 });
		}
	};

	if (auth.loading) {
		return <div>Loading...</div>;
	}

	const handleInputChange = (field, value) => {
		console.log('Updating state:', field, value);
		auth.setAuth(value, field);  // Now passing value first, then field name
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<h1 className="h3 mb-3">Settings</h1>
						<div className="row">
							<div className="col-md-3 col-xl-2">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title mb-0">Profile Settings</h5>
									</div>
									<div className="list-group list-group-flush" role="tablist">
										<a className="list-group-item list-group-item-action active" data-bs-toggle="list" href="#details" role="tab" aria-selected="true">
											Details
										</a>
										<a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#account" role="tab" aria-selected="true">
											Avatar
										</a>
										<a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#password" role="tab" aria-selected="false" tabIndex={-1}>
											Password
										</a>
										<a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#notifications" role="tab" aria-selected="false" tabIndex={-1}>
											Notifications
										</a>
										<a className="list-group-item list-group-item-action" data-bs-toggle="list" href="#delete" role="tab" aria-selected="false" tabIndex={-1}>
											Delete account
										</a>
									</div>
								</div>
							</div>
							<div className="col-md-9 col-xl-10">
								<div className="tab-content">
									<div className="tab-pane fade show active" id="details" role="tabpanel">
										<div className="card">
											<div className="card-header">
												<h5 className="card-title mb-0">Account Details</h5>
											</div>
											<div className="card-body">
												<form>
													<div className="row">
														<div className="col-md-12">
															<div className="form-group">
																<label className="form-label" htmlFor="inputUsername">
																	Username
																</label>
																<p className="form-control">{getStyledUsername(auth.username, auth.level)}</p>
															</div>
															<div className="form-group">
																<label className="form-label" htmlFor="inputUsername">
																	Rank
																</label>
																<p className="form-control">{getStyledUsername(auth.level, auth.level)}</p>
															</div>
															<div className="form-group">
																<label className="form-label" htmlFor="inputUsername">
																	XP
																</label>
																<p className="form-control">
																	{auth.xp} XP / {currentLevelXP} XP
																</p>
															</div>
															<div className="form-group">
																<label className="form-label" htmlFor="inputUsername">
																	Date Joined
																</label>
																<p className="form-control">{auth.dateJoined ? timeAgo(auth.dateJoined) : "Unknown"}</p>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>

									<div className="tab-pane fade show" id="account" role="tabpanel">
										<div className="card">
											<div className="card-header">
												<h5 className="card-title mb-0">Change Avatar</h5>
											</div>
											<div className="card-body">
												<form onSubmit={handleSubmit}>
													<div className="row">
														<div className="col-md-12">
															<div className="text-center">
																<img
																	alt="Avatar"
																	src={
																		auth.avatar && auth.avatar !== null && auth.avatar !== "undefined" && auth.avatar !== "" && auth.avatar !== "null"
																			? auth.avatar
																			: "img/newLogoLight.png"
																	}
																	className="rounded img-responsive mt-2 border border-2 border-primary"
																	style={{ objectFit: "cover" }}
																	height={128}
																	width={128}
																/>
																<input type="file" onChange={handleFileChange} className="form-control mt-3" />
																<p className="mt-4">OR enter a URL</p>
																<input onChange={handleUrlChange} type="text" className="form-control mb-4" id="inputPasswordCurrent" placeholder="Enter URL" />
															</div>
														</div>
													</div>
													<button type="submit" className="btn btn-primary">
														Save changes
													</button>
												</form>
											</div>
										</div>
									</div>

									<div className="tab-pane fade" id="password" role="tabpanel">
										<div className="card">
											<div className="card-body">
												<h5 className="card-title">Password</h5>
												<form onSubmit={handleChangePasswordSubmit}>
													<div className="mb-3">
														<label className="form-label" htmlFor="inputPasswordCurrent">
															Current password
														</label>
														<input
															type="password"
															className="form-control"
															id="inputPasswordCurrent"
															value={currentPassword}
															onChange={(e) => setCurrentPassword(e.target.value)}
														/>
														<small>
															<Link to="/forgot-password">Forgot your password?</Link>
														</small>
													</div>
													<div className="mb-3">
														<label className="form-label" htmlFor="inputPasswordNew">
															New password
														</label>
														<input type="password" className="form-control" id="inputPasswordNew" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
													</div>
													<div className="mb-3">
														<label className="form-label" htmlFor="inputPasswordNew2">
															Verify password
														</label>
														<input
															type="password"
															className="form-control"
															id="inputPasswordNew2"
															value={newPasswordConfirm}
															onChange={(e) => setNewPasswordConfirm(e.target.value)}
														/>
													</div>
													<button type="submit" className="btn btn-primary">
														Save changes
													</button>
												</form>
											</div>
										</div>
									</div>

									<div className="tab-pane fade" id="notifications" role="tabpanel">
										<div className="card">
											<div className="card-body">
												<h5 className="card-title">Notifications</h5>
												<form onSubmit={handleNotificationsSubmit}>
													<div className="form-check mb-4">
														<input
															className="form-check-input"
															type="checkbox"
															value=""
															id="defaultCheck1"
															checked={auth.useGlobalTelegramPings}
															onChange={handleCheckboxChange}
														/>
														<label className="form-check-label" htmlFor="defaultCheck1">
															Use Global Telegram Notifications For All Domains
														</label>
													</div>
													{/*<div className="mb-3">
														<label className="form-label" htmlFor="inputPasswordNew">
															Telegram Global Token
														</label>
														<input
															type="text"
															className="form-control"
															id="inputPasswordNew"
															value={auth.telegramGlobalToken}
															onChange={(e) => auth.setAuth(e.target.value, 'telegramGlobalToken')}
														/>
													</div>*/}
													<div className="mb-3">
														<label className="form-label" htmlFor="inputPasswordNew">
															Telegram Chat ID
														</label>
														<input
															type="text"
															readOnly
															disabled
															className="form-control"
															id="inputPasswordNew"
															value={auth.telegramChatID}
														/>
													</div>
													<hr />

													<div className="mb-3">
														<label className="form-label" htmlFor="inputPasswordNew">
															Group Chat Chat ID
														</label>
														<input
															type="text"
															className="form-control"
															value={auth.groupChatID || ''}
															onChange={(e) => handleInputChange('groupChatID', e.target.value)}
														/>
													</div>

													<hr />

													<div className="mb-3">
														<label className="form-label" htmlFor="inputPasswordNew">
															Ping Method
														</label>
														<select
															className="form-select"
															onChange={(e) => handleInputChange('pingMethod', e.target.value)}
															value={auth.pingMethod}
														>
															<option value="userChatID">User Chat ID</option>
															<option value="groupChatID">Group Chat ID</option>
														</select>
													</div>

													<button type="submit" className="btn btn-primary">
														Save changes
													</button>
												</form>
											</div>
										</div>
									</div>

									<div className="tab-pane fade" id="delete" role="tabpanel">
										<div className="card">
											<div className="card-body">
												<h5 className="card-title">Delete Account</h5>
												<div className="alert alert-danger alert-outline-coloured alert-dismissible mt-3" role="alert">
													<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
													<div className="alert-icon">
														<i className="far fa-fw fa-bell" />
													</div>
													<div className="alert-message">
														<strong>Warning!</strong> This action is irreversible and all of your data will be lost.
													</div>
												</div>

												<button className="btn btn-danger" onClick={() => setShowDeleteConfirm(true)}>
													Delete Account
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			{showDeleteConfirm && (
				<>
					<div className="modal fadeUp show" tabIndex="-1" style={{ display: "block" }} aria-modal="true" role="dialog">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Confirm Delete</h5>
									<button type="button" className="btn-close" onClick={() => setShowDeleteConfirm(false)} />
								</div>
								<div className="modal-body">Are you sure you want to delete your account? This action cannot be undone.</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" onClick={() => setShowDeleteConfirm(false)}>
										Cancel
									</button>
									<button type="button" className="btn btn-danger" onClick={handleDeleteAccount}>
										Confirm Action
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
		</div>
	);
}