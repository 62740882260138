import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { useParams } from "react-router-dom";

export default function EditDesign() {
	const auth = useAuth();
	const { designId } = useParams();

	const [design, setDesign] = useState({
		id: 0,
		name: "",
		previewImg: "",
		serverLocationFile: "",
		active: 0,
	});

	useEffect(() => {
		if (auth.loading) return;
		getDesign(designId);
	}, [designId]);

	const getDesign = async (designId) => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/getDesignData`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({ id: designId, username: auth.username, token: auth.token }),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch design data");
			}
			const data = await response.json();
			setDesign(data?.data);
		} catch (error) {
			console.error("Failed to fetch design:", error);
			window.notyf.open({
				type: "danger",
				message: `Failed to fetch design: ${error.message}`,
				duration: 4000,
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/updateDesign`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({ username: auth.username, token: auth.token, data: design }),
			});
			if (!response.ok) {
				throw new Error("Failed to update design");
			}
			const data = await response.json();
			window.notyf.open({
				type: "success",
				message: "Design updated successfully",
				duration: 4000,
			});
		} catch (error) {
			console.error("Error updating design:", error);
			window.notyf.open({
				type: "danger",
				message: `Error updating design: ${error.message}`,
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Design {designId}</h1>
							<div>
								<Link to="/panel/admin/manage-designs/view" className="btn btn-primary rounded-pill">
									<i className="fa-solid fa-arrow-left"></i> Back
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
										<form onSubmit={handleSubmit}>
											<div class="mb-3">
												<label for="id" class="form-label">
													ID
												</label>
												<input type="number" class="form-control" id="id" value={design?.id || ""} readOnly disabled />
											</div>

											<div class="mb-3">
												<label for="name" class="form-label">
													Name
												</label>
												<input type="text" class="form-control" id="name" value={design?.name || ""} onChange={(e) => setDesign({ ...design, name: e.target.value })} />
											</div>

											<div className="mb-3 row align-items-center">
												<div className="col-md-11">
													<label htmlFor="previewImg" className="form-label">
														Preview Image URL
													</label>
													<input
														type="url"
														className="form-control"
														id="previewImg"
														value={design?.previewImg || ""}
														onChange={(e) => setDesign({ ...design, previewImg: e.target.value })}
													/>
												</div>
												<div className="col-md-1 d-flex align-items-center">
													<img src={design?.previewImg || ""} alt="Preview" className="img-fluid rounded responsive-image" style={{ width: "100%", marginTop: "24px" }} />
												</div>
											</div>

											<div class="mb-3">
												<label for="serverLocationFile" class="form-label">
													Server Location File
												</label>
												<input type="text" class="form-control" id="serverLocationFile" value={design?.serverLocationFile || ""} onChange={(e) => setDesign({ ...design, serverLocationFile: e.target.value })} />
											</div>

											<div class="mb-3">
												<label for="active" class="form-label">
													Status
												</label>
												<select class="form-select" id="active" value={design.active == 1 ? "1" : "0"} onChange={(e) => setDesign({ ...design, active: Number(e.target.value) })}>
													<option value="1">Active</option>
													<option value="0" selected>
														Inactive
													</option>
												</select>
											</div>

											<button type="submit" class="btn btn-primary">
												Save
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
