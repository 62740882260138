import { useState } from "react";
import { useHistory } from "react-router-dom";
import TopBar from "../Sections/Header/TopBar"
import Menu from "../Sections/Header/Menu"
import { Link } from "react-router-dom"
import {useAuth} from "../../../Hooks/useAuthentication";
import {config} from "../../../config";

export default function NewTicket() {

    const history = useHistory();
    const {username, token, loading} = useAuth();

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('token'); // Adjust how you access the token as needed
        const username = localStorage.getItem('username'); // Adjust as needed
    
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/user/submitTicket`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    username,
                    token,
                    subject: title,
                    message
                })
            });
    
            const data = await response.json();
    
            if (data.success) {
                window.notyf.open({
                    type: 'success',
                    message: 'Ticket submitted successfully',
                    duration: 4000
                });

                history.push('/panel/support/all');
            } else {
                window.notyf.open({
                    type: 'danger',
                    message: data.message,
                    duration: 4000
                });
            }
        } catch (error) {
            window.notyf.open({
                type: 'danger',
                message: 'Failed to submit ticket',
                duration: 4000
            });
        }
    };

    return (
        <div className="wrapper">
        <Menu />
        <div className="main">
          <TopBar />
          <main className="content">
            
            <div className="container-fluid p-0">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="h3">New Ticket</h1>
                <div>
                  <Link to="/panel/support/all" className="btn btn-primary rounded-pill">
                    <i className="fas fa-fw fa-arrow-left"></i> Back to Tickets
                  </Link>
                </div>
              </div>
            </div>
  
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Subject</label>
                                    <input type="text" className="form-control" id="title" name="title" onChange={(e) => setTitle(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Message</label>
                                    <textarea className="form-control" id="message" name="message" rows="3" onChange={(e) => setMessage(e.target.value)} />
                                </div>
                                <button type="submit" className="btn btn-primary rounded-pill">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </div>
        </div>
    )
}