import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../Hooks/useAuthentication";
import Menu from "../Authed/Sections/Header/Menu";
import TopBar from "../Authed/Sections/Header/TopBar";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { config } from "../../config";

export default function ManageNewsEdit() {
	const { newsId } = useParams();
	const auth = useAuth();
	const [news, setNews] = useState(null);
	const editorRef = useRef(null); // Create a ref for the editor container
	const [isEditorInitialized, setIsEditorInitialized] = useState(false);

	const [showModal, setShowModal] = useState(false); // State to control modal visibility
	const [modalContent, setModalContent] = useState(""); // State to hold the HTML content for the modal
	const [previousModalContent, setPreviousModalContent] = useState(null);

    const codeRef = useRef(null);

	useEffect(() => {
		if (auth.loading) return;
		if (!auth.token) return;

		const fetchNews = async () => {
			if (auth.loading) return;
			try {
				const response = await fetch(`${config.apiUrl}/fpanel/admin/getNewsData`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${auth.token}`,
					},
					body: JSON.stringify({ username: auth.username, token: auth.token, id: newsId }),
				});

				const data = await response.json();
				if (data.success) {
					setNews(data.data);
				} else {
					console.error("API did not return success:", data.message);
				}
			} catch (error) {
				console.error("Failed to fetch news:", error);
				window.notyf.open({
					type: "danger",
					message: "Failed to get news",
					duration: 4000,
				});
			}
		};

		fetchNews();
	}, [auth.token, auth.loading, auth.username, newsId]);

	const formattedDate = news && news.dateAdded && !isNaN(news.dateAdded) ? new Date(news.dateAdded * 1000).toISOString().split("T")[0] : "";
	const setFormattedDate = (date) => {
		setNews((prevNews) => ({ ...prevNews, dateAdded: new Date(date).getTime() / 1000 }));
	};

	useEffect(() => {
		if (!news) return;

		console.log("Effect running", { isEditorInitialized, currentRef: editorRef.current });

		if (isEditorInitialized) {
			console.log("Editor already initialized");
			return;
		}

		const initializeEditor = () => {
			if (!editorRef.current) {
				console.log("Editor ref not available yet, retrying...");
				setTimeout(initializeEditor, 50); // Retry after a short delay
				return;
			}

			console.log("Initializing editor");

			const editor = window.pell.init({
				element: editorRef.current,
				onChange: (html) => {
					setNews((prevNews) => ({ ...prevNews, content: html }));
				},
				defaultParagraphSeparator: "p",
				styleWithCSS: true,
				actions: [
					"bold",
					"underline",
					"italic",
					"link",
					"image",
					"quote",
					"code",
					"heading1",
					"heading2",
					"paragraph",
					{
						name: "changeColor",
						icon: "C",
						title: "Change Color",
						result: () => {
							const input = document.createElement("input");
							input.type = "color";
							input.value = "#000000"; // Default color
							input.onchange = (e) => {
								window.pell.exec("foreColor", e.target.value);
							};
							input.click(); // Trigger the color picker
						},
					},
					{
						name: "indent",
						icon: "I",
						title: "Indent",
						result: () => window.pell.exec("indent", 1, true),
					},
					// Add action to edit image size
					{
						name: "resizeImage",
						icon: "<b>S</b>", // Use a bold 'S' as the icon
						title: "Resize Image",
						result: () => {
							let selection = window.getSelection();
							if (!selection.rangeCount) {
								console.log("No selection found", selection);
								return;
							}

							// Directly accessing the first child of the focusNode
							let img = selection.focusNode.firstChild;

							if (img && img.tagName === "IMG") {
								const newSize = prompt("Enter new width x height (e.g., 640x480):");
								if (newSize) {
									const [width, height] = newSize.split("x");
									img.style.width = `${width}px`;
									img.style.height = `${height}px`;
								}
							} else {
								alert("Please select an image.");
							}
						},
					},
					{
						name: "editHTML",
						icon: "<b>H</b>", // Use a bold 'H' as the icon
						title: "Edit HTML",
						result: () => {
							const selection = window.getSelection();
							if (selection.rangeCount > 0) {
								const range = selection.getRangeAt(0);
								const container = document.createElement("div");
								container.appendChild(range.cloneContents());
								setModalContent(container.innerHTML); // Set the selected HTML content to modal
								setPreviousModalContent(container.innerHTML);
							} else {
								setModalContent(editorRef.current.innerHTML); // Fallback to the entire editor content if no selection
							}
							setShowModal(true); // Open the modal
						},
					},
				],
			});

			setIsEditorInitialized(true);

			console.log(`news content: ${news.content}`);

			// To change the editor's content:
			editor.content.innerHTML = news.content;

			// Cleanup function to prevent memory leaks
			return () => {
				if (editor.destroy) {
					editor.destroy();
				}
				setIsEditorInitialized(false);
			};
		};

		initializeEditor();
	}, [news]); // Make sure this effect runs only once

	// Now let's make the update news function that will update the html for the news
	const updateNews = async () => {
		if (!news) return;

		const response = await fetch(`${config.apiUrl}/fpanel/admin/updateNews`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${auth.token}`,
			},
			body: JSON.stringify({ username: auth.username, token: auth.token, data: news }),
		});

		const data = await response.json();
		if (data.success) {
			window.notyf.open({
				type: "success",
				message: "News updated successfully",
				duration: 4000,
			});
		} else {
			window.notyf.open({
				type: "danger",
				message: "Failed to update news",
				duration: 4000,
			});
		}
	};

	const handleModalSave = () => {
		const editor = editorRef.current; // Assuming this is the Pell editor instance
		if (!editor) {
			console.error("Editor instance not found");
			return;
		}

		console.log(`editor found`, editor);

		// Ensure selection is within the .pell-content element
		const pellContent = editorRef.current.querySelector(".pell-content");
		const selection = window.getSelection();
		if (pellContent.contains(selection.anchorNode)) {
			const selectedHtml = selection.toString();
			console.log(`selected HTML: ${selectedHtml}`);
		} else {
			console.log("Selection is not inside the editor content.");
		}

		console.log(`modal content of selected html: ${previousModalContent}`);

		// Get the selected HTML content
		const selectedHtml = selection;
		console.log(`selected HTML: ${selectedHtml}`);

		// Get the new text from the modal
		const newText = modalContent;
		console.log(`new modal content: ${newText}`);

		console.log(`current editor content: ${editor.content.innerHTML}`);

		// Replace the selected HTML with the new HTML
		editor.content.innerHTML = editor.content.innerHTML.replace(previousModalContent, newText);

		// Update the news content state
		setNews((prevNews) => ({ ...prevNews, content: editor.innerHTML }));

		// Close the modal
		setShowModal(false);
	};
    const handleInput = (e) => {
        const currentContent = e.currentTarget.textContent;
        const selection = window.getSelection();
        const cursorPos = selection.anchorOffset; // Save current cursor position
        setModalContent(currentContent);

        // Restore the cursor position and reapply highlighting
        requestAnimationFrame(() => {
            if (codeRef.current && selection.rangeCount > 0) {
                const range = document.createRange();
                range.setStart(codeRef.current.firstChild, cursorPos);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        });
    };

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage News</h1>
							<div>
								<Link to="/panel/admin/manage-news/view" className="btn btn-primary rounded-pill">
									<i className="fa-solid fa-arrow-left"></i> Back
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										{news ? (
											<form>
												<div className="mb-3">
													<label htmlFor="newsTitle" className="form-label">
														Title
													</label>
													<input type="text" className="form-control" id="newsTitle" value={news.title || ""} />
												</div>
												<div className="mb-3">
													<div id="editorjs" className="pell" ref={editorRef}></div>
												</div>
												<div className="mb-3">
													<label htmlFor="newsDate" className="form-label">
														Date
													</label>
													<input type="date" className="form-control" id="newsDate" value={formattedDate} onChange={(e) => setFormattedDate(e.target.value)} />
												</div>
											</form>
										) : (
											<p>No news data available.</p>
										)}
										<button type="button" onClick={updateNews} className="btn btn-primary rounded-pill">
											Update News
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			{showModal && (
				<>
					<div className="modal fadeUp show" id="centeredModalPrimary" tabIndex={-1} style={{ display: "block" }} aria-modal="true" role="dialog">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" style={{ textTransform: "capitalize" }}>
										Edit HTML Content
									</h5>
									<button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close" />
								</div>
								<div className="modal-body m-0 p-0">
                                    <pre className="language-html" style={{ width: "100%" }}>
                                        <code
                                            ref={codeRef}
                                            onInput={handleInput}
                                            style={{ minHeight: "150px", whiteSpace: "pre-wrap", width: "100%", display: "block" }}
                                            contentEditable="true"
                                            className="language-html"
                                        >
                                            {modalContent}
                                        </code>
                                    </pre>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-primary" onClick={handleModalSave}>
										Save Changes
									</button>
									<button
										type="button"
										className="btn btn-secondary"
										onClick={() => {
											setShowModal(false);
											setPreviousModalContent(null);
										}}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
		</div>
	);
}
