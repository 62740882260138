import { ethers } from "ethers";
import { useState } from "react";
import { rewardPotConfig } from "../config";

export const connectMetaMask = async (isCheck = false) => {
	if (typeof window.ethereum !== "undefined") {
		try {
			// For connection check, don't request accounts (which prompts the user)
			if (!isCheck) {
				await window.ethereum.request({ method: "eth_requestAccounts" });
			}

			const accounts = await window.ethereum.request({ method: "eth_accounts" });

			if (accounts.length > 0) {
				const address = accounts[0];
				const chainId = await window.ethereum.request({ method: "eth_chainId" });

				if (chainId !== rewardPotConfig.chainId) {
					await window.ethereum.request({ method: "wallet_switchEthereumChain", params: [{ chainId: `0x${rewardPotConfig.chainId.toString(16)}` }] });
				}

				const balance = await window.ethereum.request({
					method: "eth_getBalance",
					params: [address, "latest"],
				});

				return {
					address,
					balance: ethers.utils.formatEther(balance), // Convert from wei to ETH using ethers
				};
			} else if (!isCheck) {
				throw new Error("No accounts found. Please connect to MetaMask.");
			}
		} catch (error) {
			if (!isCheck) {
				throw error;
			}
		}
	} else if (!isCheck) {
		throw new Error("MetaMask not detected");
	}

	return null;
};

export const sendEther = async (amount) => {
    if (typeof window.ethereum !== "undefined") {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
			console.log(signer);

            // Define the contract address and ABI
            const contractAddress = rewardPotConfig.address;
            const contractABI = [
                // Include only the necessary function's ABI
                "function deposit() external payable {"
            ];

            // Load the contract
            const contract = new ethers.Contract(contractAddress, contractABI, signer);

            // Convert amount to wei
            const amountWei = ethers.utils.parseEther(amount.toString());

            // Call the contract function
            const tx = await contract.deposit({ value: amountWei });

            // Wait for the transaction to be mined
            const receipt = await tx.wait();

            return {
                success: true,
                transactionHash: receipt.transactionHash,
                blockNumber: receipt.blockNumber,
            };
        } catch (error) {
            return {
                success: false,
                error: error.message,
            };
        }
    } else {
        throw new Error("MetaMask not detected");
    }
};

export function SendEtherComponent({inputAddress}) {
    const [address, setAddress] = useState(inputAddress);
    const [amount, setAmount] = useState("");
    const [result, setResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleSend = async () => {
      try {
        setIsLoading(true);
        setResult(null);
        const txResult = await sendEther(amount);
        setResult(txResult);
      } catch (error) {
        setResult({ success: false, error: error.message });
      } finally {
        setIsLoading(false);
      }
    };

	return (
		<div className="container mt-4">
			<form className="mb-3">
				<div className="mb-3">
					<label htmlFor="amount" className="form-label">
						Amount in ETH
					</label>
					<input type="number" className="form-control" id="amount" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="0.0" step="0.01" />
				</div>
                <button type="button" className="btn btn-primary" onClick={handleSend} disabled={isLoading}>
					{isLoading ? "Sending..." : "Send ETH"}
				</button>
				{isLoading && (
					<div className="alert alert-primary alert-dismissible mt-2" role="alert">
						<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
						<div className="alert-message">Transaction in progress. Please check MetaMask.</div>
					</div>
				)}
			</form>
			{result && !isLoading && (
				<div className={`alert ${result.success ? "alert-success" : "alert-warning"} alert-dismissible`} role="alert">
					<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
					<div className="alert-message">
						<strong>{result.success ? "Success!" : "Error!"}</strong>{" "}
						{result.success ? `Transaction successful! Hash: ${result.transactionHash}` : `Error: ${result.error.replace(/\(.*/, "").trim()}`}
					</div>
				</div>
			)}
		</div>
	);
}
