import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Hooks/useAuthentication";
import { Link } from "react-router-dom";
import { splitNameservers } from "../../../Helpers/splitNameservers";
import useDomainIcons from "../../../Hooks/useDomainIcons";
import { getDefaultIcon, placeholderIcon } from "../../../Helpers/getDomainIcon";
import { config, chainMappingRPCs } from "../../../config";
import { ChainLogo } from "../../../Helpers/getChainLogo";
import { ethers } from "ethers";

export default function XPricing() {
	const auth = useAuth();

	const [pricing, setPricing] = useState(0);

    const fetchPricing = async () => {
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/user/getPricing`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: auth.username,
                    token: auth.token
                })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if(!data) {
                throw new Error(data.message);
            }
            if(data.data) {
                setPricing(data.data);
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            window.notyf.open({
                type: 'danger',
                message: 'Failed to fetch pricing',
                duration: 5000,
            });
        }
    }

    useEffect(() => {
        if (!auth.isLoading) {
            fetchPricing();
        }
    }, [auth.isLoading]);

    const included = {
        'Panel': true,
        'Detailed Statistics': true,
        'All Supported Chains': true,
        'Auto NFT Seller': true,
        'Auto Token Seller': true,
        'Dedicated Support': true,

        'Website Builder': true,
        'Lightning RPCs': true,
        'Automated Reward Pot': true,
        'Dedicated Support': true,
        'Dedicated Support': true,
        'Dedicated Support': true,
    }

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">{config.siteName} Pricing</h1>
						</div>

						<div class="row">
							<div class="col-md-10 col-xl-8 mx-auto">
								<h1 class="text-center">We have a plan for everyone</h1>
								<p class="lead text-center mb-4">Whether you're a pro, or a newbie, our plan is equal for everyone.</p>

								<div class="row justify-content-center mt-3 mb-2">
									<div class="col-auto">
										<nav class="nav btn-group">
											<a
												href="#monthly"
												class="btn btn-outline-primary active"
												data-bs-toggle="tab"
											>
												Monthly billing
											</a>
											<a
												href="#annual"
												class="btn btn-outline-primary"
												data-bs-toggle="tab"
											>
												Annual billing
											</a>
										</nav>
									</div>
								</div>

								<div class="tab-content">
									<div
										class="tab-pane fade show active"
										id="monthly"
									>
										<div class="row py-4">
											<div class="col-sm-12 mb-3 mb-md-0">
												<div class="card text-center h-100">
													<div class="card-body d-flex flex-column">
														<div class="mb-4">
															<h5>Plus</h5>
															<span class="display-4">{pricing ? `${pricing}%` : '0%'}</span>
															<span>/hit</span>
														</div>
														<h6>Includes:</h6>
														<ul class="list-unstyled">
                                                            {Object.keys(included).map((feature, index) => (
                                                                <li class="mb-2" key={index}>{feature} <i className="fa-solid fa-check-circle text-success"></i></li>
                                                            ))}
														</ul>
														<div class="mt-auto">
															<Link
																to="/panel/domains/add"
																class="btn btn-lg btn-primary"
															>
																Try it for free
															</Link>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										class="tab-pane fade"
										id="annual"
									>
										<div class="row py-4">
											<div class="col-sm-12 mb-3 mb-md-0">
												<div class="card text-center h-100">
													<div class="card-body d-flex flex-column">
														<div class="mb-4">
															<h5>Plus</h5>
															<span class="display-4">{pricing ? `${pricing}%` : '0%'}</span>
															<span>/hit</span>
														</div>
														<h6>Includes:</h6>
														<ul class="list-unstyled">
                                                            {Object.keys(included).map((feature, index) => (
                                                                <li class="mb-2" key={index}>{feature} <i className="fa-solid fa-check-circle text-success"></i></li>
                                                            ))}
														</ul>
														<div class="mt-auto">
                                                        <Link
																to="/panel/domains/add"
																class="btn btn-lg btn-primary"
															>
																Try it for free
															</Link>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
