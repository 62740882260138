import { useState, useEffect } from "react"; // Ensure useState and useEffect are imported
import {useAuth} from "../../Hooks/useAuthentication"; // Ensure useAuth is imported
import { config } from "../../config"; // Assuming config file path
import TopBar from "./Sections/Header/TopBar";
import Menu from "./Sections/Header/Menu";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { renderColorCircle } from "../../Helpers/moduleColorIcon";
import { useXPSystem } from "../../Hooks/useXPSystem";
import { blurUsername } from "../../Helpers/blurUsername";
import { getStyledUsername } from "../../Hooks/useXPSystem"; // Import the function

export default function GlobalHits() {
	const { username, token, loading, userID } = useAuth();
	const [hits, setHits] = useState([]);

	const { styledUsername, styledBluredUsername } = useXPSystem(0, username);

	useEffect(() => {
		if (loading) return;
		fetchGlobalHits();
	}, [token, loading]); // Add loading to dependency array

	const fetchGlobalHits = async () => {
		const url = `${config.apiUrl}/fpanel/user/globalHits`;
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ username, token }),
			});
			const data = await response.json();
			if (data.success) {
				if (data.data.length > 0) {
					setHits(
						data.data
							? data.data.sort((a, b) => {
									return b.datetime - a.datetime;
							  })
							: []
					);
				} else {
					window.notyf.open({
						type: "danger",
						message: "No hits data available",
						duration: 4000,
					});
				}
			} else {
				throw new Error("Failed to fetch hits");
			}
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: error.message || "Failed to fetch hits",
				duration: 4000,
			});
		}
	};

	const fixWorth = (worth) => {
		// Convert worth to a float if it's a string, then format to two decimal places
		return parseFloat(worth).toFixed(2);
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Top 100 Recent Global Hits</h1>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped" style={{ width: "100%" }}>
												<thead>
													<tr>
														<th>Username</th>
														<th>Hit Value</th>
														<th>Design</th>
														<th>Module Name</th>
														<th>Date</th>
													</tr>
												</thead>
												<tbody>
													{hits.map((hit, index) => (
														<tr key={hit.id}>
															<td>
																<img
																	src={hit.avatarURL && hit.avatarURL !== null ? hit.avatarURL : "/img/newLogoLight.png"}
																	alt={hit.username}
																	className="rounded me-2"
																	style={{ width: "30px", height: "30px" }}
																/>
																{getStyledUsername(hit.username, hit.levelName)}
															</td>
															<td>${hit.totalWorth ? fixWorth(hit.totalWorth) : 0}</td>
															<td style={{ textTransform: "capitalize" }}>{hit.designName ? hit.designName : (hit.designId === '999' ? "Custom" : "N/A")}</td>
															<td style={{ textTransform: "capitalize" }}>
																{hit.moduleName ? renderColorCircle(hit.moduleName, { display: "inline-block", marginRight: "5px" }) : "N/A"}
																{hit.moduleName ? hit.moduleName : "-"}
															</td>
															<td>{hit.datetime ? timeAgo(hit.datetime) : "N/A"}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
