import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Hooks/useAuthentication";
import { Link } from "react-router-dom";
import { splitNameservers } from "../../../Helpers/splitNameservers";
import useDomainIcons from "../../../Hooks/useDomainIcons";
import { getDefaultIcon, placeholderIcon } from "../../../Helpers/getDomainIcon";
import { config, chainMappingRPCs } from "../../../config";
import { ChainLogo } from "../../../Helpers/getChainLogo";
import { ethers } from "ethers";
import { timeAgo } from "../../../Helpers/epochTimeAgo";

export default function Cloaker() {
	const { username, token, loading, userID } = useAuth();

	const [domains, setDomains] = useState([]);

    const fetchWebsites = async () => {
        const response = await fetch(`${config.apiUrl}/fpanel/user/getDomainsWithRulesCount`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ username, token })
        });
  
        const data = await response.json();
        if (data.success && data.data.length > 0) {
          setDomains(data.data);
        }
    };

    useEffect(() => {
        if (loading) return;
        fetchWebsites();
    }, [loading]);

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Cloaker</h1>
						</div>
					</div>

					<div
						className="alert alert-warning alert-outline-coloured alert-dismissible"
						role="alert"
					>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="alert"
							aria-label="Close"
						/>
						<div className="alert-icon">
							<i className="far fa-fw fa-bell" />
						</div>
						<div className="alert-message">Only active domains that are connected via CDN are supported with the cloaker</div>
					</div>

					<div class="row">
						{domains &&
							domains.length > 0 &&
							domains.map((domain) => {
								return (
									<div class="col-md-4 col-sm-6">
										<div class="card">
											<div class="card-body">
												<h3 className="text-center d-block">{domain.domain}</h3>
                                                <div className="text-center mt-3 d-flex justify-content-center align-items-center gap-2 mb-4">
                                                    <span className="badge bg-primary">Added {domain.dateAdded ? timeAgo(domain.dateAdded) : 'N/A'}</span>
                                                    <span className={`badge bg-${domain.status === 'active' ? 'success' : 'danger'}`}>{domain.status}</span>
                                                    <span className="badge bg-secondary">{domain.rulesCount} rule(s)</span>
                                                    <span className="badge bg-warning">{domain.logsCount} log(s)</span>
                                                </div>
												<Link
													to={`/panel/cloaker/manage/${domain.domain}`}
													className="btn btn-primary mt-2 w-100"
												>
													Manage
												</Link>
											</div>
										</div>
									</div>
								);
							})}
					</div>
				</main>
			</div>
		</div>
	);
}
