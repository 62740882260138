// Usage: https://github.com/Grsmto/simplebar
import SimpleBar from "simplebar";

let simplebarInstance;
let toggleSidebar;
let resizeTimeout;

const initializeSimplebar = () => {
  const simplebarElement = document.querySelector(".js-simplebar");

  if (simplebarElement) {
    //console.log("SimpleBar element found");
    simplebarInstance = new SimpleBar(simplebarElement);

    const sidebarDropdowns = document.querySelectorAll(".js-sidebar [data-bs-parent]");
    sidebarDropdowns.forEach(link => {
      link.addEventListener("shown.bs.collapse", recalculateSimpleBar);
      link.addEventListener("hidden.bs.collapse", recalculateSimpleBar);
    });
  } else {
    //console.log("SimpleBar element not found");
  }
};

const recalculateSimpleBar = () => {
  if (simplebarInstance) {
    //console.log("Recalculating SimpleBar");
    try {
      simplebarInstance.recalculate();
    } catch (error) {
      console.error("Error recalculating SimpleBar:", error);
    }
  }
};

const initializeSidebarCollapse = () => {
  const sidebarToggleElement = document.querySelector(".js-sidebar-toggle");

  if (sidebarToggleElement) {
    //console.log("Sidebar toggle element found");

    toggleSidebar = () => {
      const sidebarElement = document.querySelector(".js-sidebar");
      if (sidebarElement) {
        //console.log("Sidebar toggle clicked");
        sidebarElement.classList.toggle("collapsed")

        sidebarElement.addEventListener("transitionend", handleTransitionEnd, { once: true });
      } else {
        //console.log("Sidebar element not found on toggle click");
      }
    };

    sidebarToggleElement.addEventListener("click", toggleSidebar);
  } else {
    //console.log("Sidebar toggle element not found");
  }
};

const handleTransitionEnd = () => {
  //console.log("Sidebar transition ended, dispatching resize event");
  window.dispatchEvent(new Event("resize"));
};

const cleanup = () => {
  const sidebarToggleElement = document.querySelector(".js-sidebar-toggle");
  if (sidebarToggleElement && toggleSidebar) {
    sidebarToggleElement.removeEventListener("click", toggleSidebar);
  }

  const sidebarDropdowns = document.querySelectorAll(".js-sidebar [data-bs-parent]");
  sidebarDropdowns.forEach(link => {
    link.removeEventListener("shown.bs.collapse", recalculateSimpleBar);
    link.removeEventListener("hidden.bs.collapse", recalculateSimpleBar);
  });

  clearTimeout(resizeTimeout);
};

const initialize = () => {
  //console.log("Initializing sidebar");
  initializeSimplebar();
  initializeSidebarCollapse();
};

// Wait until DOM is fully loaded
document.addEventListener("DOMContentLoaded", initialize);

// Fallback to reinitialize if the sidebar doesn't work initially
resizeTimeout = setTimeout(() => {
  if (!document.querySelector(".js-sidebar.collapsed")) {
    //console.log("Reinitializing sidebar as a fallback");
    cleanup();
    initialize();
  }
}, 500); // Adjust the delay as needed

export { initialize, cleanup };