import React from 'react';
import { useHistory } from 'react-router-dom';
import { config } from '../config';
import { Link } from 'react-router-dom';

const Landing = () => {
  const history = useHistory();

  const handleRegister = () => {
    history.push('/register');
  };

  const handleLogin = () => {
    history.push('/login');
  };

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      color: '#fff',
      fontFamily: 'Arial, sans-serif'
    },
    content: {
      textAlign: 'center',
      maxWidth: '600px',
      padding: '50px',
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '10px'
    },
    heading: {
      fontSize: '1.5em',
      marginBottom: '5px'
    },
    paragraph: {
      fontSize: '1.2em',
      marginBottom: '30px'
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px'
    },
    button: {
      padding: '10px 20px',
      fontSize: '1em',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background 0.3s ease',
      color: '#fff'
    },
    registerButton: {
      background: '#191f2e'
    },
    loginButton: {
      background: '#191f2e'
    }
  };

  return (
    <div style={styles.container}>
        <div className="card p-4">
        <img className="card-img-top rounded" src="img/newLogoLight.png" style={{ width: "30px", height: "30px", textAlign: "center", display: "block", margin: "auto", marginTop: "20px" }} alt="Card image cap" />
        <div className="card-body text-center">
            <h5 className="card-title">Welcome to {config.siteName}</h5>
            <p className="card-text">
            {config.siteDescription}
            </p>
            <div class="d-flex justify-content-evenly align-items-center" role="group" aria-label="Basic example">

                <Link to="/register" className="btn btn-primary">
                Register
                </Link>
                <Link to="/login" className="btn btn-primary">
                Login
                </Link>
            </div>
        </div>
        </div>

    </div>
  );
};

export default Landing;
