import Menu from '../Authed/Sections/Header/Menu';
import TopBar from '../Authed/Sections/Header/TopBar';
import {useAuth} from '../../Hooks/useAuthentication';
import { useState, useEffect } from 'react';
import { config } from '../../config';

export default function Designs() {
  const { username, token, loading } = useAuth();

  useEffect(() => {
    console.log(`token: ${token}`)
  }, [token])

  const [designs, setDesigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [showPreviewButton, setShowPreviewButton] = useState(null);

  useEffect(() => {
    if (loading) return;
    fetchDesigns();
  }, [username, token]);

  const fetchDesigns = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/fpanel/user/getDesigns`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          username,
          token
        })
      });
      const data = await response.json();

      if (data && data.success) {
        console.log(data.data);
        setDesigns(data.data || []);
      } else {
        setDesigns([]);
        window.notyf.open({
          type: 'danger',
          message: 'Failed fetching designs!',
          duration: 4000
        });
      }
    } catch (error) {
      console.error('Failed to fetch designs:', error);
      window.notyf.open({
        type: 'danger',
        message: 'Failed fetching designs!',
        duration: 4000
      });
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredDesigns = designs.filter((design) => design.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const openModal = (design) => {
    setSelectedDesign(design);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="wrapper">
      <Menu />
      <div className="main">
        <TopBar />
        <main className="content">
          <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Designs</h1>

            <div className="alert alert-primary alert-outline-coloured alert-dismissible" role="alert">
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
              <div className="alert-icon">
                <i className="far fa-fw fa-bell" />
              </div>
              <div className="alert-message">Open a ticket if you need us to make a design for you.</div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <form className="mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for a design"
                        value={searchTerm}
                        onChange={handleSearchChange} // Handle input changes
                      />
                    </form>

                    <div className="row">
                      {filteredDesigns.map(
                        (
                          design, index
                        ) => (
                          <div className="col-md-6 col-sm-2 col-lg-3">
                            <div
                              className={`card w-100 border-2`}
                              style={
                                design.previewImg
                                  ? {
                                      minHeight: '200px',
                                      backgroundPosition: 'center',
                                      backgroundImage: `url(${design.previewImg})`,
                                      backgroundSize: 'cover'
                                    }
                                  : {
                                      backgroundColor: '#19222C'
                                    }
                              }
                              onMouseEnter={() => setShowPreviewButton(index)}
                              onMouseLeave={() => setShowPreviewButton(null)}
                            >
                              <div className="card-body">
                                <h5 className="badge bg-primary">{design.name}</h5>
                                {showPreviewButton === index && (
                                  <button
                                    className="btn btn-primary preview-btn shadow-lg"
                                    style={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)'
                                    }}
                                    onClick={() => openModal(design)}
                                  >
                                    Preview
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {showModal && (
        <>
          <div
            className="modal fadeUp show"
            id="centeredModalPrimary"
            tabIndex={-1}
            style={{ display: "block" }}
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" style={{ textTransform: 'capitalize' }}>{selectedDesign ? selectedDesign.name : 'Design Preview'}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body m-0 p-0">
                  {selectedDesign && (
                    <img src={selectedDesign.previewImg} alt="Design Preview" style={{ width: '100%' }} />
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </div>
  );
}
