import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { truncateText } from "../../Helpers/truncateText";
import { useXPSystem } from "../../Hooks/useXPSystem";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { splitNameservers } from "../../Helpers/splitNameservers";

// Charts and Stat Boxes
import StatBoxesHelper from "../StatBoxesHelper";
import { LineChartHelper } from "../ChartHelper";

export default function ManageDomains() {
	const auth = useAuth();

	const [domains, setDomains] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	// Stat and chart data
	// Add these new state variables
	const [chartData, setChartData] = useState([]);
	const [stats, setStats] = useState([]);

	// Filter by a value and type
	const [filterType, setFilterType] = useState("all");
	const [filterValue, setFilterValue] = useState("");

    const fetchDomains = useCallback(async (page) => {
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/admin/getSites`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.token}`,
                },
                body: JSON.stringify({
                    token: auth.token,
                    username: auth.username,
                    page: page,
                    filterType: filterType,
                    filterValue: filterValue,
                }),
            });

            const data = await response.json();
            if (data.success) {
                setDomains(data?.data);
                setTotalPages(data?.totalPages);
                setChartData(data?.chartData);
                setStats(data?.statData);
                console.log("Total Pages:", data.totalPages); // Debugging
            } else {
                console.error("API did not return success:", data.message);
            }
        } catch (error) {
            console.error("Failed to fetch domains:", error);
            window.notyf.open({
                type: "danger",
                message: "Failed to get domains",
                duration: 4000,
            });
        }
    }, [auth.token, auth.username, filterType, filterValue]);

    const handlePageChange = useCallback((newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            fetchDomains(newPage);
        }
    }, [totalPages, fetchDomains]);

    useEffect(() => {
        if (auth.loading) return;
        fetchDomains(currentPage);
    }, [auth.loading, currentPage, fetchDomains]);

	const handleDeleteSelected = async () => {
		const selectedIds = domains.filter((domain) => domain.isSelected).map((domain) => domain.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No domains selected",
				duration: 4000,
			});
			return;
		}
		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteSites`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "Domains deleted successfully",
				duration: 4000,
			});
			// Optionally refresh the list or remove deleted users from state
			setDomains((prev) => prev.filter((domain) => !selectedIds.includes(domain.id)));
			fetchDomains(currentPage);
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: "Failed to delete domains",
				duration: 4000,
			});
		}
	};

	const handleSelectDomain = (domainId) => {
		setDomains((prevDomains) =>
			prevDomains.map((domain) => {
				if (domain.id === domainId) {
					return { ...domain, isSelected: !domain.isSelected };
				}
				return domain;
			})
		);
	};

	const handleSelectAll = () => {
		const areAllSelected = domains.every((domain) => domain.isSelected);
		setDomains((prevDomains) =>
			prevDomains.map((domain) => ({
				...domain,
				isSelected: !areAllSelected,
			}))
		);
	};

	const makeStatusBadge = (status) => {
		switch (status) {
			case "active":
				return (
					<span className="badge bg-success" style={{ textTransform: "capitalize" }}>
						{status}
					</span>
				);
			case "pending-setup":
				return (
					<span className="badge bg-warning" style={{ textTransform: "capitalize" }}>
						{status}
					</span>
				);
			case "banned":
				return (
					<span className="badge bg-danger" style={{ textTransform: "capitalize" }}>
						{status}
					</span>
				);
			default:
				return (
					<span className="badge bg-secondary" style={{ textTransform: "capitalize" }}>
						{status}
					</span>
				);
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Domains ({stats?.totalDomains || 0})</h1>
							<div>
								<button onClick={handleDeleteSelected} className="btn btn-danger rounded-pill ms-2">
									<i className="fa-solid fa-trash"></i> Delete Selected
								</button>
							</div>
						</div>

						<div className="row">
							<StatBoxesHelper
								title="Highest $"
								value={`${stats?.highestRevenue?.username} ($${stats?.highestRevenue?.revenue.toFixed(2)})`}
								cardSize="3"
								icon="users"
								useSmallText={true}
							/>
							<StatBoxesHelper
								title="Oldest Domain"
								value={`${stats?.oldestDomain?.domain} (${timeAgo(stats?.oldestDomain?.dateAdded)})`}
								cardSize="3"
								icon="users"
								useSmallText={true}
							/>
							<StatBoxesHelper
								title="Newest Domain"
								value={`${stats?.newestDomain?.domain} (${timeAgo(stats?.newestDomain?.dateAdded)})`}
								cardSize="3"
								icon="users"
								useSmallText={true}
							/>
							<StatBoxesHelper
								title="Most Recent Hit"
								value={`${stats?.recentHit?.domain} (${timeAgo(stats?.recentHit?.datetime)}) ($${
									stats?.recentHit?.totalWorth ? parseFloat(stats?.recentHit?.totalWorth).toFixed(2) : 0
								})`}
								cardSize="3"
								icon="users"
								useSmallText={true}
							/>
						</div>

						<div className="row">
							<div className="col-12 col-md-12 col-xl-12">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title">Top 5 Domains Revenue</h4>
									</div>
									<div className="card-body">
										<LineChartHelper chartData={chartData} labelType="domain" />
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
									<div className="mb-3 d-flex">
											<select className="form-select me-2" style={{ width: "auto" }} value={filterType} onChange={(e) => setFilterType(e.target.value)}>
												<option value="all">All</option>
												<option value="domain">Domain</option>
												<option value="userID">User ID</option>
												<option value="connectionNameservers">Nameservers</option>
												<option value="status">Status</option>
												<option value="zone_id">Zone ID</option>
												<option value="dns_id">DNS ID</option>
												<option value="designId">Design ID</option>
											</select>
											{filterType !== "all" && (
												<input
													type="text"
													className="form-control"
													style={{ width: "auto" }}
													value={filterValue}
													onChange={(e) => setFilterValue(e.target.value)}
													placeholder={`Filter by ${filterType}`}
												/>
											)}
										</div>
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>
															<input
																onChange={handleSelectAll}
																checked={domains.length > 0 && domains.every((domain) => domain.isSelected)}
																type="checkbox"
																className="form-check-input"
															/>
														</th>
														<th>ID</th>
														<th>Domain</th>
														<th>User ID</th>
														<th>Total Revenue</th>
														<th>Best Design</th>
														<th>Connection</th>
														<th>Nameservers</th>
														<th>Created</th>
														<th>Status</th>
														<th>Zone ID</th>
														<th>DNS ID</th>
														<th>Last NS Check</th>
														<th>Design</th>
														<th style={{ minWidth: "100px" }}>% Override</th>
														<th>Manage</th>
													</tr>
												</thead>
												<tbody>
													{domains && domains.length > 0 ? (
														domains.map((domain) => (
															<tr key={domain.id}>
																<td>
																	<input
																		checked={domain.isSelected || false}
																		onChange={() => handleSelectDomain(domain.id)}
																		type="checkbox"
																		className="form-check-input"
																	/>
																</td>
																<td>{domain.id}</td>
																<td>
																	<span className="badge bg-primary">{domain.domain}</span>
																</td>
																<td style={{ minWidth: "200px" }}>
																	{domain.userID} {domain.username ? <>
																	<span className="badge bg-secondary">{domain.username}</span>
																	<Link to={`/panel/admin/manage-users/${domain.userID}`} className="ms-2 text-dark"><i className="fas fa-edit"></i></Link>
																	<Link to={`/panel/admin/manage-users/${domain.userID}/analytics`} className="ms-2 text-dark"><i className="fas fa-chart-line"></i></Link>
																	<Link to={`/panel/admin/manage-users/${domain.userID}/withdraw`} className="ms-2 text-dark"><i className="fa-solid fa-balance-scale"></i></Link>
																	</> : "N/A"}
																</td>
																<td style={{ minWidth: "130px" }}>
																	{domain.totalRevenue ? `$${domain.totalRevenue.toFixed(2)}` : "N/A"}
																</td>
																<td style={{ minWidth: "130px" }}>{domain.bestDesign}</td>
																<td>{domain.connectionType}</td>
																<td dangerouslySetInnerHTML={{ __html: domain.connectionNameservers ? splitNameservers(domain.connectionNameservers) : "N/A" }}></td>
																<td style={{ minWidth: "130px" }}>{domain.dateAdded ? timeAgo(domain.dateAdded) : "N/A"}</td>
																<td style={{ minWidth: "130px" }}>{domain.status ? makeStatusBadge(domain.status) : "N/A"}</td>
																<td>
																	<code>{domain.zone_id}</code>
																</td>
																<td>
																	<code>{domain.dns_id}</code>
																</td>
																<td style={{ minWidth: "130px" }}>{domain.lastNSCheck ? timeAgo(domain.lastNSCheck) : "N/A"}</td>
																<td style={{ minWidth: "130px" }}>
																	{domain.designId}{" "}
																	<span className="badge bg-secondary" style={{ textTransform: "capitalize" }}>
																		{domain.designName}
																	</span>
																</td>
																<td>
																	{domain.percentage_override ? (
																		<>
																			<i className="fas fa-check-circle text-success"></i> {domain.percentage_override}%
																		</>
																	) : (
																		<>
																			<i className="fas fa-times-circle text-danger"></i>
																		</>
																	)}
																</td>
																<td style={{ minWidth: "300px" }}>
																	<Link to={`/panel/admin/manage-domains/${domain.id}/edit`} className="btn btn-primary">
																		<i className="far fa-edit"></i>
																	</Link>
																	<Link to={`/panel/admin/manage-domains/${domain.id}/drain-settings`} className="btn btn-primary ms-2">
																		<i className="fas fa-fw fa-cogs"></i>
																	</Link>
																	<Link to={`/panel/admin/manage-domains/${domain.id}/actions`} className="btn btn-primary ms-2">
																		<i className="fas fa-fw fa-eye"></i>
																	</Link>
																	<Link to={`/panel/admin/manage-domains/${domain.id}/cloudflare`} className="btn btn-primary ms-2">
																		<i className="fas fa-fw fa-cloud"></i>
																	</Link>
																	<Link to={`/panel/admin/manage-domains/${domain.id}/analytics`} className="btn btn-primary ms-2">
																		<i className="fas fa-fw fa-chart-line"></i>
																	</Link>
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="12" className="text-center">
																No tickets found
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<footer className="footer bg-light w-100">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span>
													Showing {Math.min((currentPage - 1) * 10 + 1, domains.length)}-{Math.min(currentPage * 10, domains.length)} of {domains.length} results
												</span>
											</div>
											<nav className="w-100">
												<ul className="pagination">
													{/* Previous Page Button */}
													<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
															Previous
														</button>
													</li>
													{/* Current Page Button */}
													{totalPages > 0 &&
														Array.from({ length: totalPages }, (_, i) => (
															<li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
																<button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
																	{i + 1}
																</button>
															</li>
														))}
													{/* Next Page Button */}
													<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
															Next
														</button>
													</li>
												</ul>
											</nav>
										</footer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
