import React, { useEffect, useState } from "react";
import { config } from "../../../config";
import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import initializeScripts from "../../../Hooks/useInitializeScripts";
import { useAuth } from "../../../Hooks/useAuthentication";
import { Link } from "react-router-dom";
import { timeAgo } from "../../../Helpers/epochTimeAgo";

import DashboardCardDetails from "./DashboardCardDetails";
import DashboardNews from "./DashboardNews";
import DashboardTickets from "./DashboardTickets";
import XPSystem from "./XPSystem";
import { useXPSystem } from "../../../Hooks/useXPSystem";
import { useRef } from "react";
import './health.css';

const HealthBar = ({ healthPercentage }) => {
    const [amount, setAmount] = useState(0);
    const [progress, setProgress] = useState(0);
    const healthBarRef = useRef(null);

    useEffect(() => {
        // Animate from 0 to healthPercentage
        let start = 0;
        const end = healthPercentage;
        const duration = 1000; // Duration of the animation in milliseconds
        const stepTime = 20; // Time between each step in milliseconds
        const totalSteps = duration / stepTime;
        const increment = (end - start) / totalSteps;

        const interval = setInterval(() => {
            start += increment;
            if (start >= end) {
                start = end; // Ensure it doesn't exceed the target
                clearInterval(interval);
            }
            setProgress(start / 100); // Update progress as a decimal
            setAmount(start); // Update amount
        }, stepTime);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [healthPercentage]);

    useEffect(() => {
        updateHealthBar();
    }, [progress]);

    const updateHealthBar = () => {
        // Set styles based on progress
        if (progress <= 0.1) {
            healthBarRef.current.style.setProperty('--label-color', '#444');
            healthBarRef.current.style.setProperty('--health-level-1', '#444');
            healthBarRef.current.style.setProperty('--health-level-2', '#444');
        } else if (progress <= 0.35) {
            healthBarRef.current.style.setProperty('--label-color', '#DC143C');
            healthBarRef.current.style.setProperty('--health-level-1', '#8B0000');
            healthBarRef.current.style.setProperty('--health-level-2', '#DC143C');
        } else if (progress <= 0.65) {
            healthBarRef.current.style.setProperty('--label-color', 'goldenrod');
            healthBarRef.current.style.setProperty('--health-level-1', 'goldenrod');
            healthBarRef.current.style.setProperty('--health-level-2', 'gold');
        } else {
            healthBarRef.current.style.setProperty('--label-color', '#83FA21');
            healthBarRef.current.style.setProperty('--health-level-1', '#83FA21');
            healthBarRef.current.style.setProperty('--health-level-2', '#C8FF9B');
        }

        updateLiColors();
    };

    const updateLiColors = () => {
        const liElements = document.querySelectorAll('.health-bar li');
        const totalBars = liElements.length;
        const filledBars = Math.floor(progress * totalBars);

        liElements.forEach((li, index) => {
            if (index < filledBars) {
                li.classList.remove('grayscale');
                li.style.backgroundImage = 'linear-gradient(0deg, var(--health-level-1) 0%, var(--health-level-2) 84%)';
            } else {
                li.classList.add('grayscale');
                li.style.backgroundImage = 'linear-gradient(0deg, var(--health-level-1) 0%, var(--health-level-2) 84%)';
            }
        });
    };

    return (
        <div className="health-bar-container" ref={healthBarRef}>
            <div className="health-bar">
                <div className="col">
                    <h3 className="health-val">
                        <span>{('000' + Math.floor(amount)).substr(-3)}</span>
                    </h3>
                </div>
                <div className="col">
                    <ul>
                        {Array.from({ length: 8 }, (_, i) => (
                            <li key={i} className="grayscale" style={{ transition: 'background-image 0.5s ease' }}></li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

function Dashboard() {
	const { username, token, loading, level, xp } = useAuth();
	const [dashStats, setDashStats] = useState({
		totalHits: 0,
		totalDomains: 0,
		bestHittingDesign: "",
		bestHittingModule: "",
		recentNews: [],
		recentTickets: [],
		registrationDate: "",
		totalRevenue: 0,
		totalTickets: 0,
	});

	const [onlineUsers, setOnlineUsers] = useState(0);

	const [accountHealth, setAccountHealth] = useState(0); // is a percentage. 100% is healthy, 0% is unhealthy.

	const { currentLevel, xpPercentage, levelColor, xpToNextLevel, currentLevelXP, nextLevel, currentXP, levels, highestLevel, styledUsername } = useXPSystem(xp || 0, username);

	useEffect(() => {
		const fetchDashboardStats = async () => {
			if (!loading && token && username) {
				try {
					const response = await fetch(`${config.apiUrl}/fpanel/user/dashStats`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({ token, username }),
					});
					const data = await response.json();
					if (data.success) {
						// Handle success scenario
						console.log("Dashboard stats:", data.data);
						setDashStats({
							// Update the state with the fetched data
							totalHits: data.data.totalHits || 0,
							totalDomains: data.data.totalDomains || 0,
							bestHittingDesign: data.data.bestHittingDesign || "N/A",
							bestHittingModule: data.data.bestHittingModule || "N/A",
							recentNews: data.data.recentNews || [],
							recentTickets: data.data.recentSupportTickets || [],
							registrationDate: data.data.registrationDate || "",
							totalRevenue: data.data.totalRevenue || 0,
							totalTickets: data.data.totalSupportTickets || 0,
						});
					} else {
						throw new Error("Failed to fetch dashboard stats");
					}
				} catch (error) {
					window.notyf.open({
						type: "danger",
						message: error.message || "Failed to fetch dashboard stats",
						duration: 4000,
					});
				}
			}
		};

		const fetchOnlineUsers = async () => {
			if (!loading && token && username) {
				try {
					const response = await fetch(`${config.apiUrl}/fpanel/user/getTotalOnlineUsers`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({ token, username }),
					});
					const data = await response.json();
					if (data.success) {
						setOnlineUsers(data?.data?.totalOnlineUsers || 0);
					} else {
						throw new Error("Failed to fetch online users");
					}
				} catch (error) {
					window.notyf.open({
						type: "danger",
						message: error.message || "Failed to fetch online users",
						duration: 4000,
					});
				}
			}
		};

		const fetchAccountHealth = async () => {
			if (!loading && token && username) {
				try {
					const response = await fetch(`${config.apiUrl}/fpanel/user/checkAccountHealth`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify({ token, username }),
					});
					const data = await response.json();
					if (data.success) {
						setAccountHealth(data?.data?.accountHealthPercentage || 0);
						console.log(`accountHealth set`, data?.data?.accountHealthPercentage);
					} else {
						throw new Error("Failed to fetch account health");
					}
				} catch (error) {
					window.notyf.open({
						type: "danger",
						message: error.message || "Failed to fetch online users",
						duration: 4000,
					});
				}
			}
		}

		fetchOnlineUsers();
		fetchDashboardStats();
		fetchAccountHealth();
	}, [loading, token, username]);

	const MakeAccountHealthCircle = () => {
		var healthPercentage = accountHealth;
		let circleClass = 'rounded-circle ';
	
		// Determine the class based on the health percentage
		if (healthPercentage <= 20) {
			circleClass += 'bg-secondary'; // Dark black/gray
		} else if (healthPercentage <= 40) {
			circleClass += 'bg-danger'; // Red
		} else if (healthPercentage <= 70) {
			circleClass += 'bg-warning'; // Yellow
		} else {
			circleClass += 'bg-success'; // Green
		}
	
		return (
			<div className="health-circle" style={{ width: '10px', height: '10px', display: 'inline-block' }}>
				<div className={circleClass} style={{ width: '100%', height: '100%' }}></div>
			</div>
		);
	}

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="row mb-2 mb-xl-3">
							<div className="col-auto d-none d-sm-block">
								<h3>
									<strong>{config.siteName}</strong> Dashboard
								</h3>
							</div>
							<div className="col-auto ms-auto text-end mt-n1">
								<Link to="/panel/leaderboard" className="btn btn-outline-primary me-2">
									View Leaderboard <i className="fas fa-fw fa-chart-line" />
								</Link>
								<Link to="/panel/domains/add" className="btn btn-primary">
									New Domain <i className="fas fa-fw fa-plus" />
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="alert alert-primary alert-outline-coloured alert-dismissible" role="alert">
									<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
									<div className="alert-icon">
										<i className="far fa-fw fa-bell mt-3" />
									</div>
									<div className="alert-message">
										Welcome to {config.siteName}, <b>{styledUsername}</b>!<br />
										You've been registered for <b>{dashStats.registrationDate ? timeAgo(dashStats.registrationDate, false) : "N/A"}</b> and have made{" "}
										<b>${dashStats.totalRevenue ? dashStats.totalRevenue.toFixed(2) : "0"}</b> in revenue.
									</div>
								</div>
							</div>
						</div>

						{/* New Online Users Section */}
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title mb-4">Online Users</h5>
										<div className="d-flex align-items-center">
											<div className="flex-shrink-0">
												<div className="avatar avatar-xl bg-primary-light rounded-circle d-flex justify-content-center align-items-center">
													<i className="fas fa-users text-primary"></i>
												</div>
											</div>
											<div className="flex-grow-1 ms-3">
												<h3 className="mb-0">
													<span className="online-users-count">{onlineUsers}</span>
												</h3>
												<p className="text-muted mb-0">Users currently online</p>
											</div>
											<div className="flex-shrink-0 ms-3">
												<div className="chart-sparkline chart-sparkline-sm" id="onlineUsersSparkline"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-sm-12 col-md-6 col-lg-6">
								<div className="card">
									<div className="card-body">
										<div className="card-title mb-4">
											<h5>Account Health</h5>
										</div>
										<HealthBar healthPercentage={accountHealth.toFixed()} />
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-12 col-md-6 col-lg-6">
								<div className="card">
									<div className="card-body">
										<div className="card-title mb-4">
											<h5>Account Health</h5>
										</div>
										<h3>Health: {accountHealth.toFixed()}% / 100% <MakeAccountHealthCircle /></h3>
										<p>The higher the bar / health, the healthier your account is.<br/>The bar is based on your total gas used, against your total profit gained.</p>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<h4 className="card-title mb-4">Experience Points (XP) System</h4>
										<Link to="/panel/ranks" className="btn btn-primary btn-sm rounded" style={{ position: "absolute", right: "20px", top: "20px" }}>
											View All Ranks <i className="fas fa-trophy ms-1"></i>
										</Link>
										<div className="row">
											<div className="col-md-12">
												<div className="d-flex align-items-center mb-3">
													<div className="flex-shrink-0">
														<div className="avatar avatar-xl bg-primary-light rounded-circle d-flex justify-content-center align-items-center">
															<i className="fas fa-star text-primary fa-2x"></i>
														</div>
													</div>
													<div className="flex-grow-1 ms-3">
														<div className="d-flex align-items-center mb-3">
															<div className="flex-shrink-0">
																<h5 className="mb-2">Current Level:</h5>
																<span className="badge text-light" style={{ background: levelColor }}>
																	{currentLevel.name} <span dangerouslySetInnerHTML={{ __html: currentLevel.icon }}></span>
																</span>
																{!nextLevel && <span className="badge bg-primary ms-2">Max Level <i className="fas fa-trophy ms-1"></i></span>}
															</div>
														</div>
													</div>
												</div>
												<div className="mb-3">
													<h6>Progress to Next Level</h6>
													<div className="progress" style={{ height: "25px" }}>
														<div
															className="progress-bar progress-bar-striped progress-bar-animated"
															role="progressbar"
															style={{ width: `${xpPercentage}%`, background: levelColor }}
															aria-valuenow={xpPercentage}
															aria-valuemin="0"
															aria-valuemax="100"
														>
															{xpPercentage}%
														</div>
													</div>
													<div className="d-flex justify-content-between mt-1">
														<small>{currentLevel?.xpRange[0]} XP</small>
														<small>{currentLevel?.xpRange[1]} XP</small>
													</div>
												</div>
											</div>
										</div>
										{nextLevel && (
											<div className="alert alert-primary alert-dismissible mt-2" role="alert">
												<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
												<div className="alert-message">
													<i className="fas fa-info-circle me-2"></i>
													Next Level: <strong>{nextLevel.name}</strong> - Unlock at {xpToNextLevel} XP
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<DashboardCardDetails title="My Total Hits" value={dashStats.totalHits ? dashStats.totalHits : "0"} icon="dollar-sign" color="success" />
							<DashboardCardDetails title="My Total Domains" value={dashStats.totalDomains ? dashStats.totalDomains : "0"} icon="layout" color="success" />
							<DashboardCardDetails title="My Best Design" value={dashStats.bestHittingDesign ? dashStats.bestHittingDesign : "N/A"} icon="framer" color="success" />
						</div>

						<div className="row">
							<DashboardCardDetails title="My Best Module" value={dashStats.bestHittingModule ? dashStats.bestHittingModule : "N/A"} icon="cloud-lightning" color="success" />
							<DashboardCardDetails title="My Total Revenue" value={dashStats.totalRevenue ? `$${dashStats.totalRevenue.toFixed(2)}` : "0"} icon="shopping-cart" color="success" />
							<DashboardCardDetails title="My Total Tickets" value={dashStats.totalTickets ? dashStats.totalTickets : "0"} icon="help-circle" color="success" />
						</div>

						<div className="row mb-3">
							<div className="col-12 col-sm-12 col-md-6 col-lg-6">
								<div className="card">
									<div className="card-body">
										<DashboardNews recentNews={dashStats.recentNews} />
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-12 col-md-6 col-lg-6">
								<div className="card">
									<div className="card-body">
										<DashboardTickets recentTickets={dashStats.recentTickets} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}

export default Dashboard;
