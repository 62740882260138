import { config } from '../../../../config';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import feather from 'feather-icons';
import { useEffect } from 'react';

// Define the users menu items
const adminMenuItems = [
  {
    label: 'Dashboard',
    icon: 'home',
    path: '/panel/admin/dashboard',
    hasSubmenu: false
  },
  {
    label: 'Manage Cloaker',
    icon: 'eye-off',
    path: '/panel/admin/manage-cloaker',
    hasSubmenu: false,
  },
  {
    label: 'Manage Users',
    icon: 'users',
    path: '#users',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'View Users', path: '/panel/admin/manage-users/view' },
      { label: 'Add User', path: '/panel/admin/manage-users/add' },
    ]
  },
  {
    label: 'Manage Domains',
    icon: 'layout',
    path: '#domains',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'View Domains', path: '/panel/admin/manage-domains/view' },
    ]
  },
  {
    label: 'Manage Tickets',
    icon: 'help-circle',
    path: '#tickets',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'View Tickets', path: '/panel/admin/manage-tickets/view' },
      { label: 'Add Ticket', path: '/panel/admin/manage-tickets/add' }
    ]
  },
  {
    label: 'Manage News',
    icon: 'send',
    path: '#news',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'View News', path: '/panel/admin/manage-news/view' },
      { label: 'Add News', path: '/panel/admin/manage-news/add' }
    ]
  },
  {
    label: 'Manage Designs',
    icon: 'edit-2',
    path: '#designs',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'View Designs', path: '/panel/admin/manage-designs/view' },
      { label: 'Add Design', path: '/panel/admin/manage-designs/add' }
    ]
  },
  {
    label: 'Manage Blacklists',
    icon: 'slash',
    path: '#blacklists',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'View Blacklists', path: '/panel/admin/manage-blacklists/view' },
      { label: 'Add Blacklist', path: '/panel/admin/manage-blacklists/add' }
    ]
  },
  {
    label: 'Manage Globals',
    icon: 'settings',
    path: '/panel/admin/manage-globals',
    hasSubmenu: false,
  },
  {
    label: 'Manage Reward Pot',
    icon: 'gift',
    path: '/panel/admin/manage-reward-pot',
    hasSubmenu: false,
  },
  {
    label: 'Manage Create2s',
    icon: 'link-2',
    path: '/panel/admin/manage-create2s',
    hasSubmenu: false
  },
  {
    label: 'Manage Daddy',
    icon: 'cpu',
    path: '/panel/admin/manage-daddy',
    hasSubmenu: false
  },
  {
    label: 'Manage API Usages',
    icon: 'share-2',
    path: '/panel/admin/manage-api-usages',
    hasSubmenu: false
  },
  {
    label: 'Manage Sales',
    icon: 'tv',
    path: '#sales',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'Manage NFT Sales', path: '/panel/admin/manage-nft-sales' },
      { label: 'Manage Token Sales', path: '/panel/admin/manage-token-sales' }
    ]
  },
  {
    label: 'My Account',
    icon: 'user',
    path: '/panel/admin/my-account',
    hasSubmenu: false
  },
  {
    label: 'User Views ',
    icon: 'eye',
    path: '#user-views',
    hasSubmenu: true,
    subMenuItems: [
      { label: 'Leaderboard', path: '/panel/leaderboard' },
      { label: 'Reward Pot', path: '/panel/reward-pot' },
      { label: 'Global Hits', path: '/panel/global-hits' },
      { label: 'Ranks', path: '/panel/ranks' },
      { label: 'Auto Seller Statuses', path: '/panel/auto-seller-statuses' }
    ]
  },
];

export default function Menu() {
  const location = useLocation(); // Get the current location

  useEffect(() => {
    console.log("Location changed:", location.pathname);
    feather.replace();
  }, []);

  return (
    <nav id="sidebar" className="sidebar js-sidebar">
      <div className="sidebar-content js-simplebar" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Link className="sidebar-brand" to="/panel/dashboard">
          <span className="sidebar-brand-text align-middle d-flex flex-row justify-content-between align-items-center">
            <img src='/img/newLogoLight.png' alt="Logo" width={32} height={32} className='siteLogo' />
            <span className='siteTextLogo'>{config.siteName}</span>
          </span>
          <svg
            className="sidebar-brand-icon align-middle"
            width="32px"
            height="32px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="miter"
            color="#FFFFFF"
            style={{ marginLeft: '-3px' }}
          >
            <path d="M12 4L20 8.00004L12 12L4 8.00004L12 4Z" />
            <path d="M20 12L12 16L4 12" />
            <path d="M20 16L12 20L4 16" />
          </svg>
        </Link>

        <ul className="sidebar-nav">
          {adminMenuItems.map((item, index) => (
            <li
              key={index}
              className={`sidebar-item ${item.hasSubmenu ? 'has-submenu' : ''} ${location.pathname.endsWith(item.path) ? 'active' : ''}`}
            >
              <Link
                to={item.hasSubmenu ? '#' : item.path}
                className={`sidebar-link ${item.hasSubmenu ? 'collapsed' : ''}`}
                data-bs-target={item.path}
                data-bs-toggle={item.hasSubmenu ? 'collapse' : ''}
              >
                <i className="align-middle" data-feather={item.icon} /> <span className="align-middle">{item.label}</span>
                {item.badge && <span className="sidebar-badge badge bg-primary">{item.badge}</span>}
              </Link>
              {item.hasSubmenu && (
                <ul id={item.path.substring(1)} className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                  {item.subMenuItems.map((subItem, subIndex) => (
                    <li
                      key={subIndex}
                      className={`sidebar-item ${location.pathname.startsWith(subItem.path) ? 'active' : ''}`}
                    >
                      <Link className="sidebar-link" to={subItem.path}>
                        {subItem.label}
                        {subItem.badge && <span className="sidebar-badge badge bg-primary">{subItem.badge}</span>}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>

        <div style={{ flexGrow: 1 }}></div>

        <div className="sidebar-cta">
          <div className="sidebar-cta-content">
            <strong className="d-inline-block mb-2">24/7 Support</strong>
            <div className="mb-3 text-sm">We're here, on hand whenever you need us.</div>
            <div className="d-grid">
              <a href="https://t.me/+Jv8ZJZjQ5ZhjZTNi" className="btn btn-primary" target="_blank">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export {adminMenuItems}