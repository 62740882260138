import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { truncateText } from "../../Helpers/truncateText";
import { useXPSystem } from "../../Hooks/useXPSystem";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { copyToClipboard } from "../../Helpers/copyToClipboard";

// Charts and Stat Boxes
import StatBoxesHelper from "../StatBoxesHelper";
import { LineChartHelper } from "../ChartHelper";

export default function ManageUsers() {
	const auth = useAuth();

	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	// Chart and stats
	const [chartData, setChartData] = useState([]);
	const [stats, setStats] = useState([]);

	// Filter by a value and type
	const [filterType, setFilterType] = useState("all");
	const [filterValue, setFilterValue] = useState("");

    const fetchUsers = useCallback(async (page) => {
        if (auth.loading) return;
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/admin/getUsers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.token}`,
                },
                body: JSON.stringify({
                    token: auth.token,
                    username: auth.username,
                    page: page,
                    filterType: filterType,
                    filterValue: filterValue,
                }),
            });

            const data = await response.json();
            if (data.success) {
                setUsers(data.data);
                setTotalPages(data.totalPages);
                setChartData(data?.chartData);
                setStats(data?.statData);
                console.log("Total Pages:", data.totalPages); // Debugging
            } else {
                console.error("API did not return success:", data.message);
            }
        } catch (error) {
            console.error("Failed to fetch users:", error);
            window.notyf.open({
                type: "danger",
                message: "Failed to get users",
                duration: 4000,
            });
        }
    }, [auth.loading, auth.token, auth.username, filterType, filterValue]);

    const handlePageChange = useCallback((newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            fetchUsers(newPage);
        }
    }, [totalPages, fetchUsers]);

    useEffect(() => {
        if (auth.loading) return;
        fetchUsers(currentPage);
    }, [auth.loading, currentPage, fetchUsers]);

	const makeUserLevelBadge = (level) => {
		switch (level) {
			case "user":
				return <span className="badge bg-primary">User</span>;
			case "admin":
				return <span className="badge bg-danger">Admin</span>;
			default:
				return <span className="badge bg-warning">{level}</span>;
		}
	};

	const makeUserStatusBadge = (status) => {
		switch (status) {
			case "activated":
				return <span className="badge bg-success">Active</span>;
			case "pending_approval":
				return <span className="badge bg-warning">Pending Approval</span>;
			case "banned":
				return <span className="badge bg-danger">Banned</span>;
			default:
				return <span className="badge bg-primary">{status}</span>;
		}
	};

	const handleDeleteSelected = async () => {
		const selectedIds = users.filter((user) => user.isSelected).map((user) => user.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No users selected",
				duration: 4000,
			});
			return;
		}
		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteUsers`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "Users deleted successfully",
				duration: 4000,
			});
			// Optionally refresh the list or remove deleted users from state
			setUsers((prev) => prev.filter((user) => !selectedIds.includes(user.id)));
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: "Failed to delete users",
				duration: 4000,
			});
		}
	};

	const handleSelectUser = (userId) => {
		setUsers((prevUsers) =>
			prevUsers.map((user) => {
				if (user.id === userId) {
					return { ...user, isSelected: !user.isSelected };
				}
				return user;
			})
		);
	};

	const handleSelectAll = () => {
		const areAllSelected = users.every((user) => user.isSelected);
		setUsers((prevUsers) =>
			prevUsers.map((user) => ({
				...user,
				isSelected: !areAllSelected,
			}))
		);
	};

	const GetXPLevel = ({ xp, username }) => {
		const { currentLevel } = useXPSystem(xp || 0, username);
		console.log(`Current Level: ${currentLevel}`);
		return <>{currentLevel ? currentLevel.name : "N/A"}</>;
	};

	const MakeAccountHealthCircle = (accountHealth) => {
		var healthPercentage = accountHealth;
		let circleClass = 'rounded-circle ';
	
		// Determine the class based on the health percentage
		if (healthPercentage <= 20) {
			circleClass += 'bg-secondary'; // Dark black/gray
		} else if (healthPercentage <= 40) {
			circleClass += 'bg-danger'; // Red
		} else if (healthPercentage <= 70) {
			circleClass += 'bg-warning'; // Yellow
		} else {
			circleClass += 'bg-success'; // Green
		}
	
		return (
			<div className="health-circle" style={{ width: '10px', height: '10px', display: 'inline-block' }}>
				<div className={circleClass} style={{ width: '100%', height: '100%' }}></div>
			</div>
		);
	}

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Users</h1>
							<div>
								<Link to="/panel/admin/manage-users/add" className="btn btn-primary rounded-pill">
									<i className="far fa-plus"></i> New User
								</Link>
								<button onClick={handleDeleteSelected} className="btn btn-danger rounded-pill ms-2">
									<i className="fa-solid fa-trash"></i> Delete Selected
								</button>
							</div>
						</div>

						<div className="row">
							<StatBoxesHelper title="Total Users" value={stats.totalUsers} icon="users" />
							<StatBoxesHelper title="Highest $" value={`${stats?.highestRevenueUser?.username} ($${stats?.highestRevenueUser?.revenue.toFixed(2)})`} icon="users" />
							<StatBoxesHelper
								title="Longest Registered"
								value={`${stats?.longestRegisteredUser?.username} (${timeAgo(stats?.longestRegisteredUser?.registration_date)})`}
								icon="users"
							/>
							<StatBoxesHelper title="Most Domains" value={`${stats?.mostDomainsUser?.username} (${stats?.mostDomainsUser?.domainCount})`} icon="users" />
							<StatBoxesHelper title="Most Recent" value={stats?.mostRecentUser?.username} icon="users" />
							<StatBoxesHelper title="Most XP" value={`${stats?.mostXPUser?.username} (${stats?.mostXPUser?.xp} XP)`} icon="users" />
						</div>

						<div className="row">
							<div className="col-12 col-md-12 col-xl-12">
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="col mt-0">
												<h5 className="card-title">Online Users</h5>
											</div>
											<div className="col-auto">
												<div className="avatar">
													<div className="avatar-title rounded-circle bg-primary-light">
														<i className="fas fa-users text-primary"></i>
													</div>
												</div>
											</div>
										</div>
										<h1 className="display-5 mt-1 mb-3">{stats?.totalOnline || 0}</h1>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-md-12 col-xl-12">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title">Top 5 Users Revenue</h4>
									</div>
									<div className="card-body">
										<LineChartHelper chartData={chartData} labelType="username" />
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="mb-3 d-flex">
											<select className="form-select me-2" style={{ width: "auto" }} value={filterType} onChange={(e) => setFilterType(e.target.value)}>
												<option value="all">All</option>
												<option value="username">Username</option>
												<option value="user_level">Level</option>
												<option value="withdraw_address">Withdraw Address</option>
												<option value="bot_chat_id">Bot Chat ID</option>
											</select>
											{filterType !== "all" && (
												<input
													type="text"
													className="form-control"
													style={{ width: "auto" }}
													value={filterValue}
													onChange={(e) => setFilterValue(e.target.value)}
													placeholder={`Filter by ${filterType}`}
												/>
											)}
										</div>
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>
															<input
																onChange={handleSelectAll}
																checked={users.length > 0 && users.every((user) => user.isSelected)}
																type="checkbox"
																className="form-check-input"
															/>
														</th>
														<th>ID</th>
														<th>Username</th>
														<th>Health</th>
														<th style={{ minWidth: "150px" }}>Last Online</th>
														<th style={{ minWidth: "120px" }}>Total $</th>
														<th style={{ minWidth: "120px" }}>Total Domains</th>
														<th style={{ minWidth: "120px" }}>Total Tickets</th>
														<th>Level</th>
														<th>Status</th>
														<th>Registered</th>
														<th>Approved</th>
														<th style={{ minWidth: "130px" }}>Traffic Method</th>
														<th style={{ minWidth: "130px" }}>How Find</th>
														<th style={{ minWidth: "130px" }}>
															<i className="fab fa-fw fa-telegram"></i> Bot Token
														</th>
														<th style={{ minWidth: "130px" }}>
															<i className="fab fa-fw fa-telegram"></i> Bot Chat ID
														</th>
														<th>XP</th>
														<th>Avatar</th>
														<th>Main Withdraw Addy</th>
														<th>Manage</th>
													</tr>
												</thead>
												<tbody>
													{users && users.length > 0 ? (
														users.map((user) => (
															<tr key={user.id}>
																<td>
																	<input checked={user.isSelected || false} onChange={() => handleSelectUser(user.id)} type="checkbox" className="form-check-input" />
																</td>
																<td style={{ textTransform: "capitalize" }}>{user.id}</td>
																<td>{user.username}</td>
																<td style={{minWidth: "100px"}}>{user.accountHealthPercentage ? <>{user.accountHealthPercentage ? user.accountHealthPercentage.toFixed(2) : 0}% <MakeAccountHealthCircle accountHealth={user.accountHealthPercentage} /></> : "N/A"}</td>
																<td>
																	{user.last_online ? (
																		<>
																			{timeAgo(user.last_online)}
																			{Math.floor(Date.now() / 1000) - user.last_online < 300 ? (
																				<span
																					className="bg-success border border-light rounded-circle d-inline-block ms-2"
																					style={{ width: "10px", height: "10px" }}
																				></span>
																			) : (
																				<span
																					className="bg-danger border border-light rounded-circle d-inline-block ms-2"
																					style={{ width: "10px", height: "10px" }}
																				></span>
																			)}
																		</>
																	) : (
																		"N/A"
																	)}
																</td>
																<td>{user.totalRevenue ? `$${user.totalRevenue.toFixed(2)}` : "$0"}</td>
																<td>{user.totalDomains ? user.totalDomains : "0"}</td>
																<td>{user.totalSupportTickets ? user.totalSupportTickets : "0"}</td>
																<td style={{ textTransform: "capitalize" }}>{user.user_level ? makeUserLevelBadge(user.user_level) : "N/A"}</td>
																<td style={{ textTransform: "capitalize" }}>{user.status ? makeUserStatusBadge(user.status) : "N/A"}</td>
																<td style={{ textTransform: "capitalize", minWidth: "150px" }}>{user.registration_date ? timeAgo(user.registration_date) : "N/A"}</td>
																<td style={{ textTransform: "capitalize", minWidth: "150px" }}>{user.approved_date ? timeAgo(user.approved_date) : "N/A"}</td>
																<td style={{ textTransform: "capitalize", minWidth: "150px" }}>{user.traffic_method}</td>
																<td style={{ textTransform: "capitalize" }}>{user.how_find_us}</td>
																<td style={{ textTransform: "capitalize" }}>{user.bot_token ? <code>{user.bot_token}</code> : "N/A"}</td>
																<td style={{ textTransform: "capitalize" }}>{user.bot_chat_id ? <code>{user.bot_chat_id}</code> : "N/A"}</td>
																<td style={{ textTransform: "capitalize", minWidth: "150px" }}>
																	{user.xp ? (
																		<>
																			{user.xp} XP (<GetXPLevel xp={user.xp} username={user.username} />)
																		</>
																	) : (
																		"0"
																	)}
																</td>
																<td style={{ textTransform: "capitalize" }}>
																	{user.avatarURL ? (
																		<img style={{ width: "40px", height: "100%" }} src={user.avatarURL} alt="Avatar" className="rounded img-fluid" />
																	) : (
																		<img src="/img/newLogoLight.png" alt="Avatar" className="rounded img-fluid" style={{ width: "40px", height: "100%" }} />
																	)}
																</td>
																<td style={{ textTransform: "capitalize", minWidth: "340px" }}>
																	{user.withdraw_address ? (
																		<>
																			<span
																				className="badge bg-primary d-inline-block"
																				style={{ cursor: "pointer" }}
																				onClick={() => copyToClipboard(user.withdraw_address)}
																			>
																				{user.withdraw_address}
																			</span>{" "}
																			<i
																				className="fa-solid fa-copy d-inline-block"
																				style={{ cursor: "pointer" }}
																				onClick={() => copyToClipboard(user.withdraw_address)}
																			/>
																		</>
																	) : (
																		"N/A"
																	)}
																</td>
																<td style={{ minWidth: "200px" }}>
																	<Link to={`/panel/admin/manage-users/${user.id}`} className="btn btn-primary rounded">
																		<i className="fa-solid fa-edit" />
																	</Link>
																	<Link to={`/panel/admin/manage-users/${user.id}/analytics`} className="btn btn-primary rounded ms-2">
																		<i className="fa-solid fa-chart-bar" />
																	</Link>
																	<Link to={`/panel/admin/manage-users/${user.id}/withdraw`} className="btn btn-primary rounded ms-2">
																		<i className="fa-solid fa-money-bill-alt" />
																	</Link>
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="12" className="text-center">
																No tickets found
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<footer className="footer bg-light w-100">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span>
													Showing {Math.min((currentPage - 1) * 10 + 1, users.length)}-{Math.min(currentPage * 10, users.length)} of {users.length} results
												</span>
											</div>
											<nav className="w-100">
												<ul className="pagination">
													{/* Previous Page Button */}
													<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
															Previous
														</button>
													</li>
													{/* Current Page Button */}
													{totalPages > 0 &&
														Array.from({ length: totalPages }, (_, i) => (
															<li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
																<button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
																	{i + 1}
																</button>
															</li>
														))}
													{/* Next Page Button */}
													<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
															Next
														</button>
													</li>
												</ul>
											</nav>
										</footer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
