import { Link } from "react-router-dom";
import { useAuth } from "../../../../Hooks/useAuthentication";
import { useEffect } from "react";
import { config } from "../../../../config";

export default function TopBar({ showToggle = false, showBlurred = false, setShowBlurred = () => {} }) {
	const { avatar, loading } = useAuth();

	useEffect(() => {
		console.log(`Avatar updated in TopBar: ${avatar}`);
	}, [avatar]); // This will log every time avatar changes

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<nav className="navbar navbar-expand navbar-light navbar-bg">
			<a className="sidebar-toggle js-sidebar-toggle">
				<i className="hamburger align-self-center" />
			</a>
			<div className="d-flex align-items-center">
				<form className="d-none d-sm-inline-block">
					<div className="input-group input-group-navbar mb-0">
						<input type="text" className="form-control" placeholder="Search…" aria-label="Search" />
						<button className="btn" type="button">
							<i className="align-middle" data-feather="search" />
						</button>
					</div>
				</form>
				{showToggle && (
					<button onClick={() => setShowBlurred(!showBlurred)} className="btn btn-primary toggleBlur ml-2 btn-sm ms-2"><i className="fa-solid fa-eye"></i></button>
				)}
			</div>
			<div className="navbar-collapse collapse">
				<ul className="navbar-nav navbar-align">
					<li className="nav-item dropdown">
						<a className="nav-icon pe-md-0 dropdown-toggle position-relative" href="#" data-bs-toggle="dropdown">
							<img
								src={avatar && avatar !== "null" && avatar !== "undefined" && avatar !== "" ? avatar : "img/newLogoLight.png"}
								style={{ objectFit: "cover" }}
								className="avatar img-fluid rounded"
								alt={config.siteName}
							/>
							<span className="bg-success border border-light rounded-circle d-none d-md-block" style={{ width: "10px", height: "10px", left: "10px", bottom: "-5px", position: "absolute" }}></span>
						</a>
						<div className="dropdown-menu dropdown-menu-end">
							<Link to="/panel/admin/my-account" className="dropdown-item">
								<i className="align-middle me-1" data-feather="user" /> Profile
							</Link>
							<div className="dropdown-divider" />
							<Link to="/panel/admin/logout" className="dropdown-item">
								Log out
							</Link>
						</div>
					</li>
				</ul>
			</div>
		</nav>
	);
}
