import TopBar from "../components/Authed/Sections/Header/TopBar";
import Menu from "../components/Authed/Sections/Header/Menu";
import { useAuth } from "../Hooks/useAuthentication";
import { useEffect, useState } from "react";
import { config } from "../config";

import StatBoxesHelper from "./StatBoxesHelper/index";

export default function APIUsages() {
	const auth = useAuth();
	const [apiUsages, setAPIUsages] = useState({});

	const fetchAPIUsages = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/checkApiUsage`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${auth.token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to fetch API usages");
			}

			const data = await response.json();
			setAPIUsages(data?.data);
		} catch (error) {
			window.notyf.open({
				type: "error",
				message: error.message,
			});
		}
	};

	useEffect(() => {
		fetchAPIUsages();
	}, []);

	const renderDebankUsage = () => {
        if (!apiUsages.debank) return null;

        const lastWeekUsage = apiUsages.debank.stats.slice(0, 7).reduce((sum, stat) => sum + stat.usage, 0);
        const totalUsage = apiUsages.debank.stats.reduce((sum, stat) => sum + stat.usage, 0);


		return (
			<div className="card mb-4">
				<div className="card-header">
					<h5 className="card-title mb-0">DeBank API Usage</h5>
				</div>
				<div className="card-body">
					<div className="row">
						<StatBoxesHelper title="Current Balance" icon="dollar-sign" value={apiUsages.debank.balance} cardSize={4} cardClasses="bg-light" />
						<StatBoxesHelper title="7 Days Usage" icon="activity" value={lastWeekUsage} cardSize={4} cardClasses="bg-light" />
						<StatBoxesHelper title="Total Usage" icon="bar-chart-2" value={totalUsage} cardSize={4} cardClasses="bg-light" />
					</div>

					<div className="d-flex justify-content-between align-items-center mb-3">
						<h6 className="mb-0">Current Balance:</h6>
						<div className="d-flex align-items-center gap-2">
							<span className={`badge bg-${apiUsages.debank.balance > 100000 ? "success" : "danger"}`}>{apiUsages.debank.balance}</span>
							<span>Remaining</span>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped table-hover">
							<thead>
								<tr>
									<th>Date</th>
									<th>Usage</th>
									<th>Remains</th>
								</tr>
							</thead>
							<tbody>
								{apiUsages.debank.stats.map((stat, index) => (
									<tr key={index}>
										<td>{stat.date}</td>
										<td>{stat.usage}</td>
										<td>
											<span className={`badge bg-${stat.remains > 100000 ? "success" : "danger"}`}>{stat.remains}</span>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	};

	const renderDrpcUsage = () => {
        if (!apiUsages.drpc) return null;

        const totalRequests = apiUsages.drpc.data.reduce((sum, item) => sum + item.request_count, 0);
        const totalComputeUnits = apiUsages.drpc.data.reduce((sum, item) => sum + item.cu, 0);


		return (
			<div className="card">
				<div className="card-header">
					<h5 className="card-title mb-0">dRPC API Usage</h5>
				</div>
				<div className="card-body">
					<div className="row">
						<StatBoxesHelper title="Current Balance" icon="dollar-sign" value={apiUsages.drpc.balance} cardSize={4} cardClasses="bg-light" />
						<StatBoxesHelper title="Total Requests" icon="refresh-cw" value={totalRequests} cardSize={4} cardClasses="bg-light" />
						<StatBoxesHelper title="Total CU" icon="cpu" value={totalComputeUnits} cardSize={4} cardClasses="bg-light" />
					</div>

					<div className="d-flex justify-content-between align-items-center mb-3">
						<h6 className="mb-0">Current Balance:</h6>
						<div className="d-flex align-items-center gap-2">
							<span className={`badge bg-${apiUsages.drpc.balance > 100000 ? "success" : "danger"}`}>{apiUsages.drpc.balance}</span>
							<span>Remaining</span>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped table-hover">
							<thead>
								<tr>
									<th>Network</th>
									<th>Method</th>
									<th>Provider</th>
									<th>Requests</th>
									<th>Compute Units</th>
								</tr>
							</thead>
							<tbody>
								{apiUsages.drpc.data.map((item, index) => (
									<tr key={index}>
										<td>{item.network}</td>
										<td>{item.method}</td>
										<td>{item.provider_id}</td>
										<td>{item.request_count}</td>
										<td>
											<span className={`badge bg-primary`}>{item.cu}</span>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">API Usage Dashboard</h1>
						</div>

                        <div className="row mb-4">
                            <div className="col-md-4">
                                <div className="card h-100">
                                    <div className="card-body d-flex flex-column">
                                        <h5 className="card-title">Reservoir API</h5>
                                        <p className="card-text flex-grow-1">View and manage your Reservoir API usage.</p>
                                        <a href="https://dashboard.reservoir.tools/" target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-auto">View Usage <i className="fa-solid fa-link"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card h-100">
                                    <div className="card-body d-flex flex-column">
                                        <h5 className="card-title">dRPC API</h5>
                                        <p className="card-text flex-grow-1">Check your dRPC API usage and statistics.</p>
                                        <a href="https://drpc.org/login" target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-auto">View Usage <i className="fa-solid fa-link"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card h-100">
                                    <div className="card-body d-flex flex-column">
                                        <h5 className="card-title">DeBank API</h5>
                                        <p className="card-text flex-grow-1">Monitor your DeBank API usage and balance.</p>
                                        <a href="https://cloud.debank.com/" target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-auto">View Usage <i className="fa-solid fa-link"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>

						<div className="row">
							<div className="col-12 col-lg-6">{renderDebankUsage()}</div>
							<div className="col-12 col-lg-6">{renderDrpcUsage()}</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
