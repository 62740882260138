export function formatDateTimeForDisplay(epochTime) {
	if (!epochTime) return "N/A";

	const date = new Date(epochTime * 1000);
	return date.toLocaleString("en-US", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	});
}

export function formatDateTimeForInput(epochTime) {
    if (!epochTime) return '';
    const date = new Date(epochTime * 1000);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16);
  }

export function dateTimeToEpoch(dateTimeString, adjustForTimezone = false) {
    if (!dateTimeString) return null;
    const date = new Date(dateTimeString);
    if (adjustForTimezone) {
      return Math.floor((date.getTime() + date.getTimezoneOffset() * 60000) / 1000);
    }
    return Math.floor(date.getTime() / 1000);
  }
