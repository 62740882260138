import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";
import { config } from "../config";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

	console.log(`[*] AuthProvider accessed`);

    // Function to load initial state from localStorage
    const loadInitialState = () => {
        const savedAuth = localStorage.getItem('auth');
		console.log(`[*] Loading initial state from localStorage`, savedAuth);

        return savedAuth ? JSON.parse(savedAuth) : {
            isAuthenticated: false,
            isExpired: false,
            username: "",
            userID: 0,
            token: localStorage.getItem("token") || "",
            avatar: localStorage.getItem("avatar") || "",
            telegramChatID: localStorage.getItem("telegramChatID") || "",
            telegramGlobalToken: localStorage.getItem("telegramGlobalToken") || "",
            useGlobalTelegramPings: localStorage.getItem("useGlobalTelegramPings") === "1",
            userLevel: null,
            loading: true,
            xp: 0,
            level: 0,
            dateJoined: "",
            groupChatID: localStorage.getItem("groupChatID") || "",
            pingMethod: localStorage.getItem("pingMethod") || "",
        };
    };

    const [user, setUser] = useState(loadInitialState);

    const history = useHistory();

	const clearUserState = useCallback(() => {
		const initialState = {
			isAuthenticated: false,
			isExpired: false,
			username: "",
			userID: 0,
			token: "",
			avatar: "",
			telegramChatID: "",
			groupChatID: "",
			pingMethod: "",
			telegramGlobalToken: "",
			useGlobalTelegramPings: false,
			userLevel: null,
			loading: false,
			xp: 0,
			level: 0,
			dateJoined: "",
		};
		setUser(initialState);
		localStorage.removeItem('auth');
		history.push("/login"); // Ensure this is called
	}, [history]);

	// updates the new state (this is why its not working...)
	const updateUserState = useCallback((updates, field) => {
		setUser(prevState => {
			// If field is provided, treat updates as a single value
			const newState = field 
				? { ...prevState, [field]: updates }
				: { ...prevState, ...updates };
			
			console.log('Updating user state:', field || 'bulk update', updates, newState);
			localStorage.setItem('auth', JSON.stringify(newState));
			return newState;
		});
	}, []);

    // New function to fetch user XP to update it in the state
	const fetchUserXP = useCallback(async () => {
		const token = user.token || localStorage.getItem("token");
		if (!token) {
			console.log(`refreshAuthToken no token CLEARING STATE`);
			clearUserState();
			return;
		}
	
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/xp`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ token, username: user.username }),
			});
			const data = await response.json();
			if (data.data !== undefined) {
				updateUserState(data.data, 'xp'); // Changed to use new pattern (value, field)
			}
		} catch (error) {
			console.error("Failed to fetch user XP:", error);
		}
	}, [user.token, user.username, updateUserState, clearUserState]);

    const refreshAuthToken = useCallback(async () => {
        const token = user.token || localStorage.getItem("token");
        if (!token) {
            console.log(`refreshAuthToken no token CLEARING STATE`);
            clearUserState();
            return;
        }
        try {
            const decodedToken = jwtDecode(token);
            const response = await fetch(`${config.apiUrl}/fpanel/auth/refresh-token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ token }),
            });
            const data = await response.json();
            if (data.token) {
                const newDecodedToken = jwtDecode(data.token);
                updateUserState({
                    ...newDecodedToken,
                    token: data.token,
                    isAuthenticated: true,
                    loading: false,
                    dateJoined: user.dateJoined,
                });
            } else {
                throw new Error("Token refresh failed");
            }
        } catch (error) {
            console.error("Failed to refresh token:", error);
            clearUserState();
            history.push("/login");
        }
    }, [user.token, user.dateJoined, updateUserState, clearUserState, history]);

	useEffect(() => {
		console.log("useEffect running, user state:", user);
		const token = user.token || localStorage.getItem("token");
		if (!token || !user.isAuthenticated) { // Check if user is authenticated
			console.log("No token found or user not authenticated, clearing state");
			clearUserState();
			return;
		}
	
		const checkAndRefreshToken = async () => {
			try {
				const decoded = jwtDecode(token);
				const currentTime = Date.now();
				const expTime = decoded.exp * 1000;
				const refreshThreshold = 5 * 60 * 1000;
		
				console.log("Token decoded, currentTime:", currentTime, "expTime:", expTime);
		
				if (expTime - currentTime < refreshThreshold) {
					console.log("Token close to expiring or expired, refreshing");
					refreshAuthToken();
				} else {
					console.log("Token is valid, next check scheduled");
					updateUserState({
						...decoded,
						token: token,
						isAuthenticated: true,
						loading: false,
						dateJoined: user.dateJoined || decoded.dateJoined,
						avatar: user.avatar || decoded.avatar, // Preserve the avatar from state
						telegramChatID: user.telegramChatID || localStorage.getItem("telegramChatID") || "",
						telegramGlobalToken: user.telegramGlobalToken || localStorage.getItem("telegramGlobalToken") || "",
						useGlobalTelegramPings: user.useGlobalTelegramPings || localStorage.getItem("useGlobalTelegramPings") === "1",
						pingMethod: user.pingMethod || localStorage.getItem("pingMethod") || "",
						groupChatID: user.groupChatID || localStorage.getItem("groupChatID") || "",
					});
				}
			} catch (error) {
				console.error("Token decoding error", error);
				clearUserState();
			}
		};
	
		// Immediate check on mount
		checkAndRefreshToken();
	
		// Set up an interval for periodic checks
		const intervalId = setInterval(checkAndRefreshToken, 1 * 60 * 1000); // Check every 1 minutes
	
		return () => {
			console.log("Cleaning up: clearing interval and timeout");
			clearInterval(intervalId);
		};
	}, [user.token, user.isAuthenticated, user.dateJoined, updateUserState, clearUserState, refreshAuthToken]);
    
    // Add this console log after the useEffect
    console.log("After useEffect, user state:", user);

	// Add this function to check both auth and XP
	const verifyAuthAndXP = useCallback(async () => {
		console.log(`[*] verifyAuthAndXP called`);

		const token = user.token || localStorage.getItem("token");
		if (!token || !user.isAuthenticated) {
			console.log("No token found or user not authenticated, clearing state");
			clearUserState();
			return false;
		}

		try {
			// First verify the token is valid
			const decoded = jwtDecode(token);
			const currentTime = Date.now();
			const expTime = decoded.exp * 1000;

			// If token is valid, fetch XP
			if (currentTime < expTime) {
				await fetchUserXP();
				return true;
			}

			// If token is expired, trigger refresh
			await refreshAuthToken();
			return true;
		} catch (error) {
			console.error("Auth verification failed:", error);
			clearUserState();
			return false;
		}
	}, [user.token, user.isAuthenticated, clearUserState, fetchUserXP, refreshAuthToken]);

    return (
        <AuthContext.Provider value={{ ...user, setAuth: updateUserState, clearAuth: clearUserState, refreshAuthToken, verifyAuthAndXP }}>
            {user.loading ? <div>Loading...</div> : children}
        </AuthContext.Provider>
    );
};