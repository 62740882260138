import React, { useState, useEffect } from 'react'; // Import React and hooks
import Menu from '../Authed/Sections/Header/Menu';
import TopBar from '../Authed/Sections/Header/TopBar';
import {config} from '../../config';
import {useAuth} from '../../Hooks/useAuthentication';
import {timeAgo} from '../../Helpers/epochTimeAgo';

export default function News() {

    const {username, token, loading} = useAuth();

    const [news, setNews] = useState([]);

    useEffect(() => {
        if (loading) return;
        fetchNews();
    }, [loading]);

    const fetchNews = async () => {
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/user/getNews`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ username, token })
            });
            const data = await response.json();
            if (data.success) {
                setNews(data.data.sort((a, b) => b.id - a.id));
            } else {
                throw new Error('Failed to fetch news!');
            }
        } catch (error) {
            window.notyf.open({
                type: 'danger',
                message: error.message || 'Error fetching news!',
                duration: 4000
            });
        }
    };

    return (
        <div className="wrapper">
            <Menu />
            <div className="main">
                <TopBar />
                <main className="content">
                    <div className="container-fluid p-0">
                        <h1 className="h3 mb-3">News</h1>
                        <div className="rowv align-items-center">
                            {news && news.length > 0 && news.map((item, index) => (
                                <div key={index} className="col-12 col-md-6 col-lg-12 mt-2">
                                    <div className="card h-100 news-card main-news">
                                        <div className="card-header px-4 pt-4 ">
                                            <h5 className="card-title mb-0" style={{ textTransform: 'capitalize' }}>{item.title} (#{news.length - index})</h5>
                                            <div className="badge bg-primary my-2">{item.dateAdded ? timeAgo(item.dateAdded) : ''}</div>
                                            <br/>
                                            <p className="mt-2 p small">Posted by <span className="badge bg-danger rounded-pill">{item.authorUsername}</span></p>
                                        </div>
                                        <div className="card-body px-4 pt-2">

                                            <p className='news-content' dangerouslySetInnerHTML={{ __html: item.content }} style={{ whiteSpace: 'pre-wrap', maxWidth: '100%', width: '100%'}}>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}