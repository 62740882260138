import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {useAuth} from '../Hooks/useAuthentication';

function AdminLogout() {
  const { setAuth, clearAuth } = useAuth();
  const history = useHistory();

  useEffect(() => {
    console.log("AdminLogout");
  }, []);

  useEffect(() => {
    clearAuth(); // Pass false or null to trigger logout
    // Redirect after setting auth to false
    history.push('/login');
  }, [clearAuth, history]);

  return null;
}

export default AdminLogout;