import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, setState } from "react";
import {useAuth} from "../../../Hooks/useAuthentication";
import {config} from "../../../config";
import { useHistory } from "react-router-dom";

export default function AddBlacklist() {
    const history = useHistory();
    const {username, token, loading} = useAuth();

    const [type, setType] = useState('ip');
    const [value, setValue] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (loading) return;
    
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/user/add-blacklist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    type,
                    value,
                    token,
                    username
                })
            });
    
            const data = await response.json();
            if (response.ok) {
                console.log('Success:', data);
                window.notyf.open({ type: 'success', message: 'Blacklist entry added successfully! Redirecting...', duration: 2000 });
                setTimeout(() => {
                    history.push('/panel/blacklists/view');
                }, 2000);
                // Optionally redirect or clear form here
            } else {
                console.error('Error:', data.message);
                window.notyf.open({ type: 'error', message: `${data.message || 'Failed to add blacklist entry'}`, duration: 2000 });
            }
        } catch (error) {
            console.error('Network error:', error);
            window.notyf.open({ type: 'error', message: 'Network error, please try again later.', duration: 2000 });
        }
    }

    return (
        <div className="wrapper">
        <Menu />
        <div className="main">
          <TopBar />
          <main className="content">
            <div className="container-fluid p-0">
  
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="h3">Add Blacklist</h1>
                <Link to="/panel/blacklists/view" className="btn btn-primary rounded"><i className="fa-solid fa-arrow-left"></i> Back to Blacklists</Link>
              </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label">Type</label>
                                    <select className="form-control" id="type" name="type" onChange={(e) => setType(e.target.value)}>
                                        <option value="ip">IP</option>
                                        <option value="address">Address</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Value</label>
                                    <input type="text" className="form-control" id="value" name="value" onChange={(e) => setValue(e.target.value)} />
                                </div>
                                <button type="submit" className="btn btn-primary rounded">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

          </main>
        </div>
      </div>
    );
}