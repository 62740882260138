import React from 'react';

const DomainLogsCardDetails = ({ title, value, icon, additionClasses = "" }) => (
    <div className="col-sm-6 col-xl-2">
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col mt-0">
                        <h5 className="card-title text-sm" style={{textTransform: 'capitalize'}}>{title}</h5>
                    </div>
                    <div className="col-auto">
                        <div className={"stat text-primary " + additionClasses}>
                            <i className={`align-middle`} data-feather={icon} />
                        </div>
                    </div>
                </div>
                <h1 className="mt-1" style={{textTransform: 'capitalize'}}>{value || 0}</h1>
            </div>
        </div>
    </div>
);

export default DomainLogsCardDetails;