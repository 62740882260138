import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuthentication";
import { config } from "../../config";

export default function ManageDomain() {
	const auth = useAuth();
	const { domainId } = useParams();
	const [domain, setDomain] = useState({
		id: 0,
		userID: 0,
		domain: "",
		connectionType: "nameservers",
		connectionIP: "",
		connectionNameservers: "",
		dateAdded: "",
		dateActivated: "",
		status: "active",
		zone_id: "",
		access_token: "",
		designId: 0,
		lastNSCheck: "",
		dns_id: "",
		percentage_override: "",
		usersname: "",
		designName: "",
	});

	useEffect(() => {
		if (auth.loading) return;

		const fetchUser = async () => {
			if (auth.loading) return;
			try {
				const response = await fetch(`${config.apiUrl}/fpanel/admin/getSiteData`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${auth.token}`,
					},
					body: JSON.stringify({ username: auth.username, token: auth.token, id: domainId }),
				});

				const data = await response.json();
				if (data.success) {
					delete data.data.pass_salt; // delete the pass_salt from the data
					delete data.data.password; // delete the password from the data
					setDomain(data.data);
				} else {
					console.error("API did not return success:", data.message);
				}
			} catch (error) {
				console.error("Failed to fetch domain:", error);
				window.notyf.open({
					type: "danger",
					message: "Failed to get domain",
					duration: 4000,
				});
			}
		};

		fetchUser();
	}, [auth.token, auth.loading, auth.username]);

	// Convert epoch to YYYY-MM-DD format
	const formatDate = (epoch) => {
		if (!epoch) return "";
		const date = new Date(epoch * 1000);
		return date.toISOString().split("T")[0];
	};

	// Handle date change
	const handleDateChange = (key, value) => {
		const epoch = new Date(value).getTime() / 1000;
		setDomain({ ...domain, [key]: epoch.toString() });
	};

	// Handle form submission
	const handleSubmit = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/updateSite`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					data: domain,
				}),
			});

			const data = await response.json();
			if (data.success) {
				console.log("Domain updated successfully:", data);
				window.notyf.open({
					type: "success",
					message: "Domain updated successfully",
					duration: 4000,
				});
			} else {
				console.error("API did not return success:", data.message);
				window.notyf.open({
					type: "danger",
					message: `Failed to update domain: ${data.message}`,
					duration: 4000,
				});
			}
		} catch (error) {
			console.error("Failed to update domain:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to update domain",
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Domain {domainId}</h1>
							<div>
								<Link to="/panel/admin/manage-domains/view" className="btn btn-primary rounded-pill">
									<i className="far fa-plus"></i> Back
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
										<form onSubmit={handleSubmit}>
											{Object.entries(domain).map(([key, value]) => {
												if (key === "dateAdded" || key === "dateActivated" || key === "lastNSCheck") {
													return (
														<div className="mb-3" key={key}>
															<label htmlFor={key} className="form-label">
																{key.charAt(0).toUpperCase() + key.slice(1)}
															</label>
															<input type="date" className="form-control" id={key} value={formatDate(value)} onChange={(e) => handleDateChange(key, e.target.value)} />
														</div>
													);
												} else if (key === "status") {
													return (
														<div className="mb-3" key={key}>
															<label htmlFor={key} className="form-label">
																{key.charAt(0).toUpperCase() + key.slice(1)}
															</label>
															<select className="form-control" id={key} value={value} onChange={(e) => setDomain({ ...domain, [key]: e.target.value })}>
																<option value="active">Active</option>
																<option value="pending-setup">Pending Setup</option>
																<option value="banned">Banned</option>
															</select>
														</div>
													);
												} else if (key === "connectionType") {
													return (
														<div className="mb-3" key={key}>
															<label htmlFor={key} className="form-label">
																{key.charAt(0).toUpperCase() + key.slice(1)}
															</label>
															<select className="form-control" id={key} value={value} onChange={(e) => setDomain({ ...domain, [key]: e.target.value })}>
																<option value="nameservers">Nameservers</option>
																<option value="cdn">CDN</option>
															</select>
														</div>
													);
												} else if (key === "percentage_override") {
													return (
														<div className="mb-3" key={key}>
															<label htmlFor={key} className="form-label">
																{key.charAt(0).toUpperCase() + key.slice(1)}
															</label>
															<input
																type="number"
																max={100}
																min={0}
																className="form-control"
																id={key}
																value={value || ""}
																onChange={(e) => setDomain({ ...domain, [key]: e.target.value })}
															/>
														</div>
													);
												} else if (key === "designName" || key === "usersname" || key === "id") {
													return (
														<div className="mb-3" key={key}>
															<label htmlFor={key} className="form-label">
																{key.charAt(0).toUpperCase() + key.slice(1)}
															</label>
															<input
																type="text"
																disabled
																readOnly
																className="form-control"
																id={key}
																value={value || ""}
																onChange={(e) => setDomain({ ...domain, [key]: e.target.value })}
															/>
														</div>
													);
												} else {
													return (
														<div className="mb-3" key={key}>
															<label htmlFor={key} className="form-label">
																{key.charAt(0).toUpperCase() + key.slice(1)}
															</label>
															<input
																type="text"
																className="form-control"
																id={key}
																value={value || ""}
																onChange={(e) => setDomain({ ...domain, [key]: e.target.value })}
															/>
														</div>
													);
												}
											})}
											<div className="mb-3">
												<button type="submit" className="btn btn-primary">
													Update Domain
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
