import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";

export default function ManageBlacklist() {
    const auth = useAuth();
    const { blacklistId } = useParams();
    const [blacklist, setBlacklist] = useState(null);

    useEffect(() => {
        if(auth.loading || !auth.token ) return;
        fetchBlacklist(blacklistId);
    }, [blacklistId, auth.token, auth.loading]);

    const fetchBlacklist = async (blacklistId) => {
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/admin/getBlacklist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`
                },
                body: JSON.stringify({ id: blacklistId, token: auth.token, username: auth.username })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setBlacklist(data.data);
        } catch (error) {
            console.error('Failed to fetch blacklist:', error);
            // Handle errors or display error messages here
            window.notyf.open({
                type: "danger",
                message: "Failed to fetch blacklist",
                duration: 4000,
            });
        }
    }

    const handleUpdateBlacklist = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/admin/updateBlacklist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`
                },
                body: JSON.stringify({ id: blacklist.id, token: auth.token, username: auth.username, data: blacklist })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            window.notyf.open({
                type: "success",
                message: "Blacklist updated successfully",
                duration: 4000,
            });
        } catch (error) {
            console.error('Failed to update blacklist:', error);
            window.notyf.open({
                type: "danger",
                message: "Failed to update blacklist",
                duration: 4000,
            });
        }
    }

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Blacklist {blacklistId}</h1>
							<div>
								<Link to="/panel/admin/manage-blacklists/view" className="btn btn-primary rounded-pill">
									<i className="fa-solid fa-arrow-left"></i> Back
								</Link>
							</div>
						</div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                <div className="card-body">
                                        <form onSubmit={handleUpdateBlacklist}>
                                            <div className="mb-3">
                                                <label htmlFor="blacklistID" className="form-label">Blacklist ID</label>
                                                <input type="text" className="form-control" id="blacklistID" value={blacklist ? blacklist.id : ''} onChange={(e) => setBlacklist({ ...blacklist, id: e.target.value })} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="userID" className="form-label">User ID</label>
                                                <input type="text" className="form-control" id="userID" value={blacklist ? blacklist.userID : ''} onChange={(e) => setBlacklist({ ...blacklist, userID: e.target.value })} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="blacklistType" className="form-label">Blacklist Type</label>
                                                <select className="form-select" id="blacklistType" value={blacklist ? blacklist.blacklistType : ''} onChange={(e) => setBlacklist({ ...blacklist, blacklistType: e.target.value })}>
                                                    <option value="ip">IP</option>
                                                    <option value="address">Address</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="blacklistValue" className="form-label">Blacklist Value</label>
                                                <input type="text" className="form-control" id="blacklistValue" value={blacklist ? blacklist.blacklistValue : ''} onChange={(e) => setBlacklist({ ...blacklist, blacklistValue: e.target.value })} />
                                            </div>
                                            <button type="submit" className="btn btn-primary">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

					</div>
				</main>
			</div>
		</div>
	);
}
