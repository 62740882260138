import { moduleColorCodes } from "../config";

export const renderColorCircle = (moduleName, styles={}) => {
    // Ensure moduleName is defined and in lowercase, then check if it exists in the moduleColorCodes
    const normalizedModuleName = moduleName && moduleName.length > 0 ? moduleName.toLowerCase() : "";
    console.log(`normalizedModuleName`, normalizedModuleName);
    // Assuming moduleColorCodes is an array of objects with 'name' and 'colorCode' properties
    const moduleInfo = moduleColorCodes.find((m) => m.moduleName.toLowerCase() === normalizedModuleName);
    const color = moduleInfo ? moduleInfo.colorCode : "#ccc"; // Default to grey if no color found
    console.log(`color for module ${moduleName}:`, color);
    return <div style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: color, ...styles }}></div>;
};