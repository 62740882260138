import { useState } from "react";
import { Link } from "react-router-dom";
import { config } from "../config";

export default function ForgotPassword() {
	const [username, setUsername] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username }),
            });
            const data = await response.json();
            if (data.success) {
                // Handle success (e.g., show a success message)
                window.notyf.open({
					message: data.message,
					type: "success",
					duration: 4000,
				});
            } else {
                // Handle failure (e.g., show an error message)
                window.notyf.open({
					message: data.message,
					type: "danger",
					duration: 4000,
				});
            }
        } catch (error) {
            // Handle errors (e.g., network error)
            window.notyf.open({
				message: "Failed to send password reset request.",
				type: "danger",
				duration: 4000,
			});
        }
    };

	return (
		<main className="d-flex w-100 h-100">
			<div class="container d-flex flex-column">
				<div class="row vh-100">
					<div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
						<div class="d-table-cell align-middle">
							<div class="text-center mt-4">
								<h1 class="h2">Reset password</h1>
								<p class="lead">Enter your username to reset your password.</p>
							</div>


                            <div className="alert alert-warning alert-outline-coloured alert-dismissible" role="alert">
									<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
									<div className="alert-icon">
										<i className="far fa-fw fa-bell" />
									</div>
									<div className="alert-message">
										<strong>Warning!</strong> We will send you a new password to your telegram you used to sign up with!
									</div>
								</div>

							<div class="card">
								<div class="card-body">
									<div class="m-sm-3">
										<form onSubmit={handleSubmit}>
											<div class="mb-3">
												<label class="form-label">Username</label>
												<input class="form-control form-control-lg" type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Enter your username" />
											</div>
											<div class="d-grid gap-2 mt-3">
												<button type="submit" class="btn btn-lg btn-primary">
													Reset password
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div class="text-center mb-3">
								Don't have an account? <Link to="/register">Sign up</Link>
							</div>
							<div class="text-center mb-3">
								Already have an account? <Link to="/login">Login</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}
