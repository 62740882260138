import { useState, useEffect } from "react";
import { config } from "../../config";
import { copyToClipboard } from "../../Helpers/copyToClipboard";

export default function DesignDirectory({ auth }) {
	const [designs, setDesigns] = useState([]);
    const [designHTML, setDesignHTML] = useState([]);
    const [showHTMLModal, setShowHTMLModal] = useState(false);
    const [isLoadingDesignHTML, setIsLoadingDesignHTML] = useState(false);

	const fetchDesigns = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/designDirectory`, {
                method: 'POST',
				headers: {
					Authorization: `Bearer ${auth.token}`,
                    "Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch designs directory");
			}
			const data = await response.json();
			const designs = data.data;
			if (designs && designs.length > 0) {
				setDesigns(designs);
			} else {
				window.notyf.open({
					type: "warning",
					duration: 5000,
					message: "No designs found",
				});
			}
		} catch (error) {
			console.error(error);
			window.notyf.open({
				type: "danger",
				duration: 5000,
				message: "Failed to fetch designs directory",
			});
		}
	};

	useEffect(() => {
		if (auth.loading) return;
        console.log(`fetching design directory for ${auth.username}`);
		fetchDesigns();
	}, [auth.loading]);

    const viewFile = async(design) => {
        setIsLoadingDesignHTML(true);
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/loadDesignHTML`, {
                method: 'POST',
				headers: {
					Authorization: `Bearer ${auth.token}`,
                    "Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
                    designFileName: design
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch design html");
			}
			const data = await response.json();
			const designHTML = data.data;
			if (designHTML && designHTML.length > 0) {
				setDesignHTML(designHTML);
                setShowHTMLModal(true);
			} else {
				window.notyf.open({
					type: "warning",
					duration: 5000,
					message: "Design html not found",
				});
			}
		} catch (error) {
			console.error(error);
			window.notyf.open({
				type: "danger",
				duration: 5000,
				message: "Failed to fetch design html",
			});
		} finally {
			setIsLoadingDesignHTML(false);
		}
    }

    const deleteFile = async(design) => {
        setIsLoadingDesignHTML(true);
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/deleteDesignFile`, {
                method: 'POST',
				headers: {
					Authorization: `Bearer ${auth.token}`,
                    "Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
                    designFileName: design
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to delete design file");
			}
			const data = await response.json();
			const success = data.success;
			if (success) {
				setDesignHTML(null);
                setShowHTMLModal(false);
                window.notyf.open({
                    type: "success",
                    duration: 5000,
                    message: "Design file deleted successfully",
                });
                await fetchDesigns();
			} else {
				window.notyf.open({
					type: "warning",
					duration: 5000,
					message: "Design file not found",
				});
			}
		} catch (error) {
			console.error(error);
			window.notyf.open({
				type: "danger",
				duration: 5000,
				message: "Failed to delete design file",
			});
		} finally {
			setIsLoadingDesignHTML(false);
		}
    }

    const previewHTML = (designHTML) => {
        const newTab = window.open('', '_blank');
        newTab.document.write(designHTML);
        newTab.document.close();
    }

	return (
		<div>
			<div className="col-md-12">
				<h4>Design Directory</h4>
				<div className="card">
					<div className="card-body">
						<div className="row">
							{designs.map((design, index) => (
								<div key={index} className="col-md-3 mb-3">
									<div className="design-box border p-3 text-center rounded bg-light">
										<span className="badge bg-primary text-dark mb-3">{design}</span>
										{/* Buttons for actions */}
										<div className="d-flex justify-content-center">
											<button className={`btn btn-primary me-2 ${isLoadingDesignHTML ? 'disabled' : ''}`} onClick={() => viewFile(design)}>
												{isLoadingDesignHTML ? <span className="spinner-border spinner-border-sm"></span> : "View"}
											</button>
											<button className="btn btn-danger" onClick={() => deleteFile(design)}>{isLoadingDesignHTML ? <span className="spinner-border spinner-border-sm"></span> : "Delete"}</button>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			{showHTMLModal && (
				<>
					<div
						className="modal modal-xl fadeUp show"
						id="centeredModalPrimary"
						tabIndex={-1}
						style={{ display: "block" }}
						aria-modal="true"
						role="dialog"
					>
						<div
							className="modal-dialog modal-dialog-centered"
							role="document"
						>
							<div className="modal-content">
								<div className="modal-header">
									<h5
										className="modal-title"
										style={{ textTransform: "capitalize" }}
									>
										Design HTML Preview
									</h5>
									<button
										type="button"
										className="btn-close"
										onClick={() => setShowHTMLModal(false)}
										aria-label="Close"
									/>
								</div>
								<div className="modal-body m-0 p-0">
									<textarea className="form-control" value={designHTML} readOnly style={{ minHeight: "500px" }} />
								</div>
								<div className="modal-footer">
                                    <button className="btn btn-info" onClick={() => copyToClipboard(designHTML)}>Copy HTML</button>
                                    <button className="btn btn-secondary" onClick={() => previewHTML(designHTML)}>Preview HTML</button>
                                    <button
										type="button"
										className="btn btn-primary"
										onClick={() => setShowHTMLModal(false)}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
		</div>
	);
}
