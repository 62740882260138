import React, { useRef, useState, useEffect } from 'react';
import { timeAgo } from "../../../Helpers/epochTimeAgo";

export default function DashboardNews({ recentNews }) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return (
        <div className="dashboard-news card h-100">
            <div className="d-flex flex-column">
                <h4 className="card-title mb-3">Latest Updates</h4>
                <div className="dashboard-news__list">
                    {recentNews.map((news, index) => (
                        <div 
                            className="news-card card mb-3 w-100" 
                            key={index} 
                            style={{
                                margin: '0 auto',
                                maxWidth: '100%', // Adjust width based on mobile
                            }}
                        >
                            <div className="card-body d-flex bg-light">
                                <img 
                                    src='./img/newLogoLight.png' // Assuming news has an imageUrl property
                                    alt={news.title}
                                    className="news-card__image me-3" // Add margin to the right
                                    style={{ width: '50px', height: 'auto', maxWidth: '50px', maxHeight: '50px' }} // Adjust size as needed
                                />
                                <div className="flex-grow-1 d-flex flex-column">
                                    <h5 className="card-title">{news.title}</h5>
                                    <div 
                                        className="news-card__content flex-grow-1 w-100" 
                                        dangerouslySetInnerHTML={{ __html: news.content }}
                                        style={{ 
                                            maxHeight: 'calc(100% - 60px)',
                                        }}
                                    ></div>
                                    <div className="news-card__footer mt-2 d-flex justify-content-between p-0">
                                        <small className="text-muted">
                                            {news.dateAdded ? timeAgo(news.dateAdded) : "Unknown"}
                                        </small>
                                        <small className="text-muted">
                                            {news.authorUsername ? <span className="badge bg-primary rounded">{news.authorUsername}</span> : <span className="news-card__author">??</span>}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}