import React, { useState, useEffect } from 'react';
import { allowedWallets } from '../config'; // Ensure you have a similar config for wallets

const importSvg = (walletName) => {
    return import(`@web3icons/core/svgs/wallets/branded/${walletName}.svg`)
        .then(module => module.default)
        .catch(error => {
            console.error('Failed to load SVG:', error);
            return null;
        });
};

export const WalletLogo = ({ walletName, showName = true }) => {
    const [logoUrl, setLogoUrl] = useState(null);
    const [walletDisplayName, setWalletDisplayName] = useState('');

    useEffect(() => {
        const wallet = allowedWallets.find(wallet => wallet.name.toLowerCase() === walletName.toLowerCase());
        if (!wallet) {
            setLogoUrl(null);
            setWalletDisplayName('');
            return;
        }

        setWalletDisplayName(wallet.displayName); // Set the wallet display name
        importSvg(wallet.web3IconName).then(setLogoUrl);
    }, [walletName]);

    if (!logoUrl) return(
        <span className="ms-2">{walletName == 'Unknown' ? <i className="fa fa-question-circle"></i> : walletName}</span>
    )

    return (
        <>
            <img src={logoUrl} alt={walletDisplayName} />
            {showName && <span className="ms-2">{walletDisplayName}</span>}
        </>
    );
};