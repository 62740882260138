
function loadFullCalendarScript(callback) {
    const script = document.createElement('script');
    script.src = '/js/fullcalendar.js'; // Update with the correct path
    script.onload = callback;
    script.onerror = () => {
        console.error('Failed to load the FullCalendar script. Please check the path and try again.');
    };
    document.head.appendChild(script);
}

// Usage example
loadFullCalendarScript(() => {
    console.log('FullCalendar script loaded');
    // Initialize FullCalendar or perform other actions here
});