import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { truncateText } from "../../Helpers/truncateText";
import { useXPSystem } from "../../Hooks/useXPSystem";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { useParams } from "react-router-dom";
import { ethers } from "ethers";
import { useTheme } from "../../Hooks/useTheme";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { allowedChains } from "../../config";

export default function Withdraw() {
	const auth = useAuth();
	const theme = useTheme();
	const { userId } = useParams();

	const [userBalances, setUserBalances] = useState({
		balances: [],
		balanceInWei: 0,
		balanceUSD: 0,
		withdrawAddress: "",

		totalBalanceETH: 0,
		totalBalanceUSD: 0,
	});
	const [lastUpdated, setLastUpdated] = useState(new Date());

	// Withdrawing
	const [withdrawAmount, setWithdrawAmount] = useState("");
	const [withdrawAmountEther, setWithdrawAmountEther] = useState(0);
	const [isWithdrawing, setIsWithdrawing] = useState(false);
    const [withdrawChainID, setWithdrawChainID] = useState(null);

	const getUserBalance = useCallback(
		async (refresh = false) => {
			try {
				const response = await fetch(`${config.apiUrl}/fpanel/admin/getUserBalance`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${auth.token}`,
					},
					body: JSON.stringify({
						token: auth.token,
						username: auth.username,
						userId: userId,
					}),
				});
				if (!response.ok) {
					throw new Error("Failed to get user balance");
				}
				const data = await response.json();
				setUserBalances({
					balances: data.balances,
					totalBalanceETH: data.totalBalanceETH,
					totalBalanceUSD: data.totalBalanceUSD,
					withdrawAddress: data?.withdrawAddress,
				});
				setLastUpdated(new Date());
				if (refresh) {
					window.notyf.open({
						type: "success",
						message: "Balance refreshed",
						duration: 4000,
					});
				}
			} catch (error) {
				console.error("Error fetching user balance:", error);
				window.notyf.open({
					type: "danger",
					message: "Failed to fetch user balance",
					duration: 4000,
				});
			}
		},
		[auth.token, auth.username, userId]
	);

	const handleWithdraw = async (e) => {
		e.preventDefault();
		setIsWithdrawing(true);

		try {

			const response = await fetch(`${config.apiUrl}/fpanel/admin/withdrawNative`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					userId: userId,
					amountEth: withdrawAmountEther,
					recipient: userBalances.withdrawAddress,
                    chainId: withdrawChainID,
				}),
			});

			const data = await response.json();

			if (data.success) {
				window.notyf.open({
					type: 'success',
					message: "Withdrawal successful",
					duration: 4000
				});
				getUserBalance(); // Refresh balance after withdrawal
			} else {
				window.notyf.open({
					type: 'danger',
					message: `Withdrawal failed: ${data.message}`,
					duration: 4000
				});
			}
		} catch (error) {
			console.error("Error during withdrawal:", error);
            window.notyf.open({
                type: 'danger',
                message: error.message,
                duration: 4000
            });
		} finally {
			setIsWithdrawing(false);
			setWithdrawAmountEther("");
		}
	};

	useEffect(() => {
		if (auth.loading) return;
		getUserBalance();
	}, [getUserBalance, auth.loading]);

    return (
        <div className="wrapper">
          <Menu />
          <div className="main">
            <TopBar />
            <main className="content">
              <div className="container-fluid p-0">
                <div className="row mb-4">
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <h1 className="h3 mb-0">Manage Withdrawals for User ({userId})</h1>
                    <Link to="/panel/admin/manage-users/view" className="btn btn-primary">
                      <i className="fa-solid fa-arrow-left me-2"></i> Back to Users
                    </Link>
                  </div>
                </div>
    
                <div className="row">
                  <div className="col-12 col-lg-6 mb-4">
                    <div className="card h-100">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="card-title mb-0">Total Balance</h5>
                        <button onClick={() => getUserBalance(true)} className="btn btn-sm btn-outline-primary">
                          <i className="fas fa-sync-alt me-2"></i> Refresh
                        </button>
                      </div>
                      <div className="card-body">
                        <h2 className="mb-2">{parseFloat(userBalances.totalBalanceETH).toFixed(8)} ETH</h2>
                        <p className="text-muted mb-0">${parseFloat(userBalances.totalBalanceUSD).toFixed(2)} USD</p>
                      </div>
                    </div>
                  </div>
    
                  <div className="col-12 col-lg-6 mb-4">
                    <div className="card h-100">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Withdrawal Address</h5>
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="text-break">{userBalances.withdrawAddress}</span>
                          <div>
                            <button onClick={() => copyToClipboard(userBalances.withdrawAddress)} className="btn btn-sm btn-outline-secondary me-2">
                              <i className="fas fa-copy"></i>
                            </button>
                            <button
                              onClick={() => window.open(`https://etherscan.io/address/${userBalances.withdrawAddress}`, "_blank")}
                              className="btn btn-sm btn-outline-secondary"
                            >
                              <i className="fas fa-external-link-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
                <div className="row">
                  {userBalances.balances.map((balance) => (
                    <div key={balance.chainId} className="col-12 col-md-6 col-lg-4 mb-4">
                      <div className="card h-100">
                        <div className="card-header d-flex align-items-center">
                          <ChainLogo chainID={balance.chainId} className="me-2" />
                          <h5 className="card-title mb-0 ms-2">
                            {allowedChains.find((chain) => chain.chainId === balance.chainId)?.name}
                          </h5>
                        </div>
                        <div className="card-body">
                          <p className="mb-1">
                            <strong>Balance:</strong> {parseFloat(balance.balanceETH).toFixed(8)} ETH
                          </p>
                          <p className="mb-1">
                            <strong>USD Value:</strong> ${parseFloat(balance.balanceUSD).toFixed(2)}
                          </p>
                          <p className="mb-3">
                            <strong>Wei:</strong> {parseInt(balance.balanceInWei).toLocaleString()}
                          </p>
                          <form onSubmit={(e) => handleWithdraw(e, balance.chainId)}>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={withdrawAmountEther}
                                onChange={(e) => {setWithdrawAmountEther(e.target.value); setWithdrawChainID(balance.chainId);}}
                                placeholder="Amount in ETH"
                                required
                              />
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isWithdrawing || !withdrawAmountEther}
                              >
                                {isWithdrawing ? 'Processing...' : 'Withdraw'}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </main>
          </div>
        </div>
      );
}
