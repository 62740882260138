import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Hooks/useAuthentication";
import { Link } from "react-router-dom";
import { splitNameservers } from "../../../Helpers/splitNameservers";
import useDomainIcons from "../../../Hooks/useDomainIcons";
import { getDefaultIcon, placeholderIcon } from "../../../Helpers/getDomainIcon";
import { config, chainMappingRPCs } from "../../../config";
import { ChainLogo } from "../../../Helpers/getChainLogo";
import { ethers } from "ethers";

export default function FAQ() {
	const auth = useAuth();

	const [pricing, setPricing] = useState([]);

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">{config.siteName} FAQs</h1>
						</div>

						<div class="row">
							<div class="col-md-6 col-sm-6">
								<div class="card">
									<div class="card-body">
										<h5 class="h6 card-title">Do I need a credit card to sign up?</h5>
										<p class="mb-0">
											Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit
											vel, luctus pulvinar, hendrerit id, lorem.
										</p>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-sm-6">
								<div class="card">
									<div class="card-body">
										<h5 class="h6 card-title">Do you offer a free trial?</h5>
										<p class="mb-0">
											Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit
											vel, luctus pulvinar, hendrerit id, lorem.
										</p>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-sm-6">
								<div class="card">
									<div class="card-body">
										<h5 class="h6 card-title">What if I decide to cancel my plan?</h5>
										<p class="mb-0">
											Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit
											vel, luctus pulvinar, hendrerit id, lorem.
										</p>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-sm-6">
								<div class="card">
									<div class="card-body">
										<h5 class="h6 card-title">Can I cancel at anytime?</h5>
										<p class="mb-0">
											Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit
											vel, luctus pulvinar, hendrerit id, lorem.
										</p>
									</div>
								</div>
							</div>
						</div>

					</div>
				</main>
			</div>
		</div>
	);
}
