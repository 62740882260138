import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { config, chainMappingRPCs } from "../../config";
import StatBoxesHelper from "../StatBoxesHelper";
import { useAuth } from "../../Hooks/useAuthentication";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { LineChartHelper } from "../ChartHelper";
import React from "react";
import { useMemo } from "react";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { formatDateTimeForDisplay } from "../../Helpers/epochToString";
import { getStyledUsername } from "../../Hooks/useXPSystem";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { truncateText } from "../../Helpers/truncateText";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { ethers } from "ethers";

export default function ManageNFTSales() {

    const auth = useAuth();
    const [nftSales, setNftSales] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

    const fetchNFTSales = useCallback(async () => {
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/admin/getAllNFTSales`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`,
                },
                body: JSON.stringify({
                    token: auth.token,
                    username: auth.username,
                    page: currentPage || 1,
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if(!data) {
                window.notyf.open({
                    type: 'danger',
                    message: 'Failed to load NFT Sales',
                    duration: 4000,
                });
                throw new Error('No data returned');
            }
            if(data.data) {
                setNftSales(data.data);
            }
            if(data.totalPages) {
                setTotalPages(data.totalPages);
            }
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            window.notyf.open({
                type: 'danger',
                message: 'Failed to load NFT Sales',
                duration: 4000,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        if(!auth.isLoading) {
            fetchNFTSales();
        }
    }, [auth.isLoading, fetchNFTSales, currentPage]);

    const handleSelect = (nftSaleId) => {
		setNftSales((prevNftSales) =>
			prevNftSales.map((nftSale) => {
				if (nftSale.id === nftSaleId) {
					return { ...nftSale, isSelected: !nftSale.isSelected };
				}
				return nftSale;
			})
		);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleSelectAll = () => {
		const areAllSelected = nftSales.every((nftSale) => nftSale.isSelected);
		setNftSales((prevNftSales) =>
			prevNftSales.map((nftSale) => ({
				...nftSale,
				isSelected: !areAllSelected,
			}))
		);
	};

	const handleDeleteSelected = async () => {
		const selectedIds = nftSales.filter((nftSale) => nftSale.isSelected).map((nftSale) => nftSale.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No NFT Sales selected",
				duration: 4000,
			});
			return;
		}

		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteBypassAddresses`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "NFT Sales deleted successfully",
				duration: 4000,
			});
			setNftSales((prev) => prev.filter((nftSale) => !selectedIds.includes(nftSale.id)));
		} catch (error) {
			console.error("Failed to delete NFT Sales:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to delete NFT Sales",
				duration: 4000,
			});
		}
	};

    const convertWeiToEther = (wei) => {
        return ethers.utils.formatEther(wei);
    }

    const findExplorerUrl = (chainID) => {
        return chainMappingRPCs[chainID]?.explorerUrl || "https://etherscan.io"; 
    }

    const makeOpenseaUrl = (tokenAddress, tokenId, chainID) => {
        var chainName = chainMappingRPCs[chainID]?.name;
        return `https://opensea.io/assets/${chainName.toLowerCase()}/${tokenAddress}/${tokenId}`;
    }

    const makeStatusBadge = (status) => {
        switch (status) {
            case "processing":
                return <span className="badge bg-warning">Processing</span>;
            case "completed":
                return <span className="badge bg-success">Completed</span>;
            case "failed":
                return <span className="badge bg-danger">Failed</span>;
            default:
                return <span className="badge bg-danger">Failed</span>;
        }
    }

    return (
        <div className="wrapper">
            <Menu />
            <div className="main">
                <TopBar />
                <main className="content">
                    <div className="container-fluid p-0">
                        <div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage NFT Sales</h1>
						</div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>
															<input type="checkbox" className="form-check-input" onClick={handleSelectAll} checked={nftSales.length > 0 && nftSales.every((nftSale) => nftSale.isSelected)} />
														</th>
                                                        <th style={{minWidth: "100px"}}>ID</th>
                                                        <th style={{minWidth: "100px"}}>Username</th>
                                                        <th style={{minWidth: "100px"}}>ChainID</th>
                                                        <th style={{minWidth: "150px"}}>Worth USD</th>
                                                        <th style={{minWidth: "100px"}}>Attempts</th>
                                                        <th style={{minWidth: "100px"}}>Payout Address</th>
                                                        <th style={{minWidth: "100px"}}>NFT</th>
                                                        <th style={{minWidth: "100px"}}>NFT Sale Reason</th>
                                                        <th style={{minWidth: "100px"}}>NFT Sale Status</th>
                                                        <th style={{minWidth: "200px"}}>NFT Listing OrderHash</th>
                                                        <th style={{minWidth: "100px"}}>NFT Sale Success</th>
                                                        <th style={{minWidth: "100px"}}>NFT Sale TXID</th>
                                                        <th style={{minWidth: "140px"}}>Sent NFT Back As Failed</th>
                                                        <th style={{minWidth: "140px"}}>Sent NFT Back Reason</th>
                                                        <th style={{minWidth: "140px"}}>Sent NFT Back TXID</th>
                                                        <th style={{minWidth: "140px"}}>Sent Native Back</th>
                                                        <th style={{minWidth: "140px"}}>Native Received Back</th>
                                                        <th style={{minWidth: "140px"}}>Native Sent TXID</th>
                                                        <th style={{minWidth: "140px"}}>Native Sent Reason</th>
                                                        <th style={{minWidth: "140px"}}>Sent Native Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {nftSales.length > 0 ? (
                                                        nftSales.map((sale, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <input type="checkbox" className="form-check-input" checked={sale.isSelected} onChange={() => handleSelect(sale.id)} />
                                                                </td>
                                                                <td>{sale.id}</td>
                                                                <td style={{minWidth: "100px"}}>{sale.username ? <Link to={`/panel/admin/manage-users/${sale.forUserID}`} className="text-decoration-none text-dark">{sale.username} <i className="fa-solid fa-external-link-alt ms-2"></i></Link> : "N/A"}</td>
                                                                <td style={{minWidth: "100px"}}>{sale.nft_sale_chainID ? <ChainLogo chainID={sale.nft_sale_chainID} showSymbol={false} /> : "N/A"}</td>
                                                                <td style={{minWidth: "150px"}}>{sale.nft_sale_worthUSD ? <span className="badge bg-secondary">${sale.nft_sale_worthUSD}</span> : "N/A"}</td>
                                                                <td style={{minWidth: "100px"}}>{sale.nft_sale_attempts}</td>
                                                                <td style={{minWidth: "100px"}}>{sale.users_payout_address ? <span className="badge bg-primary">{sale.users_payout_address}</span> : "N/A"}</td>
                                                                <td style={{minWidth: "100px"}}>
                                                                    {sale.nft_sale_token_address && sale.nft_sale_token_id ? (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span className="badge bg-primary">{sale.nft_sale_token_address}:{sale.nft_sale_token_id}</span>
                                                                            <a href={makeOpenseaUrl(sale.nft_sale_token_address, sale.nft_sale_token_id, sale.nft_sale_chainID)} target="_blank" rel="noopener noreferrer">
                                                                                <i className="fa-solid fa-external-link-alt ms-2 text-decoration-none text-dark"></i>
                                                                            </a>
                                                                        </div>
                                                                    ) : "N/A"}
                                                                </td>
                                                                <td style={{minWidth: "140px"}}>{sale.nft_sale_reason}</td>
                                                                <td style={{minWidth: "150px"}}>{sale.nft_sale_status ? makeStatusBadge(sale.nft_sale_status) : "N/A"}</td>
                                                                <td style={{minWidth: "200px"}}>{sale.nft_sale_listing_orderHash}</td>
                                                                <td style={{minWidth: "150px"}}>{sale.nft_sale_success == 1 ? <span className="badge bg-success">Success</span> : <span className="badge bg-danger">Failed</span>}</td>
                                                                <td style={{minWidth: "160px"}}>{sale.nft_sale_txid ? <span className="badge bg-primary">{sale.nft_sale_txid}</span> : "N/A"}</td>
                                                                <td style={{minWidth: "200px"}}>{sale.sentNFTBackToUserAsFailed == 1 ? <span className="badge bg-danger">Yes</span> : <span className="badge bg-success">No</span>}</td>
                                                                <td style={{minWidth: "200px"}}>{sale.sentNFTBackAsFailedReason}</td>
                                                                <td style={{minWidth: "200px"}}>
                                                                    {sale.sentNFTBackAsFailedTXID ? (
                                                                        <a href={`${findExplorerUrl(sale.nft_sale_chainID)}/tx/${sale.sentNFTBackAsFailedTXID}`} className="d-flex align-items-center text-decoration-none text-dark" target="_blank" rel="noopener noreferrer">
                                                                            <span className="badge bg-secondary">{sale.sentNFTBackAsFailedTXID}</span>
                                                                            <i className="fa-solid fa-external-link-alt ms-2"></i>
                                                                        </a>
                                                                    ) : "N/A"}
                                                                </td>
                                                                <td style={{minWidth: "200px"}}>{sale.didSendNativeBack == 1 ? <span className="badge bg-success">Yes</span> : <span className="badge bg-danger">No</span>}</td>
                                                                <td style={{minWidth: "200px"}}>{sale.nativeReceivedBack ? <span>{convertWeiToEther(sale.nativeReceivedBack)} <ChainLogo chainID={1} showSymbol={false} /></span> : "N/A"}</td>
                                                                <td style={{minWidth: "200px"}}>
                                                                    {sale.sentNativeTXID ? (
                                                                        <a href={`${findExplorerUrl(sale.nft_sale_chainID)}/tx/${sale.sentNativeTXID}`} className="d-flex align-items-center text-decoration-none text-dark" target="_blank" rel="noopener noreferrer">
                                                                            <span className="badge bg-secondary">{sale.sentNativeTXID}</span>
                                                                            <i className="fa-solid fa-external-link-alt ms-2"></i>
                                                                        </a>
                                                                    ) : "N/A"}
                                                                </td>
                                                                <td style={{minWidth: "200px"}}>{sale.sentNativeReason}</td>
                                                                <td style={{minWidth: "200px"}}>{sale.sentNativeStatus ? makeStatusBadge(sale.sentNativeStatus) : "N/A"}</td>
                                                        </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6" className="text-center">No NFT sales to display.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
										<footer className="footer bg-light w-100">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span>
													Showing {Math.min((currentPage - 1) * 10 + 1, nftSales.length)}-{Math.min(currentPage * 10, nftSales.length)} of {nftSales.length} results
												</span>
											</div>
											<nav className="w-100">
												<ul className="pagination">
													{/* Previous Page Button */}
													<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
															Previous
														</button>
													</li>
													{/* Current Page Button */}
													{totalPages > 0 &&
														Array.from({ length: totalPages }, (_, i) => (
															<li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
																<button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
																	{i + 1}
																</button>
															</li>
														))}
													{/* Next Page Button */}
													<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
															Next
														</button>
													</li>
												</ul>
											</nav>
										</footer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}