
export async function deleteMultipleRows(apiUrl, idsToDelete, token, username) {
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ ids: idsToDelete, token: token, username: username })
        });
        const data = await response.json();
        if (!data.success) {
            throw new Error(data.message || 'Failed to delete rows');
        }
        return data;
    } catch (error) {
        console.error('Error deleting rows:', error);
        window.notyf.open({
            message: error.message || 'Failed to delete rows',
            type: 'error',
            duration: 4000
        });
        throw error;
    }
}