import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../../Hooks/useAuthentication";
import { config } from "../../config";

export default function AddUser() {
	const auth = useAuth();
	const [user, setUser] = useState({
		username: "",
		registration_date: "",
		approved_date: "",
		traffic_method: "",
		how_find_us: "",
		bot_token: "",
		status: "activated",
		user_level: "admin",
		bot_chat_id: "",
		use_global_telegram_tokens: false,
		withdraw_address: "",
		avatarURL: "",
		xp: "",
		password: "",
	});

	// Function to handle changes in input fields
	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		if (type === "date") {
			setUser((prevState) => ({
				...prevState,
				[name]: new Date(value).getTime() / 1000, // Convert back to epoch timestamp
			}));
		} else {
			setUser((prevState) => ({
				...prevState,
				[name]: type === "checkbox" ? checked : value,
			}));
		}
	};

	const handleAddUser = async (event) => {
		if (auth.loading) return;
		if (!auth.token) return;

		event.preventDefault(); // Prevent the default form submission behavior

		const response = await fetch(`${config.apiUrl}/fpanel/admin/addUser`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${auth.token}`,
			},
			body: JSON.stringify({
				token: auth.token,
				username: auth.username,
				data: user,
			}),
		});

		const responseData = await response.json();
		if (responseData.success) {
			window.notyf.open({
				type: "success",
				message: "User added successfully",
				duration: 4000,
			});
		} else {
			console.error("Failed to add user:", responseData.message);
			window.notyf.open({
				type: "danger",
				message: "Failed to add user: " + responseData.message,
				duration: 4000,
			});
		}
	};

	// Convert epoch to date string for date input
	const registrationDate = user.registration_date && !isNaN(user.registration_date) ? new Date(user.registration_date * 1000).toISOString().split("T")[0] : "";
	const approvedDate = user.approved_date && !isNaN(user.approved_date) ? new Date(user.approved_date * 1000).toISOString().split("T")[0] : "";

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Add New User</h1>
							<div>
								<Link to="/panel/admin/manage-users/view" className="btn btn-primary rounded-pill">
									<i className="far fa-plus"></i> Back
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
										{/* Form for adding new user details */}
										<form onSubmit={handleAddUser}>
											<div className="mb-3">
												<label htmlFor="username" className="form-label">
													Username
												</label>
												<input type="text" className="form-control" id="username" value={user.username} onChange={handleChange} name="username" />
											</div>
											<div className="mb-3">
												<label htmlFor="registrationDate" className="form-label">
													Registration Date
												</label>
												<input type="date" className="form-control" id="registration_date" value={registrationDate} onChange={handleChange} name="registration_date" />
											</div>
											<div className="mb-3">
												<label htmlFor="approvedDate" className="form-label">
													Approved Date
												</label>
												<input type="date" className="form-control" id="approved_date" value={approvedDate} onChange={handleChange} name="approved_date" />
											</div>
											<div className="mb-3">
												<label htmlFor="trafficMethod" className="form-label">
													Traffic Method
												</label>
												<input type="text" className="form-control" id="traffic_method" value={user.traffic_method} onChange={handleChange} name="traffic_method" />
											</div>
											<div className="mb-3">
												<label htmlFor="howFindUs" className="form-label">
													How Did They Find Us?
												</label>
												<input type="text" className="form-control" id="how_find_us" value={user.how_find_us} onChange={handleChange} name="how_find_us" />
											</div>
											<div className="mb-3">
												<label htmlFor="botToken" className="form-label">
													Bot Token
												</label>
												<input type="text" className="form-control" id="bot_token" value={user.bot_token} onChange={handleChange} name="bot_token" />
											</div>
											<div className="mb-3">
												<label htmlFor="status" className="form-label">
													Status
												</label>
												<select className="form-select" value={user.status} onChange={handleChange} name="status">
													<option value="activated">Active</option>
													<option value="pending_approval">Pending Approval</option>
													<option value="banned">Banned</option>
												</select>
											</div>
											<div className="mb-3">
												<label htmlFor="userLevel" className="form-label">
													User Level
												</label>
												<select className="form-select" value={user.user_level} onChange={handleChange} name="user_level">
													<option value="admin">Admin</option>
													<option value="user">User</option>
												</select>
											</div>
											<div className="mb-3">
												<label htmlFor="botChatId" className="form-label">
													Bot Chat ID
												</label>
												<input type="text" className="form-control" id="bot_chat_id" value={user.bot_chat_id} onChange={handleChange} name="bot_chat_id" />
											</div>
											<div className="mb-3 form-check">
												<input
													type="checkbox"
													className="form-check-input"
													id="useGlobalTelegramTokens"
													checked={user.use_global_telegram_tokens}
													onChange={handleChange}
													name="use_global_telegram_tokens"
												/>
												<label className="form-check-label" htmlFor="useGlobalTelegramTokens">
													Use Global Telegram Tokens
												</label>
											</div>
											<div className="mb-3">
												<label htmlFor="withdrawAddress" className="form-label">
													Withdraw Address
												</label>
												<input type="text" className="form-control" id="withdraw_address" value={user.withdraw_address} onChange={handleChange} name="withdraw_address" />
											</div>
											<div className="mb-3">
												<label htmlFor="avatarURL" className="form-label">
													Avatar URL
												</label>
												<div className="d-flex align-items-center">
													<input type="text" className="form-control" id="avatarURL" value={user.avatarURL} onChange={handleChange} name="avatarURL" />
													<img
														className="rounded"
														src={user.avatarURL ? user.avatarURL : "img/newLogoLight.png"}
														alt="User Avatar"
														style={{ width: "50px", height: "50px", marginLeft: "10px" }}
													/>
												</div>
											</div>
											<div className="mb-3">
												<label htmlFor="xp" className="form-label">
													XP
												</label>
												<input type="number" className="form-control" id="xp" value={user.xp} onChange={handleChange} name="xp" />
											</div>
											<div className="mb-3">
												<label htmlFor="changePassword" className="form-label">
													Password
												</label>
												<input type="text" className="form-control" id="change_password" onChange={handleChange} name="password" />
											</div>
											<button type="submit" className="btn btn-primary">
												Save Changes
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
