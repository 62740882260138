import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { useParams } from "react-router-dom";
import { truncateText } from "../../Helpers/truncateText";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { renderColorCircle } from "../../Helpers/moduleColorIcon";

export default function ManageCreate2s() {
	const auth = useAuth();

	const [create2s, setCreate2s] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [showBytecodeModal, setShowBytecodeModal] = useState(false);
	const [selectedBytecode, setSelectedBytecode] = useState("");

	// Filter by a value and type
	const [filterType, setFilterType] = useState("all");
	const [filterValue, setFilterValue] = useState("");

	useEffect(() => {
		if (auth.loading || !auth.token || !auth.username) return;
		getCreate2s();
	}, [auth, currentPage, filterValue, filterType]); // Add currentPage to the dependency array

	const getCreate2s = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/getBypassAddresses`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${auth.token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					page: currentPage || 1,
                    filterType: filterType,
                    filterValue: filterValue,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch Create2s data");
			}
			const data = await response.json();
			setCreate2s(data?.data);
			setTotalPages(data?.totalPages);
		} catch (error) {
			console.error("Failed to fetch Create2s:", error);
			window.notyf.open({
				type: "danger",
				message: `Failed to fetch Create2s: ${error.message}`,
				duration: 4000,
			});
		}
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleShowBytecode = (bytecode) => {
		setSelectedBytecode(bytecode);
		setShowBytecodeModal(true);
	};

    const handleSelect = (create2Id) => {
		setCreate2s((prevCreate2s) =>
			prevCreate2s.map((create2) => {
				if (create2.id === create2Id) {
					return { ...create2, isSelected: !create2.isSelected };
				}
				return create2;
			})
		);
	};

	const handleSelectAll = () => {
		const areAllSelected = create2s.every((create2) => create2.isSelected);
		setCreate2s((prevCreate2s) =>
			prevCreate2s.map((create2) => ({
				...create2,
				isSelected: !areAllSelected,
			}))
		);
	};

	const handleDeleteSelected = async () => {
		const selectedIds = create2s.filter((create2) => create2.isSelected).map((create2) => create2.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No create2s selected",
				duration: 4000,
			});
			return;
		}

		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteBypassAddresses`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "Create2s deleted successfully",
				duration: 4000,
			});
			setCreate2s((prev) => prev.filter((create2) => !selectedIds.includes(create2.id)));
		} catch (error) {
			console.error("Failed to delete create2s:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to delete create2s",
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Create2s</h1>
							<div>
								<button className="btn btn-danger rounded-pill" onClick={handleDeleteSelected}>
									<i className="fa-solid fa-trash"></i> Delete Selected
								</button>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
									<div className="mb-3 d-flex">
											<select className="form-select me-2" style={{ width: "auto" }} value={filterType} onChange={(e) => setFilterType(e.target.value)}>
												<option value="all">All</option>
												<option value="futureAddress">Address</option>
												<option value="for_user_ID">User ID</option>
												<option value="forModuleName">Module Name</option>
												<option value="moduleChainID">Chain ID</option>
												<option value="used">Used?</option>
											</select>
											{filterType !== "all" && (
												<input
													type="text"
													className="form-control"
													style={{ width: "auto" }}
													value={filterValue}
													onChange={(e) => setFilterValue(e.target.value)}
													placeholder={`Filter by ${filterType}`}
												/>
											)}
										</div>
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>
															<input type="checkbox" className="form-check-input" onClick={handleSelectAll} checked={create2s.length > 0 && create2s.every((create2) => create2.isSelected)} />
														</th>
														<th>ID</th>
                                                        <th style={{ minWidth: "150px" }}>For Domain</th>
														<th>Future Address</th>
														<th>Salt</th>
														<th>Bytecode</th>
														<th style={{ minWidth: "150px" }}>For User ID</th>
														<th style={{ minWidth: "150px" }}>For Module</th>
														<th style={{ minWidth: "150px" }}>Run Worth</th>
														<th style={{ minWidth: "100px" }}>Chain ID</th>
														<th>Used?</th>
														<th>Manage</th>
													</tr>
												</thead>
												<tbody>
													{create2s && create2s.length > 0 ? (
														create2s.map((create2, index) => (
															<tr key={index}>
																<td>
																	<input type="checkbox" className="form-check-input" onClick={() => handleSelect(create2.id)} checked={create2.isSelected || false} />
																</td>
																<td>{create2.id}</td>
                                                                <td>{create2.for_domain ? <span className="badge bg-primary">{create2.for_domain}</span> : "N/A"}</td>
																<td style={{ minWidth: "200px" }}>{create2.futureAddress ? <span className="badge bg-primary">{truncateText(create2.futureAddress, 20)}</span> : "N/A"} <i className="fa-solid fa-copy ms-2" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(create2.futureAddress)}></i></td>
																<td>{create2.salt}</td>
																<td style={{ minWidth: "200px" }}>
																	{create2.bytecode ? (
																		<>
																			{truncateText(create2.bytecode, 20)}
																			<span className="text-muted" style={{ cursor: "pointer" }} onClick={() => handleShowBytecode(create2.bytecode)}>
																				<i className="fa-solid fa-eye"></i>
																			</span>
																		</>
																	) : (
																		"N/A"
																	)}
																</td>{" "}
																<td>{create2.for_user_ID} {create2.forUsername ? <span className="badge bg-primary">{create2.forUsername}</span> : ""} {create2.forUsername ? <Link to={`/panel/admin/manage-users/${create2.for_user_ID}`}><i className="fa-solid fa-link text-primary ms-2"></i></Link> : ""}</td>
																<td style={{textTransform: "capitalize", minWidth: "200px"}}>{create2.forModuleName ? <span style={{color: renderColorCircle(create2.forModuleName)}}>{renderColorCircle(create2.forModuleName, {display: "inline-block"})}</span> : ""} {create2.forModuleName}</td>
																<td>{create2.moduleRunWorth ? <span className="badge bg-primary">${parseFloat(create2.moduleRunWorth).toFixed(2)}</span> : "$0"}</td>
																<td>{create2.moduleChainID ? <ChainLogo chainID={create2.moduleChainID} /> : "N/A"}</td>
																<td>{create2.used ? <i className="fas fa-check text-success"></i> : <i className="fa-solid fa-times text-danger"></i>}</td>
																<td>
																	<Link to={`/panel/admin/manage-create2s/${create2.id}/balances`} className="btn btn-sm btn-primary"><i className="fa-solid fa-money-bill-alt"></i></Link>
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="12" className="text-center">
																No Create2s found
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<footer className="footer bg-light w-100">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span>
													Showing {Math.min((currentPage - 1) * 10 + 1, create2s.length)}-{Math.min(currentPage * 10, create2s.length)} of {create2s.length} results
												</span>
											</div>
											<nav className="w-100">
												<ul className="pagination">
													{/* Previous Page Button */}
													<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
															Previous
														</button>
													</li>
													{/* Current Page Button */}
													{totalPages > 0 &&
														Array.from({ length: totalPages }, (_, i) => (
															<li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
																<button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
																	{i + 1}
																</button>
															</li>
														))}
													{/* Next Page Button */}
													<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
															Next
														</button>
													</li>
												</ul>
											</nav>
										</footer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
            {showBytecodeModal && (
        <>
          <div
            className="modal fadeUp show"
            id="centeredModalPrimary"
            tabIndex={-1}
            style={{ display: "block" }}
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" style={{ textTransform: 'capitalize' }}>Full Bytecode <span className="badge bg-primary" style={{ fontSize: "12px" }} onClick={() => copyToClipboard(selectedBytecode)}><i className="fa-solid fa-copy"></i></span></h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowBytecodeModal(false)}
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body m-0 p-0">
                  {selectedBytecode && (
                    <textarea style={{width: "100%", height: "100%", minHeight: "300px"}} className="form-control p-4" value={selectedBytecode} readOnly></textarea>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setShowBytecodeModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
		</div>
	);
}
