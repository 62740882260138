import { useParams, useHistory } from "react-router-dom"; // Add useHistory here
import { useEffect, useState, useRef } from "react";
import { config } from "../../../config";
import { useAuth } from "../../../Hooks/useAuthentication";
import { Link } from "react-router-dom";

import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { timeAgo } from "../../../Helpers/epochTimeAgo";
import { useXPSystem } from "../../../Hooks/useXPSystem";
import { getStyledUsername, getLevelFromXP } from "../../../Hooks/useXPSystem";

export default function TicketChat() {
	const history = useHistory(); // Initialize useHistory
	const { ticketId } = useParams();
	const [ticketDetails, setTicketDetails] = useState(null);
	const { username, token, loading, userID, avatar, xp } = useAuth();
	const { styledUsername, styledBluredUsername } = useXPSystem(0, username);
	const messageRef = useRef(null); // Define messageRef here
	const ws = useRef(null);
	const chatMessagesRef = useRef(null); // Add this line to create a reference for the chat messages container

	const [wsConnected, setWsConnected] = useState(false);

	useEffect(() => {
		if (loading) return;
		fetchTicketDetails();
	}, [ticketId, loading]);

	useEffect(() => {

		let reconnectInterval;

		const connectWebSocket = () => {
			// Establish WebSocket connection
			const websocket = new WebSocket("ws://localhost:3000/ws");
			ws.current = websocket;
			ws.current.onmessage = async (event) => {
				if (event.data instanceof Blob) {
					const text = await event.data.text();
					const data = JSON.parse(text);
					// Process the data as before
					switch (data.type) {
						case "NEW_MESSAGE":
							setTicketDetails((currentTicketDetails) => ({
								...currentTicketDetails,
								chats: [...currentTicketDetails.chats, data.message],
							}));
							await fetchTicketDetails(); // Fetch updated ticket and chats after receiving a new message
							// Auto-scroll to the bottom of the chat messages container
							if (chatMessagesRef.current) {
								chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
							}
							break;
						default:
							break;
					}
				} else {
					console.log(`Received message`, event.data);
					const data = JSON.parse(event.data);
					// Existing switch case as before
					switch (data.type) {
						case "NEW_MESSAGE":
							setTicketDetails((currentTicketDetails) => ({
								...currentTicketDetails,
								chats: [...currentTicketDetails.chats, data.message],
							}));
							await fetchTicketDetails(); // Fetch updated ticket and chats after receiving a new message
							// Auto-scroll to the bottom of the chat messages container
							if (chatMessagesRef.current) {
								chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
							}
							break;
						default:
							break;
					}
				}
			}
			ws.current.onopen = () => {
				console.log("WebSocket connected");
				clearInterval(reconnectInterval);
				setWsConnected(true);

                // Join the specific ticket room
                ws.current.send(JSON.stringify({
                    type: 'JOIN_ROOM',
                    ticketId: ticketId,
					roomId: ticketId
                }));
			};
		  
			ws.current.onclose = () => {
				console.log("WebSocket disconnected. Attempting to reconnect...");
				setWsConnected(false);
				reconnectInterval = setInterval(connectWebSocket, 5000);
			};
		}

		connectWebSocket();

		// Clean up WebSocket connection and interval on component unmount
		return () => {
		  clearInterval(reconnectInterval);
		  if (ws.current && ws.current.readyState === WebSocket.OPEN) {
			// Leave the room when component unmounts
			ws.current.send(JSON.stringify({
				type: 'LEAVE_ROOM',
				ticketId: ticketId,
				roomId: ticketId
			}));
			ws.current.close();
		  }
		};
	}, [ticketId]);

	const fetchTicketDetails = async () => {
		const apiUrl = `${config.apiUrl}/fpanel/user/getTicket`;
		try {
			const response = await fetch(apiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					token,
					username,
					ticketID: ticketId,
				}),
			});

			const data = await response.json();
			if (!data.success) {
				if (data.message === "Ticket was not found") {
					// Redirect if ticket is not found
					history.push("/panel/support/all");
				}
				throw new Error(data.message || "Unknown error occurred");
			}

			console.log(data.data);
			setTicketDetails(data.data || []);
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: error.message || "Failed getting ticket details",
				duration: 4000,
			});
		}
	};

	const makeStatusBadge = (status) => {
		switch (status) {
			case "open":
				return <span className="badge bg-primary rounded-pill">Open</span>;
			case "closed":
				return <span className="badge bg-danger rounded-pill">Closed</span>;
			case "awaiting-reply":
				return <span className="badge bg-warning rounded-pill text-light">Awaiting Reply</span>;
			default:
				return <span className="badge bg-secondary rounded-pill">Unknown</span>;
		}
	};

	const sendMessage = async () => {
		const apiUrl = `${config.apiUrl}/fpanel/user/ticketReply`;
		try {
			const response = await fetch(apiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					token,
					username,
					ticketID: ticketId,
					message: messageRef.current.value,
				}),
			});

			const data = await response.json();
			if (!data.success) throw new Error(data.message || "Failed to send message");

			window.notyf.open({
				type: "success",
				message: data.message,
				duration: 4000,
			});

			if (wsConnected && ws.current.readyState === WebSocket.OPEN) {

				// Send message to WebSocket server
				ws.current.send(
					JSON.stringify({
						type: "NEW_MESSAGE",
						ticketId,
						message: messageRef.current.value,
						roomId: ticketId
					})
				);

			}

			await fetchTicketDetails();
			messageRef.current.value = "";

			// Auto-scroll to the bottom of the chat messages container
			if (chatMessagesRef.current) {
				chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
			}
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: error.message || "Error sending message",
				duration: 4000,
			});
		}
	};

	const closeTicket = async () => {
		const apiUrl = `${config.apiUrl}/fpanel/user/closeTicket`;
		try {
			const response = await fetch(apiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					token,
					username,
					ticketID: ticketId,
				}),
			});

			const data = await response.json();
			if (!data.success) throw new Error(data.message || "Failed to close ticket");

			window.notyf.open({
				type: "success",
				message: data.message,
				duration: 4000,
			});

			if (wsConnected && ws.current.readyState === WebSocket.OPEN) {

				// Notify WebSocket server about ticket closure
				ws.current.send(
					JSON.stringify({
						type: "TICKET_CLOSED",
						ticketId,
						roomId: ticketId
					})
				);

				// Send message to WebSocket server
				ws.current.send(
					JSON.stringify({
						type: "NEW_MESSAGE",
						ticketId,
						message: messageRef.current.value,
						roomId: ticketId
					})
				);

			}

			// Auto-scroll to the bottom of the chat messages container
			if (chatMessagesRef.current) {
				chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
			}

			// Optionally refresh ticket details to reflect the closed status
			await fetchTicketDetails();
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: error.message || "Error closing ticket",
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Ticket {ticketId}</h1>
							<div>
								<Link to="/panel/support/all" className="btn btn-primary rounded-pill">
									<i className="fas fa-fw fa-arrow-left"></i> Back to Tickets
								</Link>
								<button
									className={`ms-2 btn btn-danger rounded-pill ${ticketDetails?.ticket?.ticketStatus === "closed" ? "opacity-50" : ""}`}
									disabled={ticketDetails?.ticket?.ticketStatus === "closed"}
									onClick={closeTicket} // Add onClick handler here
								>
									<i className="fas fa-fw fa-window-close"></i> Close Ticket
								</button>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-8 col-lg-8 col-xl-8">
							<h4>Ticket Chat</h4>
							<div className={`card ${ticketDetails?.ticket?.ticketStatus === "closed" ? "opacity-50" : ""}`}>
								<div className="card-body">
									{ticketDetails?.chats?.length > 0 ? (
										<div className="position-relative">
											<div className="chat-messages p-4" style={{ maxHeight: "500px", overflowY: "auto", minHeight: "500px" }} ref={chatMessagesRef}>
												{ticketDetails?.chats?.map((message, index) => (
													<div key={index} className={`chat-message-${message.authorUserLevel === "admin" ? "right" : "left"} pb-4`}>
														<div>
															<img
																src={`${message.authorUserLevel === "admin" ? "/img/newLogoLight.png" : avatar ? avatar : "/img/newLogoLight.png"}`}
																className="rounded"
																alt={message.author}
																width={40}
																height={40}
															/>
															<div className="text-muted small text-nowrap mt-2">{message.dateSent ? timeAgo(message.dateSent) : "Unknown"}</div>
														</div>
														<div className={`flex-shrink-1 bg-light rounded py-2 px-3 ${message.authorUserLevel === "admin" ? "me-3" : "ms-3"}`}>
															<div className="font-weight-bold mb-1">{message.author === username ? getStyledUsername(message.author, getLevelFromXP(xp)?.name) : <span className="badge bg-danger rounded">{message.author}</span>}</div>
															{message.message}
														</div>
													</div>
												))}
											</div>
										</div>
									) : (
										<div className="alert alert-warning alert-dismissible" role="alert">
											<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
											<div className="alert-icon">
												<i className="far fa-fw fa-bell" />
											</div>
											<div className="alert-message">No ticket chats yet. Create one below.</div>
										</div>
									)}
									<div className="flex-grow-0 py-3 px-4 border-top">
										<div className="input-group">
											<input disabled={ticketDetails?.ticket?.ticketStatus === "closed"} type="text" className="form-control" placeholder="Type your message" ref={messageRef} />
											<button disabled={ticketDetails?.ticket?.ticketStatus === "closed"} className="btn btn-primary" onClick={() => sendMessage()}>
												Send
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-4 col-lg-4 col-xl-4">
							<h4>Main Ticket Details</h4>
							<div className="card">
								<div className="card-body">
									<div class="mb-3">
										<label class="form-label" for="inputAddress">
											Subject
										</label>
										<input type="text" readOnly class="form-control" id="inputAddress" placeholder="1234 Main St" value={ticketDetails?.ticket?.subject} />
									</div>
									<div class="mb-3">
										<label class="form-label" for="inputAddress">
											Message
										</label>
										<textarea type="text" readOnly class="form-control" id="inputAddress" placeholder="1234 Main St" value={ticketDetails?.ticket?.message} />
									</div>
									<div class="mb-3">
										<label className="form-label d-block" for="status">
											Status
										</label>
										{makeStatusBadge(ticketDetails?.ticket?.ticketStatus)}
									</div>
									<div class="mb-3">
										<label className="form-label d-block" for="createdAt">
											Created
										</label>
										{ticketDetails?.ticket?.dateCreated ? (
											<span id="createdAt" className="badge bg-primary rounded-pill">
												{timeAgo(ticketDetails?.ticket?.dateCreated)}
											</span>
										) : (
											"Created ??"
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
