import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom'; // Import useHistory
import {config} from '../config';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [howFind, setHowFind] = useState('');
  const [trafficMethod, setTrafficMethod] = useState('');
  const [botCode, setBotCode] = useState('');

  const history = useHistory(); // Initialize useHistory

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(`${config.apiUrl}/fpanel/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, how_find: howFind, traffic_method: trafficMethod, bot_code: botCode }),
    });

    console.log('Response status:', response.status); // Log response status

    if (response.ok) {
        const data = await response.json();
        console.log('Response data:', data); // Log response data
  
        if (data && data.success) {
          window.notyf.open({
            type: 'success',
            message: data.message || 'Successfully registered! Please wait for admin approval.',
            duration: 4000,
          });
          history.push('/login');
        } else {
          console.error('Registration failed: Invalid response data');
          window.notyf.open({
            type: 'error',
            message: data.message || 'Registration failed!',
            duration: 4000,
          });
        }
      } else {
        console.error('Registration failed');
        window.notyf.open({
          type: 'error',
          message: 'Registration failed!',
          duration: 4000,
        });
      }
    };

  return (
    <main className="d-flex w-100 h-100">
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">

              <div className="text-center mt-4">
                <h1 className="h2">Get started</h1>
                <p className="lead">
                  Start your journey on X today!
                </p>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="m-sm-3">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label">Username</label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="username"
                          placeholder="Enter your username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input
                          className="form-control form-control-lg"
                          type="password"
                          name="password"
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">How did you find us?</label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="how_find"
                          placeholder="How did you find us?"
                          value={howFind}
                          onChange={(e) => setHowFind(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Traffic Method</label>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="traffic_method"
                          placeholder="Enter your traffic method"
                          value={trafficMethod}
                          onChange={(e) => setTrafficMethod(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Bot Code</label>
                        <small className='d-block mb-2'>Click <a href="https://t.me/TargetXPanel_bot?start" target="_blank">here</a> and type <code>/start</code> to get your bot code</small>
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          name="bot_code"
                          placeholder="Enter your bot code"
                          value={botCode}
                          onChange={(e) => setBotCode(e.target.value)}
                        />
                      </div>
                      <div className="d-grid gap-2 mt-3">
                        <button type="submit" className="btn btn-lg btn-primary">Sign up</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="text-center mb-3">
                Already have an account? <Link to="/login">Log In</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Register;