import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { config, chainMappingRPCs } from "../../config";
import StatBoxesHelper from "../StatBoxesHelper";
import { useAuth } from "../../Hooks/useAuthentication";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { LineChartHelper } from "../ChartHelper";
import React from "react";
import { useMemo } from "react";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { formatDateTimeForDisplay } from "../../Helpers/epochToString";
import { getStyledUsername } from "../../Hooks/useXPSystem";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { truncateText } from "../../Helpers/truncateText";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { ethers } from "ethers";

export default function ManageTokenSales() {
	const auth = useAuth();
	const [tokenSales, setTokenSales] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const fetchTokenSales = useCallback(async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/getAllTokenSales`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					page: currentPage,
					token: auth.token,
					username: auth.username,
				}),
			});
			if (!response.ok) {
				window.notyf.open({
					type: "danger",
					message: `Failed to fetch token sales`,
					duration: 4000,
				});
				throw new Error("Failed to fetch token sales data");
			}
			const data = await response.json();
			if (!data) {
				window.notyf.open({
					type: "danger",
					message: `Failed to fetch token sales`,
					duration: 4000,
				});
				throw new Error("Failed to fetch token sales data");
			}
			if (data.data) {
				setTokenSales(data.data);
			}
			if (data.totalPages) {
				setTotalPages(data.totalPages);
			}
		} catch (error) {
			console.error("Error fetching token sales:", error);
			window.notyf.open({
				type: "danger",
				message: `Failed to fetch token sales`,
				duration: 4000,
			});
		}
	}, [currentPage]);

	useEffect(() => {
		if (!auth.isLoading) {
			fetchTokenSales();
		}
	}, [auth.isLoading, currentPage, fetchTokenSales]);

	const handleSelect = (tokenSaleId) => {
		setTokenSales((prevTokenSales) =>
			prevTokenSales.map((tokenSale) => {
				if (tokenSale.id === tokenSaleId) {
					return { ...tokenSale, isSelected: !tokenSale.isSelected };
				}
				return tokenSale;
			})
		);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleSelectAll = () => {
		const areAllSelected = tokenSales.every((tokenSale) => tokenSale.isSelected);
		setTokenSales((prevTokenSales) =>
			prevTokenSales.map((tokenSale) => ({
				...tokenSale,
				isSelected: !areAllSelected,
			}))
		);
	};

	const handleDeleteSelected = async () => {
		const selectedIds = tokenSales.filter((tokenSale) => tokenSale.isSelected).map((tokenSale) => tokenSale.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No token sales selected",
				duration: 4000,
			});
			return;
		}

		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteBypassAddresses`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "Token sales deleted successfully",
				duration: 4000,
			});
			setTokenSales((prev) => prev.filter((tokenSale) => !selectedIds.includes(tokenSale.id)));
		} catch (error) {
			console.error("Failed to delete token sales:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to delete token sales",
				duration: 4000,
			});
		}
	};

	const convertWeiToEther = (wei) => {
		return ethers.utils.formatEther(wei);
	};

	const findExplorerUrl = (chainID) => {
		return chainMappingRPCs[chainID]?.explorerUrl || "https://etherscan.io";
	};

    const makeTokenExplorerUrl = (tokenAddress, chainID) => {
        var chainName = chainMappingRPCs[chainID]?.name;
        var explorerUrl = chainMappingRPCs[chainID]?.explorerUrl;
        return `${explorerUrl}/token/${tokenAddress}`;
    }

    const formatRealTokenAmount = (tokenAmount, tokenDecimals) => {
        const divisor = Math.pow(10, tokenDecimals);
        return (tokenAmount / divisor).toFixed(tokenDecimals);
    }

    const makeStatusBadge = (status) => {
        switch (status) {
            case "processing":
                return <span className="badge bg-warning">Processing</span>;
            case "completed":
                return <span className="badge bg-success">Completed</span>;
            case "failed":
                return <span className="badge bg-danger">Failed</span>;
            default:
                return <span className="badge bg-danger">Failed</span>;
        }
    }

    const convertRawNativeAmountToEther = (rawAmount) => {
        return ethers.utils.formatEther(rawAmount);
    }

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Token Sales</h1>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>
															<input
																type="checkbox"
																className="form-check-input"
																onClick={handleSelectAll}
																checked={tokenSales.length > 0 && tokenSales.every((tokenSale) => tokenSale.isSelected)}
															/>
														</th>
														<th style={{ minWidth: "100px" }}>ID</th>
														<th style={{ minWidth: "100px" }}>Username</th>
                                                        <th style={{ minWidth: "100px" }}>ChainID</th>
                                                        <th style={{ minWidth: "100px" }}>Payout Address</th>
                                                        <th style={{ minWidth: "100px" }}>Token</th>
                                                        <th style={{ minWidth: "100px" }}>Token Amount</th>
                                                        <th style={{ minWidth: "100px" }}>Sell Status</th>
                                                        <th style={{ minWidth: "100px" }}>TXID of sale</th>
                                                        <th style={{ minWidth: "100px" }}>Did Sell</th>
                                                        <th style={{ minWidth: "100px" }}>Sale Reason</th>
                                                        <th style={{ minWidth: "100px" }}>Sent Native</th>
                                                        <th style={{ minWidth: "100px" }}>Sent Native Amount</th>
                                                        <th style={{ minWidth: "100px" }}>Sent Native TXID</th>
                                                        <th style={{ minWidth: "100px" }}>Sent Native Reason</th>
                                                        <th style={{ minWidth: "100px" }}>Native Status</th>
                                                        <th style={{ minWidth: "100px" }}>USD Worth of sold</th>
                                                        <th style={{ minWidth: "100px" }}>Sent Token Back As Failed</th>
                                                        <th style={{ minWidth: "100px" }}>Is X Fee</th>
                                                        <th style={{ minWidth: "100px" }}>Has Cross Chain Swapped</th>
                                                        <th style={{ minWidth: "100px" }}>Cross Chain Swap TXID</th>
                                                        <th style={{ minWidth: "100px" }}>Cross Chain Swap Status</th>
                                                        <th style={{ minWidth: "100px" }}>Cross Chain Swap Reason</th>
                                                        <th style={{ minWidth: "100px" }}>Has Been Used For Reward Pot</th>
													</tr>
												</thead>
												<tbody>
													{tokenSales.length > 0 ? (
														tokenSales.map((sale, index) => (
															<tr key={index}>
																<td>
																	<input
																		type="checkbox"
																		className="form-check-input"
																		checked={sale.isSelected}
																		onChange={() => handleSelect(sale.id)}
																	/>
																</td>
                                                                <td>{sale.id}</td>
                                                                <td style={{minWidth: "100px"}}>{sale.username ? <Link to={`/panel/admin/manage-users/${sale.forUserID}`} className="text-decoration-none text-dark">{sale.username} <i className="fa-solid fa-external-link-alt ms-2"></i></Link> : "N/A"}</td>
                                                                <td style={{minWidth: "100px"}}>{sale.chainID ? <><ChainLogo chainID={sale.chainID} showSymbol={false} /> {'->'} <ChainLogo chainID={sale.toChainID} showSymbol={false} /></> : "N/A"}</td>
                                                                <td style={{minWidth: "100px"}}>{sale.payoutAddress ? sale.payoutAddress : "N/A"}</td>
                                                                <td style={{minWidth: "150px"}}>
                                                                    {sale.tokenAddress ? (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span className="badge bg-primary">{sale.tokenAddress}</span>
                                                                            <a href={makeTokenExplorerUrl(sale.tokenAddress, sale.chainID)} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '8px' }}>
                                                                                <i className="fa-solid fa-external-link-alt text-decoration-none text-dark"></i>
                                                                            </a>
                                                                        </div>
                                                                    ) : "N/A"}
                                                                </td>
                                                                <td style={{minWidth: "100px"}}>{sale.tokenAmount ? formatRealTokenAmount(sale.tokenAmount, sale.tokenDecimals || 18) : "N/A"}</td>
                                                                <td style={{minWidth: "100px"}}>{sale.txStatus ? makeStatusBadge(sale.txStatus) : "N/A"}</td>
                                                                <td style={{minWidth: "200px"}}>
                                                                    {sale.txid ? (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span className="badge bg-primary">{sale.txid}</span>
                                                                            <a href={`${findExplorerUrl(sale.chainID)}/tx/${sale.txid}`} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '8px' }}>
                                                                                <i className="fa-solid fa-external-link-alt text-decoration-none text-dark"></i>
                                                                            </a>
                                                                        </div>
                                                                    ) : "N/A"}
                                                                </td>
                                                                <td style={{minWidth: "100px"}}>{sale.didAutoSell == 1 ? <span className="badge bg-success">Yes</span> : <span className="badge bg-danger">No</span>}</td>
                                                                <td style={{minWidth: "140px"}}>{sale.reason ? sale.reason : "N/A"}</td>
                                                                <td style={{minWidth: "130px"}}>{sale.didSendNative == '1' ? <span className="badge bg-success">Yes</span> : <span className="badge bg-danger">No</span>}</td>
                                                                <td style={{minWidth: "230px"}}>{sale.sentNativeAmountRaw ? <>{convertRawNativeAmountToEther(sale.sentNativeAmountRaw)} <ChainLogo chainID={1} showSymbol={false} /></> : "N/A"}</td>
                                                                <td style={{minWidth: "230px"}}>
                                                                    {sale.sentNativeTxid ? (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span className="badge bg-primary">{sale.sentNativeTxid}</span>
                                                                            <a href={`${findExplorerUrl(sale.chainID)}/tx/${sale.sentNativeTxid}`} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '8px' }}>
                                                                                <i className="fa-solid fa-external-link-alt text-decoration-none text-dark"></i>
                                                                            </a>
                                                                        </div>
                                                                    ) : "N/A"}
                                                                </td>
                                                                <td style={{minWidth: "240px"}}>{sale.nativeReason ? sale.nativeReason : "N/A"}</td>
                                                                <td style={{minWidth: "130px"}}>{sale.nativeTxStatus ? makeStatusBadge(sale.nativeTxStatus) : "N/A"}</td>
                                                                <td style={{minWidth: "130px"}}>{sale.usdWorthOfSoldTokens ? `$${sale.usdWorthOfSoldTokens}` : "N/A"}</td>
                                                                <td style={{minWidth: "230px"}}>{sale.sentTokenBackToUserAsFailed == '1' ? <span className="badge bg-danger">Yes</span> : <span className="badge bg-success">No</span>}</td>
                                                                <td style={{minWidth: "130px"}}>{sale.isXFeeCut == 1 ? <i className="fa-solid fa-check-circle text-success"></i> : <i className="fa-solid fa-times-circle text-danger"></i>}</td>
                                                                <td style={{minWidth: "230px"}}>{sale.hasCrossChainSwapped == 1 ? <i className="fa-solid fa-check-circle text-success"></i> : <i className="fa-solid fa-times-circle text-danger"></i>}</td>
                                                                <td style={{minWidth: "200px"}}>
                                                                    {sale.crossChainSwapTX ? (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <span className="badge bg-primary">{sale.crossChainSwapTX}</span>
                                                                            <a href={`${findExplorerUrl(sale.chainID)}/tx/${sale.crossChainSwapTX}`} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '8px' }}>
                                                                                <i className="fa-solid fa-external-link-alt text-decoration-none text-dark"></i>
                                                                            </a>
                                                                        </div>
                                                                    ) : "N/A"}
                                                                </td>
                                                                <td style={{minWidth: "200px"}}>{sale.crossChainSwapStatus ? makeStatusBadge(sale.crossChainSwapStatus) : "N/A"}</td>
                                                                <td style={{minWidth: "230px"}}>{sale.crossChainSwapReason ? sale.crossChainSwapReason : "N/A"}</td>
                                                                <td style={{minWidth: "260px"}}>{sale.hasBeenUsedForRewardPot == 1 ? <i className="fa-solid fa-check-circle text-success"></i> : <i className="fa-solid fa-times-circle text-danger"></i>}</td>
                                                            </tr>
														))
													) : (
														<tr>
															<td
																colSpan="6"
																className="text-center"
															>
																No token sales to display.
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<footer className="footer bg-light w-100">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span>
													Showing {Math.min((currentPage - 1) * 10 + 1, tokenSales.length)}-{Math.min(currentPage * 10, tokenSales.length)} of {tokenSales.length} results
												</span>
											</div>
											<nav className="w-100">
												<ul className="pagination">
													{/* Previous Page Button */}
													<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
														<button
															className="page-link"
															onClick={() => handlePageChange(currentPage - 1)}
															disabled={currentPage === 1}
														>
															Previous
														</button>
													</li>
													{/* Current Page Button */}
													{totalPages > 0 &&
														Array.from({ length: totalPages }, (_, i) => (
															<li
																key={i + 1}
																className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
															>
																<button
																	className="page-link"
																	onClick={() => handlePageChange(i + 1)}
																	disabled={currentPage === i + 1}
																>
																	{i + 1}
																</button>
															</li>
														))}
													{/* Next Page Button */}
													<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
														<button
															className="page-link"
															onClick={() => handlePageChange(currentPage + 1)}
															disabled={currentPage === totalPages}
														>
															Next
														</button>
													</li>
												</ul>
											</nav>
										</footer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
