import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Hooks/useAuthentication";
import { Link } from "react-router-dom";
import { splitNameservers } from "../../../Helpers/splitNameservers";
import useDomainIcons from "../../../Hooks/useDomainIcons";
import { getDefaultIcon, placeholderIcon } from "../../../Helpers/getDomainIcon";
import { config, chainMappingRPCs } from "../../../config";
import { ChainLogo } from "../../../Helpers/getChainLogo";
import { ethers } from "ethers";

export default function AutoSellerStatuses() {
	const auth = useAuth();

	const [statuses, setStatuses] = useState({});
    // Add a new state for loading
    const [isLoading, setIsLoading] = useState(true);
    const [err, setErr] = useState(null);

	const fetchAutoNFTStatus = async () => {
		try {
            setIsLoading(true);
			const response = await fetch(`${config.apiUrl}/fpanel/user/getAutoSellerStatus`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					username: auth.username,
					token: auth.token,
				}),
			});
			if (!response.ok) {
                setIsLoading(false);
				if (response.status === 429) {
					setErr("Rate limit exceeded. Please wait 1 minute and try again.");
				} else {
					window.notyf.open({
						type: "danger",
						title: "Error",
						message: "Failed fetching Auto NFT Seller status",
						duration: 5000,
					});
					throw new Error(`HTTP error! status: ${response.status}`);
				}
			}
			const data = await response.json();
			if (data.success) {
				setStatuses(data.data || []);
                setErr(null);
			} else {
                setErr(data.message || "An error occurred. Please try again.");
            }
            setIsLoading(false);
		} catch (error) {
			console.error("Failed fetching Auto NFT Seller status", error);
			window.notyf.open({
				type: "danger",
				title: "Error",
				message: "Failed fetching Auto NFT Seller status",
				duration: 5000,
			});
            setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchAutoNFTStatus();
	}, []);

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Auto Seller Tool Status</h1>
							<button className="btn btn-primary" onClick={fetchAutoNFTStatus}><i className="fa-solid fa-refresh"></i> Refresh Statuses</button>
						</div>

                        {err && <div className="alert alert-warning" role="alert"><div className="alert-message"><strong>Error!</strong> {err}</div></div>}

						<div className="row">
							{isLoading ? (
								<div className="col-12 d-flex justify-content-center align-items-center">
									<div className="spinner-border text-primary" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							) : statuses && Object.keys(statuses).length > 0 ? (
								Object.entries(statuses).map(([key, status]) => (
									<div className="col-12 col-md-6 col-lg-4">
										<div className="card">
											<div className="card-header px-4 pt-4 d-flex justify-content-between align-items-center">
												<h5 className="card-title mb-0">{status.name}</h5>
												<div className={`badge ${status.isOnline ? "bg-success" : "bg-danger"}`}>
													<span
														className="dot"
														style={{
															height: "10px",
															width: "10px",
															backgroundColor: status.isOnline ? "green" : "red",
															borderRadius: "50%",
															display: "inline-block",
															marginRight: "5px",
														}}
													></span>
													{status.isOnline ? "Online" : "Offline"}
												</div>
											</div>
											<p className="px-4">Status: {status.isOnline ? "Active" : "Offline"}</p>
										</div>
									</div>
								))
							) : (
								<div className="col-12">
									<div className="card pb-0 mb-0">
										<p className="p-4 pb-0">No statuses found</p>
									</div>
								</div>
							)}
						</div>

                        <div className="row">
                            <div className="col-12">
                                <h3 className="text-center mt-3">If an api is down, open a ticket and we'll do our best to get it back online ASAP</h3>
                                <div className="text-center">
                                    <Link to="/panel/support/new" className="btn btn-primary mt-3">Open Ticket</Link>
                                </div>
                            </div>
                        </div>
					</div>
				</main>
			</div>
		</div>
	);
}
