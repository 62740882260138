export const copyToClipboard = (text, message=null) => {
    navigator.clipboard.writeText(text).then(() => {
      window.notyf.open({
        type: 'success',
        message: message || 'Text copied to clipboard!',
        duration: 2000
      });
    }, (err) => {
      window.notyf.open({
        type: 'error',
        message: 'Error copying text!',
        duration: 2000
      });
      console.error('Failed to copy text: ', err);
    });
  };