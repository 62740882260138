import { Link } from "react-router-dom";

export default function StatBoxesHelper({
	title,
	value,
	icon,
	cardSize = "4",
	useSmallText = false,
	showTitleBadge = false,
	badgeColor = "primary",
	cardClasses = "",
	showButton = false,
	buttonText = "",
	buttonLink = "",
	buttonWidth = "w-100",
	buttonDisplayBlock = false,
	html = null,
}) {
	return (
		<div className={`col-sm-${cardSize} col-xl-${cardSize}`}>
			<div className={`card ${cardClasses}`}>
				<div className="card-body">
					<div className="row">
						<div className="col mt-0">
							{showTitleBadge ? (
								<span className={`badge bg-${badgeColor}`}>{title}</span>
							) : (
								<h5
									className="card-title"
									style={{ textTransform: "capitalize" }}
								>
									{title}
								</h5>
							)}
						</div>
						<div className="col-auto">
							<div className="stat text-primary">
								<i
									className={`align-middle`}
									data-feather={icon}
								/>
							</div>
						</div>
					</div>
					<h1
						className="mt-1"
						style={{ textTransform: "capitalize", fontSize: useSmallText ? "1rem" : "1.5rem" }}
					>
						{value} {html ? html : null} {/* Render html directly as a React element */}
					</h1>
					{showButton ? (
						<Link
							to={buttonLink}
							className={`btn btn-sm btn-primary rounded ${buttonWidth} ${buttonDisplayBlock ? "d-block" : ""}`}
						>
							{buttonText}
						</Link>
					) : null}
				</div>
			</div>
		</div>
	);
}
