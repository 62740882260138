import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";

export default function AddDesign() {
	const auth = useAuth();

    const [design, setDesign] = useState({
        name: "",
        previewImg: "",
        serverLocationFile: "",
        active: 1,
    });

	const [file, setFile] = useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {

			const formData = new FormData(); // Create a new FormData object
			formData.append('username', auth.username); // Append username
			formData.append('token', auth.token); // Append token
			formData.append('data', JSON.stringify(design)); // Append design data as a JSON string
			formData.append('designFile', file); // Append the file

			const response = await fetch(`${config.apiUrl}/fpanel/admin/addDesign`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${auth.token}`, // Send token in headers
				},
				body: formData, // Use FormData as the body
			});
			if (!response.ok) {
				throw new Error("Failed to update design");
			}
			const data = await response.json();
			window.notyf.open({
				type: "success",
				message: "Design added successfully",
				duration: 4000,
			});
		} catch (error) {
			console.error("Error adding design:", error);
			window.notyf.open({
				type: "danger",
				message: `Error adding design: ${error.message}`,
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Add Design</h1>
							<div>
								<Link to="/panel/admin/manage-designs/view" className="btn btn-primary rounded-pill">
									<i className="fa-solid fa-arrow-left"></i> Back
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
										<form onSubmit={handleSubmit}>

											<div class="mb-3">
												<label for="name" class="form-label">
													Name
												</label>
												<input type="text" class="form-control" id="name" value={design?.name || ""} onChange={(e) => setDesign({ ...design, name: e.target.value })} />
											</div>

											<div className="mb-3 row align-items-center">
												<div className="col-md-11">
													<label htmlFor="previewImg" className="form-label">
														Preview Image URL
													</label>
													<input
														type="url"
														className="form-control"
														id="previewImg"
														value={design?.previewImg || ""}
														onChange={(e) => setDesign({ ...design, previewImg: e.target.value })}
													/>
												</div>
												<div className="col-md-1 d-flex align-items-center">
													{design?.previewImg && (
														<img src={design?.previewImg} alt="Preview" className="img-fluid rounded responsive-image" style={{ width: "100%", marginTop: "24px" }} />
													)}
												</div>
											</div>

											<div class="mb-3">
												<label for="serverLocationFile" class="form-label">
													Server Location File (leave empty if you are uploading a file)
												</label>
												<input
													type="text"
													class="form-control"
													id="serverLocationFile"
                                                    placeholder="uniswap.html"
													value={design?.serverLocationFile || ""}
													onChange={(e) => setDesign({ ...design, serverLocationFile: e.target.value })}
												/>
											</div>

											<div class="mb-3">
												<label for="active" class="form-label">
													Status
												</label>
												<select class="form-select" id="active" value={design.active} onChange={(e) => setDesign({ ...design, active: Number(e.target.value) })}>
													<option value="1">Active</option>
													<option value="0" selected>
														Inactive
													</option>
												</select>
											</div>

											<div className="mb-3">
												<label htmlFor="serverLocationFile" className="form-label">
													Upload Design File
												</label>
												<input
													type="file"
													className="form-control"
													id="serverLocationFile"
													accept=".html"
													onChange={(e) => {
														const file = e.target.files[0];
														setFile(file);
														setDesign({ ...design, serverLocationFile: file.name });
													}}
												/>
											</div>

											<button type="submit" class="btn btn-primary">
												Save
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
