import TopBar from "./Sections/Header/TopBar";
import Menu from "./Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuthentication";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { allowedChains } from "../../config";
import { chainMappingRPCs } from "../../config";

export default function MyStorageBalances() {

    const auth = useAuth();

    const [balances, setBalances] = useState([]);
    const [withdrawAddress, setWithdrawAddress] = useState("");
    const [totalBalanceETH, setTotalBalanceETH] = useState(0);
    const [totalBalanceUSD, setTotalBalanceUSD] = useState(0);

    // Withdrawing
	const [lastUpdated, setLastUpdated] = useState(new Date());

	// Withdrawing
	const [withdrawAmount, setWithdrawAmount] = useState("");
	const [withdrawAmountEther, setWithdrawAmountEther] = useState(0);
	const [isWithdrawing, setIsWithdrawing] = useState(false);
    const [withdrawChainID, setWithdrawChainID] = useState(null);

    // Processing
    const [isProcessing, setIsProcessing] = useState(false);

    // TXhash
    const [txHash, setTxHash] = useState(null);

    useEffect(() => {
        fetchBalances();
    }, [auth.token]);

    const fetchBalances = async (refresh=false) => {
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/user/storageBalances`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: auth.token,
                    username: auth.username,
                })
            });
            if (!response.ok) {
                throw new Error("Failed to fetch storage balances");
            }
            const data = await response.json();
            setBalances(data?.balances);
            setWithdrawAddress(data?.withdrawAddress);
            setTotalBalanceETH(data?.totalBalanceETH);
            setTotalBalanceUSD(data?.totalBalanceUSD);
            if(refresh) {
                window.notyf.open({
                    type: 'success',
                    message: 'Balances fetched successfully',
                    duration: 4000
                });
            }
        } catch (error) {
            window.notyf.open({
                type: 'error',
                message: error.message,
                duration: 4000
            });
        }
    }

	const handleWithdraw = async (e) => {
		e.preventDefault();
		setIsWithdrawing(true);
        setIsProcessing(true);
        setTxHash(null);

		if (isNaN(parseFloat(withdrawAmountEther))) {
            window.notyf.open({
                type: 'error',
                message: 'Invalid withdrawal amount',
                duration: 4000
            });
            setIsWithdrawing(false);
            setIsProcessing(false);
            return;
        }

		try {

			const response = await fetch(`${config.apiUrl}/fpanel/user/withdrawNative`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${auth.token}`,
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					amountEth: withdrawAmountEther,
                    chainId: withdrawChainID,
				}),
			});

			const data = await response.json();

			if (data.success) {
                setTxHash(data?.transactionHash);
				window.notyf.open({
					type: 'success',
					message: "Withdrawal successful",
					duration: 4000
				});
				fetchBalances(); // Refresh balance after withdrawal
			} else {
				window.notyf.open({
					type: 'danger',
					message: `Withdrawal failed: ${data.message}`,
					duration: 4000
				});
			}
		} catch (error) {
			console.error("Error during withdrawal:", error);
            window.notyf.open({
                type: 'danger',
                message: error.message,
                duration: 4000
            });
		} finally {
			setIsWithdrawing(false);
            setIsProcessing(false);
			setWithdrawAmountEther("");
		}
	};

    const findExplorerFromChainID = (chainId) => {
        return chainMappingRPCs[chainId]?.explorerUrl || false;
    }

    return (
        <div className="wrapper">
          <Menu />
          <div className="main">
            <TopBar />
            <main className="content">
              <div className="container-fluid p-0">
                <div className="row mb-4">
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <h1 className="h3 mb-0">My Storage Balances</h1>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-6 mb-4">
                    <div className="card h-100">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="card-title mb-0">Total Balance</h5>
                        <button onClick={() => fetchBalances(true)} className="btn btn-sm btn-outline-primary">
                          <i className="fas fa-sync-alt me-2"></i> Refresh
                        </button>
                      </div>
                      <div className="card-body">
                        <h2 className="mb-2">{parseFloat(totalBalanceETH).toFixed(8)} ETH</h2>
                        <p className="text-muted mb-0">${parseFloat(totalBalanceUSD).toFixed(2)} USD</p>
                      </div>
                    </div>
                  </div>
    
                  <div className="col-12 col-lg-6 mb-4">
                    <div className="card h-100">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Withdrawal Address</h5>
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="text-break">{withdrawAddress}</span>
                          <div>
                            <button onClick={() => copyToClipboard(withdrawAddress)} className="btn btn-sm btn-outline-secondary me-2">
                              <i className="fas fa-copy"></i>
                            </button>
                            <button
                              onClick={() => window.open(`https://etherscan.io/address/${withdrawAddress}`, "_blank")}
                              className="btn btn-sm btn-outline-secondary"
                            >
                              <i className="fas fa-external-link-alt"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
                <div className="row">

                {isProcessing ? (
                    <div className="col-12 text-center">
                        <div className="spinner-border text-dark me-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p>Processing withdrawal...</p>
                    </div>
                ) : (
                  balances.map((balance) => (
                    <div key={balance.chainId} className="col-12 col-md-6 col-lg-4 mb-4">
                      <div className="card h-100">
                        <div className="card-header d-flex align-items-center">
                          <ChainLogo chainID={balance.chainId} className="me-2" />
                          <h5 className="card-title mb-0 ms-2">
                            {allowedChains.find((chain) => chain.chainId === balance.chainId)?.name}
                          </h5>
                        </div>
                        <div className="card-body">
                          <p className="mb-1">
                            <strong>Balance:</strong> {balance.balanceETH ? parseFloat(balance.balanceETH).toFixed(8) : "0"} ETH
                          </p>
                          <p className="mb-1">
                            <strong>USD Value:</strong> {balance.balanceUSD ? `$${parseFloat(balance.balanceUSD).toFixed(2)}` : "0"}
                          </p>
                          <p className="mb-3">
                            <strong>Wei:</strong> {balance.balanceInWei ? parseInt(balance.balanceInWei).toLocaleString() : "0"}
                          </p>
                          <form onSubmit={(e) => handleWithdraw(e, balance.chainId)}>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={withdrawAmountEther}
                                onChange={(e) => {setWithdrawAmountEther(e.target.value); setWithdrawChainID(balance.chainId);}}
                                placeholder="Amount in ETH"
                                required
                              />
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isWithdrawing || !withdrawAmountEther}
                              >
                                {isWithdrawing ? 'Processing...' : 'Withdraw'}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )))}
                </div>
                
                {txHash && (
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">Transaction Hash</h5>
                        <p className="card-text">{txHash} <a href={`${findExplorerFromChainID(withdrawChainID) ? findExplorerFromChainID(withdrawChainID) : "https://etherscan.io"}/tx/${txHash}`} target="_blank" rel="noopener noreferrer"><i className="fas fa-external-link-alt ms-2"></i></a></p>
                      </div>
                    </div>
                    </div>
                  </div>
                )}

              </div>
            </main>
          </div>
        </div>
    );
}