const validateWeb3Address = (address) => {
    // Check if address is defined and is a string
    if (!address || typeof address !== 'string') {
        return false;
    }

    // Check if it matches the Ethereum address format:
    // - Starts with 0x
    // - Followed by 40 hexadecimal characters (20 bytes)
    // - Total length should be 42 characters
	return /^0x[0-9a-fA-F]{40}$/.test(address);
};

export default validateWeb3Address;