// Usage: https://quilljs.com/
// Function to dynamically load the Quill script
function loadQuillScript(callback) {
    const script = document.createElement('script');
    script.src = 'https://cdn.quilljs.com/1.3.6/quill.min.js';
    script.onload = callback;
    document.head.appendChild(script);
  }
  
  // Usage example
  loadQuillScript(() => {
    console.log('Quill script loaded');
    // Initialize Quill or perform other actions here
  });