import { useEffect } from "react";
import { getModeForFileReference } from "typescript";
import { useMemo } from "react";
import { useState } from "react";

export default function DomainChart({chartData}) {
    const [currentTheme, setCurrentTheme] = useState(window.theme.id); // {{ edit_2 }} State for current theme

    const getFontColorByTheme = () => {
        switch (currentTheme) { // {{ edit_3 }} Use currentTheme state
            case "greyscale":
                return "white";
            case "light":
            case "coloured":
                return "black";
            case "dark":
                return "white";
            default:
                return "black";
        }
    };

    useEffect(() => {
        const handleThemeChange = () => {
            setCurrentTheme(window.theme.id); // {{ edit_4 }} Update theme state on change
        };

        window.addEventListener("themeChange", handleThemeChange); // {{ edit_5 }} Listen for theme changes

        return () => {
            window.removeEventListener("themeChange", handleThemeChange); // Cleanup listener
        };
    }, []);

    // Calculate the past 12 months labels and filter the data
    const { labels, dataSets } = useMemo(() => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const today = new Date();
        const last12Months = Array.from({ length: 12 }, (_, i) => {
            const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
            return `${months[date.getMonth()]} ${date.getFullYear()}`;
        }).reverse();

        // Define an array of colors for the datasets
        const colors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];
        
        const dataSets = chartData.map((domainData, index) => {
            const data = last12Months.map(month => {
                const [monthStr, year] = month.split(' ');
                const monthIndex = months.indexOf(monthStr) + 1;
                const chartInfo = domainData.chartData.find(d => d.month === monthIndex && d.year === parseInt(year));
                return chartInfo ? chartInfo.monthlyRevenue ? chartInfo.monthlyRevenue.toFixed(2) : 0 : 0;
            });

            return {
                label: `${domainData.domain}`,
                fill: false,
                borderColor: colors[index % colors.length], // Use modulo to loop through colors
                data
            };
        });

        return { labels: last12Months, dataSets };
    }, [chartData]);

    useEffect(() => {
        var ctx = document.getElementById("chartjs-dashboard-line").getContext("2d");
        var gradientLight = ctx.createLinearGradient(0, 0, 0, 225);
        gradientLight.addColorStop(0, "rgba(215, 227, 244, 1)");
        gradientLight.addColorStop(1, "rgba(215, 227, 244, 0)");
        var gradientDark = ctx.createLinearGradient(0, 0, 0, 225);
        gradientDark.addColorStop(0, "rgba(51, 66, 84, 1)");
        gradientDark.addColorStop(1, "rgba(51, 66, 84, 0)");
    
        // Create the chart instance
        const chartInstance = new window.Chart(ctx, {
            type: "line",
            data: {
                labels,
                datasets: dataSets
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    labels: {
                        fontColor: getFontColorByTheme(), // {{ edit_6 }} Use dynamic color for legend
                    },
                },
                tooltips: {
                    intersect: false,
                },
                hover: {
                    intersect: true,
                },
                plugins: {
                    filler: {
                        propagate: false,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            reverse: true,
                            gridLines: {
                                color: "rgba(0,0,0,0.0)",
                            },
                            ticks: {
                                fontColor: getFontColorByTheme(), // {{ edit_7 }} Use dynamic color for x-axis labels
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                stepSize: 1000,
                                fontColor: getFontColorByTheme(), // {{ edit_8 }} Use dynamic color for y-axis labels
                            },
                            display: true,
                            borderDash: [3, 3],
                            gridLines: {
                                color: "rgba(0,0,0,0.0)",
                                fontColor: getFontColorByTheme(),
                            },
                        },
                    ],
                },
            },
        });
    
        // Cleanup function to destroy chart instance on component unmount or re-render
        return () => {
            chartInstance.destroy();
        };
    }, [labels, dataSets, currentTheme]);

	return (
		<div className="col-xl-12 col-xxl-12 col-sm-12 col-md-12 col-lg-12">
			<div className="card flex-fill w-100">
				<div className="card-header">
					<h5 className="card-title mb-0">My Top 5 Domains Hit Chart</h5>
				</div>
				<div className="card-body pt-2 pb-3">
					<div className="chart chart-sm">
						<div className="chartjs-size-monitor">
							<div className="chartjs-size-monitor-expand">
								<div className="" />
							</div>
							<div className="chartjs-size-monitor-shrink">
								<div className="" />
							</div>
						</div>
						<canvas id="chartjs-dashboard-line" width={733} height={250} style={{ display: "block", width: 733, height: 250 }} className="chartjs-render-monitor" />
					</div>
				</div>
			</div>
		</div>
	);
}
