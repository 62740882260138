import TopBar from "./Sections/Header/TopBar";
import Menu from "./Sections/Header/Menu";
import { config } from "../../config";
import { useXPSystem } from "../../Hooks/useXPSystem";
import { getStyledUsername } from "../../Hooks/useXPSystem";

export default function Ranks() {
	const { levels } = useXPSystem(0);

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="row mb-2 mb-xl-3">
							<div className="col-12">
								<h3>
									<b>{config.siteName}</b> Ranks
								</h3>
								<div className="card">
									<div className="card-body">
										<div className="card flex-fill">
                                            <div className="table-responsive">
											<table className="table table-borderless my-0">
												<thead>
													<tr>
														<th>Icon</th>
														<th style={{minWidth: "100px"}}>XP Range</th>
														<th>Color</th>
														<th>Perks</th>
                                                        <th style={{minWidth: "150px"}}>Styled Username</th>
													</tr>
												</thead>
												<tbody>
													{levels.map((level, index) => (
														<tr key={index}>
															<td>
																<div className="d-flex">
																	<div className="flex-shrink-0">
																		<div className="bg-light rounded-2">
																			<img className="p-2" src={level.img} style={{ width: "50px" }} />
																		</div>
																	</div>
																	<div className="flex-grow-1 ms-3">
																		<strong>{level.name}</strong>
																		<div className="text-muted">{level.description}</div>
																	</div>
																</div>
															</td>
															<td>
																<strong>{level.xpRange[0]} XP</strong>
																<div className="text-muted">
																	<i className="fa-solid fa-arrow-right"></i> {level.xpRange[1]} XP
																</div>
															</td>
															<td>
																<div className="d-flex align-items-center">
																	<div className="rounded-circle me-2" style={{ width: "20px", height: "20px", backgroundColor: level.color }}></div>
																	<strong>{level.color}</strong>
																</div>
															</td>
															<td style={{minWidth: "200px"}}>{level.perks}</td>
                                                            <td>{getStyledUsername("username", level.name)}</td>
														</tr>
													))}
												</tbody>
											</table>
                                            </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
