import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { useState, useEffect } from "react";
import { useAuth } from "../../Hooks/useAuthentication";
import { Link } from "react-router-dom";
import { splitNameservers } from "../../Helpers/splitNameservers";
import useDomainIcons from "../../Hooks/useDomainIcons";
import { getDefaultIcon, placeholderIcon } from "../../Helpers/getDomainIcon";
import { config, chainMappingRPCs } from "../../config";
import { ethers } from "ethers";
import { useHistory, useParams } from "react-router-dom";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { timeAgo } from "../../Helpers/epochTimeAgo";

// Define the allowed rules
const ruleTypes = [
    { type: "ip", label: "IP", requiresCondition: true },
    { type: "country", label: "Country", requiresCondition: true },
    { type: "referrer", label: "Referrer", requiresCondition: true },
    { type: "user-agent", label: "User Agent", requiresCondition: true },
    { type: "ip-score", label: "IP Score", requiresCondition: true },
    { type: "vpn", label: "VPN", requiresCondition: true },
    { type: "browser", label: "Browser", requiresCondition: true },
    { type: "domainURL", label: "Domain URL", requiresCondition: true },
];

const conditions = [
    { type: "contains", label: "Contains", requiresValue: true },
    { type: "does-not-contain", label: "Doesn't Contain", requiresValue: true },
    { type: "matches", label: "Matches", requiresValue: true },
    { type: "does-not-match", label: "Doesn't Match", requiresValue: true },
    { type: "is-empty", label: "Is Empty", requiresValue: false },
    { type: "is-not-empty", label: "Is Not Empty", requiresValue: false },
    { type: "is-below", label: "Is Below", requiresValue: true }, // Added
    { type: "is-above", label: "Is Above", requiresValue: true },  // Added
    { type: "true", label: "True", requiresValue: false },  // Added
    { type: "false", label: "False", requiresValue: false },  // Added
];

export default function ManageSingleCloaker() {
	const history = useHistory();
    const { domainId } = useParams();

	const { username, token, loading, userID } = useAuth();
	const [domain, setDomain] = useState({});
    const [userRules, setUserRules] = useState([]);
    const [logs, setLogs] = useState([]);
    const [misc, setMisc] = useState({
        bad_request_action: "block",
        redirect_to_domain: "",
        smart_bot_detection: false,
        telegram_notifications: false,
        telegram_chat_id: "",
        block_google_ip_ranges: false
    }); // contains misc data

    // Logs pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    // ------------------------------------------------------------------------------------------------

    // Function to handle page change
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    // ------------------------------------------------------------------------------------------------

    const fetchDomain = async () => {
        const response = await fetch(`${config.apiUrl}/fpanel/user/getWebsite`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ username, token, website: domainId })
        });
  
        const data = await response.json();

        if (data.success && data.data) {
            console.log(data.data.domainData);
            setDomain(data.data.domainData ? data.data.domainData[0] : {});
        }
    };

    // ------------------------------------------------------------------------------------------------

    const fetchUserRules = async () => {
        const response = await fetch(`${config.apiUrl}/fpanel/user/getMyRules`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ username, token, website: domainId })
        });
  
        const data = await response.json();

        if (data.success && data.data) {
            console.log(data.data);
            setUserRules(data.data);
        } else {
            history.push("/panel/cloaker");
        }
    };

    // ------------------------------------------------------------------------------------------------

    const deleteARule = async (ruleId) => {
        const response = await fetch(`${config.apiUrl}/fpanel/user/deleteARule`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ username, token, website: domainId, ruleID: ruleId })
        });
  
        const data = await response.json();

        if (data.success) {
            window.notyf.open({
                type: 'success',
                message: 'Rule deleted successfully',
                duration: 4000
            });
            await fetchUserRules();
        } else {
            window.notyf.open({
                type: 'danger',
                message: 'Failed to delete rule',
                duration: 4000
            });
        }
    };

    // ------------------------------------------------------------------------------------------------
    
    useEffect(() => {
        if(loading) return;
        fetchDomain();
        fetchUserRules();
    }, [loading, domainId]);

    // ------------------------------------------------------------------------------------------------

    
	// State for rules
	const [rules, setRules] = useState([]);
	const [selectedRuleType, setSelectedRuleType] = useState("");
	const [ruleValue, setRuleValue] = useState("");
	const [selectedCondition, setSelectedCondition] = useState("");

    // ------------------------------------------------------------------------------------------------

    const addNewRule = async () => {
        const response = await fetch(`${config.apiUrl}/fpanel/user/addNewRule`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ username, token, website: domainId, ruleInfo: {
            rule: selectedRuleType,
            condition: selectedCondition,
            value: ruleValue
          }})
        });
  
        const data = await response.json();

        if (data.success) {
            window.notyf.open({
                type: 'success',
                message: 'Rule added successfully',
                duration: 4000
            });
            await fetchUserRules();
        } else {
            window.notyf.open({
                type: 'danger',
                message: 'Failed to add new rule',
                duration: 4000
            });
        }
    };

    // ------------------------------------------------------------------------------------------------

    const fetchMisc = async () => {
        const response = await fetch(`${config.apiUrl}/fpanel/user/getMisc`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ username, token, website: domainId})
        });
  
        const data = await response.json();

        if (data.success) {
            
            if(data.data) {
                setMisc(data.data);
            }

        } else {
            window.notyf.open({
                type: 'danger',
                message: 'Failed to get misc data',
                duration: 4000
            });
        }
    };

    const updateMisc = async (e) => {
        e.preventDefault();
        const response = await fetch(`${config.apiUrl}/fpanel/user/updateMisc`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ username, token, website: domainId, misc_: {
            bad_request_action: misc.bad_request_action,
            redirect_to_domain: misc.redirect_to_domain,
            smart_bot_detection: misc.smart_bot_detection,
            telegram_notifications: misc.telegram_notifications,
            telegram_chat_id: misc.telegram_chat_id,
            block_google_ip_ranges: misc.block_google_ip_ranges
          }})
        });
  
        const data = await response.json();

        if (data.success) {
            window.notyf.open({
                type: 'success',
                message: 'Misc updated successfully',
                duration: 4000
            });
            await fetchMisc();
        } else {
            window.notyf.open({
                type: 'danger',
                message: 'Failed to update misc',
                duration: 4000
            });
        }
    };

    useEffect(() => {
        if(loading) return;
        fetchMisc();
    }, [loading]);

    // ------------------------------------------------------------------------------------------------

    const fetchLogs = async () => {
        const response = await fetch(`${config.apiUrl}/fpanel/user/getLogs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ username, token, website: domainId, page, perPage })
        });
  
        const data = await response.json();

        if (data.success) {

            setPage(data.currentPage);
            setTotalPages(data.totalPages); // Update total pages
            
            if(data.data) {
                setLogs(data.data);
            }

        } else {
            window.notyf.open({
                type: 'danger',
                message: 'Failed to get logs',
                duration: 4000
            });
        }
    };

    useEffect(() => {
        if(loading) return;
        fetchLogs();
    }, [loading, page]);

    // ------------------------------------------------------------------------------------------------

	// Handle adding a new rule
	const handleAddRule = async (e) => { // Make this function async
		e.preventDefault();
		const selectedRule = ruleTypes.find(rule => rule.type === selectedRuleType);
		const selectedConditionObj = conditions.find(condition => condition.type === selectedCondition); // Find the selected condition

		if (selectedRule && selectedConditionObj) {
			const conditionRequiresValue = selectedConditionObj.requiresValue; // Check if the condition requires a value

			if ((conditionRequiresValue && ruleValue) || !conditionRequiresValue) { // Check condition value requirement
				//setRules([...rules, { type: selectedRuleType, condition: selectedCondition, value: conditionRequiresValue ? ruleValue : "" }]);

				// Call addNewRule to send the new rule to the server
				await addNewRule(); // Added await

				// Reset the dropdowns and input after the rule has been added
				setSelectedRuleType(""); // Reset the dropdown
				setRuleValue(""); // Reset the input
				setSelectedCondition(""); // Reset the condition dropdown
			}
		}
	};

	// Handle removing a rule
	const handleRemoveRule = async (ruleId) => { // Make this function async
		await deleteARule(ruleId); // Call deleteARule with the rule ID
	};

	// Handle rule type change
	const handleRuleTypeChange = (e) => {
		setSelectedRuleType(e.target.value);
	};

	// Handle rule value change
	const handleRuleValueChange = (e) => {
		setRuleValue(e.target.value);
	};

	// Handle condition change
	const handleConditionChange = (e) => {
		setSelectedCondition(e.target.value);
	};

    // ------------------------------------------------------------------------------------------------

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage cloaker ({domain.domain})</h1>
							<div>
								<Link
									to="/panel/admin/manage-cloaker"
									className="btn btn-primary rounded"
								>
									<i className="fas fa-fw fa-arrow-left"></i> Back to Cloaker
								</Link>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12 col-sm-12">
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">Rules</h5>
								</div>
								<div className="card-body">
									{/* New Rule Form */}
									<form onSubmit={handleAddRule} className="mb-3">
                                        <label htmlFor="ruleType" className="form-label">Block request by</label>
										<div className="input-group mb-2">
											<select className="form-select p-3" value={selectedRuleType} onChange={handleRuleTypeChange}>
												<option value="">Select Rule Type</option>
                                                {ruleTypes.map((ruleType) => (
                                                    <option key={ruleType.type} value={ruleType.type}>
                                                        {ruleType.label}
                                                    </option>
                                                ))}
											</select>
										</div>

                                        <label htmlFor="condition" className="form-label">If</label>
										<div className="input-group mb-2">
                                            <select className="form-select" value={selectedCondition} onChange={handleConditionChange}>
                                                    <option value="">Select Condition</option>
                                                    {conditions.map((condition) => (
                                                        <option key={condition.type} value={condition.type}>
                                                            {condition.label}
                                                        </option>
                                                    ))}
                                            </select>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                value={ruleValue} 
                                                onChange={handleRuleValueChange} 
                                                placeholder="Enter value" 
                                            />
                                            <button type="submit" className="btn btn-primary">Add Rule</button>
										</div>
									</form>

                                    {/* Current Rules */}

                                    <hr />

                                    <h5 className="card-title mt-4">Current Rules</h5>

                                    <div className="mb-3">
                                        {userRules && userRules.length > 0 ? (
                                        <ul className="list-group">
                                        {userRules && userRules.length > 0 && [...userRules].reverse().map((rule, index) => (
                                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <strong>Block by type:</strong> {rule.ruleType} <br />
                                                        <strong>Condition:</strong> {rule.conditionType} <br />
                                                        <strong>Value:</strong> {rule.ruleValue}
                                                    </div>
                                                    <button className="btn btn-danger btn-sm" onClick={() => handleRemoveRule(rule.id)}>Remove</button>
                                                </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <div
                                            className="alert alert-warning alert-outline-coloured alert-dismissible"
                                            role="alert"
                                        >
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="alert"
                                                aria-label="Close"
                                            />
                                                <div className="alert-message">No rules found. Add a new rule above.</div>
                                            </div>
                                        )}
                                    </div>

								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12 col-sm-12">
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">Misc</h5>
								</div>
								<div className="card-body">

                                    <form onSubmit={updateMisc}>

									<div className='form-group'>
										<label htmlFor="domainName" className="form-label">Bad request action</label>
										<select className="form-select" id="bad_request_action" name="bad_request_action" aria-label="Default select example" value={misc.bad_request_action} onChange={(e) => setMisc({...misc, bad_request_action: e.target.value})}>
											<option value="redirect">redirect to my chosen domain</option>
											<option value="block">block the request</option>
										</select>
									</div>

									<div className='form-group mt-3'>
										<label htmlFor="domainName" className="form-label">Redirect to domain</label>
										<input type="text" className="form-control" id="redirect_to_domain" name="redirect_to_domain" placeholder="https://example.com" value={misc.redirect_to_domain} onChange={(e) => setMisc({...misc, redirect_to_domain: e.target.value})} />
									</div>

                                    <div className="form-check form-switch mt-3">
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                            Smart bot detection
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="smart_bot_detection"
                                            name="smart_bot_detection"
                                            checked={misc.smart_bot_detection}
                                            onChange={(e) => setMisc({...misc, smart_bot_detection: e.target.checked})}
                                        />
                                    </div>

                                    <div className="form-check form-switch mt-3">
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                            Telegram notifications
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="telegram_notifications"
                                            name="telegram_notifications"
                                            checked={misc.telegram_notifications}
                                            onChange={(e) => setMisc({...misc, telegram_notifications: e.target.checked})}
                                        />
                                    </div>

									<div className='form-group mt-3'>
										<label htmlFor="domainName" className="form-label">Telegram Chat ID (for notifications)</label>
										<input type="text" className="form-control" id="telegram_chat_id" name="telegram_chat_id" placeholder="1234567890" value={misc.telegram_chat_id} onChange={(e) => setMisc({...misc, telegram_chat_id: e.target.value})} />
									</div>

                                    <div className="form-check form-switch mt-3">
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                            Block google ip ranges
                                        </label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="block_google_ip_ranges"
                                            name="block_google_ip_ranges"
                                            checked={misc.block_google_ip_ranges}
                                            onChange={(e) => setMisc({...misc, block_google_ip_ranges: e.target.checked})}
                                        />
                                    </div>
                                    
                                    <div className="form-group mt-3">
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    </div>

                                    </form>

								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-12 col-sm-12">
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">Logs</h5>
								</div>
								<div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">IP</th>
                                                    <th scope="col">Country</th>
                                                    <th scope="col">Datetime</th>
                                                    <th scope="col">Referrer</th>
                                                    <th scope="col">Browser</th>
                                                    <th scope="col">VPN</th>
                                                    <th scope="col">User Agent</th>
                                                    <th scope="col">IP Score</th>
                                                    <th scope="col">Allowed visit?</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* Map over the logs array to create table rows */}
                                                {logs.map(log => (
                                                    <tr key={log.id}>
                                                        <td style={{minWidth: "150px"}}>{log.ip} <i className="fas fa-fw fa-copy" onClick={() => copyToClipboard(log.ip)}></i></td>
                                                        <td style={{minWidth: "150px"}}>{log.country}</td>
                                                        <td style={{minWidth: "150px"}}>{log.datetime ? timeAgo(log.datetime) : 'N/A'}</td> {/* Convert timestamp to readable format */}
                                                        <td style={{minWidth: "150px"}}>{log.referrer} <i className="fas fa-fw fa-copy" onClick={() => copyToClipboard(log.referrer)}></i></td>
                                                        <td style={{minWidth: "150px"}}>{log.browser}</td>
                                                        <td style={{minWidth: "150px"}}>{log.isVPN === 0 ? <><i className="fas fa-times text-danger"></i> No</> : <><i className="fas fa-check text-success"></i> Yes</>}</td>
                                                        <td>{log.user_agent} <i className="fas fa-fw fa-copy" onClick={() => copyToClipboard(log.user_agent)}></i></td>
                                                        <td style={{minWidth: "150px"}}>{log.ip_score}/1</td>
                                                        <td style={{minWidth: "150px"}}>{log.allowed_visit ? <><i className="fas fa-check text-success"></i> Yes</> : <><i className="fas fa-times text-danger"></i> No</>}</td> {/* Convert allowed_visit to Yes/No */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <footer className="footer bg-light w-100">
										<div className="w-100">
											<div className="pagination">
												<nav aria-label="Page navigation example">
													<ul className="pagination">
														<li className={`page-item ${page === 1 ? "disabled" : ""}`}>
															<button className="page-link" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
																Previous
															</button>
														</li>
														<li className="page-item active">
															<span className="page-link">
																Page {page}
																<span className="sr-only">(current)</span>
															</span>
														</li>
														<li className="page-item">
															<button className="page-link" onClick={() => handlePageChange(page + 1)} disabled={page >= totalPages}>
																Next
															</button>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</footer>
								</div>
							</div>
						</div>
					</div>

				</main>
			</div>
		</div>
	);
}
