import { useState, useEffect } from 'react';

export const useTheme = () => {
  const [theme, setTheme] = useState(window.theme?.id || 'light');

  useEffect(() => {
    const handleThemeChange = () => {
      setTheme(window.theme?.id || 'light');
    };

    // Initial check
    handleThemeChange();

    // Set up an event listener for theme changes
    window.addEventListener('themeChange', handleThemeChange);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('themeChange', handleThemeChange);
  }, []);

  return theme;
};