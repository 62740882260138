import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config, chainMappingRPCs, rewardPotConfig } from "../../config";
import { useAuth } from "../../Hooks/useAuthentication";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { useParams } from "react-router-dom";
import { truncateText } from "../../Helpers/truncateText";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { ChainLogo } from "../../Helpers/getChainLogo";
import { renderColorCircle } from "../../Helpers/moduleColorIcon";
import { rewardPotPositionAmounts } from "../../config";
import { connectMetaMask, sendEther, SendEtherComponent } from "../../Helpers/connectMetamask";
import { formatDateTimeForInput, dateTimeToEpoch } from "../../Helpers/epochToString";
import { timeAgo } from "../../Helpers/epochTimeAgo";

export default function ManageRewardPot() {
	const auth = useAuth();

	const [potDetails, setPotDetails] = useState({
		address: "",
		balance: 0,
		chainId: 0,
		balanceUSD: 0,
	});
	const [potConfig, setPotConfig] = useState(null);
	const [addReward, setAddReward] = useState(null);
	const [lastUpdated, setLastUpdated] = useState(new Date());
	const [potTransactions, setPotTransactions] = useState([]);

	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);

	const [walletInfo, setWalletInfo] = useState({
		address: "",
		balance: 0,
		balanceUSD: 0,
	});

	const [isWalletConnected, setIsWalletConnected] = useState(false);

	useEffect(() => {
		if (auth.loading) return;
		fetchPotDetails();
	}, [currentPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const checkWalletConnection = async () => {
		const storedWalletInfo = localStorage.getItem("walletInfo");
		if (storedWalletInfo) {
			const parsedWalletInfo = JSON.parse(storedWalletInfo);
			setWalletInfo(parsedWalletInfo);
			setIsWalletConnected(true);
			await getWalletBalanceUSD(parsedWalletInfo.address);
		}
	};

	const handleConnectWallet = async () => {
		try {
			const info = await connectMetaMask();

			const updatedInfo = {
				...info,
				balanceUSD: 0,
			};

			console.log(`updatedInfo`, updatedInfo);
			setWalletInfo(updatedInfo);
			setIsWalletConnected(true);
			localStorage.setItem("walletInfo", JSON.stringify(updatedInfo));
			window.notyf.open({ type: "success", message: "Wallet connected successfully", duration: 4000 });

			// Now fetch the USD balance
			await getWalletBalanceUSD(info.address, info.balance);
			setLastUpdated(new Date());
		} catch (error) {
			console.error("Error connecting wallet:", error);
			window.notyf.open({ type: "error", message: error.message || "Failed to connect wallet", duration: 4000 });
		}
	};

	const handleDisconnectWallet = () => {
		setWalletInfo({ address: "", balance: 0, balanceUSD: 0 });
		setIsWalletConnected(false);
		localStorage.removeItem("walletInfo");
		window.notyf.open({ type: "success", message: "Wallet disconnected", duration: 4000 });
	};

	const fetchPotDetails = async (showPing = false) => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/getRewardPotDetails`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${auth.token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					page: currentPage,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch pot details");
			}
			const data = await response.json();
			setPotDetails(data?.data);
			setLastUpdated(new Date());
			setPotConfig(data?.data?.config || []);
			setPotTransactions(data?.data?.transactions || []);
			setTotalPages(data?.data?.totalPages || 1);
			if (showPing) {
				window.notyf.open({ type: "success", message: "Pot details refreshed", duration: 4000 });
			}
		} catch (error) {
			window.notyf.open({ type: "error", message: error.message || "Error fetching pot details", duration: 4000 });
		}
	};

	const getWalletBalanceUSD = async (address, balance = null) => {
		try {
			console.log("Fetching USD conversion for address:", address);
			const response = await fetch(`${config.apiUrl}/fpanel/admin/ethUSDConversion`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${auth.token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					address: address,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch USD conversion rate");
			}
			const result = await response.json();
			console.log("USD conversion fetch result:", result);

			const oneUSDInETH = result?.data?.ethUsdRate || 0;
			const balanceETH = balance || walletInfo.balance;

			console.log(`balanceETH: ${balanceETH}`);

			const balanceUSD = balanceETH * oneUSDInETH;

			console.log("Updating wallet info with:", { balanceETH, balanceUSD });
			setWalletInfo((prevState) => ({
				...prevState,
				balanceUSD: balanceUSD,
			}));
			localStorage.setItem(
				"walletInfo",
				JSON.stringify({
					...walletInfo,
					balanceUSD: balanceUSD,
				})
			);
		} catch (error) {
			console.error("Error fetching wallet balance USD:", error);
			window.notyf.open({ type: "error", message: error.message || "Error fetching wallet balance USD", duration: 4000 });
		}
	};

	const handleUpdateConfig = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/updateRewardPotConfig`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${auth.token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					config: potConfig[0],
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to update pot configuration");
			}

			const data = await response.json();
			await fetchPotDetails();
			window.notyf.open({ type: "success", message: "Pot configuration updated successfully", duration: 4000 });
		} catch (error) {
			window.notyf.open({ type: "error", message: error.message || "Error updating pot configuration", duration: 4000 });
		}
	};

	const makeExplorerLink = (hash) => {
		var potChain = parseInt(rewardPotConfig.chainId);
		var matchingChain = chainMappingRPCs[potChain];
		if (!matchingChain) {
			return `https://etherscan.io/tx/${hash}`;
		}
		return `${matchingChain.explorerUrl}/tx/${hash}`;
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Reward Pot</h1>
						</div>

						<div className="row">
							<div className="col-md-8 col-sm-12 col-lg-8">
								<h4>View Pot Details</h4>
								<div className="card">
									<div className="card-body">
										<h2 className="text-center mt-3">Pot Balances</h2>
										<span className="text-center d-block">Last Updated: {lastUpdated.toLocaleString()}</span>
										<div className="d-flex justify-content-center align-items-center">
											<div className="circular-container bg-light">
												<div className="circular-content text-dark">
													<h2
														className="text-dark"
														style={{ fontSize: "14px" }}
													>
														{potDetails?.balance ? Number(potDetails.balance).toLocaleString("en-US", { minimumFractionDigits: 4, maximumFractionDigits: 4 }) : "0.0000"}{" "}
														<ChainLogo
															chainID={Number(potDetails.chainId)}
															showSymbol={true}
														/>
													</h2>
													<p>{ChainLogo(potDetails.chainId)}</p>
													<p>
														$
														{potDetails?.balanceUSD
															? Number(potDetails.balanceUSD).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
															: "0.00"}{" "}
														USD
													</p>
												</div>
											</div>
										</div>
										<div className="mt-3 text-center">
											<p>
												Pot Address: <span className="badge bg-primary">{potDetails.address}</span>{" "}
												<a
													href={`${chainMappingRPCs[rewardPotConfig.chainId].explorerUrl}/address/${potDetails.address}`}
													target="_blank"
													rel="noopener noreferrer"
												>
													<i className="fa-solid fa-link"></i>
												</a>
											</p>
											<button
												className="btn btn-sm btn-primary rounded"
												onClick={() => copyToClipboard(potDetails.address)}
											>
												Copy Address <i className="fa-solid fa-copy"></i>
											</button>
											<button
												className="btn btn-sm btn-primary rounded ms-2"
												onClick={() => fetchPotDetails(true)}
											>
												Refresh <i className="fa-solid fa-refresh"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-sm-12 col-lg-4">
								<h4>Add To Pot</h4>
								<div className="card">
									<div className="card-body">
										{isWalletConnected ? (
											<div>
												<p className="text-center">
													Connected to wallet:{" "}
													<span
														className="badge bg-primary d-block w-100 mt-2 rounded"
														style={{ cursor: "pointer" }}
														onClick={() => copyToClipboard(walletInfo.address)}
													>
														{walletInfo.address} <i className="fa-solid fa-copy"></i>
													</span>
												</p>
												<p className="text-center">
													Balance: {walletInfo.balance}{" "}
													<ChainLogo
														chainID={1}
														showSymbol={true}
													/>
												</p>
												<p className="text-center">
													Balance USD: $
													{typeof walletInfo.balanceUSD === "number"
														? walletInfo.balanceUSD.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
														: "0.00"}
												</p>
												<SendEtherComponent inputAddress={potDetails.address} />
												<button
													onClick={handleDisconnectWallet}
													className="btn btn-danger d-block w-100 mt-3"
												>
													Disconnect Wallet
												</button>
											</div>
										) : (
											<div>
												<p className="text-center">Connect your wallet to add to the pot directly</p>
												<button
													onClick={handleConnectWallet}
													className="btn metamask-btn d-block w-100"
													style={{ fontWeight: "bold" }}
												>
													<img
														src="/icons/metamask.webp"
														style={{ width: "20px", marginRight: "10px" }}
														alt="Metamask"
													/>
													Connect Wallet
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12 col-sm-12 col-lg-12">
								<h4>Position Amounts</h4>
								<div className="card">
									<div className="card-body">
										<table className="table table-striped">
											<thead>
												<tr>
													<th>Position</th>
													<th>Percentage</th>
													<th>Amount (USD)</th>
													<th>Amount (ETH)</th>
												</tr>
											</thead>
											<tbody>
												{rewardPotPositionAmounts.map((item, index) => {
													var data = item;
													var { position, percenatge } = data;
													const amountUSD = (potDetails.balanceUSD * percenatge) / 100;
													const amountETH = (potDetails.balance * percenatge) / 100;
													return (
														<tr key={position}>
															<td>{position}</td>
															<td>{percenatge}%</td>
															<td>${Number(amountUSD.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
															<td>
																{Number(amountETH.toFixed(6)).toLocaleString("en-US", { minimumFractionDigits: 6, maximumFractionDigits: 6 })}{" "}
																<ChainLogo
																	chainID={Number(potDetails.chainId)}
																	showSymbol={true}
																/>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12 col-sm-12 col-lg-12">
								<h4>Previous Payouts</h4>
								<div className="card">
									<div className="card-body">
									<div className="table-responsive">
										<table className="table table-striped">
											<thead>
												<tr>
													<th>ID</th>
													<th>For User</th>
													<th>Position</th>
													<th>Amount</th>
													<th>Type</th>
													<th>Date</th>
													<th>Hash</th>
												</tr>
											</thead>
											<tbody>
												{potTransactions && potTransactions.length > 0 && potTransactions.map((item, index) => {
													return (
														<tr key={index}>
															<td>{item.id}</td>
															<td>{item.user_id && item.username ? <>{item.username} <Link to={`/panel/admin/manage-users/${item.user_id}`} className='text-dark'><i className="fa-solid fa-link"></i></Link></> : "N/A"}</td>
															<td>
																{item.winner_position} / 3 &nbsp;
																{item.winner_position === 1 && <i className="fa-solid fa-trophy text-warning"></i>}
																{item.winner_position === 2 && <i className="fa-solid fa-trophy text-secondary"></i>}
																{item.winner_position === 3 && <i className="fa-solid fa-trophy text-danger"></i>}
															</td>
															<td>{item.amount ? <>{item.amount} <ChainLogo chainID={Number(1)} showSymbol={true} /></> : "N/A"}</td>
															<td>{item.type}</td>
															<td>{item.datetime ? timeAgo(item.datetime) : "N/A"}</td>
															<td>
																{item.tx_hash ?
																<>
																	<a href={makeExplorerLink(item.tx_hash)} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-primary rounded"><i className="fa-solid fa-link"></i></a>
																	
																	<span className="ms-2" onClick={() => copyToClipboard(item.tx_hash)}>
																			<i className="fa-solid fa-copy"></i>
																	</span>
																</>
																:
																"N/A"}
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
										</div>
									</div>
									<footer className="footer bg-light w-100">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span>
													Showing {Math.min((currentPage - 1) * 10 + 1, potTransactions.length)}-{Math.min(currentPage * 10, potTransactions.length)} of {potTransactions.length} results
												</span>
											</div>
											<nav className="w-100">
												<ul className="pagination">
													{/* Previous Page Button */}
													<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
															Previous
														</button>
													</li>
													{/* Current Page Button */}
													{totalPages > 0 &&
														Array.from({ length: totalPages }, (_, i) => (
															<li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
																<button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
																	{i + 1}
																</button>
															</li>
														))}
													{/* Next Page Button */}
													<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
															Next
														</button>
													</li>
												</ul>
											</nav>
										</footer>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12 col-sm-12 col-lg-12">
								<h4>Pot Config</h4>
								<div className="card">
									<div className="card-body">
										{potConfig && potConfig.length > 0 ? (
											<form onSubmit={handleUpdateConfig}>
												<div className="mb-3">
													<label
														htmlFor="nextPayoutTime"
														className="form-label"
													>
														Next Payout Time (dd/mm/yyyy hh:mm)
													</label>
													<input
														type="datetime-local"
														className="form-control"
														id="nextPayoutTime"
														value={formatDateTimeForInput(potConfig[0].next_payout_time)}
														onChange={(e) => {
															const newConfig = [...potConfig];
															newConfig[0].next_payout_time = dateTimeToEpoch(e.target.value);
															setPotConfig(newConfig);
														}}
													/>
												</div>
												<div className="mb-3">
													<label
														htmlFor="createdAt"
														className="form-label"
													>
														Created At (dd/mm/yyyy hh:mm)
													</label>
													<input
														type="datetime-local"
														className="form-control"
														id="createdAt"
														value={formatDateTimeForInput(potConfig[0].created_at)}
														onChange={(e) => {
															const newConfig = [...potConfig];
															newConfig[0].created_at = dateTimeToEpoch(e.target.value);
															setPotConfig(newConfig);
														}}
													/>
												</div>
												<div className="mb-3">
													<label
														htmlFor="updatedAt"
														className="form-label"
													>
														Updated At (dd/mm/yyyy hh:mm)
													</label>
													<input
														type="datetime-local"
														className="form-control"
														id="updatedAt"
														value={formatDateTimeForInput(potConfig[0].updated_at)}
														onChange={(e) => {
															const newConfig = [...potConfig];
															newConfig[0].updated_at = dateTimeToEpoch(e.target.value);
															setPotConfig(newConfig);
														}}
													/>
												</div>
												<button
													type="submit"
													className="btn btn-primary"
												>
													Update Config
												</button>
											</form>
										) : (
											<p>No configuration data available.</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
