export function blurUsername(username) {
    if(!username || username.length <= 2) {
        return username;
    }
    if (username.length <= 2) {
        return username; // Returns the username as is if it's too short to blur
    }

    const firstChar = username.charAt(0);
    const lastChar = username.charAt(username.length - 1);
    const middle = '*'.repeat(username.length - 2);

    return `${firstChar}${middle}${lastChar}`;
}