import { Link } from "react-router-dom";
import {useAuth} from "../../../../Hooks/useAuthentication";
import { useEffect } from "react";
import {config} from "../../../../config";
import TopBarAdmin from "../../../../adminComponents/Authed/Sections/Header/TopBar";

export default function TopBar() {

  const { avatar, loading, userLevel } = useAuth();

  useEffect(() => {
    console.log(`Avatar updated in TopBar: ${avatar}`);
}, [avatar]); // This will log every time avatar changes 

    if(loading) {
        return <div>Loading...</div>;
    }

    return (
      <> 
      {userLevel === 'user' ? (
        <nav className="navbar navbar-expand navbar-light navbar-bg">
          <a className="sidebar-toggle js-sidebar-toggle">
            <i className="hamburger align-self-center" />
          </a>
          <form className="d-none d-sm-inline-block">
            <div className="input-group input-group-navbar">
              <input type="text" className="form-control" placeholder="Search…" aria-label="Search" />
              <button className="btn" type="button">
                <i className="align-middle" data-feather="search" />
              </button>
            </div>
          </form>
          <div className="navbar-collapse collapse">
            <ul className="navbar-nav navbar-align">
              <li className="nav-item dropdown">
                <a className="nav-icon pe-md-0 dropdown-toggle" href="#" data-bs-toggle="dropdown">
                  <img src={avatar && avatar !== 'null' && avatar !== 'undefined' && avatar !== '' ? avatar : "img/newLogoLight.png"} style={{ objectFit: 'cover' }} className="avatar img-fluid rounded" alt={config.siteName} />
                  <span className="bg-success border border-light rounded-circle d-none d-md-block" style={{ width: "10px", height: "10px", left: "10px", bottom: "-5px", position: "absolute" }}></span>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link to="/panel/my-account" className="dropdown-item">
                    <i className="align-middle me-1" data-feather="user" /> Profile
                  </Link>
                  <Link to="/panel/domains/view" className="dropdown-item">
                    <i className="align-middle me-1" data-feather="pie-chart" /> Domains
                  </Link>
                  <div className="dropdown-divider" />
                  <Link to="/panel/support/new" className="dropdown-item">
                    <i className="align-middle me-1" data-feather="help-circle" /> Help Center
                  </Link>
                  <div className="dropdown-divider" />
                  <Link to="/panel/logout" className="dropdown-item">
                    Log out
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      ) : userLevel === 'admin' ? (
        <TopBarAdmin />
      ) : (
        <></>
      )}
      </>
    );
}