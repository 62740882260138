import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Landing from "./components/Landing";
import Register from "./components/Register";
import Login from "./components/Login";
import PrivateRoute from "./Helpers/PrivateRouter";
import Logout from "./components/Authed/Logout"; // Import the Logout component
import { useAuth } from "./Hooks/useAuthentication"; // Import the useAuth hook
import "./modules/js/app";
import "./modules/js/datatables";
import "./modules/js/fullcalendar";
import "./modules/js/settings";

// User Components
import ViewAllDomains from "./components/Authed/My Domains/ViewAll";
import AddDomain from "./components/Authed/My Domains/AddDomain";
import Designs from "./components/Authed/Designs";
import News from "./components/Authed/News";
import AllTickets from "./components/Authed/Tickets/AllTickets";
import Leaderboard from "./components/Authed/Leaderboard";
import RewardPot from "./components/Authed/RewardPot";
import GlobalHits from "./components/Authed/GlobalHits";
import ManageDomain from "./components/Authed/My Domains/ManageDomain/ManageDomain";
import DomainLogs from "./components/Authed/My Domains/ManageDomain/DomainLogs";
import useFeatherIcons from "./Hooks/useFeatherIcons";
import TicketChat from "./components/Authed/Tickets/TicketChat";
import NewTicket from "./components/Authed/Tickets/NewTicket";
import Blacklist from "./components/Authed/Blacklist/Blacklist";
import AddBlacklist from "./components/Authed/Blacklist/AddBlacklist";
import MyAccount from "./components/Authed/MyAccount";
import Ranks from "./components/Authed/Ranks";
import Dashboard from "./components/Authed/Dashboard/index";
import ForgotPassword from "./components/ForgotPassword";
import MyStorageBalances from "./components/Authed/MyStorageBalances";
import NFTSales from "./components/Authed/NFTSales";
import TokenSales from "./components/Authed/TokenSales";
import XPricing from "./components/Authed/XPricing";
import FAQ from "./components/Authed/FAQ";
import AutoSellerStatuses from "./components/Authed/AutoSellerStatuses";
import Tools from "./components/Authed/Tools";
import Cloaker from "./components/Authed/Cloaker";
import ManageDomainCloaker from "./components/Authed/Cloaker/manageDomainCloaker";
import Docs from "./components/Authed/Docs";

// Admin Components
import AdminRoute from "./routes/AdminRoute";
import AdminDashboard from "./adminComponents/AdminDashboard";
import AdminLogout from "./adminComponents/AdminLogout";
import Redirect404 from "./routes/Redirect404";
import ManageUsers from "./adminComponents/ManageUsers";
import ManageUser from "./adminComponents/ManageUsers/ManageUser";
import AddUser from "./adminComponents/ManageUsers/AddUser";
import ManageDomains from "./adminComponents/ManageDomains";
import ManageAdminDomain from "./adminComponents/ManageDomains/ManageDomain";
import ManageDrainSettings from "./adminComponents/ManageDomains/ManageDrainSettings";
import ManageGlobals from "./adminComponents/ManageGlobals";
import ManageNews from "./adminComponents/ManageNews";
import ManageNewsEdit from "./adminComponents/ManageNews/ManageNews";
import AddNews from "./adminComponents/ManageNews/AddNews";
import ManageBlacklists from "./adminComponents/ManageBlacklists";
import ManageBlacklist from "./adminComponents/ManageBlacklists/ManageBlacklist";
import AddBlacklistAdmin from "./adminComponents/ManageBlacklists/AddBlacklist";
import ManageTickets from "./adminComponents/ManageTickets";
import EditTicket from "./adminComponents/ManageTickets/EditTicket";
import AddTicket from "./adminComponents/ManageTickets/AddTicket";
import ViewTicket from "./adminComponents/ManageTickets/ViewTicket";
import ManageDesigns from "./adminComponents/ManageDesigns";
import EditDesign from "./adminComponents/ManageDesigns/EditDesign";
import AddDesign from "./adminComponents/ManageDesigns/AddDesign";
import ManageCreate2s from "./adminComponents/ManageCreate2s";
import ManageRewardPot from "./adminComponents/ManageRewardPot";
import MyAccountAdmin from "./adminComponents/MyAccount";
import ManageActions from "./adminComponents/ManageDomains/ManageActions";
import ManageCloudflare from "./adminComponents/ManageDomains/ManageCloudflare";
import UserAnalytics from "./adminComponents/ManageUsers/UserAnalytics";
import DomainAnalytics from "./adminComponents/ManageDomains/DomainAnalytics";
import APIUsages from "./adminComponents/APIUsages";
import Withdraw from "./adminComponents/ManageUsers/Withdraw";
import Balances from "./adminComponents/ManageCreate2s/Balances";
import DesignDrainSettings from "./adminComponents/ManageDesigns/DesignDrainSettings";
import ManageNFTSales from "./adminComponents/ManageNFTSales";
import ManageTokenSales from "./adminComponents/ManageTokenSales";
import ManageDaddy from "./adminComponents/ManageDaddy";
import ManageCloaker from "./adminComponents/ManageCloaker";
import ManageSingleCloaker from "./adminComponents/ManageCloaker/manageSingleCloaker";

function App() {
	const { isAuthenticated, userLevel, loading } = useAuth(); // Use the useAuth hook to get the auth state

	useFeatherIcons();

	useEffect(() => {
		console.log(`isAuthenticated: ${isAuthenticated}`);
		console.log(`userLevel: ${userLevel}`);
	}, [isAuthenticated, userLevel]);

	if (loading) {
		console.log(`[*] App.js Loading...`)
		return (
			<div className="d-flex justify-content-center align-items-center h-100">
				<div className="spinner-border text-primary" role="status">
					<span className="sr-only"><img src="/images/newLogoLight.png" alt="Loading..." style={{ animation: 'zoomInZoomOut 2s infinite' }} /></span>
				</div>
			</div>
		);
	}

    const redirectToDashboard = (isCatchAll) => {
        return isAuthenticated ? (
            <Redirect to={userLevel === "admin" ? "/panel/admin/dashboard" : "/panel/dashboard"} />
        ) : isCatchAll ? <Redirect to="/login" /> : null;
    };

	return (
		<Router>
			<Switch>
				<Route exact path="/">
					{redirectToDashboard() || <Landing />}
				</Route>
                <Route path="/register">
                    {redirectToDashboard() || <Register />}
                </Route>
                <Route path="/login">
                    {redirectToDashboard() || <Login />}
                </Route>

                <Route path="/forgot-password" component={ForgotPassword} />

				{/* Conditional rendering based on userLevel */}
				{isAuthenticated && userLevel === "admin" && (
					<Switch>
						<AdminRoute exact path="/panel/admin/dashboard" component={AdminDashboard} />
						<AdminRoute exact path="/panel/admin/logout" component={AdminLogout} />
						<AdminRoute exact path="/panel/admin/manage-users/view" component={ManageUsers} />
						<AdminRoute exact path="/panel/admin/manage-users/add" component={AddUser} />
						<AdminRoute exact path="/panel/admin/manage-users/:userId" component={ManageUser} />
						<AdminRoute exact path="/panel/admin/manage-domains/view" component={ManageDomains} />
						<AdminRoute exact path="/panel/admin/manage-domains/:domainId/edit" component={ManageAdminDomain} />
						<AdminRoute exact path="/panel/admin/manage-domains/:domainId/drain-settings" component={ManageDrainSettings} />
						<AdminRoute exact path="/panel/admin/manage-globals" component={ManageGlobals} />
						<AdminRoute exact path="/panel/admin/manage-news/view" component={ManageNews} />
						<AdminRoute exact path="/panel/admin/manage-news/edit/:newsId" component={ManageNewsEdit} />
						<AdminRoute exact path="/panel/admin/manage-news/add" component={AddNews} />
						<AdminRoute exact path="/panel/admin/manage-blacklists/view" component={ManageBlacklists} />
						<AdminRoute exact path="/panel/admin/manage-blacklists/edit/:blacklistId" component={ManageBlacklist} />
						<AdminRoute exact path="/panel/admin/manage-blacklists/add" component={AddBlacklistAdmin} />
						<AdminRoute exact path="/panel/admin/manage-tickets/view" component={ManageTickets} />
						<AdminRoute exact path="/panel/admin/manage-tickets/edit/:ticketId" component={EditTicket} />
						<AdminRoute exact path="/panel/admin/manage-tickets/add" component={AddTicket} />
						<AdminRoute exact path="/panel/admin/manage-tickets/view/:ticketId" component={ViewTicket} />
						<AdminRoute exact path="/panel/admin/manage-designs/view" component={ManageDesigns} />
						<AdminRoute exact path="/panel/admin/manage-designs/add" component={AddDesign} />
						<AdminRoute exact path="/panel/admin/manage-designs/:designId" component={EditDesign} />
						<AdminRoute exact path="/panel/admin/manage-create2s" component={ManageCreate2s} />
						<AdminRoute exact path="/panel/admin/manage-reward-pot" component={ManageRewardPot} />
						<AdminRoute exact path="/panel/admin/my-account" component={MyAccountAdmin} />
						<AdminRoute exact path="/panel/admin/manage-domains/:domainId/actions" component={ManageActions} />
						<AdminRoute exact path="/panel/admin/manage-domains/:domainId/cloudflare" component={ManageCloudflare} />
						<AdminRoute exact path="/panel/admin/manage-users/:userId/analytics" component={UserAnalytics} />
						<AdminRoute exact path="/panel/admin/manage-domains/:domainId/analytics" component={DomainAnalytics} />
						<AdminRoute exact path="/panel/admin/manage-api-usages" component={APIUsages} />
						<AdminRoute exact path="/panel/admin/manage-users/:userId/withdraw" component={Withdraw} />
						<AdminRoute exact path="/panel/admin/manage-create2s/:create2Id/balances" component={Balances} />
						<AdminRoute exact path="/panel/admin/manage-designs/:designId/drain_settings" component={DesignDrainSettings} />

						<AdminRoute exact path="/panel/admin/manage-nft-sales" component={ManageNFTSales} />
						<AdminRoute exact path="/panel/admin/manage-token-sales" component={ManageTokenSales} />
						<AdminRoute exact path="/panel/admin/manage-daddy" component={ManageDaddy} />

						<AdminRoute exact path="/panel/admin/manage-cloaker" component={ManageCloaker} />
						<AdminRoute exact path="/panel/admin/manage-cloaker/:domainId/cloaker" component={ManageSingleCloaker} />

						<PrivateRoute path="/panel/leaderboard" component={Leaderboard} />
						<PrivateRoute path="/panel/reward-pot" component={RewardPot} />
						<PrivateRoute path="/panel/global-hits" component={GlobalHits} />
						<PrivateRoute path="/panel/ranks" component={Ranks} />
						<PrivateRoute path="/panel/auto-seller-statuses" component={AutoSellerStatuses} />

						<Redirect from="/panel/admin/*" to="/panel/admin/dashboard" />
					</Switch>
				)}

				{isAuthenticated && userLevel === "user" && (
					<Switch>
						<PrivateRoute path="/panel/dashboard" component={Dashboard} />
						<PrivateRoute path="/panel/domains/view" component={ViewAllDomains} />
						<PrivateRoute path="/panel/domains/add" component={AddDomain} />
						<PrivateRoute path="/panel/designs" component={Designs} />
						<PrivateRoute path="/panel/news" component={News} />
						<PrivateRoute path="/panel/support/all" component={AllTickets} />
						<PrivateRoute path="/panel/tickets/:ticketId" component={TicketChat} />
						<PrivateRoute path="/panel/support/new" component={NewTicket} />
						<PrivateRoute path="/panel/leaderboard" component={Leaderboard} />
						<PrivateRoute path="/panel/reward-pot" component={RewardPot} />
						<PrivateRoute path="/panel/global-hits" component={GlobalHits} />
						<PrivateRoute path="/panel/domains/manage/:domainId" component={ManageDomain} />
						<PrivateRoute path="/panel/domains/:domainId/logs" component={DomainLogs} />
						<PrivateRoute path="/panel/blacklists/view" component={Blacklist} />
						<PrivateRoute path="/panel/blacklists/new" component={AddBlacklist} />
						<PrivateRoute path="/panel/my-account" component={MyAccount} />
						<PrivateRoute path="/panel/ranks" component={Ranks} />

						<PrivateRoute path="/panel/cloaker/manage/:domain_" component={ManageDomainCloaker} />
						<PrivateRoute path="/panel/cloaker" component={Cloaker} />

						<PrivateRoute path="/panel/sales/nft" component={NFTSales} />
						<PrivateRoute path="/panel/sales/token" component={TokenSales} />

						<PrivateRoute path="/panel/storageBalances" component={MyStorageBalances} />
						<PrivateRoute path="/panel/pricing" component={XPricing} />
						<PrivateRoute path="/panel/faq" component={FAQ} />
						<PrivateRoute path="/panel/auto-seller-statuses" component={AutoSellerStatuses} />
						<PrivateRoute path="/panel/tools" component={Tools} />

						<PrivateRoute path="/panel/logout" component={Logout} />

						<PrivateRoute path="/panel/documentation/get-started" component={Docs} />
					</Switch>
				)}

				<Route path="*">
					{redirectToDashboard(true)}
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
