import React, { useEffect, useState } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useAuth } from "../Hooks/useAuthentication";
import Dashboard from "../components/Authed/Dashboard";

const PrivateRoute = ({ component: Component, ...rest }) => {
  console.log("PrivateRoute component rendered");

  const auth = useAuth();
  const { isAuthenticated, loading, userLevel } = auth;

  console.log("isAuthenticated:", isAuthenticated, "loading:", loading, "userLevel:", userLevel);

  // On every private route, check the users XP and set inside state..
  useEffect(() => {
    auth.verifyAuthAndXP();
  }, []);

  if (loading) {
      return <h1>Loading...</h1>; // Show spinner while loading
  }

  return (
      <Route
          {...rest}
          render={(props) => {
              if (!isAuthenticated) {
                  console.log("User not authenticated, redirecting to login");
                  return <Redirect to="/login" />;
              }
              return <Component {...props} />;
          }}
      />
  );
};

export default PrivateRoute;
