export const getDefaultIcon = () => `<div class="spinner-border text-dark me-2" role="status" style="width: 20px; height: 20px; display: inline-block;">
  <span class="visually-hidden">Loading...</span>
</div>`;

export const placeholderIcon = `/img/newLogoLight.png`;

export async function getDomainIcon(domain) {
  const iconUrl = `https://${domain}/favicon.ico`;
  const timeout = 5000; // 5 seconds timeout

  try {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(iconUrl, { signal: controller.signal });
    clearTimeout(id);

    if (!response.ok) throw new Error('Icon not found');
    return iconUrl;
  } catch (error) {
    console.log(`Error fetching icon for domain: ${domain}, using placeholder. Error: ${error}`);
    return placeholderIcon;
  }
}