import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { useAuth } from "../../Hooks/useAuthentication";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { useRef } from "react";
import { useXPSystem, getLevelFromXP, getStyledUsername } from "../../Hooks/useXPSystem";

export default function ViewTicket() {
	const auth = useAuth();
	const { username, token, avatar } = auth;
	const { ticketId } = useParams();

	const messageRef = useRef(null);
    const chatMessagesRef = useRef(null); // Add this line to create a reference for the chat messages container

	const [ticket, setTicket] = useState({});
	const [chats, setChats] = useState([]);
    const [ws, setWs] = useState(null);

	useEffect(() => {
		if (auth.loading || !auth.token || !auth.username) return;
		fetchTicket();
	}, [ticketId, auth]);

    useEffect(() => {
        let reconnectInterval;
        let websocket;
    
        const connectWebSocket = () => {
            websocket = new WebSocket('ws://localhost:3000/ws');
    
            websocket.onopen = () => {
                console.log("WebSocket connected");
                clearInterval(reconnectInterval);
                setWs(websocket);

                // Join the specific ticket room only after the connection is established
                if (ticketId && ticket?.ticketID) {
                    websocket.send(JSON.stringify({
                        type: 'JOIN_ROOM',
                        ticketId: ticketId,
                        roomId: ticket?.ticketID
                    }));
                }
            };
    
            websocket.onclose = () => {
                console.log("WebSocket disconnected. Attempting to reconnect...");
                reconnectInterval = setInterval(connectWebSocket, 5000);
            };
    
            websocket.onmessage = async (event) => {
                let data;
                if (event.data instanceof Blob) {
                    const text = await event.data.text();
                    data = JSON.parse(text);
                } else {
                    console.log(`Received message`, event.data);
                    data = JSON.parse(event.data);
                }
    
                switch (data.type) {
                    case 'NEW_MESSAGE':
                        setChats(currentChats => [...currentChats, data.message]);
                        await fetchTicket();
                        if (chatMessagesRef.current) {
                            chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
                        }
                        break;
                    default:
                        break;
                }
            };
        };
    
        connectWebSocket();
    
        return () => {
            clearInterval(reconnectInterval);
            if (ws) {
                // Leave the room when component unmounts
                ws.send(JSON.stringify({
                    type: 'LEAVE_ROOM',
                    ticketId: ticketId,
                    roomId: ticket?.ticketID
                }));
                ws.close();
            }
        };
    }, [ticket, ticketId]);

	const fetchTicket = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/getTicketChats`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${auth.token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					id: ticketId,
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch ticket data");
			}
			const data = await response.json();
			setTicket(data.ticketDetails);
			setChats(data.data.sort((a,b) => a.id - b.id));
		} catch (error) {
			console.error("Error fetching ticket:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to fetch ticket data",
				duration: 4000,
			});
		}
	};

	const makeStatusBadge = (status) => {
		switch (status) {
			case "open":
				return <span className="badge bg-primary rounded-pill">Open</span>;
			case "closed":
				return <span className="badge bg-danger rounded-pill">Closed</span>;
			case "awaiting-reply":
				return <span className="badge bg-warning rounded-pill text-light">Awaiting Reply</span>;
			default:
				return <span className="badge bg-secondary rounded-pill">Unknown</span>;
		}
	};

	const sendMessage = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/addReply`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					token,
					username,
					id: ticketId,
					message: messageRef.current.value,
				}),
			});

			const data = await response.json();
			if (!data.success) throw new Error(data.message || "Failed to send message");

			window.notyf.open({
				type: "success",
				message: data.message,
				duration: 4000,
			});

            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({
                    type: 'NEW_MESSAGE',
                    ticketId: ticketId,
                    message: data.message,
                    roomId: ticket?.ticketID
                }));
            }

			await fetchTicket();
			messageRef.current.value = "";

            // Auto-scroll to the bottom of the chat messages container
            if (chatMessagesRef.current) {
                chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
            }

		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: error.message || "Error sending message",
				duration: 4000,
			});
		}
	};

    const closeTicket = async () => {
        const apiUrl = `${config.apiUrl}/fpanel/admin/closeTicket`;
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    token,
                    username,
                    id: ticketId,
                }),
            });

            const data = await response.json();
            if (!data.success) throw new Error(data.message || "Failed to close ticket");

            window.notyf.open({
                type: "success",
                message: data.message,
                duration: 4000,
            });

            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({
                    type: 'TICKET_CLOSED',
                    ticketId: ticketId,
                }));
                ws.send(JSON.stringify({
                    type: 'NEW_MESSAGE',
                    ticketId: ticketId,
                    message: data.message,
                    roomId: ticket?.ticketID
                }));
            }

            // Auto-scroll to the bottom of the chat messages container
            if (chatMessagesRef.current) {
                chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
            }

            // Optionally refresh ticket details to reflect the closed status
            await fetchTicket();

        } catch (error) {
            window.notyf.open({
                type: "danger",
                message: error.message || "Error closing ticket",
                duration: 4000,
            });
        }
    };

    const openTicket = async () => {
        const apiUrl = `${config.apiUrl}/fpanel/admin/openTicket`;
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    token,
                    username,
                    id: ticketId,
                }),
            });

            const data = await response.json();
            if (!data.success) throw new Error(data.message || "Failed to open ticket");

            window.notyf.open({
                type: "success",
                message: data.message,
                duration: 4000,
            });

            await fetchTicket();
        } catch (error) {
            window.notyf.open({
                type: "danger",
                message: error.message || "Error opening ticket",
                duration: 4000,
            });
        }
    };

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Ticket {ticketId}</h1>
							<div>
								<Link to="/panel/admin/manage-tickets/view" className="btn btn-primary rounded-pill">
									<i className="fa-solid fa-arrow-left"></i> Back
								</Link>
								<button className="btn btn-danger rounded-pill ms-2" onClick={closeTicket} disabled={ticket?.ticketStatus === "closed"}>
									<i className="fa-solid fa-times"></i> Close Ticket
								</button>
								<button className="btn btn-success rounded-pill ms-2" onClick={openTicket} disabled={ticket?.ticketStatus === "open"}>
									<i className="fa fa-check"></i> Open Ticket
								</button>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-md-8 col-lg-8 col-xl-8">
								<h4>Ticket Chat</h4>
								<div className='card'>
									<div className="card-body">
										{chats?.length > 0 ? (
											<div className="position-relative">
												<div className="chat-messages p-4" style={{ maxHeight: "500px", overflowY: "auto", minHeight: "500px" }} ref={chatMessagesRef}>
													{chats?.map((message, index) => (
														<div key={index} className={`chat-message-${message.authorUserLevel === "admin" ? "right" : "left"} pb-4`}>
															<div>
																<img
																	src={message?.avatarURL ? message?.avatarURL : "/img/newLogoLight.png"}
																	className="rounded"
																	alt={message.author}
																	width={40}
																	height={40}
																/>
																<div className="text-muted small text-nowrap mt-2">{message.dateSent ? timeAgo(message.dateSent) : "Unknown"}</div>
															</div>
															<div className={`flex-shrink-1 bg-light rounded py-2 px-3 ${message.authorUserLevel === "admin" ? "me-3" : "ms-3"}`}>
																<div className="font-weight-bold mb-1">{getStyledUsername(message.author, getLevelFromXP(message.authorXP)?.name)}</div>
																{message.message}
															</div>
														</div>
													))}
												</div>
											</div>
										) : (
											<div className="alert alert-warning alert-dismissible" role="alert">
												<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
												<div className="alert-icon">
													<i className="far fa-fw fa-bell" />
												</div>
												<div className="alert-message">No ticket chats yet. Create one below.</div>
											</div>
										)}
										<div className="flex-grow-0 py-3 px-4 border-top">
											<div className="input-group">
												<input
													disabled={ticket?.ticketStatus === "closed"}
													type="text"
													className="form-control"
													placeholder="Type your message"
													ref={messageRef}
												/>
												<button disabled={ticket?.ticketStatus === "closed"} className="btn btn-primary" onClick={() => sendMessage()}>
													Send
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-4 col-lg-4 col-xl-4">
								<h4>Main Ticket Details</h4>
								<div className="card">
									<div className="card-body">
										<div class="mb-3">
											<label class="form-label" for="inputAddress">
												Subject
											</label>
											<input type="text" readOnly class="form-control" id="inputAddress" placeholder="1234 Main St" value={ticket?.subject} />
										</div>
										<div class="mb-3">
											<label class="form-label" for="inputAddress">
												Message
											</label>
											<textarea type="text" readOnly class="form-control" id="inputAddress" placeholder="1234 Main St" value={ticket?.message} />
										</div>
										<div class="mb-3">
											<label className="form-label d-block" for="status">
												Status
											</label>
											{makeStatusBadge(ticket?.ticketStatus)}
										</div>
										<div class="mb-3">
											<label className="form-label d-block" for="createdAt">
												Created
											</label>
											{ticket?.dateCreated ? (
												<span id="createdAt" className="badge bg-primary rounded-pill">
													{timeAgo(ticket?.dateCreated)}
												</span>
											) : (
												"Created ??"
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
