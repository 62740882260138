import React, { useEffect, useState } from "react";

function TemplateScripts() {
	useEffect(() => {
		const scripts = [
			{
				id: "vvveb-input-textinput",
				type: "text/html",
				content: `
            <div>
              <input name="{%=key%}" type="text" class="form-control" />
            </div>
          `,
			},
			{
				id: "vvveb-input-textareainput",
				type: "text/html",
				content: `
            <div><textarea name="{%=key%}" {% if (typeof rows !== 'undefined') { %} rows="{%=rows%}" {% } else { %} rows="3" {% } %} class="form-control"/></div>
          `,
			},
			{
				id: "vvveb-input-checkboxinput",
				type: "text/html",
				content: `
            <div class="form-check{% if (typeof className !== 'undefined') { %} {%=className%}{% } %}">
              <input name="{%=key%}" class="form-check-input" type="checkbox" id="{%=key%}_check" />
              <label class="form-check-label" for="{%=key%}_check">{% if (typeof text !== 'undefined') { %} {%=text%} {% } %}</label>
            </div>
          `,
			},
			{
				id: "vvveb-input-radioinput",
				type: "text/html",
				content: `
            <div>
              {% for ( var i = 0; i < options.length; i++ ) { %}
              <label class="form-check-label {% if (typeof inline !== 'undefined' && inline == true) { %}custom-control-inline{% } %}" title="{%=options[i].title%}">
                <input
                  name="{%=key%}"
                  class="form-check-input"
                  type="radio"
                  value="{%=options[i].value%}"
                  id="{%=key%}{%=i%}"
                  {% if (options[i].checked) { %}checked="{%=options[i].checked%}"{% } %}
                />
                {%=options[i].text%}
              </label>
              {% } %}
            </div>
          `,
			},
			{
				id: "vvveb-input-radiobuttoninput",
				type: "text/html",
				content: `
            <div class="btn-group {%if (extraclass) { %}{%=extraclass%}{% } %} clearfix" role="group">
              {% var namespace = 'rb-' + Math.floor(Math.random() * 100); %} {% for ( var i = 0; i < options.length; i++ ) { %}
              <input
                name="{%=key%}"
                class="btn-check"
                type="radio"
                value="{%=options[i].value%}"
                id="{%=namespace%}{%=key%}{%=i%}"
                {%if (options[i].checked) { %}checked="{%=options[i].checked%}"{% } %}
                autocomplete="off"
              />
              <label class="btn btn-outline-primary {%if (options[i].extraclass) { %}{%=options[i].extraclass%}{% } %}" for="{%=namespace%}{%=key%}{%=i%}" title="{%=options[i].title%}">
                {%if (options[i].icon) { %}<i class="{%=options[i].icon%}"></i>{% } %} {%=options[i].text%}
              </label>
              {% } %}
            </div>
          `,
			},
			{
				id: "vvveb-input-toggle",
				type: "text/html",
				content: `
            <div class="form-check form-switch {% if (typeof className !== 'undefined') { %} {%=className%}{% } %}">
              <input
                type="checkbox"
                name="{%=key%}"
                class="form-check-input"
                id="{%=key%}"
              />
              <label class="form-check-label" for="{%=key%}"></label>
            </div>
          `,
			},
			{
				id: "vvveb-input-autocompletelist",
				type: "text/html",
				content: `
            <div>
              <input name="{%=key%}" type="text" class="form-control" />
              <div class="form-control autocomplete-list" style="min-height: 150px; overflow: auto;"></div>
            </div>
          `,
			},
			{
				id: "vvveb-input-tagsinput",
				type: "text/html",
				content: `
            <div>
              <div class="form-control tags-input" style="height:auto;">
                <input name="{%=key%}" type="text" class="form-control" style="border:none;min-width:60px;" />
              </div>
            </div>
          `,
			},
			{
				id: "vvveb-input-noticeinput",
				type: "text/html",
				content: `
            <div>
              <div class="alert alert-dismissible fade show alert-{%=type%}" role="alert">
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                <strong class="d-block mb-1">{%=title%}</strong>
                {%=text%}
              </div>
            </div>
          `,
			},
			{
				id: "vvveb-section",
				type: "text/html",
				content: `
            <div class="section-item" draggable="true">
              <div class="controls">
                <div class="handle"></div>
                <div class="info">
                  <div class="name">
                    {%=name%}
                    <div class="type">{%=type%}</div>
                  </div>
                </div>
                <div class="buttons">
                  <a class="delete-btn" href="" title="Remove section"><i class="la la-trash text-danger"></i></a>
                  <a class="properties-btn" href="" title="Properties"><i class="icon-settings-outline"></i></a>
                </div>
              </div>
              <input class="header_check" type="checkbox" id="section-components-{%=suffix%}" />
              <label for="section-components-{%=suffix%}">
                <div class="header-arrow"></div>
              </label>
              <div class="tree">
                <ol></ol>
              </div>
            </div>
          `,
			},
		];

		scripts.forEach((scriptInfo) => {
			const script = document.createElement("script");
			script.type = scriptInfo.type;
			script.id = scriptInfo.id;
			script.textContent = scriptInfo.content;
			document.body.appendChild(script);
		});

		// Cleanup function to remove scripts when component unmounts
		return () => {
			scripts.forEach((scriptInfo) => {
				const script = document.getElementById(scriptInfo.id);
				if (script) {
					document.body.removeChild(script);
				}
			});
		};
	}, []);

	return null; // This component does not render anything itself
}

// List of scripts to load
const scripts = [
	"VvvebJs/js/popper.min.js",
	"VvvebJs/js/bootstrap.min.js",
	"VvvebJs/libs/builder/builder.js",
	"VvvebJs/libs/builder/undo.js",
	"VvvebJs/libs/builder/inputs.js",
	"VvvebJs/libs/builder/components-bootstrap5.js",
	"VvvebJs/libs/builder/components-widgets.js",
	"VvvebJs/libs/media/media.js",
	"VvvebJs/libs/builder/plugin-media.js",
	"VvvebJs/libs/builder/plugin-google-fonts.js",
	"VvvebJs/libs/builder/components-common.js",
	"VvvebJs/libs/builder/plugin-aos.js",
	"VvvebJs/libs/builder/components-html.js",
	"VvvebJs/libs/builder/components-elements.js",
	"VvvebJs/libs/builder/section.js",
	"VvvebJs/libs/builder/oembed.js",
	"VvvebJs/libs/builder/components-embeds.js",
	"VvvebJs/demo/landing/sections/sections.js",
	"VvvebJs/libs/builder/sections-bootstrap4.js",
	"VvvebJs/libs/codemirror/lib/codemirror.js",
	"VvvebJs/libs/codemirror/lib/xml.js",
	"VvvebJs/libs/codemirror/lib/css.js",
	"VvvebJs/libs/codemirror/lib/formatting.js",
	"VvvebJs/libs/builder/plugin-codemirror.js",
	"VvvebJs/libs/jszip/jszip.min.js",
	"VvvebJs/libs/jszip/filesaver.min.js",
	"VvvebJs/libs/builder/plugin-jszip.js",
	"VvvebJs/libs/builder/plugin-ai-assistant.js",
];

// List of styles to load
const styles = ["VvvebJs/css/editor.css", "VvvebJs/libs/media/media.css", "VvvebJs/libs/codemirror/lib/codemirror.css", "VvvebJs/libs/codemirror/theme/material.css"];

function WebsiteBuilder() {
	const [showModal, setShowModal] = useState(false);
	const [scriptsLoaded, setScriptsLoaded] = useState(false);

	// Load the builder scripts
	useEffect(() => {
		// Global flag to check if scripts are loaded
		if (window.scriptsLoaded) return;

		const loadResources = () => {
			// Function to load scripts dynamically
			const loadScript = (src) => {
				return new Promise((resolve, reject) => {
					const script = document.createElement("script");
					script.src = src;
					script.onload = () => resolve(script);
					script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
					document.head.appendChild(script);
				});
			};

			// Function to load styles dynamically
			const loadStyle = (href) => {
				const link = document.createElement("link");
				link.href = href;
				link.rel = "stylesheet";
				document.head.appendChild(link);
			};

			// Load all scripts and initialize after all are loaded
			Promise.all(scripts.map((script) => loadScript(script)))
				.then(() => {
					console.log("All scripts loaded successfully.");
					setScriptsLoaded(true);
					initializeEditor();
				})
				.catch((error) => console.error("Script loading failed:", error));

			// Load all styles
			styles.forEach(loadStyle);

			// Set global flag to true after loading scripts
			window.scriptsLoaded = true;
		};

		// Check if document is fully loaded
		if (document.readyState === "complete") {
			loadResources();
		} else {
			window.addEventListener("load", loadResources);
		}

		// Cleanup function to remove scripts and styles when the component unmounts
		return () => {
			scripts.forEach((scriptInfo) => {
				const script = document.getElementById(scriptInfo.id);
				if (script && document.body.contains(script)) {
					// Check if the script is a child of body
					document.body.removeChild(script);
				}
			});
			styles.forEach((href) => {
				let link = document.querySelector(`link[href="${href}"]`);
				if (link) document.head.removeChild(link);
			});
		};
	}, []);

	// Initialize the editor once after the scripts are loaded
	function initializeEditor() {
		let defaultPages = {
			offcanvas: { name: "offcanvas", title: "Offcanvas", url: "/VvvebJs/demo/offcanvas/index.html", file: "/VvvebJs/demo/offcanvas/index.html", folder: "content" },
			pricing: { name: "pricing", title: "Pricing", url: "/VvvebJs/demo/pricing/index.html", file: "/VvvebJs/demo/pricing/index.html", folder: "ecommerce" },
			product: { name: "product", title: "Product", url: "/VvvebJs/demo/product/index.html", file: "/VvvebJs/demo/product/index.html", folder: "ecommerce" },
		};

		window.Vvveb.Gui.init();

		let pages = defaultPages;
		let firstPage = Object.keys(pages)[0];

		window.Vvveb.Builder.init(pages[firstPage]["url"], function () {
			console.log("VvvebJs is initialized and ready");
		});

		window.Vvveb.FileManager.init();
		window.Vvveb.SectionList.init();
		window.Vvveb.Breadcrumb.init();

		window.Vvveb.FileManager.addPages(pages);
		window.Vvveb.FileManager.loadPage(pages[firstPage]["name"]);
		window.Vvveb.Gui.toggleRightColumn(false);
		window.Vvveb.Breadcrumb.init();

        window.Vvveb.TreeList.init();

		window.Vvveb.themeBaseUrl = "VvvebJs/demo/landing/";

		console.log(`Editor fully initialized`, window.Vvveb);
	}

	return (
		<>
			<TemplateScripts />
			<div id="vvveb-builder">
				<div id="top-panel">
					<img alt="Vvveb" className="float-start" id="logo" src="VvvebJs/img/logo.png" />
					<div className="btn-group float-start" role="group">
						<button aria-pressed="false" className="btn btn-light" data-bs-toggle="button" data-vvveb-action="toggleFileManager" id="toggle-file-manager-btn" title="Toggle file manager">
							<img alt="" height="18" role="presentation" src="VvvebJs/libs/builder/icons/file-manager-layout.svg" width="18" />
						</button>
						<button aria-pressed="false" className="btn btn-light" data-bs-toggle="button" data-vvveb-action="toggleLeftColumn" id="toggle-left-column-btn" title="Toggle left column">
							<img alt="" height="18" role="presentation" src="VvvebJs/libs/builder/icons/left-column-layout.svg" width="18" />
						</button>
						<button aria-pressed="false" className="btn btn-light" data-bs-toggle="button" data-vvveb-action="toggleRightColumn" id="toggle-right-column-btn" title="Toggle right column">
							<img alt="" height="18" role="presentation" src="VvvebJs/libs/builder/icons/right-column-layout.svg" width="18" />
						</button>
					</div>
					<div className="btn-group me-3" role="group">
						<button className="btn btn-light" data-vvveb-action="undo" data-vvveb-shortcut="ctrl+z" id="undo-btn" title="Undo (Ctrl/Cmd + Z)">
							<i className="la la-undo" />
						</button>
						<button className="btn btn-light" data-vvveb-action="redo" data-vvveb-shortcut="ctrl+shift+z" id="redo-btn" title="Redo (Ctrl/Cmd + Shift + Z)">
							<i className="la la-undo la-flip-horizontal" />
						</button>
					</div>
					<div className="btn-group me-3" role="group">
						<button aria-pressed="false" className="btn btn-light" data-bs-toggle="button" data-vvveb-action="setDesignerMode" id="designer-mode-btn" title="Designer Mode (Free dragging)">
							<i className="la la-hand-rock" />
						</button>
						<button aria-pressed="false" className="btn btn-light" data-bs-toggle="button" data-vvveb-action="preview" id="preview-btn" title="Preview" type="button">
							<i className="icon-eye-outline" />
						</button>
						<button aria-pressed="false" className="btn btn-light" data-bs-toggle="button" data-vvveb-action="fullscreen" id="fullscreen-btn" title="Fullscreen (F11)">
							<i className="icon-expand-outline" />
						</button>
						<button aria-pressed="true" className="btn btn-light active" data-bs-toggle="button" data-vvveb-action="toggleTreeList" id="toggle-tree-list" title="Toggle navigator">
							<i className="icon-layers-outline" />
						</button>
						<button className="btn btn-light" data-v-download="index.html" data-vvveb-action="download" id="download-btn" title="Download">
							<i className="la la-download" />
						</button>
					</div>
					<div className="btn-group me-2 float-end" role="group">
						<button
							className="btn btn-primary btn-sm btn-icon save-btn"
							data-v-vvveb-shortcut="ctrl+e"
							data-vvveb-action="saveAjax"
							data-vvveb-url="save.php"
							id="save-btn"
							title="Export (Ctrl + E)"
						>
							<span className="loading d-none">
								<i className="icon-save-outline" />
								<span aria-hidden="true" className="spinner-border spinner-border-sm align-middle" role="status"></span>
								<span>Saving </span> ...{" "}
							</span>
							<span className="button-text">
								<i className="icon-save-outline" /> <span>Save page</span>
							</span>
						</button>
					</div>
					<div className="float-end me-3">
						<button className="btn btn-light border-0 btn-sm btn-dark-mode" data-vvveb-action="darkMode">
							<i className="la la-sun" />
						</button>
						<a className="btn btn-light btn-sm btn-preview-url" href="#" target="blank" title="View page">
							<i className="la la-external-link-alt" />
						</a>
						<div className="btn-group responsive-btns" role="group">
							<button className="btn btn-light" data-view="mobile" data-vvveb-action="viewport" id="mobile-view" title="Mobile view">
								<i className="la la-mobile" />
							</button>
							<button className="btn btn-light" data-view="tablet" data-vvveb-action="viewport" id="tablet-view" title="Tablet view">
								<i className="la la-tablet" />
							</button>
							<button className="btn btn-light" data-view="" data-vvveb-action="viewport" id="desktop-view" title="Desktop view">
								<i className="la la-laptop" />
							</button>
							<div className="percent">
								<input className="form-control" data-vvveb-action="zoomChange" data-vvveb-on="change" defaultValue="100" id="zoom" max="100" min="10" step="10" type="number" />
							</div>
						</div>
					</div>
				</div>
				<div id="left-panel">
					<div>
						<div id="filemanager">
							<div className="header">
								<span className="text-secondary">
									<i className="la la-file la-lg" /> Pages
								</span>
								<div className="btn-group responsive-btns float-end" role="group">
									<button className="btn btn-outline-primary btn-sm  btn-add" data-vvveb-action="newPage" data-vvveb-shortcut="" id="new-file-btn" title="New file">
										<i className="icon-add" />
									</button>
								</div>
							</div>
							<div className="tree">
								<ol></ol>
							</div>
						</div>
						<div className="drag-elements">
							<div className="header">
								<ul className="nav nav-tabs  nav-fill" id="elements-tabs" role="tablist">
									<li className="nav-item sections-tab">
										<a
											aria-controls="sections"
											aria-selected="true"
											className="nav-link active"
											data-bs-toggle="tab"
											href="#sections"
											id="sections-tab"
											role="tab"
											title="Sections"
										>
											<i className="icon-layers-outline" />
										</a>
									</li>
									<li className="nav-item component-tab">
										<a
											aria-controls="components"
											aria-selected="false"
											className="nav-link"
											data-bs-toggle="tab"
											href="#components-tabs"
											id="components-tab"
											role="tab"
											title="Components"
										>
											<i className="icon-cube-outline" />
										</a>
									</li>
									<li className="nav-item component-properties-tab d-none">
										<a
											aria-controls="properties"
											aria-selected="false"
											className="nav-link"
											data-bs-toggle="tab"
											href="#properties"
											id="properties-tab"
											role="tab"
											title="Properties"
										>
											<i className="icon-settings-outline" />
										</a>
									</li>
									<li className="nav-item component-configuration-tab">
										<a
											aria-controls="configuration"
											aria-selected="false"
											className="nav-link"
											data-bs-toggle="tab"
											href="#configuration"
											id="configuration-tab"
											role="tab"
											title="Styles"
										>
											<i className="icon-color-wand-outline" />
										</a>
									</li>
								</ul>
								<div className="tab-content">
									<div aria-labelledby="sections-tab" className="tab-pane show active sections" id="sections" role="tabpanel">
										<ul className="nav nav-tabs nav-fill nav-underline  sections-tabs" id="sections-tabs" role="tablist">
											<li className="nav-item content-tab">
												<a aria-controls="components" aria-selected="false" className="nav-link active" data-bs-toggle="tab" href="#sections-new-tab" role="tab">
													<i className="icon-albums-outline" />{" "}
													<div>
														<span>Sections</span>
													</div>
												</a>
											</li>
											<li className="nav-item style-tab">
												<a aria-controls="sections" aria-selected="true" className="nav-link" data-bs-toggle="tab" href="#sections-list" role="tab">
													<i className="icon-document-text-outline" />
													<div>
														<span>Page Sections</span>
													</div>
												</a>
											</li>
										</ul>
										<div className="tab-content">
											<div aria-labelledby="style-tab" className="tab-pane" data-section="style" id="sections-list" role="tabpanel">
												<div className="drag-elements-sidepane sidepane">
													<div>
														<div className="sections-container p-4">
															<div className="section-item" draggable="true">
																<div className="controls">
																	<div className="handle" />
																	<div className="info">
																		<div className="name">
																			<div className="type"></div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="section-item" draggable="true">
																<div className="controls">
																	<div className="handle" />
																	<div className="info">
																		<div className="name">
																			<div className="type"></div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="section-item" draggable="true">
																<div className="controls">
																	<div className="handle" />
																	<div className="info">
																		<div className="name">
																			<div className="type"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div aria-labelledby="content-tab" className="tab-pane show active" data-section="content" id="sections-new-tab" role="tabpanel">
												<div className="search">
													<div className="expand">
														<button className="text-sm" data-vvveb-action="expand" title="Expand All">
															<i className="la la-plus" />
														</button>
														<button data-vvveb-action="collapse" title="Collapse all">
															<i className="la la-minus" />
														</button>
													</div>
													<input className="form-control section-search" data-vvveb-action="search" data-vvveb-on="keyup" placeholder="Search sections" type="text" />
													<button className="clear-backspace" data-vvveb-action="clearSearch" title="Clear search">
														<i className="la la-times" />
													</button>
												</div>
												<div className="drag-elements-sidepane sidepane">
													<div className="block-preview">
														<img
															src=""
															style={{
																display: "none",
															}}
														/>
													</div>
													<div>
														<ul className="sections-list clearfix" data-type="leftpanel"></ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div aria-labelledby="components-tab" className="tab-pane show" id="components-tabs" role="tabpanel">
										<ul className="nav nav-tabs nav-fill nav-underline  sections-tabs" role="tablist">
											<li className="nav-item components-tab">
												<a aria-controls="components" aria-selected="true" className="nav-link active" data-bs-toggle="tab" href="#components" role="tab">
													<i className="icon-cube-outline" />{" "}
													<div>
														<span>Components</span>
													</div>
												</a>
											</li>
											<li className="nav-item blocks-tab">
												<a aria-controls="components" aria-selected="false" className="nav-link" data-bs-toggle="tab" href="#blocks" role="tab">
													<i className="icon-copy-outline" />{" "}
													<div>
														<span>Blocks</span>
													</div>
												</a>
											</li>
										</ul>
										<div className="tab-content">
											<div aria-labelledby="components-tab" className="tab-pane show active components" data-section="components" id="components" role="tabpanel">
												<div className="search">
													<div className="expand">
														<button className="text-sm" data-vvveb-action="expand" title="Expand All">
															<i className="la la-plus" />
														</button>
														<button data-vvveb-action="collapse" title="Collapse all">
															<i className="la la-minus" />
														</button>
													</div>
													<input className="form-control component-search" data-vvveb-action="search" data-vvveb-on="keyup" placeholder="Search components" type="text" />
													<button className="clear-backspace" data-vvveb-action="clearSearch">
														<i className="la la-times" />
													</button>
												</div>
												<div className="drag-elements-sidepane sidepane">
													<div>
														<ul className="components-list clearfix" data-type="leftpanel"></ul>
													</div>
												</div>
											</div>
											<div aria-labelledby="content-tab" className="tab-pane show active blocks" data-section="content" id="blocks" role="tabpanel">
												<div className="search">
													<div className="expand">
														<button className="text-sm" data-vvveb-action="expand" title="Expand All">
															<i className="la la-plus" />
														</button>
														<button data-vvveb-action="collapse" title="Collapse all">
															<i className="la la-minus" />
														</button>
													</div>
													<input className="form-control block-search" data-vvveb-action="search" data-vvveb-on="keyup" placeholder="Search blocks" type="text" />
													<button className="clear-backspace" data-vvveb-action="clearSearch">
														<i className="la la-times" />
													</button>
												</div>
												<div className="drag-elements-sidepane sidepane">
													<div className="block-preview">
														<img src="" />
													</div>
													<div>
														<ul className="blocks-list clearfix" data-type="leftpanel"></ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div aria-labelledby="properties-tab" className="tab-pane" id="properties" role="tabpanel">
										<div className="component-properties-sidepane">
											<div>
												<div className="component-properties">
													<ul className="nav nav-tabs nav-fill nav-underline" id="properties-tabs" role="tablist">
														<li className="nav-item content-tab">
															<a
																aria-controls="components"
																aria-selected="true"
																className="nav-link content-tab active"
																data-bs-toggle="tab"
																href="#content-left-panel-tab"
																role="tab"
															>
																<i className="icon-albums-outline" /> <span>Content</span>
															</a>
														</li>
														<li className="nav-item style-tab">
															<a aria-controls="style" aria-selected="false" className="nav-link" data-bs-toggle="tab" href="#style-left-panel-tab" role="tab">
																<i className="icon-color-fill-outline" /> <span>Style</span>
															</a>
														</li>
														<li className="nav-item advanced-tab">
															<a aria-controls="advanced" aria-selected="false" className="nav-link" data-bs-toggle="tab" href="#advanced-left-panel-tab" role="tab">
																<i className="icon-settings-outline" /> <span>Advanced</span>
															</a>
														</li>
													</ul>
													<div className="tab-content" data-offset="20">
														<div aria-labelledby="content-tab" className="tab-pane show active" data-section="content" id="content-left-panel-tab" role="tabpanel">
															<div className="alert alert-dismissible fade show alert-light m-3" role="alert" style={{}}>
																<button aria-label="Close" className="btn-close" data-bs-dismiss="alert" type="button" />
																<strong>No selected element!</strong>
																<br /> Click on an element to edit.
															</div>
														</div>
														<div aria-labelledby="style-tab" className="tab-pane show" data-section="style" id="style-left-panel-tab" role="tabpanel">
															<div className="border-bottom pb-2 px-2">
																<div className="justify-content-end d-flex">
																	<select className="form-select w-50" data-vvveb-action="setState" data-vvveb-on="change">
																		<option value=""> - State - </option>
																		<option value="hover">hover</option>
																		<option value="active">active</option>
																		<option value="nth-of-type(2n)">nth-of-type(2n)</option>
																	</select>
																</div>
															</div>
														</div>
														<div aria-labelledby="advanced-tab" className="tab-pane show" data-section="advanced" id="advanced-left-panel-tab" role="tabpanel"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div aria-labelledby="configuration-tab" className="tab-pane" id="configuration" role="tabpanel">
										<ul className="nav nav-tabs nav-fill nav-underline sections-tabs" id="vars-tabs" role="tablist">
											<li className="nav-item vars-tab">
												<a aria-controls="components" aria-selected="false" className="nav-link active" data-bs-toggle="tab" href="#vars-tab" role="tab">
													<i className="icon-brush-outline" />{" "}
													<div>
														<span>Variables</span>
													</div>
												</a>
											</li>
											<li className="nav-item css-tab">
												<a aria-controls="css" aria-selected="true" className="nav-link" data-bs-toggle="tab" href="#css-tab" role="tab">
													<i className="icon-code-slash-outline" />{" "}
													<div>
														<span>Css</span>
													</div>
												</a>
											</li>
										</ul>
										<div className="tab-content">
											<div aria-labelledby="vars-tab" className="tab-pane show active" data-section="vars" id="vars-tab" role="tabpanel">
												<div className="drag-elements-sidepane sidepane">
													<div data-offset="80">
														<div className="component-properties">
															<input className="header_check" defaultChecked id="header_pallette" type="checkbox" />
															<div className="tab-pane section px-0" data-section="content"></div>
														</div>
													</div>
												</div>
											</div>
											<div aria-labelledby="css-tab" className="tab-pane" data-section="css" id="css-tab" role="tabpanel">
												<div className="drag-elements-sidepane sidepane">
													<div data-offset="80">
														<textarea className="form-control" id="css-editor" rows="24" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="canvas">
					<div id="iframe-wrapper">
						<div id="iframe-layer">
							<div className="loading-message active">
								<div className="animation-container">
									<div className="dot dot-1" />
									<div className="dot dot-2" />
									<div className="dot dot-3" />
								</div>
								<svg version="1.1" xmlns="http://www.w3.org/2000/svg">
									<defs>
										<filter id="goo">
											<feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
											<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7" />
										</filter>
									</defs>
								</svg>
							</div>
							<div id="highlight-box">
								<div id="highlight-name">
									<span className="name" />
									<span className="type" />
								</div>
								<div id="section-actions">
									<a href="" id="add-section-btn" title="Add element">
										<i className="la la-plus" />
									</a>
								</div>
							</div>
							<div id="select-box">
								<div className="default-editor" id="wysiwyg-editor">
									<a aria-label="Bold" className="hint" href="" id="bold-btn" title="Bold">
										<svg height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
											<path d="M6,4h8a4,4,0,0,1,4,4h0a4,4,0,0,1-4,4H6Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
											<path d="M6,12h9a4,4,0,0,1,4,4h0a4,4,0,0,1-4,4H6Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
										</svg>
									</a>
									<a aria-label="Italic" className="hint" href="" id="italic-btn" title="Italic">
										<svg height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
											<line fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="19" x2="10" y1="4" y2="4" />
											<line fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="14" x2="5" y1="20" y2="20" />
											<line fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="15" x2="9" y1="4" y2="20" />
										</svg>
									</a>
									<a aria-label="Underline" className="hint" href="" id="underline-btn" title="Underline">
										<svg height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M6,4v7a6,6,0,0,0,6,6h0a6,6,0,0,0,6-6V4"
												fill="none"
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												y1="2"
												y2="2"
											/>
											<line fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="4" x2="20" y1="22" y2="22" />
										</svg>
									</a>
									<a aria-label="Strikeout" className="hint" href="" id="strike-btn" title="Strikeout">
										<del>S</del>
									</a>
									<div className="dropdown">
										<button aria-expanded="false" aria-haspopup="true" className="btn btn-link dropdown-toggle" data-bs-toggle="dropdown" id="dropdownMenuButton" type="button">
											<span aria-label="Text align" className="hint">
												<i className="la la-align-left" />
											</span>
										</button>
										<div aria-labelledby="dropdownMenuButton" className="dropdown-menu" id="justify-btn">
											<a className="dropdown-item" data-value="Left" href="#">
												<i className="la la-lg la-align-left" /> Align Left
											</a>
											<a className="dropdown-item" data-value="Center" href="#">
												<i className="la la-lg la-align-center" /> Align Center
											</a>
											<a className="dropdown-item" data-value="Right" href="#">
												<i className="la la-lg la-align-right" /> Align Right
											</a>
											<a className="dropdown-item" data-value="Full" href="#">
												<i className="la la-lg la-align-justify" /> Align Justify
											</a>
										</div>
									</div>
									<div className="separator" />
									<a aria-label="Create link" className="hint" href="" id="link-btn" title="Create link">
										<i className="la la-link"></i>
									</a>
									<div className="separator" />
									<input aria-label="Text color" className="form-control form-control-color hint" id="fore-color" name="color" pattern="#[a-f0-9]{6}" type="color" />
									<input aria-label="Background color" className="form-control form-control-color hint" id="back-color" name="background-color" pattern="#[a-f0-9]{6}" type="color" />
									<div className="separator" />
									<select aria-label="Font size" className="form-select" id="font-size">
										<option value="">- Font size -</option>
										<option value="8px">8 px</option>
										<option value="9px">9 px</option>
										<option value="10px">10 px</option>
										<option value="11px">11 px</option>
										<option value="12px">12 px</option>
										<option value="13px">13 px</option>
										<option value="14px">14 px</option>
										<option value="15px">15 px</option>
										<option value="16px">16 px</option>
										<option value="17px">17 px</option>
										<option value="18px">18 px</option>
										<option value="19px">19 px</option>
										<option value="20px">20 px</option>
										<option value="21px">21 px</option>
										<option value="22px">22 px</option>
										<option value="23px">23 px</option>
										<option value="24px">24 px</option>
										<option value="25px">25 px</option>
										<option value="26px">26 px</option>
										<option value="27px">27 px</option>
										<option value="28px">28 px</option>
									</select>
									<div className="separator" />
									<select className="form-select" id="font-family" title="Font family">
										<option value=""> - Font family - </option>
										<optgroup label="System default">
											<option value="Arial, Helvetica, sans-serif">Arial</option>
											<option value="'Lucida Sans Unicode', 'Lucida Grande', sans-serif">Lucida Grande</option>
											<option value="'Palatino Linotype', 'Book Antiqua', Palatino, serif">Palatino Linotype</option>
											<option value="'Times New Roman', Times, serif">Times New Roman</option>
											<option value="Georgia, serif">Georgia, serif</option>
											<option value="Tahoma, Geneva, sans-serif">Tahoma</option>
											<option value="'Comic Sans MS', cursive, sans-serif">Comic Sans</option>
											<option value="Verdana, Geneva, sans-serif">Verdana</option>
											<option value="Impact, Charcoal, sans-serif">Impact</option>
											<option value="'Arial Black', Gadget, sans-serif">Arial Black</option>
											<option value="'Trebuchet MS', Helvetica, sans-serif">Trebuchet</option>
											<option value="'Courier New', Courier, monospace">Courier New</option>
											<option value="'Brush Script MT', sans-serif">Brush Script</option>
										</optgroup>
									</select>
								</div>
								<div id="select-actions">
									<a href="" id="drag-btn" title="Drag element">
										<i className="la la-arrows-alt" />
									</a>
									<a className="la-rotate-180" href="" id="parent-btn" title="Select parent">
										<i className="la la-level-up-alt" />
									</a>
									<a href="" id="up-btn" title="Move element up">
										<i className="la la-arrow-up" />
									</a>
									<a href="" id="down-btn" title="Move element down">
										<i className="la la-arrow-down" />
									</a>
									<a href="" id="edit-code-btn" title="Edit html code">
										<i className="icon-code-outline" />
									</a>
									<a className="mx-2" href="" id="save-reusable-btn" title="Save as reusable">
										<i className="icon-save-outline" />
									</a>
									<a href="" id="clone-btn" title="Clone element">
										<i className="icon-copy-outline" />
									</a>
									<a href="" id="delete-btn" title="Remove element">
										<i className="icon-trash-outline" />
									</a>
								</div>
								<div className="resize">
									<div className="top-left"></div>
									<div className="top-center"></div>
									<div className="top-right"></div>
									<div className="center-left"></div>
									<div className="center-right"></div>
									<div className="bottom-left"></div>
									<div className="bottom-center"></div>
									<div className="bottom-right"></div>
								</div>
							</div>
							<div className="drag-elements" id="add-section-box">
								<div className="header">
									<ul className="nav nav-tabs" id="box-elements-tabs" role="tablist">
										<li className="nav-item component-tab">
											<a
												aria-controls="components"
												aria-selected="true"
												className="nav-link px-3 active"
												data-bs-toggle="tab"
												href="#box-components"
												id="box-components-tab"
												role="tab"
											>
												<i className="icon-cube-outline" />
												<small>Components</small>
											</a>
										</li>
										<li className="nav-item sections-tab">
											<a aria-controls="blocks" aria-selected="false" className="nav-link px-3" data-bs-toggle="tab" href="#box-blocks" id="box-sections-tab" role="tab">
												<i className="icon-copy-outline" />
												<small>Blocks</small>
											</a>
										</li>
									</ul>
									<div className="section-box-actions">
										<div className="btn btn-outline-secondary btn-sm mt-1 border-0 float-end" id="close-section-btn">
											<i className="la la-times la-lg" />
										</div>
										<div className="me-4 small mt-2 float-end">
											<div className="form-check d-inline-block small me-1">
												<input
													className="form-check-input"
													defaultChecked
													defaultValue="after"
													id="add-section-insert-mode-after"
													name="add-section-insert-mode"
													type="radio"
												/>
												<label className="form-check-label" htmlFor="add-section-insert-mode-after">
													After
												</label>
											</div>
											<div className="form-check d-inline-block small">
												<input className="form-check-input" defaultValue="inside" id="add-section-insert-mode-inside" name="add-section-insert-mode" type="radio" />
												<label className="form-check-label" htmlFor="add-section-insert-mode-inside">
													Inside
												</label>
											</div>
										</div>
									</div>
									<div className="tab-content">
										<div aria-labelledby="components-tab" className="tab-pane show active" id="box-components" role="tabpanel">
											<div className="search">
												<div className="expand">
													<button className="text-sm" data-vvveb-action="expand" title="Expand All">
														<i className="la la-plus" />
													</button>
													<button data-vvveb-action="collapse" title="Collapse all">
														<i className="la la-minus" />
													</button>
												</div>
												<input className="form-control component-search" data-vvveb-action="search" data-vvveb-on="keyup" placeholder="Search components" type="text" />
												<button className="clear-backspace" data-vvveb-action="clearSearch">
													<i className="la la-times" />
												</button>
											</div>
											<div className="overflow-y-auto mb-5">
												<div>
													<ul className="components-list clearfix" data-type="addbox"></ul>
												</div>
											</div>
										</div>
										<div aria-labelledby="blocks-tab" className="tab-pane" id="box-blocks" role="tabpanel">
											<div className="search">
												<div className="expand">
													<button className="text-sm" data-vvveb-action="expand" title="Expand All">
														<i className="la la-plus" />
													</button>
													<button data-vvveb-action="collapse" title="Collapse all">
														<i className="la la-minus" />
													</button>
												</div>
												<input className="form-control block-search" data-vvveb-action="search" data-vvveb-on="keyup" placeholder="Search blocks" type="text" />
												<button className="clear-backspace" data-vvveb-action="clearSearch">
													<i className="la la-times" />
												</button>
											</div>
											<div className="overflow-y-auto mb-5">
												<div>
													<ul className="blocks-list clearfix" data-type="addbox"></ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="drop-highlight-box"></div>
						</div>
						<iframe id="iframe1" src=""></iframe>
					</div>
				</div>
				<div id="right-panel">
					<div className="component-properties">
						<ul className="nav nav-tabs nav-fill nav-underline" id="properties-tabs" role="tablist">
							<li className="nav-item content-tab">
								<a aria-controls="components" aria-selected="true" className="nav-link active" data-bs-toggle="tab" href="#content-tab" role="tab">
									<i className="icon-albums-outline" /> <span>Content</span>
								</a>
							</li>
							<li className="nav-item style-tab">
								<a aria-controls="blocks" aria-selected="false" className="nav-link" data-bs-toggle="tab" href="#style-tab" role="tab">
									<i className="icon-color-fill-outline" /> <span>Style</span>
								</a>
							</li>
							<li className="nav-item advanced-tab">
								<a aria-controls="blocks" aria-selected="false" className="nav-link" data-bs-toggle="tab" href="#advanced-tab" role="tab">
									<i className="icon-settings-outline" /> <span>Advanced</span>
								</a>
							</li>
						</ul>
						<div className="tab-content">
							<div aria-labelledby="content-tab" className="tab-pane show active" data-section="content" id="content-tab" role="tabpanel">
								<div className="alert alert-dismissible fade show alert-light m-3" role="alert">
									<button aria-label="Close" className="btn-close" data-bs-dismiss="alert" type="button" />
									<strong>No selected element!</strong>
									<br /> Click on an element to edit.
								</div>
							</div>
							<div aria-labelledby="style-tab" className="tab-pane show" data-section="style" id="style-tab" role="tabpanel">
								<div className="border-bottom pb-2 px-2">
									<div className="justify-content-end d-flex">
										<select className="form-select w-50" data-vvveb-action="setState" data-vvveb-on="change">
											<option value=""> - State - </option>
											<option value="hover">hover</option>
											<option value="active">active</option>
											<option value="nth-of-type(2n)">nth-of-type(2n)</option>
										</select>
									</div>
								</div>
							</div>
							<div aria-labelledby="advanced-tab" className="tab-pane show" data-section="advanced" id="advanced-tab" role="tabpanel"></div>
						</div>
					</div>
				</div>
				<div id="bottom-panel">
					<div>
						<div
							className="breadcrumb-navigator px-2"
							style={{
								"--bs-breadcrumb-divider": "'>'",
							}}
						>
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<a href="#">body</a>
								</li>
								<li className="breadcrumb-item">
									<a href="#">section</a>
								</li>
								<li className="breadcrumb-item">
									<a href="#">img</a>
								</li>
							</ol>
						</div>
						<div className="btn-group" role="group">
							<div
								className="form-check mt-1"
								id="toggleEditorJsExecute"
								style={{
									display: "none",
								}}
							>
								<input className="form-check-input" data-vvveb-action="toggleEditorJsExecute" id="runjs" name="runjs" type="checkbox" />
								<label className="form-check-label" htmlFor="runjs">
									<small>Run javascript code on edit</small>
								</label>
							</div>
							<button className="btn btn-sm btn-light btn-sm" data-vvveb-action="toggleEditor" id="code-editor-btn" title="Code editor">
								<i className="la la-code" /> Code editor
							</button>
						</div>
					</div>
					<div id="vvveb-code-editor">
						<textarea className="form-control" />
						<div></div>
					</div>
				</div>

				<div>
					<div id="tree-list">
						<div className="header">
							<div>Navigator</div>
							<button aria-pressed="true" className="btn btn-sm" data-vvveb-action="toggleTreeList">
								<i className="icon-close" />
							</button>
						</div>
						<div className="tree">
							<ol></ol>
						</div>
					</div>
					<div aria-hidden="true" aria-labelledby="codeEditorModal" className="modal modal-full fade" id="codeEditorModal" role="dialog" tabIndex="-1">
						<div className="modal-dialog modal-xl modal-dialog-scrollable" role="document">
							<div className="modal-content">
								<input defaultValue="" name="file" type="hidden" />
								<div className="modal-header justify-content-between">
									<span className="modal-title" />
									<div className="float-end">
										<button className="btn btn-light border btn-icon" data-bs-dismiss="modal" type="button">
											<i className="la la-times" />
											Close
										</button>
										<button className="btn btn-primary btn-icon save-btn" title="Save changes">
											<span className="loading d-none">
												<i className="la la-save" />
												<span aria-hidden="true" className="spinner-border spinner-border-sm align-middle" role="status"></span>
												<span>Saving </span> ...{" "}
											</span>
											<span className="button-text">
												<i className="la la-save" /> <span>Save changes</span>
											</span>
										</button>
									</div>
								</div>
								<div className="modal-body p-0">
									<textarea className="form-control h-100" />
								</div>
							</div>
						</div>
					</div>
					<div aria-hidden="true" aria-labelledby="textarea-modal" className="modal fade" id="textarea-modal" role="dialog" tabIndex="-1">
						<div className="modal-dialog modal-lg" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<p className="modal-title text-primary">
										<i className="la la-lg la-save" /> Export html
									</p>
									<button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"></button>
								</div>
								<div className="modal-body">
									<textarea className="form-control" cols="150" rows="25" />
								</div>
								<div className="modal-footer">
									<button className="btn btn-secondary btn-lg" data-bs-dismiss="modal" type="button">
										<i className="la la-times" /> Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal fade" id="message-modal" role="dialog" tabIndex="-1">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<p className="modal-title text-primary">
										<i className="la la-lg la-comment" /> VvvebJs
									</p>
									<button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"></button>
								</div>
								<div className="modal-body">
									<p>Page was successfully saved!.</p>
								</div>
								<div className="modal-footer">
									<button className="btn btn-secondary btn-lg" data-bs-dismiss="modal" type="button">
										<i className="la la-times" /> Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal fade" id="new-page-modal" role="dialog" tabIndex="-1">
						<div className="modal-dialog" role="document">
							<form action="save.php">
								<div className="modal-content">
									<div className="modal-header">
										<span className="modal-title text-primary fw-normal">
											<i className="la la-lg la-file" /> New page
										</span>
										<button aria-label="Close" className="btn-close p-0 me-1" data-bs-dismiss="modal" type="button"></button>
									</div>
									<div className="modal-body text">
										<div className="mb-3 row" data-key="type">
											<label className="col-sm-3 col-form-label">
												Template
												<abbr title="The contents of this template will be used as a start for the new template">
													<i className="la la-lg la-question-circle text-primary" />
												</abbr>
											</label>
											<div className="col-sm-9 input">
												<div>
													<select className="form-select" name="startTemplateUrl">
														<option value="new-page-blank-template.html">Blank Template</option>
														<option value="demo/narrow-jumbotron/index.html">Narrow jumbotron</option>
														<option value="demo/album/index.html">Album</option>
													</select>
												</div>
											</div>
										</div>
										<div className="mb-3 row" data-key="href">
											<label className="col-sm-3 col-form-label">Page name</label>
											<div className="col-sm-9 input">
												<div>
													<input className="form-control" defaultValue="My page" name="title" placeholder="My page" required type="text" />
												</div>
											</div>
										</div>
										<div className="mb-3 row" data-key="href">
											<label className="col-sm-3 col-form-label">File name</label>
											<div className="col-sm-9 input">
												<div>
													<input className="form-control" defaultValue="my-page.html" name="file" placeholder="my-page.html" required type="text" />
												</div>
											</div>
										</div>
										<div className="mb-3 row" data-key="href">
											<label className="col-sm-3 col-form-label">Save to folder</label>
											<div className="col-sm-9 input">
												<div>
													<input className="form-control" defaultValue="my-pages" name="folder" placeholder="/" required type="text" />
												</div>
											</div>
										</div>
									</div>
									<div className="modal-footer">
										<button className="btn btn-secondary btn-icon" data-bs-dismiss="modal" type="reset">
											<i className="la la-times" /> Cancel
										</button>
										<button className="btn btn-primary btn-icon" type="submit">
											<i className="la la-check" /> Create page
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className="toast-container position-fixed end-0 bottom-0 me-3 mb-3" id="top-toast">
						<div aria-atomic="true" aria-live="assertive" className="toast" role="alert">
							<div className="toast-header text-white">
								<strong className="me-auto">Page save</strong>
								<button aria-label="Close" className="btn-close text-white px-2" data-bs-dismiss="toast" type="button" />
							</div>
							<div className="toast-body ">
								<div className="flex-grow-1">
									<div className="message">
										Elements saved!
										<div>Template backup was saved!</div>
										<div>Template was saved!</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default WebsiteBuilder;
