import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { config } from "../../config";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { useAuth } from "../../Hooks/useAuthentication";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";


export default function ManageBlacklists() {
	const auth = useAuth();

    const [blacklists, setBlacklists] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
		if(!auth.token || !auth.username) return;
        fetchBlacklists(currentPage);
    }, [currentPage, auth.token, auth.username]);

    const fetchBlacklists = async (currentPage) => {
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/admin/getBlacklists`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${auth.token}`,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					token: auth.token,
					username: auth.username,
					page: currentPage,
				})
			});
            const data = await response.json();
            setBlacklists(data.data);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Failed to fetch blacklists:", error);
            window.notyf.open({
                type: "danger",
                message: "Failed to fetch blacklists",
                duration: 4000
            });
        }
    }

	const handleSelect = (blacklistId) => {
		setBlacklists((prevBlacklists) =>
			prevBlacklists.map((blacklist) => {
				if (blacklist.id === blacklistId) {
					return { ...blacklist, isSelected: !blacklist.isSelected };
				}
				return blacklist;
			})
		);
	};

	const handleSelectAll = () => {
		const areAllSelected = blacklists.every((blacklist) => blacklist.isSelected);
		setBlacklists((prevBlacklists) =>
			prevBlacklists.map((blacklist) => ({
				...blacklist,
				isSelected: !areAllSelected,
			}))
		);
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	const handleDeleteSelected = async () => {
		const selectedIds = blacklists.filter((blacklist) => blacklist.isSelected).map((blacklist) => blacklist.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No blacklists selected",
				duration: 4000,
			});
			return;
		}

		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteBlacklists`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "Blacklists deleted successfully",
				duration: 4000,
			});
			setBlacklists((prev) => prev.filter((blacklist) => !selectedIds.includes(blacklist.id)));
		} catch (error) {
			console.error("Failed to delete blacklists:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to delete blacklists",
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Blacklists</h1>
							<div>
								<Link to="/panel/admin/manage-blacklists/add" className="btn btn-primary rounded-pill">
									<i className="fa fa-plus"></i> New
								</Link>
								<button onClick={handleDeleteSelected} className="btn btn-danger rounded-pill ms-2">
									<i className="fa-solid fa-trash"></i> Delete Selected
								</button>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>
															<input
																onChange={handleSelectAll}
																checked={blacklists.length > 0 && blacklists.every((blacklist) => blacklist.isSelected)}
																type="checkbox"
																className="form-check-input"
															/>
														</th>
														<th>ID</th>
														<th>For User ID</th>
														<th>Type</th>
														<th>Value</th>
														<th>Manage</th>
													</tr>
												</thead>
												<tbody>
													{blacklists.length > 0 && blacklists.map((blacklist) => (
														<tr key={blacklist.id}>
															<td>
																<input checked={blacklist.isSelected || false} onChange={() => handleSelect(blacklist.id)} type="checkbox" className="form-check-input" />
															</td>
															<td>{blacklist.id}</td>
															<td>{blacklist.userID} {blacklist.userIDUsername ? <Link to={`/panel/admin/manage-users/${blacklist.userID}`}><span className="badge bg-primary">{blacklist.userIDUsername} <i className="fa-solid fa-link"></i></span></Link> : ""}</td>
															<td>{blacklist.blacklistType}</td>
															<td>{blacklist.blacklistValue}</td>
															<td>
																<Link to={`/panel/admin/manage-blacklists/edit/${blacklist.id}`} className="btn btn-primary">
																	<i className="fa-solid fa-edit"></i>
																</Link>
															</td>
														</tr>
													))}
													{blacklists.length === 0 && (
														<tr>
															<td colSpan="6" className="text-center">No blacklists found</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<footer className="footer bg-light w-100">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span>
													Showing {Math.min((currentPage - 1) * 10 + 1, blacklists.length)}-{Math.min(currentPage * 10, blacklists.length)} of {blacklists.length} results
												</span>
											</div>
											<nav className="w-100">
												<ul className="pagination">
													{/* Previous Page Button */}
													<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
															Previous
														</button>
													</li>
													{/* Current Page Button */}
													{totalPages > 0 &&
														Array.from({ length: totalPages }, (_, i) => (
															<li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
																<button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
																	{i + 1}
																</button>
															</li>
														))}
													{/* Next Page Button */}
													<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
															Next
														</button>
													</li>
												</ul>
											</nav>
										</footer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
