import { useEffect } from 'react';
import initializeVectorMap from '../scripts/vectormap';
import initializeDashBar from '../scripts/dashbar';
import initializeChart from '../scripts/chartjs';

const useInitializeScripts = () => {
  useEffect(() => {
    initializeVectorMap();
    initializeDashBar();
    initializeChart();
    window.feather.replace();
  }, []);
};

export default useInitializeScripts;