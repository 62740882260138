import { useEffect } from 'react';
import feather from 'feather-icons';

const useFeatherIcons = () => {
  useEffect(() => {
    const replaceIcons = () => {
      feather.replace();
    };

    // Initial call to replace icons
    replaceIcons();

    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        replaceIcons();
      });
    });

    // Observe the entire document body for changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Cleanup the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);
};

export default useFeatherIcons;