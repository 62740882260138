import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useAuth } from "../../Hooks/useAuthentication";
import { config } from "../../config";

export default function AddNews() {
	const auth = useAuth();

	const editorRef = useRef(null); // Create a ref for the editor container
	const [isEditorInitialized, setIsEditorInitialized] = useState(false);

	const [showModal, setShowModal] = useState(false); // State to control modal visibility
	const [modalContent, setModalContent] = useState(""); // State to hold the HTML content for the modal
	const [previousModalContent, setPreviousModalContent] = useState(null);

	const [news, setNews] = useState({
		title: "",
		content: "",
	});

	useEffect(() => {
		if (!news) return;

		console.log("Effect running", { isEditorInitialized, currentRef: editorRef.current });

		if (isEditorInitialized) {
			console.log("Editor already initialized");
			return;
		}

		const initializeEditor = () => {
			if (!editorRef.current) {
				console.log("Editor ref not available yet, retrying...");
				setTimeout(initializeEditor, 50); // Retry after a short delay
				return;
			}

			console.log("Initializing editor");

			const editor = window.pell.init({
				element: editorRef.current,
				onChange: (html) => {
					setNews((prevNews) => ({ ...prevNews, content: html }));
				},
				defaultParagraphSeparator: "p",
				styleWithCSS: true,
				actions: [
					"bold",
					"underline",
					"italic",
					"link",
					"image",
					"quote",
					"code",
					"heading1",
					"heading2",
					"paragraph",
					{
						name: "changeColor",
						icon: "C",
						title: "Change Color",
						result: () => {
							const input = document.createElement("input");
							input.type = "color";
							input.value = "#000000"; // Default color
							input.onchange = (e) => {
								window.pell.exec("foreColor", e.target.value);
							};
							input.click(); // Trigger the color picker
						},
					},
					{
						name: "indent",
						icon: "I",
						title: "Indent",
						result: () => window.pell.exec("indent", 1, true),
					},
					// Add action to edit image size
					{
						name: "resizeImage",
						icon: "<b>S</b>", // Use a bold 'S' as the icon
						title: "Resize Image",
						result: () => {
							let selection = window.getSelection();
							if (!selection.rangeCount) {
								console.log("No selection found", selection);
								return;
							}

							// Directly accessing the first child of the focusNode
							let img = selection.focusNode.firstChild;

							if (img && img.tagName === "IMG") {
								const newSize = prompt("Enter new width x height (e.g., 640x480):");
								if (newSize) {
									const [width, height] = newSize.split("x");
									img.style.width = `${width}px`;
									img.style.height = `${height}px`;
								}
							} else {
								alert("Please select an image.");
							}
						},
					},
					{
						name: "editHTML",
						icon: "<b>H</b>", // Use a bold 'H' as the icon
						title: "Edit HTML",
						result: () => {
							const selection = window.getSelection();
							if (selection.rangeCount > 0) {
								const range = selection.getRangeAt(0);
								const container = document.createElement("div");
								container.appendChild(range.cloneContents());
								setModalContent(container.innerHTML); // Set the selected HTML content to modal
								setPreviousModalContent(container.innerHTML);
							} else {
								setModalContent(editorRef.current.innerHTML); // Fallback to the entire editor content if no selection
							}
							setShowModal(true); // Open the modal
						},
					},
				],
			});

			setIsEditorInitialized(true);

			console.log(`news content: ${news.content}`);

			// To change the editor's content:
			editor.content.innerHTML = news.content;

			// Cleanup function to prevent memory leaks
			return () => {
				if (editor.destroy) {
					editor.destroy();
				}
				setIsEditorInitialized(false);
			};
		};

		initializeEditor();
	}, [news]); // Make sure this effect runs only once

	const handleSubmit = async (event) => {
		event.preventDefault(); // Prevent the default form submission behavior

		const token = auth.token; // Assuming token is stored in auth context
		const username = auth.username; // Assuming username is stored in auth context

		const data = {
			content: news.content,
			title: news.title,
		};

		try {
			const response = await fetch(`${config.apiUrl}/fpanel/admin/addNews`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ token, username, data }),
			});

			if (!response.ok) {
				throw new Error("Failed to submit news");
			}

			const result = await response.json();

			console.log("News added successfully:", result);
			// Optionally reset form or handle success state

			window.notyf.open({
				type: "success",
				message: "News added successfully",
				duration: 4000,
			});
		} catch (error) {
			console.error("Error submitting news:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to add news",
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage News</h1>
							<div>
								<Link to="/panel/admin/manage-news/view" className="btn btn-primary rounded-pill">
									<i className="far fa-plus"></i> Back
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-body">
										<form onSubmit={handleSubmit}>
											<div className="mb-3">
												<label htmlFor="newsTitle" className="form-label">
													Title
												</label>
												<input
													type="text"
													className="form-control"
													id="newsTitle"
													value={news.title || ""}
													onChange={(e) => setNews((prevNews) => ({ ...prevNews, title: e.target.value }))}
												/>
											</div>
											<div className="mb-3">
												<div id="editorjs" className="pell" ref={editorRef}></div>
											</div>
											<button type="submit" className="btn btn-primary rounded-pill">
												Add News
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
