import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { config } from "../config";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../Hooks/useAuthentication";

function Login() {
	console.log(`[*] Login page visited`)

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	// For admins, 2fa code
	const [twoFACode, setTwoFACode] = useState(""); // New state for 2FA code
	const [is2FAVisible, setIs2FAVisible] = useState(false); // New state to track 2FA visibility
	const [isAdmin, setIsAdmin] = useState(false); // New state for admin status

	const history = useHistory();

	const { setAuth, clearAuth, token } = useAuth();

	const isMounted = useRef(true); // Add ref to track if component is mounted

	useEffect(() => {
		// This effect sets up the isMounted flag and cleans it up.
		return () => {
			isMounted.current = false; // Set to false when component unmounts
		};
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const response = await fetch(`${config.apiUrl}/fpanel/auth/login`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ username, password }),
		});

		if (response.ok) {
			const data = await response.json();

			console.log(`data`, data);

			if (data && data.token) {
				localStorage.setItem("token", data.token);
				localStorage.setItem("username", data.username);

				const decoded = jwtDecode(data.token);
				setAuth({
					isAuthenticated: true,
					token: data.token,
					userLevel: decoded.userLevel,
					username: data.username,
					groupChatID: decoded.groupChatID ? decoded.groupChatID : "",
					pingMethod: decoded.pingMethod ? decoded.pingMethod : "",
					...decoded,
				});

				window.notyf.open({
					type: "success",
					message: "Successfully logged in!",
					duration: 4000,
				});
				console.log(`token after: ${token}`);

				// Check if the component is still mounted before navigating
				if (isMounted.current) {
					const targetPath = decoded.userLevel === "admin" ? "/panel/admin/dashboard" : "/panel/dashboard";
					console.log("Attempting to navigate to:", targetPath);
					history.push(targetPath);
				}

			} else if(data.codeSent) {
				setIs2FAVisible(true); // Show 2FA input
				setIsAdmin(true);
				window.notyf.open({
					type: "success",
					message: "Stage completed, now enter your 2FA code!",
					duration: 4000,
				});
			} else {
				console.error("Login failed: Invalid response data");
				window.notyf.open({
					type: "danger",
					message: "Login failed!",
					duration: 4000,
				});
			}
		} else {
			console.error("Login failed");
			window.notyf.open({
				type: "danger",
				message: "Login failed!",
				duration: 4000,
			});
		}
	};

	const handle2FASubmit = async (e) => {
		e.preventDefault();
		// Logic to verify the 2FA code with the backend
		const response = await fetch(`${config.apiUrl}/fpanel/auth/login`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ twofaCode: twoFACode, username, password }),
		});

		if (response.ok) {
			var data = await response.json();

			console.log(`data 2fa login`, data);

			if(data && data.success && data.token) {

				localStorage.setItem("token", data.token);
				localStorage.setItem("username", data.username);

				const decoded = jwtDecode(data.token);
				setAuth({
					isAuthenticated: true,
					token: data.token,
					userLevel: decoded.userLevel,
					username: data.username,
					...decoded,
				});

				window.notyf.open({
					type: "success",
					message: "Successfully logged in!",
					duration: 4000,
				});

				if (isMounted.current) {
					// Navigate to admin dashboard after successful 2FA verification
					history.push("/panel/admin/dashboard");
				}

			} else if(!data.success) {
				window.notyf.open({
					type: "danger",
					message: data.message || "Invalid 2FA code!",
					duration: 4000,
				});
			}
		} else {
			// Handle 2FA verification failure
			window.notyf.open({
				type: "danger",
				message: "Invalid 2FA code!",
				duration: 4000,
			});
		}
	};

	return (
		<main className="d-flex w-100 h-100">
			<div className="container d-flex flex-column">
				<div className="row vh-100">
					<div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
						<div className="d-table-cell align-middle">
							<div className="text-center mt-4">
								<h1 className="h2">Welcome back!</h1>
								<p className="lead">Sign in to your account to continue</p>
							</div>
							<div className="card">
								<div className="card-body">
									<div className="m-sm-3">
										{/* Only show login form if 2FA is not visible */}
										{!is2FAVisible && (
											<form onSubmit={handleSubmit}>
												<div className="mb-3">
													<label className="form-label">Username</label>
													<input
														className="form-control form-control-lg"
														type="text"
														name="username"
														placeholder="Enter your username"
														value={username}
														onChange={(e) => setUsername(e.target.value)}
													/>
												</div>
												<div className="mb-3">
													<label className="form-label">Password</label>
													<input
														className="form-control form-control-lg"
														type="password"
														name="password"
														placeholder="Enter your password"
														value={password}
														onChange={(e) => setPassword(e.target.value)}
													/>
													<small>
														<Link to="/forgot-password">Forgot password?</Link>
													</small>
												</div>
												<div className="d-grid gap-2 mt-3">
													<button
														type="submit"
														className="btn btn-lg btn-primary"
													>
														Sign in
													</button>
												</div>
											</form>
										)}
										{/* Show 2FA form if user is admin */}
										{isAdmin && is2FAVisible && (
											<form onSubmit={handle2FASubmit}>
												<div className="mb-3">
													<label className="form-label">2FA Code</label>
													<input
														className="form-control form-control-lg"
														type="text"
														placeholder="Enter your 2FA code"
														value={twoFACode}
														onChange={(e) => setTwoFACode(e.target.value)}
													/>
												</div>
												<div className="d-grid gap-2 mt-3">
													<button type="submit" className="btn btn-lg btn-primary">
														Verify 2FA
													</button>
												</div>
											</form>
										)}
									</div>
								</div>
							</div>
							<div className="text-center mb-3">
								Don't have an account? <Link to="/register">Sign up</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Login;
