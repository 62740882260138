import React, { useState, useEffect } from "react";

const ManageDomainChart = ({ revenueChartData, countryChartData, domainId, mapData }) => {
	const [lineChartInstance, setLineChartInstance] = useState(null);
	const [pieChartInstance, setPieChartInstance] = useState(null);

	const [filteredChartData, setFilteredChartData] = useState({
		data: [],
		startDate: null,
		endDate: null,
	});
	const [datePickerModalOpen, setDatePickerModalOpen] = useState(false);
	const [selectedTimeframe, setSelectedTimeframe] = useState("12");

	const [labels, setLabels] = useState([]);
	const [dataCounts, setDataCounts] = useState([]);
	const [backgroundColors, setBackgroundColors] = useState([]);
	const [pieChartNotFound, setPieChartNotFound] = useState(false);

	// For the world map data
	const [mapCoordinates, setMapCoordinates] = useState([]);
	const [themeId, setThemeId] = useState(window.theme.id);
	const [mapInstance, setMapInstance] = useState(null); // State to hold the map instance
	const [selectedMapType, setSelectedMapType] = useState("hits");

    const [currentTheme, setCurrentTheme] = useState(window.theme.id); // {{ edit_1 }} State for current theme

	const getFontColorByTheme = () => {
        switch (currentTheme) { // {{ edit_2 }} Use currentTheme state
            case "greyscale":
                return "white";
            case "light":
            case "coloured":
                return "black";
            case "dark":
                return "white";
            default:
                return "black";
        }
    };

	useEffect(() => {
        const handleThemeChange = () => {
            setCurrentTheme(window.theme.id); // {{ edit_3 }} Update theme state on change
        };

        window.addEventListener("themeChange", handleThemeChange); // {{ edit_4 }} Listen for theme changes

        return () => {
            window.removeEventListener("themeChange", handleThemeChange); // Cleanup listener
        };
    }, []);

	useEffect(() => {
		if (!mapData) return;
		setMapCoordinates(mapData);
		initMap();
	}, [mapData]);

	useEffect(() => {
		if (!revenueChartData || !domainId) return;
		initChart();
	}, [revenueChartData, selectedTimeframe, currentTheme]);

	function initChart(customChartData = null, customStartDate = null, customEndDate = null) {
		if (lineChartInstance) {
			lineChartInstance.destroy(); // Destroy the existing chart instance if it exists
		}

		console.log("initChart called", revenueChartData);

		const canvas = document.getElementById("chartjs-dashboard-line");
		const ctx = canvas.getContext("2d");

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		canvas.width = canvas.offsetWidth;
		canvas.height = canvas.offsetHeight;

		let currentDate = customEndDate ? new Date(customEndDate) : new Date();
		let adjustedStartDate = customStartDate ? new Date(customStartDate) : new Date();

		// Adjust start date based on the selected timeframe or custom dates
		if (!customStartDate || !customEndDate) {
			switch (selectedTimeframe) {
				case "0.25": // 1 week
					adjustedStartDate.setDate(currentDate.getDate() - 6);
					break;
				case "1": // 1 month
					adjustedStartDate.setMonth(currentDate.getMonth() - 1);
					break;
				case "3": // 3 months
					adjustedStartDate.setMonth(currentDate.getMonth() - 3);
					break;
				case "6": // 6 months
					adjustedStartDate.setMonth(currentDate.getMonth() - 6);
					break;
				case "12": // 1 year
					adjustedStartDate.setFullYear(currentDate.getFullYear() - 1);
					break;
				default:
					adjustedStartDate.setMonth(currentDate.getMonth() - 1);
					break;
			}
		} else {
			// Custom dates are used as-is without modification
			adjustedStartDate = new Date(customStartDate);
			currentDate = new Date(customEndDate);
		}

		const labels = [];
		let date = new Date(adjustedStartDate);
		const dayDifference = (currentDate - date) / (1000 * 3600 * 24);

		// Generate labels based on the actual date range
		if (dayDifference <= 31) {
			// Generate daily labels
			while (date <= currentDate) {
				labels.push(`${date.getDate()} ${date.toLocaleString("default", { month: "long" })}`);
				date.setDate(date.getDate() + 1);
			}
		} else {
			// Generate monthly labels
			while (date <= currentDate) {
				labels.push(date.toLocaleString("default", { month: "long", year: "numeric" }));
				date.setMonth(date.getMonth() + 1);
			}
		}

		var thechartdata = customChartData || revenueChartData;
		if (filteredChartData.data.length > 0) {
			thechartdata = filteredChartData.data;
		}

		const profits = new Array(labels.length).fill(0); // Initialize profits array with zeros

		// Aggregate profits for each label
		labels.forEach((label, index) => {
			thechartdata.forEach((data) => {
				const dataDate = new Date(data.year, data.month - 1, data.day);
				const labelDate =
					dayDifference <= 31 ? `${dataDate.getDate()} ${dataDate.toLocaleString("default", { month: "long" })}` : dataDate.toLocaleString("default", { month: "long", year: "numeric" });

				if (labelDate === label) {
					// Handle null, empty, or undefined profit values
					const profit = data.profit != null && data.profit !== "" ? parseFloat(data.profit) : 0;
					profits[index] += isNaN(profit) ? 0 : Number(profit);
				}
			});
		});

		// Round each profit to two decimal places
		const roundedProfits = profits.map((profit) => Number(profit.toFixed(2)));

		const chartData = {
			labels: labels, // i want to show country flag with the country code here if its found
			datasets: [
				{
					label: "Revenue ($)",
					fill: false,
					backgroundColor: "transparent",
					borderColor: getFontColorByTheme(),
					data: roundedProfits,
					borderWidth: 2,
				},
			],
		};

		const newChartInstance = new window.Chart(ctx, {
			type: "line",
			data: chartData,
			options: {
				legend: {
					display: false,
                    labels: {
                        fontColor: getFontColorByTheme(), // {{ edit_6 }} Use dynamic color for legend
                    },
				},
				responsive: true,
				maintainAspectRatio: false,
				tooltips: {
					intersect: false,
				},
				hover: {
					intersect: false,
				},
				scales: {
					xAxes: [
						{
							scaleLabel: {
								display: false,
								labelString: "Date",
								fontColor: getFontColorByTheme(),
								fontSize: 16,
								fontFamily: "Helvetica",
							},
                            ticks: {
                                fontColor: getFontColorByTheme(), // {{ edit_8 }} Use dynamic color for x-axis ticks
                            },
						},
					],
					yAxes: [
						{
							scaleLabel: {
								display: true,
								labelString: "Revenue ($)",
								fontColor: getFontColorByTheme(),
								fontSize: 16,
								fontFamily: "Helvetica",
							},
                            ticks: {
                                fontColor: getFontColorByTheme(), // {{ edit_10 }} Use dynamic color for y-axis ticks
                            },
						},
					],
				},
			},
		});

		setLineChartInstance(newChartInstance);
	}

	const handleTimeframeChange = (event) => {
		setSelectedTimeframe(event.target.value);
		if (event.target.value !== "custom") {
			setFilteredChartData({
				data: [],
				startDate: null,
				endDate: null,
			});
		}
		if (event.target.value === "0.25") {
			// Handle 1 week timeframe
			const startDate = new Date();
			startDate.setDate(startDate.getDate() - 6); // Set start date to include today and the previous 6 days
			const endDate = new Date(); // Today's date as end date
			filterChartData(startDate, endDate);
		}
	};

	const filterChartData = (startDate, endDate) => {
		if (!revenueChartData) {
			console.error("Revenue chart data is not available.");
			return;
		}

		// Adjust endDate to include the entire day
		endDate.setHours(23, 59, 59, 999);

		const filteredData = revenueChartData.filter((data) => {
			const dataDate = new Date(data.year, data.month - 1, data.day);
			return dataDate >= startDate && dataDate <= endDate; // Use <= for endDate
		});

		console.log("filteredData", filteredData);

		if (filteredData.length > 0) {
			setFilteredChartData({
				data: filteredData,
				startDate: startDate,
				endDate: endDate,
			});
			setPieChartNotFound(false);
		} else {
			console.error("No data available for the selected date range.");
			setFilteredChartData({
				data: [],
				startDate: startDate,
				endDate: endDate,
			});
			setPieChartNotFound(true);
		}
	};

	useEffect(() => {
		if (!countryChartData) return;

		// Calculate start and end dates based on selectedTimeframe
		const startDate = filteredChartData.startDate || new Date(new Date().setMonth(new Date().getMonth() - parseInt(selectedTimeframe, 10)));
		const endDate = filteredChartData.endDate || new Date();

		// Filter countryChartData based on the dates in filteredChartData
		const filteredCountryData = countryChartData.filter((data) => {
			const dataDate = new Date(data.year, data.month - 1, data.day || 1); // Include day if available
			return dataDate >= startDate && dataDate <= endDate;
		});

		console.log("filteredCountryData PIE CHART", filteredCountryData);

		const sortedData = [...filteredCountryData].sort((a, b) => b.count - a.count).slice(0, 10);
		const otherCount = filteredCountryData.slice(10).reduce((acc, curr) => acc + Number(curr.count), 0);

		const newLabels = sortedData.map((data) => data.victim_country).filter((item) => item !== null);
		const newDataCounts = sortedData.map((data) => Number(data.count));

		if (filteredCountryData.length > 10) {
			newLabels.push("Other");
			newDataCounts.push(otherCount);
		}

		const newBackgroundColors = [window.theme.primary, window.theme.warning, window.theme.danger, "#E8EAED", "#FFC107", "#17A2B8", "#28A745", "#DC3545", "#6C757D", "#007BFF"];

		var flagLabels = newLabels.map((label) => {
			const countryCode = label;
			var labl = `${label}`;
			return {
				flagEmoji: countryCode,
				label: labl,
			};
		});

		setLabels(flagLabels);
		setDataCounts(newDataCounts);
		setBackgroundColors(newBackgroundColors);

		// Chart initialization
		if (pieChartInstance) {
			pieChartInstance.destroy();
		}

		const ctx = document.getElementById("chartjs-dashboard-pie").getContext("2d");
		const newChartInstance = new window.Chart(ctx, {
			type: "pie",
			data: {
				labels: newLabels,
				datasets: [
					{
						data: newDataCounts,
						backgroundColor: newBackgroundColors,
						borderWidth: 0,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: true,
					labels: {
						fontColor: getFontColorByTheme(),
						fontSize: 16,
						fontFamily: "Helvetica",
					},
				},
			},
		});

		setPieChartInstance(newChartInstance);
	}, [countryChartData, selectedTimeframe, filteredChartData, currentTheme]);

	useEffect(() => {
		window.flatpickr(".flatpickr-range", {
			mode: "range",
			dateFormat: "Y-m-d",
		});
	});

	useEffect(() => {
		// This effect should run when selectedTimeframe changes, to update the chart data accordingly
		if (selectedTimeframe !== "custom") {
			const monthsAgo = parseInt(selectedTimeframe, 10);
			const startDate = selectedTimeframe === "0.25" ? new Date(new Date().setDate(new Date().getDate() - 7)) : new Date(new Date().setMonth(new Date().getMonth() - monthsAgo));
			const endDate = new Date();
			filterChartData(startDate, endDate);
		}
	}, [selectedTimeframe]); // Ensure this effect is dependent on selectedTimeframe

	const handleCustomDateConfirm = () => {
		const dateRange = document.querySelector(".flatpickr-range")._flatpickr.selectedDates;
		if (dateRange.length === 2) {
			const [startDate, endDate] = dateRange;

			console.log("startDate", startDate);
			console.log("endDate", endDate);

			// Set the custom timeframe and update filtered chart data
			setSelectedTimeframe("custom");
			setFilteredChartData({
				data: [],
				startDate: startDate,
				endDate: endDate,
			});

			// Filter and initialize chart with custom date range
			filterChartData(startDate, endDate);
			initChart(null, startDate, endDate);
			setDatePickerModalOpen(false);
		} else {
			window.notyf.open({
				type: "danger",
				message: "A timeframe is required!",
				duration: 4000,
			});
		}
	};

	// --------------------------------------------- MAP SYSTEM ---------------------------------------------

	function clearMapContainer() {
		const mapContainer = document.getElementById("world_map");

		if (mapInstance && 'canvas' in mapInstance && mapContainer) {
			try {
				mapInstance.destroy()
			} catch (error) {
				console.error("Error destroying map instance:", error);
			}
			setMapInstance(null);
		}

		// Remove all child nodes from the map container
		while (mapContainer && mapContainer.lastChild) {
			mapContainer.removeChild(mapContainer.lastChild);
		}

		// Remove any elements with the class 'jvm-tooltip'
		const tooltips = mapContainer && mapContainer.getElementsByClassName("jvm-tooltip");
		if (tooltips) {
		while (tooltips[0]) {
			tooltips[0].parentNode.removeChild(tooltips[0]);
			}
		}

		console.log("Map container cleared.");
	}

	// Listen for changes in theme ID
	useEffect(() => {
		const handleThemeChange = () => {
			console.log("theme changed", window.theme.id);
			setThemeId(window.theme.id);
		};

		window.addEventListener("themeChange", handleThemeChange);

		// Cleanup listener on component unmount
		return () => {
			window.removeEventListener("themeChange", handleThemeChange);
		};
	}, []);

	const initMap = () => {
		console.log("Initializing map with type:", selectedMapType);
	
		const mapContainer = document.getElementById("world_map");
		if (!mapContainer) {
			console.log("Map container not available.");
			return;
		}

		var mapDataType = [];
		var isFound = false;
		var markers = [];
		var lines = [];
		var values = [];
	
		// Determine which data to use based on selectedMapType
		if(mapData && mapData.mapDataHits && mapData.mapDataConnects) {
			mapDataType = selectedMapType === "hits" ? mapData.mapDataHits : mapData.mapDataConnects;
			isFound = true;
		}

		if(isFound) {
			console.log("Creating new markers and lines...");
			markers = mapDataType && mapDataType.length > 0 ? mapDataType.map((data) => ({
				coords: [data.coordinates[0], data.coordinates[1]],
				name: `${data.countryName} (${data.count})`,
				style: { fill: selectedMapType === "hits" ? "red" : "yellow", r: 5 },
			})) : [];
		
			lines = mapDataType && mapDataType.length > 0 ? mapDataType
				.map((data, index) => {
					if (mapDataType[index + 1]) {
						return {
							from: `${data.countryName} (${data.count})`,
							to: `${mapDataType[index + 1].countryName} (${mapDataType[index + 1].count})`,
						};
					}
					return null;
				})
				.filter((item) => item) : [];	
		}
	
		values = markers.reduce((acc, marker) => {
			acc[marker.name] = selectedMapType === "hits" ? "Hits" : "Connects";
			return acc;
		}, {});
	
		var map = new window.jsVectorMap({
			map: "world",
			selector: "#world_map",
			zoomButtons: true,
			markers: markers,
			lines: lines,
			series: {
				markers: [{
					attribute: "fill",
					legend: {
						title: 'Legend',
					},
					scale: {
						[selectedMapType === "hits" ? "Hits" : "Connects"]: selectedMapType === "hits" ? "#dc3545" : "#ffc107",
					},
					values: values
				}]
			},
			width: "100%",
			height: "100%",
			scale: 1.5582197494676169,
			baseScale: 1.5582197494676169,
			lineStyle: {
				stroke: themeId == "light" ? "#374151" : "#fff", // Updated line color
				strokeWidth: 1.5,
				fill: "#dc3545", // Updated fill color for lines
				fillOpacity: 1,
				strokeDasharray: "10 10 10",
				animation: true,
			},
			markerStyle: {
				hover: {
					stroke: "#374151", // Updated hover stroke color
				},
				initial: {
					fill: "yellow",
				},
			},
			regionStyle: {
				initial: {
					fill: getFontColorByTheme(), // Updated initial region fill color
				},
				hover: {},
			},
			markerLabelStyle: {
				initial: {
					fill: "white",
					fontSize: 12,
					fontFamily: "Helvetica",
				},
			},
			zoomOnScroll: true,
			showTooltip: true,
			onRegionTooltipShow(event, tooltip) {
				tooltip.css({ backgroundColor: "#343a40", color: "#fff" }); // Updated tooltip background and text color
			},
			zoom: 2, // Set initial zoom level to 2
		});
	
		// I want to programmatically click .jvm-zoom-btn jvm-zoomin button
		const zoomInButton = map.container.querySelector(".jvm-zoom-btn.jvm-zoomin");
		zoomInButton.click();
	
		setMapInstance(map);
	
		console.log("Map instance created", map);
	};

	useEffect(() => {
		// Ensure the DOM is cleaned up before re-initializing the map
		clearMapContainer();

		// Delay the initialization to ensure all updates are processed
		const timeoutId = setTimeout(() => {
			initMap()
		}, 0); // Timeout set to 0 to push to end of event queue

		return () => clearTimeout(timeoutId);
	}, [themeId]); // This effect runs every time themeId changes

	useEffect(() => {
		clearMapContainer(); 
		initMap();
		// Return a cleanup function that will be called on component unmount or before re-running the effect
		return () => {
			clearMapContainer();  // Assuming clearMapContainer handles destroying the map instance
		};
	}, [selectedMapType, mapData]);

	return (
		<>
			<div className="row">
				{/* Chart for revenue */}
				<div className="col-md-8 col-xl-8 col-sm-12 col-xs-8 col-lg-8 d-flex">
					<div className="card flex-fill w-100">
						<div className="card-header">
							<div className="row">
								<div className="col-6">
									<h5 className="card-title mb-0" style={{ lineHeight: "30px" }}>
										Revenue for {domainId}
									</h5>
								</div>
								<div className="col-6">
									<div className="row">
										<div className="col-12 col-md-6">
											<select
												className="form-select d-inline-block"
												value={selectedTimeframe}
												style={{ width: "100%" }} // Changed from 70% to 100% for full width in its container
												onChange={handleTimeframeChange} // Function to handle change
											>
												<option value="0.25">1 Week</option>
												<option value="1">1 Month</option>
												<option value="3">3 Months</option>
												<option value="6">6 Months</option>
												<option value="12">1 Year</option>
												<option value="custom" disabled>
													Custom
												</option>
											</select>
										</div>
										<div className="col-12 col-md-6">
											<button
												type="button"
												className="btn btn-primary d-inline-block w-100"
												onClick={() => {
													setDatePickerModalOpen(true);
													setSelectedTimeframe("custom");
												}}
											>
												Custom
											</button>
										</div>
									</div>
									{selectedTimeframe === "custom" && datePickerModalOpen && (
										<>
											<div className="modal fadeUp show" id="datePickerModal" tabIndex={-1} style={{ display: "block" }} aria-modal="true" role="dialog">
												<div className="modal-dialog modal-dialog-centered" role="document">
													<div className="modal-content">
														<div className="modal-header">
															<h5 className="modal-title">Select Date Range</h5>
															<button
																type="button"
																className="btn-close"
																data-bs-dismiss="modal"
																aria-label="Close"
																onClick={() => setDatePickerModalOpen(false)}
															></button>
														</div>
														<div className="modal-body">
															<div className="alert alert-warning alert-outline-coloured alert-dismissible" role="alert">
																<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
																<div className="alert-icon">
																	<i className="far fa-fw fa-bell" />
																</div>
																<div className="alert-message">
																	<strong>Warning!</strong> First click is start date, second click is end date
																</div>
															</div>

															<input type="text" className="w-100 form-control flatpickr-range flatpickr-input" placeholder="Select date.." readOnly="readonly" />
														</div>
														<div className="modal-footer">
															<button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleCustomDateConfirm}>
																Confirm
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="modal-backdrop fade show"></div>
										</>
									)}
								</div>
							</div>
						</div>
						<div className="card-body pt-2 pb-3" style={{ position: "relative", height: "100%" }}>
							<div className="chart chart-sm" style={{ height: "100%" }}>
								<div className="chartjs-size-monitor">
									<div className="chartjs-size-monitor-expand">
										<div className="" />
									</div>
									<div className="chartjs-size-monitor-shrink">
										<div className="" />
									</div>
								</div>
								<canvas id="chartjs-dashboard-line" style={{ display: "block", width: "100%", height: "90%", padding: "20px" }} />
							</div>
						</div>
					</div>
				</div>

				{/* Chart for country actions */}
				<div className="col-md-4 col-xxl-4 d-flex order-1 order-xxl-4 col-sm-12">
					<div className="card flex-fill w-100">
						<div className="card-header">
							<h5 className="card-title mb-0">Countries Activity</h5>
						</div>
						<div className="card-body d-flex">
							<div className="align-self-center w-100" style={{ display: pieChartNotFound == true ? "none" : "block" }}>
								<div className="py-3">
									<div className="chart chart-xs">
										<div className="chartjs-size-monitor">
											<div className="chartjs-size-monitor-expand">
												<div className="" />
											</div>
											<div className="chartjs-size-monitor-shrink">
												<div className="" />
											</div>
										</div>
										<canvas id="chartjs-dashboard-pie" width={333} height={150} style={{ display: "block", width: 333, height: 150 }} className="chartjs-render-monitor" />
									</div>
								</div>
								<table className="table mb-0">
									<tbody>
										{labels.map(({ flagEmoji, label }, index) => (
											<tr key={index}>
												<td>
													<i className="fas fa-circle fa-fw" style={{ color: backgroundColors[index] }} />
													{flagEmoji && <span className={`rounded ms-2 fi fi-${flagEmoji.toLowerCase()}`}></span>} {label}
												</td>
												<td className="text-end">{dataCounts[index]}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
							<p style={{ display: pieChartNotFound == true ? "block" : "none" }}>No data available for the selected date range.</p>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 col-md-12 col-xxl-12 order-xxl-12">
					<div className="card w-100">
						<div className="card-header">
							<div className="card-actions float-end">
								<div className="dropdown position-relative">
									<select className="form-select" value={selectedMapType} onChange={(e) => setSelectedMapType(e.target.value)}>
										<option value="hits">Hits</option>
										<option value="connects">Connects</option>
									</select>
								</div>
							</div>
							<h5 className="card-title mb-0">Country Hit / Connect Map</h5>
						</div>
						<div className="card-body px-4">
							<div id="world_map" style={{ height: 350, backgroundColor: "transparent", textAlign: "center", width: "100%" }} className="jvm-container">
								<div className="jvm-zoom-btn jvm-zoomin">+</div>
								<div className="jvm-zoom-btn jvm-zoomout">−</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ManageDomainChart;
