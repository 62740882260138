import Menu from "../Authed/Sections/Header/Menu";
import TopBar from "../Authed/Sections/Header/TopBar";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { useAuth } from "../../Hooks/useAuthentication";

import { LineChartHelper } from "../ChartHelper/index";
import StatBoxesHelper from "../StatBoxesHelper/index";

import DomainLogsChart from "../../components/Authed/My Domains/ManageDomain/DomainLogsChart";

import { config } from "../../config";

export default function UserAnalytics() {
	const auth = useAuth();
	const { userId } = useParams();

	const [stats, setStats] = useState({});
	const [monthlyData, setMonthlyData] = useState([]);

	const getUserAnalytics = async () => {
		const response = await fetch(`${config.apiUrl}/fpanel/admin/getAllUserAnalytics`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${localStorage.getItem("token")}`,
			},
			body: JSON.stringify({
				userId: userId,
				username: auth.username,
				token: auth.token,
				userId: userId,
			}),
		});
		if (!response.ok) {
			window.notyf.open({
				type: "error",
				message: "Failed to get user analytics",
				duration: 4000,
			});
			return;
		}
		const data = await response.json();
		setStats(data?.data);

		const correctedData = {
			monthlyData: data?.data?.monthlyData.map((item) => ({ ...item })),
			username: data?.data?.user?.username,
		};

		console.log(`correctedData`, correctedData);

		setMonthlyData([correctedData]);
		console.log(`monthlyData`, data?.data?.monthlyData);
	};

	useEffect(() => {
		if (auth.loading) return;
		getUserAnalytics();
	}, [auth.loading]);

	const MakeAccountHealthCircle = ({ accountHealth, renderValue = true }) => {
		var healthPercentage = accountHealth;
		let circleClass = "rounded-circle ";

		// Determine the class based on the health percentage
		if (healthPercentage <= 20) {
			circleClass += "bg-secondary"; // Dark black/gray
		} else if (healthPercentage <= 40) {
			circleClass += "bg-danger"; // Red
		} else if (healthPercentage <= 70) {
			circleClass += "bg-warning"; // Yellow
		} else {
			circleClass += "bg-success"; // Green
		}

		return (
			<div
				className="health-circle"
				style={{ width: "10px", height: "10px", display: "inline-block" }}
			>
				<div
					className={circleClass}
					style={{ width: "100%", height: "100%" }}
				></div>
			</div>
		);
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">
								User Analytics ({stats.user?.username}){" "}
								<i
									className={`fa fa-circle text-${stats.user?.lastOnline > Math.floor(Date.now() / 1000) - 300 ? "success" : "danger"}`}
									title={`Online: ${stats.user?.lastOnline > Math.floor(Date.now() / 1000) - 300 ? "Yes" : "No"}`}
								></i>
							</h1>
							<div>
								<Link
									to="/panel/admin/manage-users/view"
									className="btn btn-primary rounded-pill"
								>
									<i className="fa-solid fa-arrow-left"></i> Back to Users
								</Link>
							</div>
						</div>

						<div className="row">
							<StatBoxesHelper
								title="Total Revenue"
								value={`$${stats.totalRevenue?.toFixed(2)}`}
								icon="dollar-sign"
							/>
							<StatBoxesHelper
								title="Total Domains"
								value={stats.totalDomains}
								icon="dollar-sign"
							/>
							<StatBoxesHelper
								title="Total Tickets"
								value={stats.totalTickets}
								icon="dollar-sign"
							/>
							<StatBoxesHelper
								title="Total Blacklists"
								value={stats.totalBlacklists}
								icon="dollar-sign"
							/>
							<StatBoxesHelper
								title="Total Reward Pot Payouts"
								value={stats.totalRewardPotPayouts}
								icon="dollar-sign"
							/>
							<StatBoxesHelper
								title="Total Connects"
								value={stats.totalConnects}
								icon="dollar-sign"
							/>
							<StatBoxesHelper
								title="Account Health"
								value={`${stats.accountHealthPercentage}%`}
								icon="dollar-sign"
								cardSize="12"
								html={
									<MakeAccountHealthCircle
										accountHealth={stats.accountHealthPercentage}
										renderValue={true}
									/>
								}
							/>{" "}
						</div>

						<div className="row">
							<StatBoxesHelper
								title="Open Tickets"
								value={stats.openTickets}
								icon="dollar-sign"
								cardSize="6"
								showTitleBadge={true}
								badgeColor="success"
								showButton={true}
								buttonText="View Tickets"
								buttonLink={`/panel/admin/manage-tickets/view?filterType=ticketStatus&filterValue=open&username=${stats.user?.username}`}
							/>
							<StatBoxesHelper
								title="Closed Tickets"
								value={stats.closedTickets}
								icon="dollar-sign"
								cardSize="6"
								showTitleBadge={true}
								badgeColor="danger"
								showButton={true}
								buttonText="View Tickets"
								buttonLink={`/panel/admin/manage-tickets/view?filterType=ticketStatus&filterValue=closed&username=${stats.user?.username}`}
							/>
						</div>

						<div className="row">
							<StatBoxesHelper
								title="Best Hitting Domain"
								value={`${stats?.bestHittingDomain?.domain} ($${stats?.bestHittingDomain?.revenue ? parseFloat(stats?.bestHittingDomain?.revenue).toFixed(2) : 0})`}
								icon="dollar-sign"
								cardSize="6"
							/>
							<StatBoxesHelper
								title="Best Hitting Design"
								value={`${stats?.bestHittingDesign?.name} ($${stats?.bestHittingDesign?.revenue ? parseFloat(stats?.bestHittingDesign?.revenue).toFixed(2) : 0})`}
								icon="dollar-sign"
								cardSize="6"
							/>
						</div>

						<div className="row">
							<div className="col-md-12 col-xl-12 col-sm-12">
								<DomainLogsChart
									actionChartData={stats?.actionChartData}
									moduleChartData={stats?.moduleChartData}
									setHeight={false}
								/>
							</div>
						</div>

						<div className="row mt-4">
							<div className="col-md-12 col-xl-12 col-sm-12">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">Monthly Revenue</h5>
									</div>
									<div className="card-body">
										<LineChartHelper
											chartData={monthlyData && monthlyData.length > 0 ? monthlyData : []}
											labelType="username"
										/>
									</div>
								</div>
							</div>
							<div className="col-md-12 col-xl-12 col-sm-12">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">User Activity Summary</h5>
									</div>
									<div className="card-body">
										<table className="table table-striped table-hover">
											<thead>
												<tr>
													<th>Action Type</th>
													<th className="text-end">Total</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Total Actions</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.totalActions}</span>
													</td>
												</tr>
												<tr>
													<td>Total Module Prompts</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.totalModulePrompts}</span>
													</td>
												</tr>
												<tr>
													<td>Total Module Rejects</td>
													<td className="text-end">
														<span className="badge bg-danger">{stats.totalModuleRejects}</span>
													</td>
												</tr>
												<tr>
													<td>Total Hits</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.totalHits}</span>
													</td>
												</tr>
												<tr>
													<td>Total Connects</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.totalConnects}</span>
													</td>
												</tr>
												<tr>
													<td>Total Approvals</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.totalApprovals}</span>
													</td>
												</tr>
												<tr>
													<td>Total Chain Switches</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.totalChainSwitches}</span>
													</td>
												</tr>
												<tr>
													<td>Total Signatures Signed</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.totalSignaturesSigned}</span>
													</td>
												</tr>
												<tr>
													<td>Total Errors</td>
													<td className="text-end">
														<span className="badge bg-danger">{stats.totalErrors}</span>
													</td>
												</tr>
												<tr>
													<td>Total Chain Switch Successes</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.totalChainSwitchSuccesses}</span>
													</td>
												</tr>
												<tr>
													<td>Total Chain Switch Rejections</td>
													<td className="text-end">
														<span className="badge bg-danger">{stats.totalChainSwitchRejections}</span>
													</td>
												</tr>
												<tr>
													<td>Total Signature Prompts</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.totalSignaturePrompts}</span>
													</td>
												</tr>
												<tr>
													<td>Total Signature Rejections</td>
													<td className="text-end">
														<span className="badge bg-danger">{stats.totalSignatureRejections}</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-4">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">Recent Actions for {stats.user?.username}'s domains</h5>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>Action Type</th>
														<th>Domain</th>
														<th>Device</th>
														<th>Wallet Type</th>
														<th>Country</th>
														<th>Total Worth</th>
														<th>Status</th>
														<th>Date</th>
													</tr>
												</thead>
												<tbody>
													{stats.recentActions &&
														stats.recentActions.length > 0 &&
														stats.recentActions.map((action, index) => (
															<tr key={index}>
																<td>{action.actionType}</td>
																<td>{action.domain}</td>
																<td>{action.victim_device}</td>
																<td>{action.victim_wallet_type}</td>
																<td>{action.victim_country}</td>
																<td>${parseFloat(action.totalWorth).toFixed(2)}</td>
																<td>
																	<span className={`badge bg-${action.overallStatus === "completed" ? "success" : "warning"}`}>{action.overallStatus}</span>
																</td>
																<td>{new Date(parseInt(action.datetime) * 1000).toLocaleString()}</td>
															</tr>
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
