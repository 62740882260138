import Menu from "../Authed/Sections/Header/Menu";
import TopBar from "../Authed/Sections/Header/TopBar";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { useAuth } from "../../Hooks/useAuthentication";

import { LineChartHelper } from "../ChartHelper/index";
import StatBoxesHelper from "../StatBoxesHelper/index";

import DomainLogsChart from "../../components/Authed/My Domains/ManageDomain/DomainLogsChart";

import { config } from "../../config";

export default function DomainAnalytics() {
	const auth = useAuth();
	const { domainId } = useParams();

	const [stats, setStats] = useState({});
	const [monthlyData, setMonthlyData] = useState([]);

	const getDomainAnalytics = async () => {
		const response = await fetch(`${config.apiUrl}/fpanel/admin/getDomainAnalytics`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			body: JSON.stringify({
				domainId: domainId,
				username: auth.username,
				token: auth.token,
			}),
		});
		if (!response.ok) {
			window.notyf.open({
				type: "error",
				message: "Failed to get user analytics",
				duration: 4000,
			});
			return;
		}
		const data = await response.json();
		setStats(data?.data);

		const correctedData = {
			monthlyData: data?.data?.monthlyData.map((item) => ({ ...item })),
			username: data?.data?.user?.username,
		};

		console.log(`correctedData`, correctedData);

		setMonthlyData([correctedData]);
		console.log(`monthlyData`, data?.data?.monthlyData);
	};

	useEffect(() => {
		if (auth.loading) return;
		getDomainAnalytics();
	}, [auth.loading]);

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">
								Domain Analytics ({stats.domainInfo?.domain})
							</h1>
							<div>
								<Link to="/panel/admin/manage-domains/view" className="btn btn-primary rounded-pill">
									<i className="fa-solid fa-arrow-left"></i> Back to Domains
								</Link>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12 col-sm-12 col-xs-12">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title">Domain Split Percentage</h5>
										<div className="d-flex align-items-center">
											<div className="flex-grow-1">
												<div className="progress" style={{ height: '25px' }}>
													<div
														className="progress-bar bg-success"
														role="progressbar"
														style={{ width: `${stats.domainInfo?.domainSplitPercentage}%` }}
														aria-valuenow={stats.domainInfo?.domainSplitPercentage}
														aria-valuemin="0"
														aria-valuemax="100"
													>
														{stats.domainInfo?.domainSplitPercentage}%
													</div>
												</div>
											</div>
											<div className="ms-3">
												<span className="h4 d-block font-weight-bold mb-0">{stats.domainInfo?.domainSplitPercentage}%</span>
												<span className="text-muted">Current Split</span>
											</div>
										</div>
										<p className="mt-2 mb-0 text-muted">
											This percentage represents how much <b>{config.siteName}</b> will take from each hit on this domain.
										</p>
									</div>
								</div>
							</div>
						</div>

                        <div className='row'>
                            <StatBoxesHelper title="Time Added" value={`${stats?.domainInfo?.timeAdded ? timeAgo(stats?.domainInfo?.timeAdded) : "N/A"}`} icon="dollar-sign" cardSize="4" />
                            <StatBoxesHelper title="Status" value={`${stats?.domainInfo?.status}`} icon="dollar-sign" cardSize="4" showTitleBadge={true} badgeColor={stats?.domainInfo?.status === "active" ? "success" : "danger"}  />
                            <StatBoxesHelper title="Design Name" value={`${stats?.domainInfo?.designName}`} icon="dollar-sign" cardSize="4" />
                        </div>


						<div className="row">
							<StatBoxesHelper title="Total $" value={`$${stats.totalRevenue ? parseFloat(stats.totalRevenue).toFixed(2) : 0}`} icon="dollar-sign" cardSize="3" />
							<StatBoxesHelper title="Total Hits" value={stats?.activitySummary?.totalHits || 0} icon="dollar-sign" cardSize="3" />
							<StatBoxesHelper title="Total Connects" value={stats?.activitySummary?.totalConnects || 0} icon="dollar-sign" cardSize="3" />
                            <StatBoxesHelper title="Last Hit" value={stats.lastHit ? timeAgo(stats.lastHit) : "N/A"} icon="dollar-sign" cardSize="3" />
						</div>

						<div className="row">
							<StatBoxesHelper title="Best Hitting Design" value={`${stats?.bestHittingDesign?.designName} ($${stats?.bestHittingDesign?.revenue ? parseFloat(stats?.bestHittingDesign?.revenue).toFixed(2) : 0})`} icon="dollar-sign" cardSize="6" />
                            <StatBoxesHelper title="Best Hitting Module" value={`${stats?.bestHittingModule?.moduleName} ($${stats?.bestHittingModule?.revenue ? parseFloat(stats?.bestHittingModule?.revenue).toFixed(2) : 0})`} icon="dollar-sign" cardSize="6" />
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-xl-12 col-sm-12">
                                <DomainLogsChart actionChartData={stats?.actionChartData} moduleChartData={stats?.moduleChartData} setHeight={false} />
                            </div>
                        </div>

						<div className="row mt-4">
							<div className="col-md-12 col-xl-12 col-sm-12">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">Monthly Revenue</h5>
									</div>
									<div className="card-body">
										<LineChartHelper chartData={monthlyData && monthlyData.length > 0 ? monthlyData : []} labelType="domain" showLegend={false} />
									</div>
								</div>
							</div>
							<div className="col-md-12 col-xl-12 col-sm-12">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">User Activity Summary</h5>
									</div>
									<div className="card-body">
										<table className="table table-striped table-hover">
											<thead>
												<tr>
													<th>Action Type</th>
													<th className="text-end">Total</th>
												</tr>
											</thead>
											<tbody>
                                            <tr>
													<td>Total Actions</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.activitySummary?.totalActions || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Module Prompts</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.activitySummary?.totalModulePrompts || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Module Rejects</td>
													<td className="text-end">
														<span className="badge bg-danger">{stats.activitySummary?.totalModuleRejects || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Hits</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.activitySummary?.totalHits || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Connects</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.activitySummary?.totalConnects || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Approvals</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.activitySummary?.totalApprovals || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Chain Switches</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.activitySummary?.totalChainSwitches || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Signatures Signed</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.activitySummary?.totalSignaturesSigned || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Errors</td>
													<td className="text-end">
														<span className="badge bg-danger">{stats.activitySummary?.totalErrors || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Chain Switch Successes</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.activitySummary?.totalChainSwitchSuccesses || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Chain Switch Rejections</td>
													<td className="text-end">
														<span className="badge bg-danger">{stats.activitySummary?.totalChainSwitchRejections || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Signature Prompts</td>
													<td className="text-end">
														<span className="badge bg-success">{stats.activitySummary?.totalSignaturePrompts || 0}</span>
													</td>
												</tr>
												<tr>
													<td>Total Signature Rejections</td>
													<td className="text-end">
														<span className="badge bg-danger">{stats.activitySummary?.totalSignatureRejections || 0}</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-4">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h5 className="card-title">Recent Actions for {stats.user?.username}'s domains</h5>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>Action Type</th>
														<th>Domain</th>
														<th>Device</th>
														<th>Wallet Type</th>
														<th>Country</th>
														<th>Total Worth</th>
														<th>Status</th>
														<th>Date</th>
													</tr>
												</thead>
												<tbody>
													{stats.recentActions &&
														stats.recentActions.length > 0 &&
														stats.recentActions.map((action, index) => (
															<tr key={index}>
																<td>{action.actionType}</td>
																<td>{action.domain}</td>
																<td>{action.victim_device}</td>
																<td>{action.victim_wallet_type}</td>
																<td>{action.victim_country}</td>
																<td>${parseFloat(action.totalWorth).toFixed(2)}</td>
																<td>
																	<span className={`badge bg-${action.overallStatus === "completed" ? "success" : "warning"}`}>{action.overallStatus}</span>
																</td>
																<td>{new Date(parseInt(action.datetime) * 1000).toLocaleString()}</td>
															</tr>
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
