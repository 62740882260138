import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../../Hooks/useAuthentication";
import { config } from "../../config";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";

export default function ManageNews() {
	const auth = useAuth();

	const [news, setNews] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	const handleSelectNews = (newsId) => {
		setNews((prevNews) =>
			prevNews.map((news) => {
				if (news.id === newsId) {
					return { ...news, isSelected: !news.isSelected };
				}
				return news;
			})
		);
	};

	const handleSelectAll = () => {
		const areAllSelected = news.every((news) => news.isSelected);
		setNews((prevNews) =>
			prevNews.map((news) => ({
				...news,
				isSelected: !areAllSelected,
			}))
		);
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	useEffect(() => {
        if(auth.loading) return;

		const fetchUsers = async (currentPage) => {
			if (auth.loading) return;
			try {
				const response = await fetch(`${config.apiUrl}/fpanel/admin/getNews`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${auth.token}`,
					},
					body: JSON.stringify({
						token: auth.token,
						username: auth.username,
						page: currentPage,
					}),
				});

				const data = await response.json();
				if (data.success) {
					const newsWithSelection = data.data.map(item => ({
						...item,
						isSelected: false // Initialize isSelected for each news item
					}));
					setNews(newsWithSelection);
					setTotalPages(data.totalPages);
					console.log("Total Pages:", data.totalPages); // Debugging
				} else {
					console.error("API did not return success:", data.message);
				}
			} catch (error) {
				console.error("Failed to fetch news:", error);
				window.notyf.open({
					type: "danger",
					message: "Failed to get news",
					duration: 4000,
				});
			}
		};

		fetchUsers(currentPage);
	}, [auth.token, auth.loading, auth.username, currentPage]);

	const makeUserLevelBadge = (level) => {
		switch (level) {
			case "user":
				return <span className="badge bg-primary">User</span>;
			case "admin":
				return <span className="badge bg-danger">Admin</span>;
			default:
				return <span className="badge bg-warning">{level}</span>;
		}
	};

	const handleDeleteSelected = async () => {
		const selectedIds = news.filter((news) => news.isSelected).map((news) => news.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No news selected",
				duration: 4000,
			});
			return;
		}
		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteNews`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "News deleted successfully",
				duration: 4000,
			});
			setNews((prev) => prev.filter((news) => !selectedIds.includes(news.id)));
		} catch (error) {
			window.notyf.open({
				type: "danger",
				message: "Failed to delete news",
				duration: 4000,
			});
		}
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage News</h1>
							<div>
								<Link to="/panel/admin/manage-news/add" className="btn btn-primary rounded-pill">
									<i className="far fa-plus"></i> Add News
								</Link>
								<button className="btn btn-danger rounded-pill ms-2" onClick={handleDeleteSelected}>
									<i className="far fa-trash-alt"></i> Delete Selected
								</button>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
                                        <div className="table-responsive">
										<table className="table table-striped table-hover">
											<thead>
												<tr>
													<th>
														<input 															onChange={handleSelectAll}
															checked={news.length > 0 && news.every((news) => news.isSelected)}
															type="checkbox"
															className="form-check-input" />
													</th>
													<th>ID</th>
													<th>Subject</th>
													<th>Content</th>
													<th>Created At</th>
                                                    <th>Author</th>
													<th>Manage</th>
												</tr>
											</thead>
											    <tbody>
                                                    {news.map((news) => (
                                                        <tr key={news.id}>
															<td>
																<input checked={news.isSelected || false} onChange={() => handleSelectNews(news.id)} type="checkbox" className="form-check-input" />
															</td>
                                                            <td>{news.id}</td>
                                                            <td style={{minWidth: "200px"}}>{news.title}</td>
                                                            <td dangerouslySetInnerHTML={{ __html: news.content }}></td>
                                                            <td style={{minWidth: "130px"}}>{news.dateAdded ? timeAgo(news.dateAdded) : "N/A"}</td>
                                                            <td style={{minWidth: "150px"}}>{news.authorUsername} {news.userLevel ? makeUserLevelBadge(news.userLevel) : "N/A"}</td>
                                                            <td>
                                                                <Link to={`/panel/admin/manage-news/edit/${news.id}`} className="btn btn-primary"><i className="fa-solid fa-edit"></i></Link>
                                                            </td>
                                                        </tr>
                                                    ))}
												</tbody>
											</table>
										</div>
                                        <footer className="footer bg-light w-100">
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <span>
                                                Showing {Math.min((currentPage - 1) * 10 + 1, news.length)}-{Math.min(currentPage * 10, news.length)} of {news.length} results
                                            </span>
                                        </div>
                                        <nav className="w-100">
                                            <ul className="pagination">
                                                {/* Previous Page Button */}
                                                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                                        Previous
                                                    </button>
                                                </li>
                                                {/* Current Page Button */}
                                                {totalPages > 0 &&
                                                    Array.from({ length: totalPages }, (_, i) => (
                                                        <li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
                                                            <button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
                                                                {i + 1}
                                                            </button>
                                                        </li>
                                                    ))}
                                                {/* Next Page Button */}
                                                <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </footer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
