import TopBar from "../Authed/Sections/Header/TopBar";
import Menu from "../Authed/Sections/Header/Menu";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { config } from "../../config";
import { timeAgo } from "../../Helpers/epochTimeAgo";
import { useAuth } from "../../Hooks/useAuthentication";
import { deleteMultipleRows } from "../../Helpers/deleteMultipleRows";
import { copyToClipboard } from "../../Helpers/copyToClipboard";
import { truncateText } from "../../Helpers/truncateText";
import { getStyledUsername, useXPSystem } from "../../Hooks/useXPSystem";
import { useLocation } from "react-router-dom"; // Import useLocation

// Charts and Stat Boxes
import StatBoxesHelper from "../StatBoxesHelper";

export default function ManageTickets() {
    const location = useLocation(); // Get location object
    const queryParams = new URLSearchParams(location.search); // Parse query parameters
	const auth = useAuth();

	const [tickets, setTickets] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const [stats, setStats] = useState([]);

	const [usernameFilter, setUsernameFilter] = useState('');

    // Set initial filter states based on URL parameters or default values
    const [filterType, setFilterType] = useState('all');
    const [filterValue, setFilterValue] = useState('');

	useEffect(() => {
		setFilterType(queryParams.get('filterType') ? queryParams.get('filterType') : 'all');
		setFilterValue(queryParams.get('filterValue') || '');
		setUsernameFilter(queryParams.get('username') || '');
	}, []);

    const fetchTickets = useCallback(async (page) => {
        if (!auth.token || !auth.username || auth.loading) return;
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/admin/getTickets`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${auth.token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: auth.token,
                    username: auth.username,
                    page: page,
                    filterType: filterType,
                    filterValue: filterValue,
					usernameFilter: usernameFilter
                }),
            });
            const data = await response.json();
			if(data.success) {
				setTickets(data.data);
				setTotalPages(data.totalPages);
				setStats(data?.statData);
			}
            setStats(data?.statData);
        } catch (error) {
            console.error("Failed to fetch tickets:", error);
            window.notyf.open({
                type: "danger",
                message: "Failed to fetch tickets",
                duration: 4000,
            });
        }
    }, [auth.token, auth.username, filterType, filterValue, usernameFilter]);

    const handlePageChange = useCallback((newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            fetchTickets(newPage);
        }
    }, [totalPages, fetchTickets]);

    useEffect(() => {
        fetchTickets(currentPage);
    }, [fetchTickets, currentPage]);


	const handleSelect = (ticketId) => {
		setTickets((prevTickets) =>
			prevTickets.map((ticket) => {
				if (ticket.id === ticketId) {
					return { ...ticket, isSelected: !ticket.isSelected };
				}
				return ticket;
			})
		);
	};

	const handleSelectAll = () => {
		const areAllSelected = tickets.every((ticket) => ticket.isSelected);
		setTickets((prevTickets) =>
			prevTickets.map((ticket) => ({
				...ticket,
				isSelected: !areAllSelected,
			}))
		);
	};

	const handleDeleteSelected = async () => {
		const selectedIds = tickets.filter((ticket) => ticket.isSelected).map((ticket) => ticket.id);
		if (selectedIds.length === 0) {
			window.notyf.open({
				type: "danger",
				message: "No tickets selected",
				duration: 4000,
			});
			return;
		}

		try {
			await deleteMultipleRows(`${config.apiUrl}/fpanel/admin/deleteTickets`, selectedIds, auth.token, auth.username);
			window.notyf.open({
				type: "success",
				message: "Tickets deleted successfully",
				duration: 4000,
			});
			setTickets((prev) => prev.filter((ticket) => !selectedIds.includes(ticket.id)));
		} catch (error) {
			console.error("Failed to delete tickets:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to delete tickets",
				duration: 4000,
			});
		}
	};

	const makeTicketStatusBadge = (status) => {
		switch (status) {
			case "open":
				return <span className="badge bg-primary">Open</span>;
			case "closed":
				return <span className="badge bg-danger">Closed</span>;
			case "awaiting-reply":
				return <span className="badge bg-warning">Awaiting-Reply</span>;
			default:
				return <span className="badge bg-secondary">{status}</span>;
		}
	};

	const makeUserLevelBadge = (level) => {
		switch (level) {
			case "user":
				return <span className="badge bg-primary ms-2">user</span>;
			case "admin":
				return <span className="badge bg-danger ms-2">admin</span>;
			case "staff":
				return <span className="badge bg-warning ms-2">staff</span>;
			default:
				return <span className="badge bg-secondary ms-2">{level}</span>;
		}
	};

	const GetUsersLevel = ({ xp, username }) => {
		console.log(`XP`, xp);
		const { currentLevel } = useXPSystem(xp, username);
		console.log(`currentLevel`, currentLevel);
		return <span className={`${currentLevel.cssNameStyle}`}>{username}</span>;
	};

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Manage Tickets</h1>
							<div>
								<Link to="/panel/admin/manage-tickets/add" className="btn btn-primary rounded-pill">
									<i className="fa fa-plus"></i> New
								</Link>
								<button onClick={handleDeleteSelected} className="btn btn-danger rounded-pill ms-2">
									<i className="fa-solid fa-trash"></i> Delete Selected
								</button>
							</div>
						</div>

						<div className="row">
							<StatBoxesHelper title="Total Tickets" value={`${stats?.totalTickets}`} cardSize="3" icon="users" useSmallText={true} />
							<StatBoxesHelper title="Open Tickets" value={`${stats?.openTickets}`} cardSize="3" icon="users" useSmallText={true} />
							<StatBoxesHelper title="Closed Tickets" value={`${stats?.closedTickets}`} cardSize="3" icon="users" useSmallText={true} />
							<StatBoxesHelper title="Most Recent Ticket" value={`${stats?.mostRecentTicketUser}`} cardSize="3" icon="users" useSmallText={true} />
						</div>

						<div className="row">
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
										<div className="mb-3 d-flex">
											<select className="form-select me-2" style={{ width: "auto" }} value={filterType} onChange={(e) => setFilterType(e.target.value)}>
												<option value="all">All</option>
												<option value="ticketStatus">Status</option>
												<option value="subject">Subject</option>
												<option value="userID">User ID</option>
												<option value="username">Username</option>
												<option value="ticketID">Ticket ID</option>
											</select>
											{filterType !== "all" && (
												<input
													type="text"
													className="form-control"
													style={{ width: "auto" }}
													value={filterValue}
													onChange={(e) => setFilterValue(e.target.value)}
													placeholder={`Filter by ${filterType}`}
												/>
											)}
											<input
												type="text"
												className="form-control ms-2"
												style={{ width: "auto" }}
												value={usernameFilter}
												onChange={(e) => setUsernameFilter(e.target.value)}
												placeholder={`Filter by username (also)`}
											/>
										</div>

										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>
															<input
																onChange={handleSelectAll}
																checked={tickets && tickets.length > 0 && tickets.every((ticket) => ticket.isSelected)}
																type="checkbox"
																className="form-check-input"
															/>
														</th>
														<th>ID</th>
														<th>Ticket ID</th>
														<th>User ID</th>
														<th>Status</th>
														<th>Subject</th>
														<th>Message</th>
														<th>Created At</th>
														<th>Last Reply</th>
														<th style={{ minWidth: "150px" }}>Total Inner Chats</th>
														<th>Manage</th>
													</tr>
												</thead>
												<tbody>
													{tickets && tickets.length > 0 &&
														tickets.map((ticket) => (
															<tr key={ticket.id}>
																<td>
																	<input checked={ticket.isSelected || false} onChange={() => handleSelect(ticket.id)} type="checkbox" className="form-check-input" />
																</td>
																<td>{ticket.id}</td>
																<td style={{ minWidth: "300px" }}>
																	<span className="badge bg-primary">{ticket.ticketID}</span>{" "}
																	<i className="fa-solid fa-copy ms-2" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(ticket.ticketID)}></i>
																</td>
																<td style={{ minWidth: "150px" }}>
																	{ticket.userID}{" "}
																	{ticket.username ? (
																		<>
																			<span className="badge bg-primary">{ticket.username}</span>
																			<Link to={`/panel/admin/manage-users/${ticket.userID}`}>
																				<i className="fa-solid fa-link ms-2"></i>
																			</Link>
																		</>
																	) : (
																		""
																	)}
																</td>
																<td>{ticket.ticketStatus ? makeTicketStatusBadge(ticket.ticketStatus) : "N/A"}</td>
																<td style={{ minWidth: "200px" }}>{ticket.subject}</td>
																<td style={{ minWidth: "200px" }}>{ticket.message ? truncateText(ticket.message, 100) : "N/A"}</td>
																<td style={{ minWidth: "150px" }}>{ticket.dateCreated ? timeAgo(ticket.dateCreated) : "N/A"}</td>
																<td style={{ minWidth: "150px" }}>
																	{ticket.lastReplyFrom ? (
																		<>
																			<GetUsersLevel xp={ticket.usersXP} username={ticket.lastReplyFrom} />
																			{ticket.lastReplyUserLevel ? makeUserLevelBadge(ticket.lastReplyUserLevel) : "N/A"}
																			{ticket.lastReplyDate ? (
																				<span className="ms-2 d-inline-block rounded badge bg-primary mt-2">{timeAgo(ticket.lastReplyDate)}</span>
																			) : (
																				""
																			)}
																		</>
																	) : (
																		"N/A"
																	)}
																</td>{" "}
																<td>{ticket.chatCount}</td>
																<td style={{ minWidth: "150px" }}>
																	<Link to={`/panel/admin/manage-tickets/edit/${ticket.id}`} className="btn btn-primary">
																		<i className="fa-solid fa-edit"></i>
																	</Link>
																	<Link to={`/panel/admin/manage-tickets/view/${ticket.id}`} className="btn btn-primary ms-2">
																		<i className="fa-solid fa-eye"></i>
																	</Link>
																</td>
															</tr>
														))}
													{tickets.length === 0 && (
														<tr>
															<td colSpan="6" className="text-center">
																No tickets found
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<footer className="footer bg-light w-100">
											<div className="d-flex justify-content-between align-items-center mb-2">
												<span>
													Showing {Math.min((currentPage - 1) * 10 + 1, tickets.length)}-{Math.min(currentPage * 10, tickets.length)} of {tickets.length} results
												</span>
											</div>
											<nav className="w-100">
												<ul className="pagination">
													{/* Previous Page Button */}
													<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
															Previous
														</button>
													</li>
													{/* Current Page Button */}
													{totalPages > 0 &&
														Array.from({ length: totalPages }, (_, i) => (
															<li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
																<button className="page-link" onClick={() => handlePageChange(i + 1)} disabled={currentPage === i + 1}>
																	{i + 1}
																</button>
															</li>
														))}
													{/* Next Page Button */}
													<li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
														<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
															Next
														</button>
													</li>
												</ul>
											</nav>
										</footer>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
}
